import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';

const WorkLoader = () => {
  return (
    <>
    <Container className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold">
      <Row className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold pointer">
        <Col>
          <Row>
            <Col>
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="skeleton-row">
                <div className="skeleton skeleton-img company-logo"></div>
                <div className="skeleton skeleton-text-img"></div>
              </div>
            </Col>
            <Col sm={9.5} className="d-inline-flex align-items-center">
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold pointer">
        <Col>
          <Row>
            <Col>
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="skeleton-row">
                <div className="skeleton skeleton-img company-logo"></div>
                <div className="skeleton skeleton-text-img"></div>
              </div>
            </Col>
            <Col sm={9.5} className="d-inline-flex align-items-center">
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold pointer">
        <Col>
          <Row>
            <Col>
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="skeleton-row">
                <div className="skeleton skeleton-img company-logo"></div>
                <div className="skeleton skeleton-text-img"></div>
              </div>
            </Col>
            <Col sm={9.5} className="d-inline-flex align-items-center">
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold pointer">
        <Col>
          <Row>
            <Col>
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="skeleton-row">
                <div className="skeleton skeleton-img company-logo"></div>
                <div className="skeleton skeleton-text-img"></div>
              </div>
            </Col>
            <Col sm={9.5} className="d-inline-flex align-items-center">
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold pointer">
        <Col>
          <Row>
            <Col>
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="skeleton-row">
                <div className="skeleton skeleton-img company-logo"></div>
                <div className="skeleton skeleton-text-img"></div>
              </div>
            </Col>
            <Col sm={9.5} className="d-inline-flex align-items-center">
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
        </Col>
      </Row>
      </Container>
    </>

  );
};
export default WorkLoader;
