import { useState } from 'react';
import { Container, Row, Col, InputGroup, Form } from 'react-bootstrap';
import NavBar from '../../shared/menus/navBar';
import '../css/viewCartInfoPage.css';
import { Link } from '@reach/router';
import '../css/viewCartPage.css';
import { useCart } from '../../context/cartContext';
import { GrantImage } from '../../shared/images';
import { formatPrefix, formatTrailingCurrency } from '../../shared/utils';
import { toast } from 'react-toastify';
import ConstructingTransactionModal from '../../shared/modals/constructingTransactionModal';
import {
  generateDonationTX,
  submitDonateTx,
  sendAdaToCustodialWallet,
  addToDonationQueue,
  injectSignature,
} from '../../api/crypto';
import { navigate } from '@reach/router';
import { useGrant } from '../../context/grantsContext';

export default function ViewCartInfoPage() {
  const {
    cart,
    removeItem,
    updateItem,
    isValidCart,
    cardanoPubKeyAddress,
    connectedWalletName,
    cryptoAccounts,
    accounts,
    saveCart,
    isStaging,
  } = useCart();
  const { getAllGrants } = useGrant();
  const [loading, setLoading] = useState(false);
  const [networkBusy, setNetworkBusy] = useState(false);
  const [queueModalLoading, setQueueModalLoading] = useState(false);
  const [editInputValue, setEditInputValue] = useState({
    edit: false,
    elemId: null,
  });

  const handleEditClick = (e) => {
    setEditInputValue({
      ...editInputValue,
      edit: !editInputValue.edit,
      elemId: parseInt(e.target.id),
    });
  };

  const createCardanoDonation = async (cart) => {
    try {
      if (!connectedWalletName) {
        toast('Please connect a wallet');
        return;
      }
      setNetworkBusy(false);
      setLoading(true);
      const walletApi = await window.cardano[
        connectedWalletName.toLowerCase()
      ].enable();
      const changeAddress = await walletApi.getChangeAddress();
      const rawUtxos = await walletApi.getUtxos();
      const donateTx = await generateDonationTX(
        cart,
        changeAddress,
        rawUtxos,
        isStaging
      );
      if (donateTx.networkBusy) {
        setNetworkBusy(true);
        return;
      }
      const vKeyWitnesses = await walletApi.signTx(donateTx, true);
      const txHash = await submitDonateTx(
        vKeyWitnesses,
        donateTx,
        cart,
        isStaging
      );
      if (txHash.networkBusy) {
        setNetworkBusy(true);
        return;
      }
      await getAllGrants();
      toast('Transaction submitted');
      navigate('/grants');
      removeItem(cart[0]);
      return txHash;
    } catch (error) {
      toast(error?.error || "Couldn't send Ada Transaction");
      setLoading(false);
    }
  };

  // estimated transaction fee is .43 ADA
  const handleDonationQueue = async () => {
    try {
      setQueueModalLoading(true);
      const walletApi = await window.cardano[
        connectedWalletName.toLowerCase()
      ].enable();
      const rawUTxOs = await walletApi.getUtxos();
      const changeAddress = await walletApi.getChangeAddress();
      const sendToAddressAmount = parseInt(cart[0].amount) * 1000000 + 430000;
      const builtTransaction = await sendAdaToCustodialWallet(
        sendToAddressAmount,
        rawUTxOs,
        cardanoPubKeyAddress,
        changeAddress,
        isStaging
      );
      const vKeyWitness = await walletApi.signTx(builtTransaction, true);
      const signedTx = await injectSignature(vKeyWitness, builtTransaction);
      await walletApi.submitTx(signedTx);
      await addToDonationQueue(cart, isStaging);
      navigate(`/grants`);
      toast('Adding to queue');
      removeItem(cart[0]);
    } catch (error) {
      toast('Failed to add to queue');
      setQueueModalLoading(false);
    }
  };

  const handleTryLater = () => {
    setLoading(false);
    setNetworkBusy(false);
  };

  return (
    <div>
      <NavBar />
      <h1 className="h1 m-4">Cart</h1>
      <Container fluid className="cart-bg px-5">
        {cart.map((cartItem, index) => {
          return (
            <div key={index}>
              <Row>
                <Col xs={12} sm={12} md={4}>
                  <Row>
                    <Col
                      xs={'auto'}
                      sm={'auto'}
                      md={'auto'}
                      className="cart-align-left pb-2"
                    >
                      <img
                        className="cart-img"
                        src={GrantImage(cartItem.grant)}
                        alt="..."
                      />
                    </Col>
                    <Col xs={8} sm={8} md={8}>
                      <Row className="cart-font cart-name primary bold">
                        {cartItem.grant?.name}
                      </Row>
                      <Row className="cart-font small-text cart-small-text">
                        {cartItem.grant?.companyName}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                {editInputValue.edit === true &&
                index === editInputValue.elemId ? (
                  <Col xs={12} sm={12} md={{ span: 4, offset: 4 }}>
                    <Row>
                      <Col xs={5} sm={5} md={5}>
                        <Row className="cart-font small-text cart-small-text bold">
                          Select Wallet
                        </Row>
                        <Row>
                          <select
                            onChange={(event) => {
                              updateItem(cartItem, {
                                payment: accounts.find(
                                  (x) => x.id === event.target.value
                                ),
                              });
                            }}
                            className="cart-font small-text inputbg cart-select-box cart-small-text"
                            id="select-wallet"
                            defaultValue={cartItem.payment?.id}
                          >
                            <option value="">Select</option>
                            {cryptoAccounts.length > 0 &&
                              cryptoAccounts.map((account, idx) => (
                                <option value={account._id} key={`${idx}`}>
                                  {`${
                                    account.walletName
                                  } ${account.walletAddress.slice(
                                    0,
                                    4
                                  )}...${account.walletAddress.slice(-6)}`}
                                </option>
                              ))}
                          </select>
                        </Row>
                      </Col>
                      <Col
                        xs={{ span: 5, offset: 2 }}
                        sm={{ span: 5, offset: 2 }}
                        md={{ span: 5, offset: 2 }}
                      >
                        <Row className="cart-font small-text cart-small-text bold">
                          Amount
                        </Row>
                        <Row>
                          <InputGroup className="cart-align cart-select-box">
                            <Form.Control
                              onChange={(event) => {
                                updateItem(cartItem, {
                                  amount: event.target.value,
                                });
                              }}
                              className="cart-font cart-select-box cart-input-text primary cart-input"
                              id="amount"
                              type="number"
                              min="0"
                              value={cartItem.amount}
                            />
                            <InputGroup.Text className="cart-font small-text cart-input-text cart-input-size">
                              ₳DA
                            </InputGroup.Text>
                          </InputGroup>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col
                    xs={12}
                    sm={12}
                    md={{ span: 4, offset: 4 }}
                    className="cart-align"
                  >
                    <span className="d-flex justify-content-end">
                      <div className="cart-font small-text cart-info-text">
                        {'Payment Method: '}
                      </div>
                      <div className="cart-font small-text cart-info-text bold">
                        {cartItem.payment?.name}
                      </div>
                    </span>
                    <span className="d-flex justify-content-end cart-font cart-info-amount">
                      {formatPrefix(cartItem.grant)}
                      {cartItem.amount} {formatTrailingCurrency(cartItem.grant)}
                    </span>
                  </Col>
                )}
              </Row>
              <Row className="row-border mb-4 pb-2">
                <Col
                  className="cart-align cart-font cart-remove-btn bold pt-3"
                  type="button"
                  value="Remove"
                  onClick={() => {
                    removeItem(cartItem);
                  }}
                >
                  Remove
                </Col>
                <Col
                  onClick={handleEditClick}
                  className="cart-font bold cart-align cart-save-edit-btn text-end"
                  id={index}
                  type="button"
                  value={
                    editInputValue.edit === true &&
                    index === editInputValue.elemId
                      ? 'Save'
                      : 'Edit'
                  }
                >
                  {editInputValue.edit === true &&
                  index === editInputValue.elemId
                    ? 'Save'
                    : 'Edit'}
                </Col>
              </Row>
            </div>
          );
        })}
      </Container>
      <div className="d-flex justify-content-end mx-3">
        <Link
          className="cart-font cart-back-to-grants small-text cart-small-text bold underline px-4"
          to="/grants"
        >
          Back To Grants
        </Link>
        <ConstructingTransactionModal
          showModal={loading}
          networkBusy={networkBusy}
          queueModalLoading={queueModalLoading}
          handleTryLater={handleTryLater}
          handleTransactionQueue={handleDonationQueue}
        />
        <button
          className={
            editInputValue.edit || !isValidCart
              ? 'btn-primary cart-confirm-btn cart-confirm-disable cart-small-text'
              : 'btn-primary cart-confirm-btn cart-small-text'
          }
          onClick={(e) => {
            if (!isValidCart || editInputValue.edit) e.preventDefault();
            saveCart();
            createCardanoDonation(cart);
          }}
        >
          Purchase
        </button>
      </div>
    </div>
  );
}
