import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap';

import '../../shared/css/typography.css';
import '../css/grantsList.css';
import '../../shared/css/buttonStyles.css';
import GrantClock from '../../assets/icon/snapbrillia_update_status_icon.svg';
import GrantFlag from '../../assets/icon/snapbrillia_goal_flag_icon.svg';
import { useCart } from '../../context/cartContext';
import { GrantImage } from '../../shared/images';
import parse from 'html-react-parser';
import {
  formatGrantCurrentlyRaised,
  formatPrefix,
  formatTrailingCurrency,
  timeAgo,
} from '../../shared/utils';
import { isInactiveGrants } from '../../shared/utils';
import { getGrantsRatio } from '../../shared/utils';
import { navigate } from '@reach/router';
import { useGrant } from '../../context/grantsContext';
import { useAuth } from '../../context/authContext';

const GrantsList = () => {
  const [grantsCopy, setGrantsCopy] = useState([]);
  const { cart, addItem } = useCart();
  const { auth } = useAuth();
  const { grantId, grantFilterQuery, loading, grantsList } = useGrant();

  const userId = auth?.user?._id;

  const fieldSorter = (fields, a, b) => {
    const field = fields[0];
    if (field === 'createdAt' || field === 'updatedAt') {
      return new Date(b[field]) - new Date(a[field]);
    }
    if (field === 'currentAmount') {
      const aRatio = getGrantsRatio(a);
      const bRatio = getGrantsRatio(b);
      return bRatio - aRatio;
    }
  };

  useEffect(() => {
    let filterData = [...grantsList];

    if (grantFilterQuery.search) {
      filterData = filterData.filter((e) =>
        e.name.toLowerCase().includes(grantFilterQuery.search)
      );
    }

    if (grantFilterQuery.matchPools && grantFilterQuery.matchPools.length) {
      const filterMatchPools = grantFilterQuery.matchPools.map(
        (x) => x.userInput
      );
      filterData = filterData.filter((e) =>
        filterMatchPools.includes(e.matchPool)
      );
    }

    if (grantFilterQuery.sort && grantFilterQuery.sort.length) {
      const sortKey = grantFilterQuery.sort
        .map((x) => x.userInput)
        .map((x) => {
          if (x === 'New') {
            return 'createdAt';
          } else if (x === 'Close to funding') {
            return 'currentAmount';
          } else if (x === 'Recently updated') {
            return 'updatedAt';
          }
        });
      filterData.sort((a, b) => fieldSorter(sortKey, a, b));
    }

    setGrantsCopy(filterData);
  }, [grantFilterQuery, grantsList]);

  return (
    <Container className="px-5 pb-5">
      {!grantId && (
        <Row>
          <span className="fs-2 text-black p-0">All Grants</span>
        </Row>
      )}
      {grantsCopy.map((e, i) => {
        const currentCart = cart?.find((element) => element.id === e._id);
        return (
          <Row
            style={{ position: 'relative', left: grantId ? '12px' : null }}
            key={i}
            className="grant-list-container p-3 mb-4 pointer"
          >
            <Col>
              <Row
                onClick={() => {
                  navigate(`/grants/${e._id}`);
                }}
              >
                <Col xs={6} sm={3} xxl={2} className="align-self-center">
                  <img
                    className="img-fluid grant-list-logo"
                    src={GrantImage(e)}
                    alt="project logo"
                  />
                </Col>
                {/* Only shows on small screens */}
                <Col xs={6} className="d-block d-sm-none">
                  <Stack gap={1} className="float-end">
                    <p className="grants-text-grey m-0">Currently Raised</p>
                    <h5 className="raised-price-text-color bold">
                      {formatGrantCurrentlyRaised(e)}
                    </h5>
                  </Stack>
                </Col>
                {/* End only shows on small screens */}
                <Col xs={12} md={6} xxl={3}>
                  <div className="grants-title-text-color bold mb-0">
                    {e.name}{' '}
                  </div>
                  <p className="grants-list-author">
                    {e.candidateId?.fullName}
                  </p>
                  <div className="grants-list-stats">
                    <p className="grey-title mb-1 ">
                      <img src={GrantFlag} alt="flag-icon" />
                      Goal:
                      <strong className="grant-list-span text-black">
                        {`${formatPrefix(e)} ${e.fundraisingAmount}`}
                      </strong>
                      <span className="featured-card-currency">
                        {' '}
                        {formatTrailingCurrency(e)}
                      </span>
                    </p>
                    <p className="grey-title mb-0">
                      <img src={GrantClock} alt="clock-icon" /> Last Updated:
                      <strong className="grant-list-span text-black">
                        {' '}
                        {timeAgo(e.updatedAt)}
                      </strong>
                    </p>
                    {isInactiveGrants(e.updatedAt) && (
                      <span
                        style={{
                          backgroundColor: 'grey',
                          color: 'white',
                          borderRadius: '5px',
                          fontSize: '12px',
                          padding: '2px 5px',
                        }}
                      >
                        Inactive
                      </span>
                    )}
                  </div>
                </Col>
                {/* Only shows on XXL >= 1440 screens */}
                <Col
                  xxl={5}
                  className="text-center align-self-center d-none d-xxl-block"
                >
                  <p className="grants-text-grey grants-text-description">
                    {parse(e.description || '')}
                  </p>
                </Col>
                {/* End only shows XXL screens */}
                <Col sm={3} xxl={2} className="d-none d-sm-block">
                  <Stack gap={1} className="text-end float-end">
                    <p className="m-0 ps-1 grants-text-grey">
                      Currently Raised
                    </p>
                    <h3 className="raised-price-text-color bold">
                      {formatGrantCurrentlyRaised(e)}
                    </h3>
                  </Stack>
                  <Stack gap={1} className="text-end float-end">
                    {getGrantsRatio(e) >= 1 && (
                      <>
                        <p
                          className="m-0 ps-1 grants-text-grey"
                          style={{ fontSize: '10px' }}
                        >
                          Estimated Match Amount
                        </p>
                        <p
                          className="raised-price-text-color bold"
                          style={{ fontSize: '20px', color: '#a900a6' }}
                        >
                          {e.matchPoolAmount} ₳DA
                        </p>
                      </>
                    )}
                  </Stack>
                </Col>
                {/* Only shows on < XL screens */}
                <Col
                  xs={12}
                  className="text-center align-self-center d-block d-xs-none d-xxl-none"
                >
                  <p className="grants-text-grey grants-text-description">
                    {parse(e.description || '')}
                  </p>
                </Col>
                {/* End only show < XL screens */}
              </Row>
              <Row>
                <Col>
                  {e.candidateId._id === userId ? (
                    ''
                  ) : (
                    <button
                      style={{
                        cursor: currentCart ? 'auto' : 'pointer',
                      }}
                      onClick={() => {
                        addItem(e);
                      }}
                      className="grants-add-to-cart-btn bold float-end"
                    >
                      {/* {currentCart ? 'In Cart' : 'Add To Cart'} */}
                      Checkout
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        );
      })}
      {loading && <h3 className="mt-2 fs-3 grey-title">Loading Grants ...</h3>}
      {grantsCopy.length === 0 && !loading && (
        <h3 className="mt-2 fs-3 grey-title">No Grants Found</h3>
      )}
    </Container>
  );
};

export default GrantsList;
