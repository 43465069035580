import axios from 'axios';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
//Components
import { Container, Col, Row } from 'react-bootstrap';
import Logo from '../assets/snapbrillia_logo.svg';
import NextButton from '../shared/button/next';
import Retakebutton from '../shared/button/retake';
import Skipbutton from '../shared/button/skip';
import Submitbutton from '../shared/button/submit';
import { eeoFields } from './eeoFields';
import EeoFormDisclaimer from './eeoFormDisclaimer';
import EeoFormDisclaimerModal from './eeoFormDisclaimerModal';
import EeoFormHeader from './eeoFormHeader';
import EeoFormStepper from './eeoFormStepper';
import EeoFormSubTitle from './eeoFormSubTitle';
import EeoSubmit from './eeoSubmit';
import { useParams, navigate, Link } from '@reach/router';
import NavBar from '../shared/menus/navBar';
import NotFound from '../shared/errors/404';
import Loading from '../shared/Overlays/loading';
import { useAuth } from '../context/authContext';
import { useBounty } from '../hooks/useBounty';

//CSS
import '../shared/css/bgColors.css';
import './css/eeoFormStyles.css';
import './css/eeoSubmitStyles.css';
import { fill, result } from 'lodash';
import { submitAssessment } from '../api/bounty';
import useViewModel from '../jobEEOForm/viewModel/eeoFormViewModel';
import { getEEOForm, submitEEOForm } from '../api/job';

export default function JobApplicantEeoForm() {
  const { id } = useParams();
  const [question, setQuestion] = useState(0);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const { auth, updateProfile } = useAuth();
  const [refill, setRefill] = useState(false);
  const [finishForm, setFinishForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formId, setFormId] = useState("");
  const [initialLoadCompleted, setInititialLoadCompleted] = useState(false);
  const {
    applicant,
    application,
    eeoForm,
    getJobApplication,
    submitForm,
    editForm,
    getEEOForm,
    eeoFormFilled,
    setEEOForm,
    submitJobApplication,
  } = useViewModel();

  const handleNext = () => {
    if (question <= 6) {
      setQuestion(question + 1);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (!initialLoadCompleted) {
      getJobApplication(id, auth?.user?._id).then((result) => {
        if (!_.isNil(result) && !_.isNil(result?.candidateId?.eeoId)) {
          getEEOForm(result.candidateId._id).then((eeoResult) => {
            setFormId(eeoResult._id)
            setQuestion(7);
            if (eeoFormFilled()) {
              setFinishForm(true);
            }
            setLoading(false);
          });
        }
        setInititialLoadCompleted(true);
      });
    }
    /*
    if (auth?.user?.filedEEO) {
      const filled = {
        gender: auth?.user?.gender || [],
        ethnicity: auth?.user?.ethnicity || [],
        age: auth?.user?.age || [],
        veteranStatus: auth?.user?.veteranStatus || [],
        impairmentStatus: auth?.user?.impairmentStatus || [],
        neurodivergence: auth?.user?.neurodivergence || [],
        degree: auth?.user?.degree || [],
      };
      setResults({ ...filled });
      setQuestion(7);
      if (
        filled.gender !== [] &&
        filled.ethnicity !== [] &&
        filled.age !== [] &&
        filled.veteranStatus &&
        filled.impairmentStatus !== [] &&
        filled.neurodivergence !== [] &&
        filled.degree !== []
      ) {
        setFinishForm(true);
      }
    }*/
  }, [auth?.user?._id, eeoFormFilled, getEEOForm, getJobApplication, id, initialLoadCompleted]);

  //handles multiple inputs, function will be used if question < 2
  const handleArray = (value, field, label) => {
    let arr = eeoForm[field];
    if (arr.length > 0) {
      arr = [];
    }
    if (arr.indexOf(value) === -1) {
      arr.push(value);
    } else {
      arr.splice(arr.indexOf(value), 1);
    }
    setEEOForm({ ...eeoForm, [field]: arr });
  };
  const _createOrUpdateEEOForm = async () => {
    let result = false;
    if (!_.isNil(applicant)) {
      const eeoFormNotFilled = _.isNil(applicant?.eeoId);
      if (eeoFormNotFilled === true) {
        await submitForm();
      } else {
        await editForm(formId);
      }
      setFinishForm(true);
      result = true;
    }
    return result;
  };
  const handleSubmit = async () => {
    const upserted = await _createOrUpdateEEOForm();
    if (upserted === true) {
      navigate(`/teams`, { state: {assessment: application._id} });
    }
  };

  const handleRetake = () => {
    setEEOForm({
      gender: [],
      ethnicity: [],
      age: [],
      veteranStatus: [],
      impairmentStatus: [],
      neurodivergence: [],
      degree: [],
    });
    setQuestion(0);
    setRefill(true);
    setFinishForm(false);
  };

  const changeQuestion = (index) => {
    //takes the index of the question and moves it to that index
    setQuestion(index);
  };

  return (
    <>
      <NavBar />
      {!initialLoadCompleted ? (
        <Loading />
      ) : !_.isEmpty(application) && !_.isEmpty(eeoForm) ? (
        application.status !== 'In-Progress' ? (
          <Container className="primarybg" fluid>
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="text-center py-5">
                <h2 className="">Job No Longer Available</h2>
                <Link to="/" className="link-home">
                  Go Home
                </Link>
              </div>
            </div>
          </Container>
        ) : (
          <Container className="primarybg vh-80" fluid>
            <Col md={12}>
              <EeoFormHeader />
              <EeoFormSubTitle />
              <EeoFormStepper
                question={question}
                changeQuestion={changeQuestion}
                className="d-none d-sm-block"
                finishForm={finishForm}
              />
              <Row
                className={
                  question <= 6 ? 'eeo-form-section' : 'eeo-submit-section'
                }
              >
                <Col md={12}>
                  {question <= 6 ? (
                    <form className="eeo-options">
                      <h3 className="eeo-question-title eeo-question semi-bold">
                        {eeoFields[question].label}
                      </h3>
                      {/* will render the eeoform with it's fields if the question is <= 6, otherwise it will render the eeosubmit, passing the selected answers down to it */}
                      {eeoFields[question]['options'].map((option, i) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          /* changes it's checked state based on whether the question is < 2, there can be multiple check states for the first two questions */
                        }
                        return (
                          <div className="eeo-option" key={i}>
                            <input
                              type="radio"
                              name={`eeo-${eeoFields[question].name}`}
                              checked={eeoForm[
                                eeoFields[question].name
                              ].includes(option.value)}
                              id={`eeo-fields${i}`}
                              value={''}
                              onChange={() =>
                                handleArray(
                                  option.value,
                                  eeoFields[question].name,
                                  option.label
                                )
                              }
                            />
                            <label
                              htmlFor={`eeo-fields${i}`}
                              className="h4 semi-bold eeo-label semi-bold"
                            >
                              {option.label}
                            </label>
                          </div>
                        );
                      })}
                    </form>
                  ) : (
                    <EeoSubmit results={eeoForm} />
                  )}
                </Col>
              </Row>
              <Row className="fixed-bottom  eeo-form-bottom-row">
                <Col lg={9} className="d-none d-lg-block">
                  <Row>
                    <Col className="d-flex align-items-end">
                      <button
                        onClick={() => setShowDisclaimer(true)}
                        className="eeo-form-disclaimer-btn "
                      >
                        Disclaimer
                      </button>
                      <div onClick={handleRetake}>
                        <Retakebutton />
                      </div>{' '}
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={3}
                  className=" d-lg-none eeo-form-disclaimer-modal-col"
                >
                  <EeoFormDisclaimerModal
                    show={showDisclaimer}
                    setShowDisclaimer={setShowDisclaimer}
                  />
                </Col>
                <Col
                  lg={3}
                  className="d-flex align-items-end eeo-form-btns justify-content-end"
                >
                  {question < 7 ? (
                    <>
                      <div className="eeo-form-btn" onClick={handleNext}>
                        <Skipbutton />
                      </div>
                      <div className="eeo-form-btn" onClick={handleNext}>
                        <NextButton />
                      </div>
                    </>
                  ) : (
                    <>
                      <div onClick={handleSubmit}>
                        <Submitbutton />
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Container>
        )
      ) : (
        <NotFound />
      )}
    </>
  );
}
