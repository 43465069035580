import Close from '../../assets/icon/snapbrillia_close_icon.svg';
import '../css/typography.css';
import '../css/feedback.css';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Button, Modal } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import * as bountyApi from '../../api/bounty';
import { useAuth } from '../../context/authContext';
import { toast } from 'react-toastify';

const closeSVGStyles = {
  position: 'absolute',
  right: '10%',
  top: '5.5%',
  transform: 'scale(1.5)',
  cursor: 'pointer',
  zIndex: '999',
};

const ratings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const FeedbackModal = ({ setShowModal }) => {
  const [newRating, setNewRating] = useState(1);
  const [comments, setComments] = useState('');
  const [emailTo, setMailTo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { auth } = useAuth();
  console.log(emailTo);

  const createFeedback = async () => {
    try {
      setLoading(true);
      const { docs } = await bountyApi.feedBack({
        rating: newRating / 10,
        comment: comments,
        fullname: auth.user.fullName,
        email: auth.user.email,
        sendEmail: emailTo,
      });
      console.log(emailTo);
      setLoading(false);
      setShowModal(false);
      return toast('Your Feedback has been sent successfully');
    } catch (error) {
      console.log(error);
      return toast('Try again later');
    }
  };

  useEffect(() => {
    if (comments.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [comments]);

  return (
    <Modal
      // backdrop="static"
      keyboard={false}
      onHide={() => setShowModal(false)}
      show={true}
      size="lg"
      fullscreen="lg-down"
    >
      <div>
        <img
          src={Close}
          alt="close-icon"
          style={closeSVGStyles}
          onClick={() => setShowModal(false)}
        />
      </div>
      <div className="feedback-modal">
        <p className="light-text">Your feedback is appreciated!</p>
        <p className="bold-text">
          How would you rate your experience on snapbrillia?
        </p>
        <div className="rating-container">
          {ratings.map((value, index) => (
            <div
              key={index}
              onClick={() => setNewRating(value * 10)}
              className="rating"
            >
              {value}
            </div>
          ))}
        </div>
        <div>
          <Box sx={{ width: '400px' }}>
            <Slider
              data-testid="slider"
              value={newRating}
              defaultValue={30}
              color="secondary"
              onChange={(e) => setNewRating(e.target.value)}
              style={{
                height: 8,
              }}
            />
          </Box>
        </div>
        <div
          style={{
            width: '100%',
            display: 'inline-flex',
          }}
        >
          <p className="small-text">Poor</p>
          <p
            className="small-text"
            style={{
              marginLeft: '80%',
            }}
          >
            Excellent
          </p>
        </div>
        <div>
          <p className="small-text-bold">
            Any comments that can help us improve ?
          </p>
          <div>
            <textarea
              data-testid="comments"
              name="comments"
              cols="20"
              rows="8"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              style={{
                borderWidth: 1,
                borderColor: disabled ? 'red' : 'green',
              }}
            ></textarea>
          </div>
        </div>
        <div
          style={{
            display: 'inline-flex',
            width: '100%',
          }}
        >
          <p className="small-text-bold">
            I would like a copy of my response emailed to me
          </p>
          <div
            className="button-slider"
            onClick={() => setMailTo(!emailTo)}
            style={{
              cursor: 'pointer',
            }}
          >
            <div
              className="btn-slide"
              style={{
                float: !emailTo ? 'left' : 'right',
                backgroundColor: !emailTo ? 'rgba(0,0,0,0.5)' : 'purple',
              }}
            />
          </div>
        </div>
        <div
          style={{
            float: 'right',
          }}
        >
          <Button
            data-testid="sub-btn"
            disabled={disabled}
            onClick={() => createFeedback()}
          >
            {!loading ? (
              'submit'
            ) : (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default FeedbackModal;
