import * as OpportunityApi from '../../api/opportunities';
import { useState, useEffect, useCallback } from 'react';
import { navigate } from '@reach/router';
import {
  formatCurrency,
  formatCurrencyRange,
  formatGigOrJobSkills,
  truncateOnWord,
  compensation,
} from '../../shared/utils';
import '../css/tooltip.css';
import '../css/ribbon.css';
// import _ from 'lodash';
//COMPONENTS
import {
  Container,
  Row,
  Col,
  Card,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import IconImage from '../../assets/icon/new.png';

//CSS
import '../../shared/css/typography.css';
import '../../shared/css/textColors.css';
import './css/allBounties.css';
import '../css/improveToolTip.css';
import { BountyImage, BountyImageErrorHandler } from '../../shared/images';
// import StripeModal from '../../shared/menus/stripeModal';
import OnLoadingBountiesOverlaps from './onLoadingBountiesOverlaps';

export default function AllBounties({ bountiesFilters }) {
  const [bounties, setBounties] = useState([]);
  const [filteredBounties, setFilteredBounties] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  // API call to GET /api/bounties
  const getAllBounties = async (filter) => {
    try {
      setLoading(true);
      const { docs, hasNextPage, totalPages } =
        await OpportunityApi.getAllOpportunities({ filter });

      if (docs) {
        setBounties(docs);
        setFilteredBounties(docs);
      }
      if (nextPage !== totalPages || nextPage < totalPages) {
        setNextPage(nextPage + 1);
      } else {
        setNextPage(null);
      }
      setLoading(false);
    } catch (error) {
      setLoadingMore(false);
    }
  };
  const getPaginationBounties = useCallback(async () => {
    try {
      setLoadingMore(true);
      const result = await OpportunityApi.getAllOpportunities({
        page: nextPage,
        filter: filterValue,
      });
      const { docs, hasNextPage, totalPages } = result;
      if (docs) {
        setFilteredBounties([...filteredBounties, ...docs]);
        setBounties([...bounties, ...docs]);
      }
      if (nextPage !== totalPages || nextPage < totalPages) {
        setNextPage(nextPage + 1);
      } else {
        setNextPage(null);
      }
      setLoadingMore(false);
    } catch (error) {
      setLoadingMore(false);
    }
  }, [bounties, filterValue, filteredBounties, nextPage]);
  //scroll to top function

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const handleScroll = useCallback(
    (event) => {
      if (
        window.innerHeight + window.pageYOffset >= document.body.offsetHeight &&
        nextPage !== null
      ) {
        getPaginationBounties();
      }
    },
    [filteredBounties]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [filteredBounties, handleScroll]);

  useEffect(() => {
    let filter = '';
    for (let key in bountiesFilters) {
      if (key === 'search' && bountiesFilters.search) {
        filter += `&search=${bountiesFilters.search}`;
      }
      if (key === 'status' && bountiesFilters.status !== null) {
        filter += `&status=${bountiesFilters.status}`;
      }
      if ((key === 'type') & (bountiesFilters.type != null)) {
        filter += `&type=${bountiesFilters.type}`;
      }
      if ((key === 'skills') & (bountiesFilters.skills != null)) {
        filter += `&skills=${bountiesFilters.skills}`;
      }
    }
    setFilterValue(filter);
    getAllBounties(filter);
  }, [bountiesFilters]);

  function sortBounties(sortChoice) {
    switch (sortChoice) {
      case 'new':
        setFilteredBounties(
          [...filteredBounties].sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
          )
        );
        break;
      case 'old':
        setFilteredBounties(
          [...filteredBounties].sort(
            (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
          )
        );
        break;
      case 'high':
        setFilteredBounties(
          [...filteredBounties].sort((a, b) => b.rewardAmount - a.rewardAmount)
        );
        break;

      case 'low':
        setFilteredBounties(
          [...filteredBounties].sort((a, b) => a.rewardAmount - b.rewardAmount)
        );
        break;

      default:
        setFilteredBounties(
          [...filteredBounties].sort(
            (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
          )
        );
    }
  }

  const tooltip = (item) => {
    return (
      <Tooltip id="tooltip">
        <ul>
          {item?.skill?.map((skill) => (
            <li key={skill}>
              <strong>{skill?.name || skill}</strong>
            </li>
          ))}
        </ul>
      </Tooltip>
    );
  };

  const tooltipText = (text) => {
    return (
      <Tooltip id="tooltip">
        <strong>{text}</strong>
      </Tooltip>
    );
  };

  const displayRibbon = (date) => {
    const newDate = new Date(date).getTime() / 1000;
    if (new Date().getTime() / 1000 - newDate < 604800) {
      return true;
    }
    return false;
  };

  return (
    <Container className="px-5 pb-5">
      {!loading && (
        <Row className="mb-2">
          <Col className="ps-0 d-flex align-items-end">
            <span className="fs-2 text-black p-0mb-1">All Gigs & Jobs</span>
          </Col>
          <Col sm={3} className="bounties-sortby-dropDown pe-0">
            <label>Sort By:</label>{' '}
            <select
              className="bg-light"
              onChange={(e) => {
                sortBounties(e.target.value);
              }}
            >
              <option value="popular">Popular</option>
              <option value="new">New</option>
              <option value="old">Old</option>
              <option value="high">High-value</option>
              <option value="low">Low-value</option>
            </select>
          </Col>
        </Row>
      )}
      {!loading ? (
        filteredBounties.map((item, index) => {
          return item.type === 'Job' ? (
            <Row
              key={index}
              className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold pointer box"
              onClick={() => {
                navigate(`/jobs/${item._id}`);
                topFunction();
              }}
              id={`${item._id}`}
            >
              {item.jobListingType === 'SuperCharge' && (
                <div className={'ribbon ribbon-top-right'}>
                  <span>
                    <i
                      className="fa-solid fa-fire"
                      style={{
                        color: 'red',
                      }}
                    ></i>
                    Hot
                  </span>
                </div>
              )}
              {displayRibbon(item.createdAt) &&
                item.jobListingType !== 'SuperCharge' && (
                  <div className={'ribbon ribbon-top-right'}>
                    <span>
                      <img
                        src={IconImage}
                        alt="new"
                        style={{
                          width: 20,
                          height: 20,
                        }}
                      />
                      New
                    </span>
                  </div>
                )}
              <Col sm={2} md={1} lg={2} xl={2} xxl={2} className="px-0">
                <Card className="rounded-3 text-white">
                  <Card.Img
                    src={BountyImage(item)}
                    onError={(e) => BountyImageErrorHandler(e)}
                    alt="Card image"
                    style={{
                      borderRadius: '50% !important',
                    }}
                  />
                  <Card.ImgOverlay className="d-flex flex-column justify-content-end">
                    <Card.Text className="p-1 position-absolute bottom-0 start-0 end-0 bg-black opacity-50 text-center">
                      <small>{item.companyId?.companyName}</small>
                    </Card.Text>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col sm={12} xs={10} md={11} lg={10}>
                <Row>
                  <Col sm={12} xs={8} md={7} lg={7}>
                    <OverlayTrigger
                      placement="top"
                      overlay={tooltipText(item?.title)}
                    >
                      <Row>
                        <Col xs={1} md={1} lg={1}>
                          <i
                            class="fa-solid fa-circle-info"
                            style={{
                              color: '#f77408',
                            }}
                          ></i>
                        </Col>
                        <Col xs={11} sm={11} md={10} lg={11}>
                          <h5 className="primary">
                            {truncateOnWord(item.title, 55)}
                          </h5>
                        </Col>
                      </Row>
                    </OverlayTrigger>
                  </Col>

                  {item.maxCompensation > 0 ? (
                    <Col sm={12} md={5} lg={5} xl={4} xxl={4}>
                      <span className="text-black-50">
                        Estimated Compensation
                      </span>
                      <h5 className="dark-purple bold mt-0">
                        {formatCurrencyRange(item)}/{compensation(item)}
                      </h5>
                    </Col>
                  ) : (
                    <Col sm={12} md={5} lg={5} xl={4} xxl={4}>
                      <span className="text-black-50">
                        Estimated Compensation
                      </span>
                      <h5 className="dark-purple bold mt-0">
                        {formatCurrency(item)}/{compensation(item)}
                      </h5>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col sm={2} md={2} lg={2} xl={2} xxl={2}>
                    <Row>
                      <Col xs={1} md={1} lg={1}>
                        <i class="fa-solid fa-briefcase"></i>
                      </Col>
                      <Col xs={2} md={2} lg={2}>
                        <h3
                          style={{
                            fontSize: 18,
                            color: '#f58505',
                          }}
                        >
                          Job
                        </h3>
                      </Col>
                    </Row>
                    <Row className="">
                      <div>
                        <span className="brand-blue teams-mentor-fs medium mx-2">
                          {item.status}
                        </span>
                      </div>
                    </Row>
                  </Col>
                  <Col sm={4} md={3} lg={2} xl={2} xxl={2} className="p-1">
                    <span className="text-black-50">Type</span>
                    <Col sm={12} lg={12} xl={12} xxl={12}>
                      <span>{item.workplaceType}</span>
                    </Col>
                  </Col>
                  <OverlayTrigger
                    placement="top"
                    overlay={tooltipText(item.location)}
                  >
                    <Col sm={4} md={3} lg={3} xl={3} xxl={3} className="pr-0">
                      <span className="text-black-50">Location</span>
                      <Row>
                        <Col xs={2} md={2} lg={2}>
                          <i class="fa-solid fa-location-dot"></i>
                        </Col>
                        <Col xs={10} md={10} lg={10}>
                          <span>{truncateOnWord(item?.location, 25)}</span>
                        </Col>
                      </Row>
                    </Col>
                  </OverlayTrigger>
                  <Col
                    sm={12}
                    md={4}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="position-relative align-items-left p-0"
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={tooltip({
                        skill: item.skillIds ? item.skillIds : [],
                      })}
                    >
                      <Row className="w-100 mt-0">
                        <Row>
                          <Col xs={1} md={1} lg={1}>
                            <i class="fa-solid fa-circle-info"></i>
                          </Col>
                          <Col>
                            <span className="text-black-50">Skills</span>
                          </Col>
                        </Row>
                        <span className="main-language">
                          {formatGigOrJobSkills(item)}
                        </span>
                      </Row>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {item.isAcceptapplicant === false ? (
                      <span className="brand-green teams-mentor-fs medium mx-2">
                        Not Actively Reviewing Applicants
                      </span>
                    ) : (
                      item.isHiring && (
                        <span className="brand-green teams-mentor-fs medium mx-2">
                          Hiring
                        </span>
                      )
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row
              key={index}
              className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold pointer box"
              onClick={() => {
                navigate(`/bounties/${item._id}`);
                topFunction();
              }}
              id={`${item._id}`}
            >
              {displayRibbon(item.createdAt) && (
                <div className={'ribbon ribbon-top-right'}>
                  <span>
                    <img
                      src={IconImage}
                      alt="new"
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    New
                  </span>
                </div>
              )}

              <Col sm={2} md={1} lg={2} xl={2} xxl={2} className="px-0">
                <Card className="rounded-3 text-white">
                  <Card.Img
                    src={BountyImage(item)}
                    onError={(e) => BountyImageErrorHandler(e)}
                    alt="Card image"
                    style={{
                      borderRadius: '50% !important',
                    }}
                  />
                  <Card.ImgOverlay className="d-flex flex-column justify-content-end">
                    <Card.Text className="p-1 position-absolute bottom-0 start-0 end-0 bg-black opacity-50 text-center">
                      <small>{item.companyId?.companyName}</small>
                    </Card.Text>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col sm={12} xs={10} md={11} lg={10}>
                <Row>
                  <Col sm={12} xs={8} md={7} lg={7}>
                    <OverlayTrigger
                      placement="top"
                      overlay={tooltipText(item.title)}
                    >
                      <Row>
                        <Col xs={1} sm={1} md={1} lg={1}>
                          <i
                            class="fa-solid fa-circle-info"
                            style={{
                              color: '#f77408',
                            }}
                          ></i>
                        </Col>
                        <Col xs={11} sm={11} md={10} lg={11}>
                          <h5 className="primary">
                            {truncateOnWord(item.title, 55)}
                          </h5>
                        </Col>
                      </Row>
                    </OverlayTrigger>
                  </Col>
                  <Col sm={12} md={4} lg={5} xl={4} xxl={4}>
                    <span className="text-black-50">
                      Estimated Compensation
                    </span>
                    <h4 className="dark-purple bold mt-0">
                      {formatCurrency(item)}
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} md={2} lg={2} xl={2} xxl={2} className="pr-3">
                    <Row>
                      <Col xs={1} md={1} lg={1}>
                        <i class="fa-solid fa-briefcase"></i>
                      </Col>
                      <Col xs={1} md={1} lg={1}>
                        <h3
                          style={{
                            fontSize: 18,
                            color: '#f58505',
                          }}
                        >
                          Gig
                        </h3>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={3} md={4} lg={4} xl={3} xxl={3} className="pr-3">
                    <span className="text-black-50">Team Preference</span>
                    <Row>
                      <Col xs={1} md={1} lg={1}>
                        <i class="fa-solid fa-location-dot"></i>
                      </Col>
                      <Col xs={10} md={10} lg={10}>
                        <span>
                          {item.teamPreference === true
                            ? 'Teams Only'
                            : 'Open to All'}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={5} md={2} lg={2} xl={2} xxl={2} className="pr-3">
                    <span className="text-black-50">Status</span>
                    <Col xs={1} md={1} lg={1}>
                      <span>{item?.status || 'Open'}</span>
                    </Col>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    xl={5}
                    xxl={5}
                    className="position-relative align-items-left pr-3"
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={tooltip({ skill: item.skillIds })}
                    >
                      <Row className="w-100 mt-0">
                        <Row>
                          <Col xs={1} md={1} lg={1}>
                            <i class="fa-solid fa-circle-info"></i>
                          </Col>
                          <Col>
                            <span className="text-black-50">Skills</span>
                          </Col>
                        </Row>
                        <span className="main-language">
                          {formatGigOrJobSkills(item)}
                        </span>
                      </Row>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <Row>
                  {item.teamIds.length > 0 && (
                    <Col sm={12} lg={6} xl={6} xxl={6}>
                      <Row className="">
                        <div>
                          {item.teamIds.length > 0 && (
                            <>
                              <span className="brand-blue teams-mentor-fs medium mx-2">
                                Mentors Available
                              </span>
                              <span className="brand-green teams-mentor-fs medium mx-2">
                                Mentees Needed
                              </span>
                            </>
                          )}
                          {item.teamPreference && (
                            <span className="brand-primary teams-mentor-fs medium mx-2">
                              Teams Only
                            </span>
                          )}
                        </div>
                      </Row>
                    </Col>
                  )}

                  <Col sm={12} lg={6} xl={6} xxl={6}>
                    <span className="brand-green teams-mentor-fs medium mx-2">
                      {' '}
                      {item.status === 'NoApplications' ||
                      item.isAcceptapplicant === false
                        ? 'Not Actively Reviewing Applicants'
                        : 'Accept Application'}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })
      ) : (
        <OnLoadingBountiesOverlaps />
      )}

      {!loading && filteredBounties.length === 0 && (
        <h3 className="fs-5 grey-title medium mt-4">No gigs were found...</h3>
      )}
      {loadingMore && <OnLoadingBountiesOverlaps />}
    </Container>
  );
}
