/* eslint-disable react/jsx-no-target-blank */
import { Row, Col } from 'react-bootstrap';

import '../css/jobDescription.css';
import Linkify from 'react-linkify';

// replace with props later
const JobDescription = ({ job }) => {
  const LinkifyWithTargetBlank = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <>
      <Row className="my-3">
        <Col>
          <strong>Description:</strong>
        </Col>
      </Row>
      {/* Uses props */}
      <Row>
        <Col className="line-break">{job.taskDescription}</Col>
      </Row>
      <Row>
        <Col className="my-3">
          <strong>Requirements:</strong>
        </Col>
      </Row>
      <Row>
        <Col className="line-break">{job.submissionRequirements}</Col>
      </Row>
      <Row>
        <Col className="my-3">
          <strong>Preferred Qualifications:</strong>
        </Col>
      </Row>
      <Row>
        <Col className="line-break">{job.acceptenceCriteria}</Col>
      </Row>
      <Row>
        <Col className="my-3">
          <strong>Important Links:</strong>
        </Col>
      </Row>
      <Row className="mb-5">
        <Linkify componentDecorator={LinkifyWithTargetBlank}>
          <Col className="line-break">{job.importantLink}</Col>
        </Linkify>
      </Row>
    </>
  );
};
export default JobDescription;
