import { Image, Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import icon from '../../assets/icon/snapbrillia_help_icon.svg'

const InfoHelper = ({ info }) => {
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">{info}</Tooltip>}
        >
            {({ ref, ...triggerHandler }) => (
                <Image ref={ref} src={icon} {...triggerHandler} />
            )}
        </OverlayTrigger>
    );
}

export default InfoHelper;