import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Individual from '../../assets/icon/snapbrillia_individual_icon.png';
import companyIcon from '../../assets/icon/snapbrillia_profile_company_icon_grey.svg';
import GrantsLink from '../../assets/icon/snapbrillia_grants_link-icon.svg';
import Clock from '../../assets/icon/snapbrillia_clock_icon.svg';
import Goal from '../../assets/icon/snapbrillia_goal_flag_icon.svg';
import Internet from '../../assets/icon/snapbrillia_internet_global_icon.svg';
import grantLogoImage from '../../assets/snapbrillia_no_company_logo.png';

import '../css/grantsMatching.css';
import GrantLogo from './grantLogo';
import { GrantSetupContext } from './grantSetup';
import InfoHelper from '../../shared/Overlays/InforHelper';

const GrantSetupMatching = ({ grant, user }) => {
  const { name, projectLogo, tags, url, fundraisingAmount, website, payment } =
    grant;

  const { setData, setCompanyLogoChanged } = useContext(GrantSetupContext);
  // const [grantLogo, setGrantLogo] = useState(
  //   projectLogo && projectLogo[0].preview
  //     ? projectLogo[0].preview
  //     : grantLogoImage
  // );

  const [grantLogo, setGrantLogo] = useState(null);

  const [site, setWebSite] = useState('');

  const handleSave = useCallback(
    (file) => {
      setCompanyLogoChanged(true);
      setData({
        ...grant,
        projectLogo: [
          Object.assign(file, {
            path: file.name,
            preview: URL.createObjectURL(file),
          }),
        ],
      });
    },
    [grant]
  );

  const handleRemoveLogo = useCallback(() => {
    setCompanyLogoChanged(true);
    setData({
      ...grant,
      projectLogo: null,
    });
  }, [grant]);

  useEffect(() => {
    const ws = website
      .replace('http://', '')
      .replace('https://', '')
      .split(/[/?#]/)[0];
    setWebSite(ws === '' ? website : ws);
  }, [website]);

  useEffect(() => {
    setGrantLogo(
      projectLogo && projectLogo[0].preview
        ? projectLogo[0].preview
        : grantLogoImage
    );
  }, [projectLogo]);

  return (
    <Container className="" fluid>
      <Row className="gx-xs-0 gx-xl-5">
        <Col xs={12} md={3} xl={2}>
          <div className="grant-matching-icon ">
            <GrantLogo
              handleSave={handleSave}
              grantLogo={grantLogo}
              handleRemoveLogo={handleRemoveLogo}
              setGrantLogo={setGrantLogo}
            />
          </div>
        </Col>
        <Col className="grant-matching-details-col" xs={12} md={9} xl={6}>
          <div className="p-3 grant-matching-container">
            <Row className="h1 grant-matching-title">{name || ''}</Row>
            <div className="grant-matching-tags">
              {tags &&
                tags.map((tag, i) => (
                  <div key={i} className="grant-matching-single-tag">
                    {tag}
                  </div>
                ))}
            </div>
            <Row className="grant-matching-details grey-title">
              <Col xs={6}>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Individual} alt="individual-icon" />
                    </span>
                    Created by: <b>{user?.fullName}</b>
                  </div>
                </Row>
                <Row className="py-1 ">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Goal} alt="goal-icon" />
                    </span>
                    Goal:{' '}
                    <b>
                      {parseInt(fundraisingAmount).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })}
                      <span className="currency"> ₳DA</span>
                    </b>
                  </div>
                </Row>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={GrantsLink} alt="grants-link-icon" />
                    </span>
                    Repository:{' '}
                    <a
                      href={url && url.includes('://') ? url : '//' + url}
                      rel="noopener"
                      className="link"
                      target="_blank"
                    >
                      {url && url.includes('github')
                        ? 'Github'
                        : url && url.includes('bitbucket')
                        ? 'Bitbucket'
                        : url}
                    </a>
                  </div>
                </Row>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Clock} alt="clock-icon" />
                    </span>
                    Last Updated: <b>Just Now</b>
                  </div>
                </Row>
              </Col>
              <Col xs={6} className="right">
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={companyIcon} alt="company-icon" />
                    </span>
                    Company Name: <b>{grant?.companyName}</b>
                  </div>
                </Row>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Internet} alt="internet-icon" />
                    </span>
                    Website:{' '}
                    <a
                      href={
                        website && website.includes('://')
                          ? website
                          : '//' + website
                      }
                      rel="noopener"
                      className="link"
                      target="_blank"
                    >
                      {site}
                    </a>
                  </div>
                </Row>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Internet} alt="internet-icon" />
                    </span>
                    Github:{' '}
                    {user?.github && (
                      <a href={user?.github} className="link">
                        @{user?.github?.slice(user.github.lastIndexOf('/') + 1)}
                      </a>
                    )}
                    {!user?.github && (
                      <InfoHelper info="To add the github account you must update the company info." />
                    )}
                  </div>
                </Row>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Internet} alt="internet-icon" />
                    </span>
                    LinkedIn:{' '}
                    {user?.linkedin && (
                      <a href={user?.linkedin} className="link">
                        @
                        {user?.linkedin?.slice(
                          user.linkedin.lastIndexOf('/') + 1
                        )}
                      </a>
                    )}
                    {!user?.linkedin && (
                      <InfoHelper info="To add the linkedin account you must update the company info." />
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          className="grant-matching-container grant-matching-stats"
          xs={12}
          xl={4}
        >
          <Row>
            <div className="px-3 py-2 title">Currently Raised</div>
          </Row>
          <Row>
            <div className="fund">
              <span className="fund-cur">0</span>
              <span className="fund-total">
                /{parseInt(fundraisingAmount).toLocaleString('en-US')} ₳DA
              </span>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default GrantSetupMatching;
