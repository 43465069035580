import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Individual from '../../assets/icon/snapbrillia_individual_icon.png';
import GrantsLink from '../../assets/icon/snapbrillia_grants_link-icon.svg';
import Clock from '../../assets/icon/snapbrillia_clock_icon.svg';
import Goal from '../../assets/icon/snapbrillia_goal_flag_icon.svg';
import Internet from '../../assets/icon/snapbrillia_internet_global_icon.svg';
import GrantDetailsButton from '../../shared/button/GrantDetailsButton.jsx';

import '../css/grantsMatching.css';
import { useCart } from '../../context/cartContext';
import {
  formatPrefix,
  formatTrailingCurrency,
  timeAgo,
} from '../../shared/utils.js';
import OverlayWithChildren from '../../shared/Overlays/OverlayWithChildren';
import { useAuth } from '../../context/authContext';
import grantLogoImage from '../../assets/snapbrillia_no_company_logo.png';
import { snapbrilliaFile } from '../../api/files';
import GrantEditModal from './grantEditModal';
import { getGrantsRatio } from '../../shared/utils.js';

const GrantsMatching = ({ grant, user }) => {
  const { cart, addItem } = useCart();
  const { auth } = useAuth();

  const [isGrantOwner, setIsGrantOwner] = useState(false);
  const [edit, setEdit] = useState(false);
  const [_grant, setGrant] = useState(grant);
  const [imageSource, setImageSource] = useState('');

  useEffect(() => {
    setIsGrantOwner(
      user && auth && auth.user && user._id && user._id === auth.user._id
    );
  }, [user, auth]);

  useEffect(() => {
    setGrant(grant);
  }, [grant]);

  const { projectLogo, tags, url, updatedAt } = grant;

  useEffect(() => {
    setImageSource(projectLogo ? snapbrilliaFile(projectLogo) : grantLogoImage);
  }, [projectLogo]);

  return (
    <Container className="" fluid>
      <Row className="gx-xs-0 gx-xl-5">
        <Col xs={12} md={3} xl={2}>
          <div className="grant-matching-icon ">
            <img
              className={`${
                projectLogo
                  ? 'grant-matching-thumbnail'
                  : 'grant-matching-defualt-icon'
              }`}
              src={imageSource}
              alt="grant-logo"
            ></img>
          </div>
        </Col>
        <Col className="grant-matching-details-col" xs={12} md={9} xl={6}>
          <div className="p-3 grant-matching-container">
            <Row className="h1 grant-matching-title">{_grant.name || ''}</Row>
            <div className="grant-matching-tags">
              {tags &&
                tags.map((tag, i) => (
                  <div key={i} className="grant-matching-single-tag">
                    {tag}
                  </div>
                ))}
            </div>
            <Row className="grant-matching-details grey-title">
              <Col xs={6}>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Individual} alt="individual-icon" />
                    </span>
                    Created by: <span className="link">{user?.fullName}</span>
                  </div>
                </Row>
                <Row className="py-1 ">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Goal} alt="goal-icon" />
                    </span>
                    Goal:{' '}
                    <b>{`${formatPrefix(grant)} ${grant.fundraisingAmount}`}</b>{' '}
                    <span className="currency">
                      {formatTrailingCurrency(grant)}
                    </span>
                  </div>
                </Row>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={GrantsLink} alt="grants-link-icon" />
                    </span>
                    Repository:{' '}
                    <a
                      href={url && url.includes('://') ? url : '//' + url}
                      rel="noopener"
                      className="link"
                      target="_blank"
                    >
                      {url && url.includes('github')
                        ? 'Github'
                        : url && url.includes('bitbucket')
                        ? 'Bitbucket'
                        : url}
                    </a>
                  </div>
                </Row>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Clock} alt="clock-icon" />
                    </span>
                    Last Updated: <b>{timeAgo(updatedAt)}</b>
                  </div>
                </Row>
              </Col>
              <Col xs={6}>
                <Row className="py-1">
                  <div className="line">
                    <span className="grant-matching-svgs">
                      <img src={Internet} alt="internet-icon" />
                    </span>
                    Website: {''}
                    <a
                      href={'//' + _grant.website}
                      target="_blank"
                      rel="noopener"
                      className="link"
                    >
                      {_grant.website}
                    </a>
                  </div>
                </Row>
                <Row className="py-1">
                  {user?.facebook && (
                    <div className="line">
                      <span className="grant-matching-svgs">
                        <img src={Internet} alt="internet-icon" />
                      </span>
                      Facebook:{' '}
                      <a href={user?.facebook} className="link" target="_blank">
                        @
                        {user?.facebook?.slice(
                          user.facebook.lastIndexOf('/') + 1
                        )}
                      </a>
                    </div>
                  )}
                </Row>
                <Row className="py-1">
                  {user?.twitter && (
                    <div className="line">
                      <span className="grant-matching-svgs">
                        <img src={Internet} alt="internet-icon" />
                      </span>
                      Twitter:{' '}
                      <a href={user?.twitter} className="link" target="_blank">
                        @
                        {user?.twitter?.slice(
                          user.twitter.lastIndexOf('/') + 1
                        )}
                      </a>
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          className="grant-matching-container grant-matching-stats"
          xs={12}
          xl={4}
        >
          <Row>
            <div className="px-3 py-2 title">Donations Raised</div>
          </Row>
          <Row>
            <div className="fund">
              <span className="fund-cur">
                {formatPrefix(grant)} {grant.currentAmount}
              </span>
              <span className="fund-total">
                /{`${grant.fundraisingAmount} ${formatTrailingCurrency(grant)}`}
              </span>
            </div>
          </Row>
          {getGrantsRatio(grant) > 1 && (
            <>
              <Row>
                <div className="px-3 py-2 title">Estimated Match Amount</div>
              </Row>
              <Row>
                <div className="fund">
                  <span className="fund-cur match-pool-color">
                    {formatPrefix(grant)} {grant.matchPoolAmount} ₳DA
                  </span>
                </div>
              </Row>{' '}
            </>
          )}
        </Col>
        <div
          className="btn-toolbar"
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          {!isGrantOwner && !edit && (
            <div onClick={() => addItem(grant)}>
              {cart?.find((element) => element.id === grant._id) ? (
                'In cart'
              ) : (
                <GrantDetailsButton text="Add To Cart" />
              )}
            </div>
          )}
          {isGrantOwner && !edit && (
            <OverlayWithChildren text="This button is only visible to the grant creator.">
              <div onClick={() => setEdit(true)}>
                <GrantDetailsButton text="Edit Grant" />
              </div>
            </OverlayWithChildren>
          )}
        </div>
      </Row>
      <GrantEditModal
        show={edit}
        grant={_grant}
        setGrant={setGrant}
        setShow={setEdit}
      />
    </Container>
  );
};

export default GrantsMatching;
