import { navigate } from '@reach/router';
import { useEffect } from 'react';

//Components
import { Col, Container, Row } from 'react-bootstrap';
import SimilarBounty from './components/similarBounty';
import Tooltip from './components/tooltip';
import BountyDescription from './components/bountyDescription';
import NavBar from '../shared/menus/navBar';
import * as OpportunityAPI from '../api/opportunities';
import { toast } from 'react-toastify';

//Icons
import BackIcon from '../assets/icon/snapbrillia_softwareengineerback_icon.png';

//CSS
import NotFound from '../shared/errors/404';
import { useState } from 'react';
// import BountyReviewModal from './components/BountyReviewModal';
import Loading from '../jobs/components/loading';
import { useAuth } from '../context/authContext';
// import { isUndefined } from 'lodash';

const Bounty = ({ id }) => {
  const [showReview, setShowReview] = useState(false);
  const [bounty, setBounty] = useState({});
  const [assessment, setAssessment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState(null);
  const { auth } = useAuth();

  const loadBountyInfo = async (id) => {
    setLoading(true);
    try {
      const currentBounty = await OpportunityAPI.getOpportunity(id);
      setBounty(currentBounty);
      if (auth !== undefined) {
        const assessment = await OpportunityAPI.getAssessments(id);
        const userAssessment = assessment.filter(
          (val) => val?.candidateId?._id === auth.user._id
        );
        setAssessment(userAssessment);
      }
    } catch (err) {
      toast(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadBountyInfo(id);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      {!loading ? (
        bounty ? (
          <Container fluid className="px-3 pb-3">
            <Row>
              <Col xs={12} className="mb-3">
                <span
                  className="pointer"
                  onClick={() => navigate('/opportunities')}
                >
                  <img src={BackIcon} alt="back-icon" />
                  <span className="fs-5 primary medium ms-2">
                    <u>Back to Opportunities</u>
                  </span>
                </span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12}>
                <Tooltip
                  bounty={bounty}
                  loading={loading}
                  assessment={assessment}
                  team={team}
                  setShowReview={setShowReview}
                  showReview={showReview}
                  email={assessment[0]?.candidateId?.email}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={12}>
                <BountyDescription bounty={bounty} />
              </Col>
            </Row>
            {/* <Row>
            <Col>
              <div className="medium my-4">
                <span className="primary">Was this helpful? </span>
                <img
                  src={ThumbsUpIcon}
                  alt="thumbs-up"
                  className="pointer"
                  width={20}
                />
                {' | '}
                <img
                  src={ThumbsDownIcon}
                  alt="thumbs-down"
                  className="pointer"
                  width={20}
                />
              </div>
            </Col>
          </Row> */}
            <Row>
              <Col xs={12}>
                <SimilarBounty id={id} />
              </Col>
            </Row>
          </Container>
        ) : (
          <NotFound />
        )
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Bounty;
