import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import TeamSummaryDescription from './teamSummaryDescription';
import IndividualReview from './individualReview';

//INSERT COMPONENTS HERE

//CSS
import '../../shared/css/tabs.css';
import TeamList from './teamList';

const IndividualWorkTabs = ({ opportunity }) => {
  const [activeKey, setActiveKey] = useState('review');
  return (
    <Tabs
      activeKey={activeKey}
      className="mb-3"
      onSelect={(e) => setActiveKey(e)}
    >
      <Tab eventKey="review" title="Review">
        <IndividualReview opportunity={opportunity} />
      </Tab>
      <Tab eventKey="description" title="Description">
        <TeamSummaryDescription team={opportunity} />
      </Tab>
      {opportunity.position !== 'Individual' && (
        <Tab eventKey="team" title="Team">
          <TeamList team={opportunity} />
        </Tab>
      )}
      <Tab eventKey="git history" title="Git History"></Tab>
    </Tabs>
  );
};

export default IndividualWorkTabs;
