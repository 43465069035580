import { Col, Container, Row, Button, Spinner } from 'react-bootstrap';

import NavBar from '../shared/menus/navBar';
import MentorsPersonalInfo from './components/mentorsPersonalInfo';
// import MentorsYourResume from './components/mentorsYourResume';
import MentorsLinkedAccounts from './components/mentorsLinkedAccounts';
import SaveAndContinue from '../shared/button/saveAndContinue';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/authContext';
import { Link } from '@reach/router';
import { useParams } from '@reach/router';
import { toast } from 'react-toastify';
import { navigate } from '@reach/router';
import { uploadFile } from '../api/files';
import { useBounty } from '../hooks/useBounty';
import NotFound from '../shared/errors/404';
import Loading from '../shared/Overlays/loading';
import _ from 'lodash';
import { expressInterestOpportunity } from '../api/opportunities';
import JobHeader from './components/jobHeader';
import useViewModel from '../jobsApplicant/viewModels/individualJobViewModel';
import * as jobApi from '../api/job';

const JobIndividualPage = () => {
  const { id } = useParams();
  const { auth, updateProfile } = useAuth();
  const [resumeFile, setResumeFile] = useState(null);
  const [newLocation, setNewLocation] = useState(auth.user?.location || '');
  const [newFullName, setNewFullName] = useState(auth.user?.fullName);
  const [runing, setRuning] = useState(false);
  const { bounty, loading } = useBounty(id);
  const [skills, setSkills] = useState([]);
  const { validation } = useViewModel();
  const [selectedSkills, setSelectedSkills] = useState([]);

  useEffect(() => {
    const loadSkills = async () => {
      try {
        const skills = await jobApi.getAllSkills();
        if (_.isArray(skills) && !_.isEmpty(skills)) {
          setSkills(skills);
        }
      } catch (err) {
        toast(err.message);
      }
    };
    if (auth) {
      setNewLocation(auth.user.location);
    }
    loadSkills();
  }, [auth]);

  const onCreateSkillSelected = async (skill) => {
    setSkills(skill);
  };

  const expressInterest = async () => {
    if (!newFullName || newFullName === ''|| auth.user?.fullName === '') {
      return toast('Please add your fullname.');
    }

    if (!newLocation || newLocation === '') {
      return toast('Please add your location.');
    }

    if (auth?.user?.skills?.length === 0 && selectedSkills.length === 0) {
      return toast('Please Complete your skills.');
    }

    if ((!auth.user || !auth.user.githubId) && bounty.useGithubApp) {
      toast('Please link your github account');
      return;
    }
    if (!auth.user.resume && !resumeFile) {
      toast('Please upload your resume.');
      return;
    }
    try {
      setRuning(true);
      await updateUserProfile();
      await expressInterestOpportunity(id, {
        position: 'Job',
      });
      setRuning(false);
      navigate(`/teams`);
      toast(
        'You have successfully expressed interest to start as an individual! The gig owner will reach out soon to start. Please check your email for github invitation.'
      );
    } catch (error) {
      toast(error.message);
    }
  };

  const updateUserProfile = async () => {
    const updateInfo = {};
    if (resumeFile) {
      const formData = new FormData();
      formData.append('type', 'resume');
      formData.append('file', resumeFile[0]);
      try {
        const fileInfo = await uploadFile(formData);
        if (fileInfo.url) {
          updateInfo.resume = fileInfo.url;
        }
      } catch (err) {}
    }

    if(selectedSkills.length > 0) {
      const updatedSkills = selectedSkills.map((skill) => skill.label);
      const updatedSkillIds = selectedSkills.map((skill) => skill.value);
      const mergeSkills = updatedSkills.flat(1)
      const mergeSkillIds = updatedSkillIds.flat(1);
      updateInfo.skills = mergeSkills;
      updateInfo.skillIds = mergeSkillIds;
    }

    if (newLocation !== auth.user?.location) {
      updateInfo.location = newLocation;
    }

    if (newFullName !== auth.user?.fullName) {
      updateInfo.fullName = newFullName;
    }

    if (Object.keys(updateInfo).length) {
      try {
        await updateProfile(updateInfo);
      } catch (err) {}
    }
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <Loading />
      ) : !_.isEmpty(bounty) && bounty ? (
        <Container fluid className="px-5 pb-5">
          <Row>
            <JobHeader job={bounty} />
          </Row>
          <Row style={{ marginTop: '100px' }}>
            <Col md={6} sm={12} xs={12}>
              <MentorsPersonalInfo
                user={auth.user}
                {...{
                  skills,
                  setSkills,
                  newLocation,
                  setNewLocation,
                  resumeFile,
                  setResumeFile,
                  newFullName,
                  setNewFullName,
                  onCreateSkillSelected,
                  validation,
                  selectedSkills,
                  setSelectedSkills,
                }}
              />
            </Col>
            <Col md={6} sm={12} xs={12}>
              <MentorsLinkedAccounts user={auth.user} bounty={bounty} />
            </Col>
          </Row>
          <Row className="justify-content-center text-center justify-content-lg-end mt-5 pb-2">
            <Col xs={5} lg={3} xxl={1}>
              <button
                className="mentee_page_cancel_button"
                style={{ fontSize: '12px' }}
              >
                <Link to={`/bounties/${id}`} style={{ fontSize: '14px' }}>
                  Cancel
                </Link>
              </button>
            </Col>
            <Col xs={6} lg={4}>
              {runing ? (
                <Button disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <SaveAndContinue title="Submit Application" onClick={expressInterest} />
              )}
            </Col>
          </Row>
        </Container>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default JobIndividualPage;
