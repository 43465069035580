import { useState, useCallback, useContext, useEffect } from 'react';
import { checkIfWalletInstalled } from '../../shared/utils';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { GrantSetupContext } from './grantSetup';
import DropDown from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import UploadFiles from './uploadFiles';
import '../css/grantSetupStep.css';
import { useCart } from '../../context/cartContext';
import { useAuth } from '../../context/authContext';
import { getGrantDraft } from '../../api/grant';
import { getLogoUrlStructure, snapbrilliaFile } from '../../api/files';
import AddNamiWalletButton from '../../shared/button/addNamiButton';
import AddEtrnlWalletButton from '../../shared/button/addEternlButton';
import AddFlintWalletButton from '../../shared/button/addFlintButton';
import AddNuFiWalletButton from '../../shared/button/addNuFiButton';
import AddTyphonWalletButton from '../../shared/button/addTyphonButton';
import AddGeroWalletButton from '../../shared/button/addGeroButton';

const GrantSetupStep = ({ isEdit }) => {
  const {
    data,
    setData,
    throttle,
    companyLogoChanged,
    invalidGitUrl,
    setInvalidGitUrl,
    setInvalidWebsiteUrl,
    invalidWebsiteUrl,
  } = useContext(GrantSetupContext);
  const { cardanoWalletConnected, cardanoAddress, connectCardanoWallet } =
    useCart();
  const [wordCnt, setWordCnt] = useState(0);
  const [content, setContent] = useState('');
  const [uniqueId, setUniqueID] = useState(
    () => 'select_' + Math.random().toFixed(5).slice(2)
  );
  const { company } = useAuth();
  const { projectLogo } = data;

  const listOfAvailableWallet = [
    {
      component: (
        <AddNamiWalletButton
          handleClick={() => {
            checkIfWalletInstalled('nami')
              ? connectCardanoWallet('nami')
              : window.open('https://namiwallet.io/');
          }}
        />
      ),
    },
    {
      component: (
        <AddEtrnlWalletButton
          handleClick={() => {
            checkIfWalletInstalled('eternl')
              ? connectCardanoWallet('eternl')
              : window.open(
                  'https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka'
                );
          }}
        />
      ),
    },
    {
      component: (
        <AddFlintWalletButton
          handleClick={() => {
            checkIfWalletInstalled('flint')
              ? connectCardanoWallet('flint')
              : window.open('https://flint-wallet.com/');
          }}
        />
      ),
    },
    {
      component: (
        <AddNuFiWalletButton
          handleClick={() => {
            checkIfWalletInstalled('nufi')
              ? connectCardanoWallet('nufi')
              : window.open('https://nu.fi/');
          }}
        />
      ),
    },
    {
      component: (
        <AddTyphonWalletButton
          handleClick={() => {
            checkIfWalletInstalled('typhoncip30')
              ? connectCardanoWallet('typhoncip30')
              : window.open('https://typhonwallet.io/#/');
          }}
        />
      ),
    },
    {
      component: (
        <AddGeroWalletButton
          handleClick={() => {
            checkIfWalletInstalled('gerowallet')
              ? connectCardanoWallet('gerowallet')
              : window.open('https://gerowallet.io/');
          }}
        />
      ),
    },
  ];

  const setTextareaContent = useCallback((e) => {
    let temp = e.target.value.trim().split(' ').filter(Boolean);

    if (temp.length >= 200) {
      setContent(temp.slice(0, 200).join(' '));
      setWordCnt(200);
    } else {
      setWordCnt(temp.length);
      setContent(e.target.value);
    }
  }, []);

  const DropdownIndicator = (state) => {
    return (
      <components.DropdownIndicator {...state}>
        <div>
          <img src={DropDown} alt="dropdown-icon" />
        </div>
      </components.DropdownIndicator>
    );
  };

  const MultiValueContainer = ({ selectProps, data }) => {
    const values = selectProps.value;
    if (values) {
      return values[values.length - 1].label === data.label
        ? data.label
        : data.label + ',  ';
    } else {
      return '';
    }
  };

  const animatedComponents = makeAnimated();

  const labelStyles = {
    container: (styles) => ({
      display: 'flex',
      paddingLeft: 0,
      marginLeft: -10,
    }),
    control: (styles, state) => ({
      // height: state.hasValue ? 'auto' : setTimeout(() => 0, 1000),
      minHeight: 0,
      width: '100%',
      display: 'flex',
    }),

    menu: (provided, state) => {
      return {
        display: 'none',
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        color: '#ffffff',
        backgroundColor: '#605f92',
        borderRadius: '10px 0 0 10px',
        fontSize: '10px',
        fontWeight: 500,
      };
    },

    multiValue: (styles, { data }) => {
      return {
        ...styles,
        color: '#fafafa',
        backgroundColor: 'none',
        marginBottom: '5px',
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      backgroundColor: '#605f92',
      borderRadius: '0 10px 10px 0',
    }),
    dropdownIndicator: (provided, state) => {
      return {
        display: 'none',
      };
    },
    indicatorSeparator: (provided, state) => {
      return {
        display: 'none',
      };
    },
    clearIndicator: (provided, state) => {
      return {
        cursor: 'pointer',
      };
    },
  };

  const options = [
    { value: 'react', label: 'React' },
    { value: 'js', label: 'JavaScript' },
    { value: 'web3', label: 'Web3.js' },
    {
      value: 'c++',
      label: 'C++',
    },
    { value: 'python', label: 'Python' },
    { value: 'ruby', label: 'Ruby' },
    { value: 'ts', label: 'TypesSript' },
    { value: 'java', label: 'Java' },
  ];

  const convertTagsToOptions = (tags) => {
    return tags.map((tag) => {
      return options.find(
        (option) => option.label.toLowerCase() === tag.toLowerCase()
      );
    });
  };

  const dropdownStyles = (id) => {
    return {
      container: (styles) => ({
        paddingLeft: 0,
      }),
      option: (provided, state) => {
        return {
          color: state.isSelected || state.isFocused ? '#2d2b6f' : '#808080',
          padding: 10,
          fontWeight: 500,
          cursor: 'pointer',
        };
      },
      control: (provided, state) => {
        return {
          border:
            throttle[0] === 1 && data.tags.length === 0
              ? '2px solid #b33a3a'
              : 'none',

          // borderWidth: 0,
          minHeight: '35px',
          borderRadius: 6,
          backgroundColor: '#f4f4f4',
          display: 'flex',
          color: '#808080',
          fontSize: '14px',
          fontWeight: 500,
          width: 'calc(100% - 11px)',
          zIndex: 999,
          cursor: 'pointer',
        };
      },
      singleValue: (provided, state) => {
        return {
          color: '#ffffff',
          fontWeight: 500,
          zIndex: 999,
        };
      },
      menu: (provided, state) => {
        const { menuIsOpen } = state.selectProps;

        return {
          position: 'absolute',
          width: '40%',
          color: state.selectProps.menuColor,
          backgroundColor: '#fff',
          boxShadow: '0px 0px 20px -5px rgba(0, 0, 0, 0.25)',
          borderRadius: 4,
          transition: 'all 0.8s ease-in-out',

          zIndex: 9999,
        };
      },
      menuList: (provided, state) => {
        return {
          maxHeight: '180px',
          overflowY: 'auto',
          '::-webkit-scrollbar': {
            width: '6px',
            height: '0px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#808080',
            borderRadius: '4px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        };
      },
      dropdownIndicator: (provided, state) => {
        const { menuIsOpen } = state.selectProps;

        return {
          position: 'relative',
          right: '8px',
          bottom: '2px',

          transform: menuIsOpen
            ? 'rotate(180deg) translateY(-2px)'
            : 'rotate(0deg)',
          transition: 'all 0.8s ease-in-out',
        };
      },
      input: (provided, state) => {
        return {
          ...provided,
          color: '#808080',
          fontSize: '14px',
          fontWeight: 500,
          gridTemplateColumns: '0 min-content',
        };
      },
      valueContainer: (provided, state) => {
        return {
          ...provided,

          alignItems: 'center',
          display: '-webkit-box',
          padding: '2px 8px',
          width: '100%',
        };
      },
    };
  };

  useEffect(() => {
    let didCancel = false;
    let _projectLogo = null;

    const fetchImage = async (filePath, fileName) => {
      try {
        const response = await fetch(filePath);
        const blobImage = await response.blob();
        const file = new File([blobImage], `${fileName}-logo.png`, {
          type: 'image/jpeg',
        });

        _projectLogo = Object.assign(file, {
          preview: URL.createObjectURL(file),
          path: file.name,
        });
      } catch (error) {
        console.log(error);
      }

      if (!didCancel) {
        setData((prevState) => ({
          ...prevState,
          projectLogo: [_projectLogo],
        }));
      }
    };

    if (!companyLogoChanged) {
      if (isEdit && typeof projectLogo !== 'object') {
        fetchImage(snapbrilliaFile(projectLogo), 'projectLogo-temporal-name');
      }

      if (!isEdit && company && company.companyLogo) {
        fetchImage(
          getLogoUrlStructure(company.companyLogo),
          company.companyName
        );
      }
    }

    return () => {
      didCancel = true;
    };
  }, [companyLogoChanged, isEdit]);

  useEffect(() => {
    if (data && isEdit)
      setData({
        ...data,
        tagValues: data.tags.map((skill) => ({
          value: options.find((option) => option.label === skill).value,
          label: skill,
        })),
      });
  }, []);

  useEffect(() => {
    if (
      invalidGitUrl ||
      // data.url === '' ||
      data.name === '' ||
      !cardanoWalletConnected ||
      data.tags.length === 0 ||
      invalidWebsiteUrl
    ) {
      window.scroll(0, 0);
    }
  }, [throttle]);

  useEffect(() => {
    const fetchGrantDraft = async () => {
      try {
        const grant = await getGrantDraft();
        let tagValues = convertTagsToOptions(grant.tags); // Convert the tags to the correct format
        if (grant) {
          setData((prevState) => ({
            ...prevState,
            ...grant,
            tagValues: tagValues,
            currentFundingRound: prevState.currentFundingRound,
          }));
        }
      } catch {}
    };

    fetchGrantDraft();
  }, []);

  return (
    <Container className="grant-setup-steps">
      <Row className="h1">Setup</Row>
      <Row className="grant-setup-top">
        <Col xs={12} xl={7}>
          <Row className="grant-setup-tag">Grant Name*</Row>
          <Row>
            <textarea
              readOnly={isEdit}
              style={{
                border:
                  throttle[0] === 1 && data.name === ''
                    ? '2px solid #b33a3a'
                    : 'none',
              }}
              className="textarea"
              id="description"
              value={data.name}
              onInput={(e) => {
                setTextareaContent(e);
                setData((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }));
              }}
              onPaste={(e) => {
                wordCnt >= 200 ? e.preventDefault() : setTextareaContent(e);
              }}
            ></textarea>
          </Row>
          <Row
            style={{ color: wordCnt >= 200 ? '#dc3545' : null }}
            className="wordCount"
          >
            {wordCnt}/200
          </Row>
          {throttle[0] === 1 && data.name === '' ? (
            <Row className="grant-warning-text">
              Name is required for the created grant.
            </Row>
          ) : null}
        </Col>
        <Col className="grant-image">
          <Row className="grant-setup-tag">Company / Grant Logo</Row>
          <Row>
            <div className="grant-setup-upload">
              <UploadFiles fieldName="Logo Image" />
            </div>
          </Row>
        </Col>
      </Row>
      <div className="grant-setup-bottom">
        <Row>
          <Col xs={9} xl={7}>
            <Row className="grant-setup-tag">Company Name</Row>
            <Row>
              <input
                className="input"
                value={data.companyName}
                onChange={(e) => {
                  setData({ ...data, companyName: e.target.value });
                }}
              />
            </Row>
          </Col>
          <Col>
            <Row className="grant-setup-tag">Company / Grant Website URL</Row>
            <Row>
              <input
                className="input"
                value={data.website}
                onChange={(e) => {
                  setInvalidWebsiteUrl(false);
                  setData({ ...data, website: e.target.value });
                }}
              />
            </Row>
            {invalidWebsiteUrl ? (
              <Row className="grant-warning-text">
                Please enter a valid website URL.
              </Row>
            ) : null}
          </Col>
        </Row>
        <Row className="grant-setup-tag">
          Repository Link (Github or Bitbucket URL)
        </Row>
        <Row>
          <input
            style={{
              border: throttle[0] === 1 ? '2px solid #b33a3a' : 'none',
            }}
            className="input"
            value={data.url}
            onChange={(e) => {
              setInvalidGitUrl(false);
              setData({ ...data, url: e.target.value });
            }}
          />
          {invalidGitUrl && data.url !== '' ? (
            <Row className="grant-warning-text">
              Invalid Github or Bitbucket URL
            </Row>
          ) : null}
          {/* {throttle[0] === 1 && data.url === '' ? (
            <Row className="grant-warning-text">
              {' '}
              Missing Github or Bitbucket URL{' '}
            </Row>
          ) : null} */}
        </Row>
        {!isEdit && (
          <Row className="grant-setup-tag">
            Wallet that will recieve the funds
          </Row>
        )}
        {!isEdit && (
          <>
            <Row>
              {cardanoWalletConnected ? (
                <input
                  className="input"
                  style={{
                    border:
                      throttle[0] === 1 && !cardanoWalletConnected === ''
                        ? '2px solid #b33a3a'
                        : 'none',
                  }}
                  value={cardanoAddress}
                  disabled={true}
                />
              ) : (
                <>
                  <Card bg="dark" text="light" className="grant-setup-card">
                    <Card.Body>
                      <Row>
                        {listOfAvailableWallet.map((wallet, index) => {
                          return (
                            <Col className="text-center" key={index}>
                              {wallet.component}
                            </Col>
                          );
                        })}
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}
            </Row>
            {throttle[0] === 1 && !cardanoWalletConnected ? (
              <Row className="grant-warning-text">
                Wallet is needed to recieve payments.
              </Row>
            ) : null}
          </>
        )}
        <Row className="grant-setup-tag">Tags*</Row>
        <Row className="">
          {!isEdit && (
            <Select
              isMulti
              placeholder={''}
              isSearchable={false}
              defaultValue={data.tagValues}
              value={data.tagValues}
              onChange={(e) => {
                setData({ ...data, tags: e.map((e) => e.label), tagValues: e });
              }}
              options={options}
              styles={labelStyles}
              components={animatedComponents}
            />
          )}
        </Row>
        <Row>
          {!isEdit && (
            <CreatableSelect
              id={uniqueId}
              isMulti
              closeMenuOnSelect={false}
              maxMenuHeight={500}
              value={data.tagValues}
              placeholder={
                'Please select from the dropdown, or press Enter to save custom tags...'
              }
              onMenuClose={() => {
                const menuEl = document.querySelector(
                  `#${uniqueId} .grant-setup-dropdownMenu`
                );
                const containerEl = menuEl?.parentElement;
                const clonedMenuEl = menuEl?.cloneNode(true);

                if (!clonedMenuEl) {
                  return;
                }

                clonedMenuEl.classList.add('menu--close');
                clonedMenuEl.addEventListener('animationend', () => {
                  containerEl?.removeChild(clonedMenuEl);
                });

                containerEl?.appendChild(clonedMenuEl);
              }}
              onChange={(e) => {
                setData({ ...data, tags: e.map((e) => e.label), tagValues: e });
              }}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
                MultiValueContainer,
                Menu: (props) => (
                  <components.Menu
                    {...props}
                    className="grant-setup-dropdownMenu"
                  />
                ),
              }}
              options={options}
              styles={dropdownStyles()}
              classNamePrefix="grant-setup-dropdown"
            />
          )}
          {isEdit && (
            <div className="grant-view-tags">
              {data.tags &&
                data.tags.map((tag, i) => (
                  <div key={i} className="grant-matching-single-tag">
                    {tag}
                  </div>
                ))}
            </div>
          )}

          {throttle[0] === 1 && data.tags.length === 0 ? (
            <Row className="grant-warning-text">
              At least 1 tag is required for the created grant.
            </Row>
          ) : null}
        </Row>
      </div>
    </Container>
  );
};

export default GrantSetupStep;
