import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import useViewModel from '../viewModels/cashOutViewModel';

const CashoutView = ({ opportunity }) => {
  const {
    setWalletAddress,
    setReward,
    setSharedAmount,
    setAssessmentId,
    setBankAddress,
    loadConnectedWallets,
    loadBankInfo,
    payoutReward,
    wallets,
    bankAccounts,
  } = useViewModel();

  const [showAmount, setShowAmount] = useState(false);
  const [isBank, setIsBank] = useState(false);

  //event handlers
  const onShareAmountChanged = (e) => setShowAmount(true);

  const cashOutReward = async (e) => {
    e.preventDefault();
    const response = await payoutReward();
    if (response) {
      //TODO: display toast
    }
    e.stopPropagation();
  };

  useEffect(() => {
    loadBankInfo();
    loadConnectedWallets();
    setAssessmentId(opportunity._id);
    setReward(opportunity.opportunityId.rewardAmount);
    setIsBank(opportunity.opportunityId.rewardType === 'USD');
  });

  return (
    <Container>
      <>
        {isBank && bankAccounts.length > 0 && (
          <Row className="mb-3 mt-2">
            <Form.Group>
              <Form.Label className="form_label bounty-type-label ps-0">
                Account <span className="required">*</span>
              </Form.Label>
              <Form.Select onChange={(e) => setBankAddress(e.target.value)}>
                <option>Select Account</option>
                {bankAccounts.map((account, index) => {
                  return (
                    <option
                      className="input_fields"
                      value={account.id}
                      key={index}
                    >
                      {`${account.institution_name} (...${account.last4})`}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Row>
        )}
      </>
      <Row>
        <Form>
          <>
            {!isBank && wallets.length > 0 && (
              <Form.Group>
                <Form.Label className="form_label bounty-type-label ps-0">
                  Wallet <span className="required">*</span>
                </Form.Label>
                <Form.Select onChange={(e) => setWalletAddress(e.target.value)}>
                  <option>Select Wallet</option>
                  {wallets.map((wallet, index) => {
                    return (
                      <option
                        className="input_fields"
                        value={wallet.walletAddress}
                        key={index}
                      >
                        {wallet.walletName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            )}
          </>
          {opportunity.position === 'Mentor' && (
            <>
              <Form.Group>
                <Form.Label className="form_label bounty-type-label ps-0">
                  Share reward with mentees{' '}
                </Form.Label>
                <Form.Control as="checkbox" className="remove-radio-border">
                  <Form.Check
                    inline
                    label="Select to share reward with mentees"
                    name="shareReward"
                    type="checkbox"
                    onChange={onShareAmountChanged}
                  />
                </Form.Control>
              </Form.Group>
            </>
          )}
          {showAmount && (
            <Form.Group>
              <Form.Label className="form_label bounty-type-label ps-0">
                Amount{' '}
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                name="sharedAmount"
                className="remove-radio-border"
                onChange={(e) => setSharedAmount(e.target.value)}
              />
            </Form.Group>
          )}
        </Form>
      </Row>
      <Row>
        <Col className="mt-4">
          <button className="btn-primary" onClick={cashOutReward}>
            Cash Out
          </button>
        </Col>
      </Row>
      <Row></Row>
    </Container>
  );
};
export default CashoutView;
