import React, { useState, useEffect } from 'react';
import '../css/tooltip.css';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ToastMessage from './Toast';
import { navigate } from '@reach/router';
import { getImageByUrl } from '../../shared/images';
import { format } from 'date-fns';
import { formatCurrency, formatGigOrJobSkills } from '../../shared/utils';
import ExpressInterestTooptip from './expressInterestTooptip';
// import * as teamApi from '../../api/teams';
import { useAuth } from '../../context/authContext';

export default function Tooltip_clickable({
  bounty,
  loading,
  assessment,
  team,
  setShowReview,
  showReview,
  email,
}) {
  const [canExpress, setCanExpress] = useState(true);
  const [canJoin, setCanJoin] = useState(false);
  const [createdDate, setCreatedDate] = useState();
  const [showTooltip, setShowTooltip] = useState();
  const [show, setShow] = useState(false);
  const [logo, setLogo] = useState('');
  const { auth, setLoginDropOpen, loginBoxOpen } = useAuth();

  function countDuplicates(arr) {
    const countsByItem = {};
    for (const item of arr) {
      countsByItem[item] = (countsByItem[item] || 0) + 1;
    }
    return Object.values(countsByItem).filter((val) => val >= 2).length;
  }

  const { companyId } = bounty;
  useEffect(() => {
    if (['accepted', 'rejected', 'submitted'].includes(assessment?.status)) {
      setCanExpress(false);
    }
    if (assessment?.status === 'accepted') {
      setCanJoin(true);
    }
  }, [assessment]);

  useEffect(() => {
    if (bounty.createdAt)
      setCreatedDate(format(new Date(bounty.createdAt), 'MM/dd/yyyy'));
  }, [bounty.createdAt]);

  useEffect(() => {
    if (companyId) {
      const { companyLogo } = companyId;
      setLogo(getImageByUrl(companyLogo, 'bounty'));
    }
  }, [companyId]);

  // const start = async () => {
  //   try {
  //     await teamApi.startTeam(team._id);
  //     window.location.reload();
  //   } catch (err) {}
  // };

  function listToSentence(result) {
    let skillsWithComma = '';
    if (result === undefined || result?.length === 0) {
      return 'Loading Skills ...';
    }
    if (result)
      skillsWithComma =
        result.length === 2
          ? result.join(' and ')
          : result.length > 3
          ? `${result.slice(0, 2).join(', ')}, +${result.length - 2} others`
          : result.slice(0, 3).join(', ');
    return skillsWithComma;
  }

  const tooltip = (item) => {
    return (
      <Tooltip id="tooltip">
        <ul>
          {item?.skillIds?.map((skill) => (
            <li key={skill}>
              <strong>{skill?.name || skill}</strong>
            </li>
          ))}
        </ul>
      </Tooltip>
    );
  };

  // const _hasNotAppliedOrIsInvited = (application) => {
  //   return _.isNil(application) || application.status === 'Invited';
  // };
  // const _gigIsOpen = (jobData) => {
  //   return !_.isNil(jobData) && jobData.status === 'Open';
  // };
  // const _gigExists = (jobData) => {
  //   return !_.isNil(jobData) && !_.isEmpty(jobData);
  // };
  // if (_gigIsOpen(job)) {
  //   if (!_.isNil(auth) && !_.isNil(auth.user)) {
  //     const application = job.applicationIds.filter(
  //       (x) => x.jobApplicantId === auth.user._id
  //     );
  //     if (_hasNotAppliedOrIsInvited(application[0])) {
  //       setCanApply(true);
  //     }
  //   }
  // }

  return (
    <div className="tooltip-box px-2">
      <Row>
        <Col className="tooltip-box-title-section">
          <h3 className="tooltip-box-title col-9">{bounty.title}</h3>
          <div className="col-3">
            <h3 className="bounty-reward-text">Gig Reward</h3>
            <h3 className="money-text">{formatCurrency(bounty)}</h3>
          </div>
        </Col>

        <Col>
          <Row>
            <div className="company-name px-4 mt-4 mt-sm-0">
              <img src={logo} className="company-logo" alt="..." />
              {bounty.companyId?.companyName}
            </div>
          </Row>
        </Col>
      </Row>

      {/* <div className="text-nowrap bottom-sub-text">Team Preference</div>
          <div className="bottom-text tool-tip-time">
            {bounty.timeEstimation} {bounty.timeEstimationType}
            <div className="bottom-sub-text">Time Estimation</div>
          </div> 
           <div className="bottom-text">
            {createdDate}
            <div className="bottom-sub-text">Date</div>
          </div>
          */}

      <Row className="tooltip-content">
        <Col xs={6} md={6} lg={4} className="d-sm-block">
          <span className="text-nowrap tool-tip-teampreference top-text">
            {bounty.teamPreference === true ? 'Teams Only' : 'Open to All'}
          </span>
          <div className="bottom-sub-text pt-0">Team Preference</div>
        </Col>
        <Col xs={6} md={6} lg={4} className="d-sm-block">
          <p className="status-text bounty-status mb-0">
            {(() => {
              if (bounty.status === 'Open' && bounty.isAcceptapplicant)
                return 'Accept Application';
              if (bounty.status === 'Completed') return 'Complete/Close';
              if (
                bounty.status === 'noApplications' ||
                !bounty.isAcceptapplicant
              )
                return 'Not Actively Reviewing Applicants';
            })()}
          </p>
          <div className="bottom-sub-text pt-0 position-relative">
            Status
            <span className={`status-tag status-${bounty.status}`}>
              {bounty.status}
            </span>
          </div>
        </Col>
        <Col xs={12} md={12} lg={4} className="d-sm-block">
          <OverlayTrigger
            placement="top"
            overlay={tooltip({ skillIds: bounty.skillIds })}
          >
            <Row className="w-100 mt-0">
              <Row>
                <Col xs={1} md={1} lg={1}>
                  <i class="fa-solid fa-circle-info"></i>
                </Col>
                <Col>
                  <span className="text-black-50">Skills</span>
                </Col>
              </Row>
              <span className="main-language">
                {formatGigOrJobSkills({ skillIds: bounty.skillIds })}
              </span>
            </Row>
          </OverlayTrigger>
        </Col>
        {/* <Col xs={6} md={6} lg={3} className="d-sm-block">
          <div className="status-text bounty-status mb-0">
            {bounty?.teamIds?.length < 1
              ? `None`
              : bounty?.teamIds?.length -
                  countDuplicates(bounty?.teamIds || []) ===
                1
              ? `${
                  bounty?.teamIds?.length -
                  countDuplicates(bounty?.teamIds || [])
                } Team`
              : `${
                  bounty?.teamIds?.length -
                  countDuplicates(bounty?.teamIds || [])
                } Teams`}
            <p className="bottom-sub-text pt-0">Applicants</p>
          </div>
        </Col> */}
      </Row>
      <Row>
        <Col xs={6} md={6} lg={4} className="d-sm-block">
          <span className="text-black-50">Time Estimation</span>
          <Row>
            <Col xs={1} md={1} lg={1}>
              <i class="fa-solid fa-business-time"></i>
            </Col>
            <Col xs={2} md={5} lg={3}>
              <span>
                {' '}
                {bounty.timeEstimation} {bounty.timeEstimationType}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={6} lg={4} className="d-sm-block">
          <span className="text-black-50">Location</span>
          <Row>
            <Col xs={1} md={1} lg={1}>
              <i class="fa-solid fa-location-dot"></i>
            </Col>
            <Col xs={1} md={1} lg={1}>
              <span>{bounty?.location || 'Globe'}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={12} lg={4} className="express-box">
          <div className="text-end me-2">
            {/* <Link
                  style={{ textDecoration: 'none', color: '#605F92' }}
                  to="/submit"
                >
                  <svg
                    width="14"
                    height="20"
                    viewBox="0 2 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.1667 14.1365C13.4489 14.1365 12.8067 14.4378 12.3156 14.9096L5.58167 10.743C5.62889 10.512 5.66667 10.2811 5.66667 10.0402C5.66667 9.7992 5.62889 9.56827 5.58167 9.33735L12.24 5.21084C12.75 5.71285 13.4206 6.0241 14.1667 6.0241C15.7344 6.0241 17 4.67871 17 3.01205C17 1.34538 15.7344 0 14.1667 0C12.5989 0 11.3333 1.34538 11.3333 3.01205C11.3333 3.25301 11.3711 3.48394 11.4183 3.71486L4.76 7.84137C4.25 7.33936 3.57944 7.02811 2.83333 7.02811C1.26556 7.02811 0 8.37349 0 10.0402C0 11.7068 1.26556 13.0522 2.83333 13.0522C3.57944 13.0522 4.25 12.741 4.76 12.239L11.4844 16.4157C11.4372 16.6265 11.4089 16.8474 11.4089 17.0683C11.4089 18.6847 12.6461 20 14.1667 20C15.6872 20 16.9244 18.6847 16.9244 17.0683C16.9244 15.4518 15.6872 14.1365 14.1667 14.1365Z"
                      fill="#2D2B6F"
                    />
                  </svg>
                  <span className="share-link">Share</span>
                </Link> */}
            {!auth?.user?.email && (
              <button
                onClick={() => setLoginDropOpen(!loginBoxOpen)}
                className="express-interest-button"
              >
                {'Express Interest'}
              </button>
            )}
            {loading ? (
              <></>
            ) : bounty?.createdBy?.email === auth?.user?.email ? (
              !canJoin && (
                <button
                  onBlur={() =>
                    setTimeout(() => {
                      if (showTooltip) {
                        setShowTooltip(!showTooltip);
                      }
                    }, 200)
                  }
                  className={
                    canExpress
                      ? 'express-interest-button'
                      : 'express-interest-button-disabled'
                  }
                  disabled={!canExpress}
                >
                  <a
                    className="review-bounty"
                    // href="#review-modal"
                    href={`${process.env.REACT_APP_B2B_URL}/bounties/${bounty._id}`}
                  >
                    {canExpress ? 'Review' : 'Review'}
                  </a>
                </button>
              )
            ) : (
              bounty.status !== 'done' &&
              auth?.user?.email &&
              !canJoin &&
              email !== auth?.user?.email && (
                <button
                  // onBlur={() =>
                  //   setTimeout(() => {
                  //     if (showTooltip) {
                  //       setShowTooltip(!showTooltip);
                  //     }
                  //   }, 200)
                  // }
                  onClick={() => {
                    if (canExpress) {
                      setShowTooltip(!showTooltip);
                    }
                  }}
                  className={
                    canExpress
                      ? 'express-interest-button'
                      : 'express-interest-button-disabled'
                  }
                  disabled={!canExpress}
                >
                  {canExpress ? ' Express Interest' : 'Expressed'}
                </button>
              )
            )}

            {!loading && (
              <>
                {email === auth?.user?.email && auth !== undefined && (
                  <div
                    style={{
                      width: 190,
                      cursor: 'pointer',
                    }}
                    className="btn-primary-medium"
                    onClick={() =>
                      navigate(`/work/${bounty._id}`, { state: assessment[0] })
                    }
                  >
                    Go To Work Page
                  </div>
                )}
              </>
            )}
            {showTooltip && (
              <div className="express-interest-tooltip-placement">
                <ExpressInterestTooptip setShow={setShow} bounty={bounty} />
              </div>
            )}
            <ToastMessage
              show={show}
              tooltip_toast={true}
              setShow={setShow}
              autohide={false}
              message={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                  }}
                >
                  You have expressed your interest as an individual. User will
                  contact if your request is approved. Click confirm to proceed.
                  <span
                    style={{
                      paddingTop: '12px',
                    }}
                  >
                    <a
                      className="btn-continue"
                      href="/bounties"
                      style={{
                        float: 'right',
                        fontSize: '15px',
                        color: 'black',
                      }}
                    >
                      Confirm
                    </a>
                  </span>
                </div>
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
