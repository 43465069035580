import { useEffect, useState } from 'react';
import '../css/sharedStyles.css';
import '../../policyPage/css/policyComponents.css';
import { navigate } from '@reach/router';

export default function TermsOfServiceSideMenu({ setPolicy, setTitle, routeParams }) {
  const params = window.location.hash;
  const [active, setActive] = useState(routeParams.indexOf(params) || 0);

  useEffect(() => {
    setActive(routeParams.indexOf(params) || 0);
  }, [active, params, routeParams])

  const handleClick = (policy, title) => {
    setPolicy(policy);
    setActive(routeParams.indexOf(params));
    setTitle(title);
    navigate(`${routeParams[policy]}`);
  };

  return (
    <div className="menulabel">
      <div
        className={
          active === 0
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(0, 'Use Policies')}
      >
        <p data-testid='termsofuse_sidebar' style={{ marginLeft: '15px' }}>Terms of Use</p>
      </div>
      <div
        className={
          active === 1
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(1, 'General Privacy Policy')}
      >
        <p data-testid='generalprivacypolicy_sidebar' style={{ marginLeft: '15px' }}>General Privacy Policy</p>
      </div>
      <div
        className={
          active === 2
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(2, 'Cookie Policy')}
      >
        <p data-testid='cookiepolicy_sidebar' style={{ marginLeft: '15px' }}>Cookie Policy</p>
      </div>
      <div
        className={
          active === 3
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(3, 'California Consumer Privacy Notice')}
      >
        <p data-testid='californiaconsumerprivacynotice_sidebar' style={{ lineHeight: '1', marginLeft: '15px' }}>
          California Consumer
          <br />
          Privacy Notice
        </p>
      </div>
      <div
        className={
          active === 4
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(4, 'EU User Privacy Policy')}
      >
        <p data-testid='euuserprivacypolicy_sidebar' style={{ marginLeft: '15px' }}>EU User Privacy Policy</p>
      </div>
      <div
        className={
          active === 5
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(5, 'Data Processing and Security Addendum')}
      >
        <p data-testid='dataprocessingandsecurityaddendum_sidebar' style={{ lineHeight: '1', marginLeft: '15px' }}>
          Data Processing and
          <br />
          Security Addendum
        </p>
      </div>
      <div
        className={
          active === 6
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(6, 'Vulnerability Disclosure')}
      >
        <p data-testid='vulnerabilitydisclosure_sidebar' style={{ marginLeft: '15px' }}>Vulnerability Disclosure</p>
      </div>
    </div>
  );
}
