import React from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';

//CSS
import '../../shared/css/typography.css';
import '../css/teamSummaryProfile.css';
import Linkify from 'react-linkify';

const TeamSummaryDescription = ({ team }) => {
  const LinkifyWithTargetBlank = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
  console.log(team);
  return (
    <Container fluid>
      <Row>
        <Col>
          <div dangerouslySetInnerHTML={{ __html: team.opportunityId.taskDescription }} />
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <strong>Submission Requirements:</strong>
        </Col>
      </Row>
      <Row>
        <Col>{team.opportunityId.submissionRequirements}</Col>
      </Row>
      <Row>
        <Col className="my-3">
          <strong>Acceptence Criteria:</strong>
        </Col>
      </Row>
      <Row>
        <Col>{team.opportunityId.acceptenceCriteria}</Col>
      </Row>
      <Row>
        <Col className="my-3">
          <strong>Important Links:</strong>
        </Col>
      </Row>
      <Row className="mb-5">
        <Linkify componentDecorator={LinkifyWithTargetBlank}>
          <Col className="line-break">{team.opportunityId.importantLink}</Col>
        </Linkify>
      </Row>
    </Container>
  );
};

export default TeamSummaryDescription;
