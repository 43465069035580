import { Row, Col, Container, Accordion } from 'react-bootstrap';
import { Link } from '@reach/router';
import React, { useEffect, useMemo, useState } from 'react';
import '../css/scheduleBoard.css';

import MentorConfirmationModal from './mentorConfirmationModal';
import { useParams } from '@reach/router';
import { format } from 'date-fns';
import { buildCalendarUrl } from '../../shared/calendar';
import { useAuth } from '../../context/authContext';
import CustomListGroup from '../../shared/listGroup/customListGroup';
import defaultTimezones from '../data/timezone.json';
import SaveAndContinue from '../../shared/button/saveAndContinue';

export default function ScheduleBoard({ events }) {
  const { updateProfile } = useAuth();
  const { id } = useParams();
  const [showSummary, setShowSummary] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  
  const saveAndContinue = async () => {
    if (!events.length) {
      return setShowSummary(true);
    }
    try {
      await updateProfile({ schedules: events });
      setShowSummary(true);
    } catch (err) {}
  };

  useEffect(() => {
    if (events && events.length > 0)
      setActiveItem(events[events.length - 1].key)
  }, [events]);
  
  return (
    <Container className="p-4" fluid>
      <Row className="schedule_board_bg">
        <p className="schedule_board_content">Your Schedule</p>
        {events.length === 0 && 
          <div>
            <p>Your Schedule is empty, create a new meeting.</p>
          </div>
        }
        {events.length < 2 && events?.map((schedule) => <MettingDetails schedule={schedule} />)}
        {events.length > 1 && 
          <Accordion defaultActiveKey={activeItem} activeKey={activeItem} >
            {events?.map((schedule) => {
              return (<Accordion.Item eventKey={schedule.key} onClick={() => activeItem === schedule.key ? setActiveItem('') : setActiveItem(schedule.key) }>
                  <Accordion.Header>{`${weekDays[schedule.startTime.getDay()]} at ${format(schedule.startTime, "hh:mm aaaaa'm'")}`}</Accordion.Header>
                  <Accordion.Body>
                    <MettingDetails {...{ schedule }} />
                  </Accordion.Body>
                </Accordion.Item>
              )})}
          </Accordion>
        }
      </Row>
      <Row className="justify-content-center text-center mt-5 pb-2 justify-content-lg-end">
        <Col xs={3} lg={4} xxl={1} >
          <button className="schedule_board_back_button">
            <Link to={`/bounties/${id}/team-mentor`}>back</Link>
          </button>
        </Col>
        <Col xs={9} lg={8}>
          <MentorConfirmationModal 
            show={showSummary}
            bountyId={id}
            setShowSummary={setShowSummary}
            events={events}
          />

          <SaveAndContinue
            onClick={() => saveAndContinue()}
          />

        </Col>
      </Row>
    </Container>
  );
}

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const MettingDetails = React.memo(({ schedule }) => {

  const days = schedule?.recurring?.days
    .map((x) => {
      return x?.getDay() ? weekDays[x?.getDay()] : null;
    })
    .filter(Boolean)
    .join(' and ');
  
  const currentSchedule = {
    ...schedule,
  };
  currentSchedule.recurring.byDay = schedule.recurring.days
    .map((x) => {
      return x?.getDay() ? ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][x?.getDay()] : null;
    })
    .filter(Boolean)
    .join(',');

  const timeZoneLabel = useMemo(() => {
    const timeZoneText = defaultTimezones.find(_timezone => _timezone.value === schedule?.timezone);
    return timeZoneText ? timeZoneText.label : schedule?.timezone;
  }, [schedule])

  const eventFields = [
    { key: 'meeting_name', text: 'Meeting Name:', subText: schedule?.title === "" ? 'N/a' : schedule?.title, badge: false, badgeText: '' },
    { key: 'meeting_description', text: 'Meeting Description:', subText: schedule?.description === "" ? 'N/a' : schedule?.description, badge: false, badgeText: '' },
    {
      key: 'meeting_time',
      text: 'Meeting Time:',
      subText: `${schedule?.from.hour}:${schedule?.from.minute} ${schedule?.from.period} - ${schedule?.to.hour}:${schedule?.to.minute} ${schedule?.to.period}`,
      badge: false, badgeText: ''
    },
    { key: 'meeting_timezone', text: 'Meeting Time Zone:', subText: timeZoneLabel, badge: false, badgeText: '' },
  ];
  return (
          <div key={schedule.key}>
            <div key={schedule.key} className="lh-1 p-2">
              <span className="schedule_board_content">
                {`${days} at ${format(schedule.startTime, "hh:mm aaaaa'm'")}`}{' '}
                <br />
                <a
                  className="mentor-conf-modal-calendar-link border-0 bg-light underline p-0"
                  target="_blank"
                  rel="noreferrer"
                  href={buildCalendarUrl(currentSchedule, 'google')}
                >
                  Click here to add to calendar
                </a>
              </span>
            </div>
            <CustomListGroup groupKey={schedule.key} items={eventFields} numbered={false} />
          </div>
  );
})
