import { useState } from 'react';
import * as JobApi from '../../api/job';
import * as OpportunityApi from '../../api/opportunities';
import _ from 'lodash';

const EEOFormViewModel = () => {
  const [applicant, setJobApplicant] = useState({});
  const [application, setJobApplication] = useState({});
  const [job, setJob] = useState({});
  const [eeoForm, setEEOForm] = useState({
    gender: [],
    ethnicity: [],
    age: [],
    veteranStatus: [],
    impairmentStatus: [],
    neurodivergence: [],
    degree: [],
  });

  const getJobApplication = async (job, userId) => {
    let result = _.noop();
    const application = await OpportunityApi.getAssessments(job);
    if (!_.isNil(application)) {
      const UserApplication = application.filter((item) => item.candidateId?._id === userId)
      setJob(UserApplication[0].opportunityId);
      setJobApplicant(UserApplication[0].candidateId);
      setJobApplication(UserApplication[0]);
      result = UserApplication[0];
    }
    return result;
  };
  const submitForm = async () => {
    let result = _.noop();
    const form = await OpportunityApi.submitEEOForm(applicant._id, eeoForm);
    if (!_.isNil(form)) {
      setEEOForm({
        gender: form?.gender || [],
        ethnicity: form?.ethnicity || [],
        age: form?.age || [],
        veteranStatus: form?.veteranStatus || [],
        impairmentStatus: form?.impairmentStatus || [],
        neurodivergence: form?.neurodivergence || [],
        degree: form?.degree || [],
      });
      result = form;
    }
    return result;
  };
  const editForm = async (formId) => {
    let result = _.noop();
    const form = await OpportunityApi.editEEOForm(applicant._id, formId, eeoForm);
    if (!_.isNil(form)) {
      setEEOForm({
        gender: form?.gender || [],
        ethnicity: form?.ethnicity || [],
        age: form?.age || [],
        veteranStatus: form?.veteranStatus || [],
        impairmentStatus: form?.impairmentStatus || [],
        neurodivergence: form?.neurodivergence || [],
        degree: form?.degree || [],
      });
      result = form;
    }
    return result;
  };
  const getEEOForm = async (applicantId) => {
    let result = _.noop();
    if (!_.isNil(applicantId)) {
      const form = await OpportunityApi.getOpportunityEEOForm(applicantId);
      if (!_.isNil(form)) {
        setEEOForm({
          gender: form?.gender || [],
          ethnicity: form?.ethnicity || [],
          age: form?.age || [],
          veteranStatus: form?.veteranStatus || [],
          impairmentStatus: form?.impairmentStatus || [],
          neurodivergence: form?.neurodivergence || [],
          degree: form?.degree || [],
        });
      }
      result = form;
    }
    return result;
  };
  const eeoFormFilled = () => {
    let result = false;
    if (!_.isNil(eeoForm)) {
      return (
        eeoForm.gender !== [] &&
        eeoForm.ethnicity !== [] &&
        eeoForm.age !== [] &&
        eeoForm.veteranStatus &&
        eeoForm.impairmentStatus !== [] &&
        eeoForm.neurodivergence !== [] &&
        eeoForm.degree !== []
      );
    }
    return result;
  };
  const submitJobApplication = async (data) => {
    return await OpportunityApi.submitOpportunity(
      application._id,
      data,
    );
  };
  return {
    applicant,
    application,
    job,
    eeoForm,
    getJobApplication,
    submitForm,
    editForm,
    getEEOForm,
    eeoFormFilled,
    setEEOForm,
    submitJobApplication,
  };
};
export default EEOFormViewModel;
