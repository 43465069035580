//COMPONENTS
import { Container, Row } from 'react-bootstrap';
import GrantDescription from './grantDescription';

//CSS
import '../../shared/css/tabs.css';

const GrantSetupTabs = ({ grant, review, teamMember, funders }) => {
  return (
    <Container className="pt-5">
      <Row>
        <ul className="tabs-general">
          <li>
            <a href="#">Description</a>
          </li>
          <div className="tabs-underline"></div>
          <hr className="tabs-hr" />
        </ul>
      </Row>
      <Row>
        <GrantDescription
          grant={grant}
          teamMember={teamMember}
          funders={funders}
        />
      </Row>
    </Container>
  );
};

export default GrantSetupTabs;
