import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';
import * as orderCandidateAPI from '../api/order';
import { navigate } from '@reach/router';
import { createStripeSession, listStripeAccounts } from '../api/session';
import { addCardanoWallet, removeCardanoWallet } from '../api/bountyCandidates';
import { useAuth } from './authContext';
import { listCryptoAccounts } from '../api/session';
import { attachBank } from '../api/session';
import { formatWalletName } from '../shared/utils';

export const CartContext = createContext();
export const useCart = () => useContext(CartContext);

export function CartProvider({ children }) {
  const [orderId, setOrderId] = useState([]);
  const [cart, setCart] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [cashOut, setCashOut] = useState({
    amount: 0,
    payment: '',
  });
  const [canCashOut, setCanCashOut] = useState(false);
  const [cryptoAccounts, setCryptoAccounts] = useState([]);
  const [cartModal, setCartModal] = useState(false);
  const [linkToken, setLinkToken] = useState('');
  const [isValidCart, setValidCart] = useState(false);
  const [cardanoAddress, setCardanoAddress] = useState('');
  const [cardanoWalletConnected, setCardanoWalletConnected] = useState(false);
  const [connectedWalletName, setConnectedWalletName] = useState('');
  const [cardanoPubKeyAddress, setCardanoPubKeyAddress] = useState('');
  const [isStaging, setIsStaging] = useState(false);
  const { auth } = useAuth();

  const getEnviorment = () => {
    const url = window.location.href;
    if (url.includes('staging') || url.includes('localhost')) {
      setIsStaging(true);
    }
  };

  useEffect(() => {
    getEnviorment();
  }, []);

  const connectCardanoWallet = async (wallet) => {
    if (auth) {
      const walletName = formatWalletName(wallet);
      toast(`Connecting to ${walletName}...`);
      try {
        const walletApi = await window.cardano[wallet].enable();
        const changeAddress = await walletApi.getChangeAddress();
        const userWalletNetwork = await walletApi.getNetworkId();
        const networkId = isStaging ? 0 : 1;
        if (userWalletNetwork !== networkId) {
          toast(
            `Please connect to the ${
              networkId === 1 ? 'Mainnet' : 'Preprod'
            } network`
          );
          return;
        }
        await addCardanoWallet(walletName, changeAddress);
        toast(`Connected to ${walletName} successfully!`);
        await loadCryptoInfo();
        setConnectedWalletName(walletName);
        return walletApi;
      } catch (error) {
        toast(`Failed to connect to ${walletName}`);
      }
    } else {
      toast('Please login to connect your wallet');
    }
  };

  const connectBankAccount = async () => {
    const session = await createStripeSession();
    setLinkToken(session.client_secret);
  };

  const loadBankInfo = async () => {
    try {
      const response = await listStripeAccounts();
      if (response) {
        setBankAccounts(response);
      }
      return response;
    } catch (error) {
      // toast(error.message);
    }
  };

  const attachBankToAccount = async (token) => {
    let result = false;
    try {
      const response = await attachBank(token);
      if (response) {
        result = true;
      }
    } catch (error) {
      result = false;
    }
    return result;
  };

  const loadCryptoInfo = async () => {
    try {
      const connectedWallets = await listCryptoAccounts();
      if (connectedWallets.length > 0) {
        if (!connectedWallets[0].walletAddress) {
          return;
        }
        setCryptoAccounts([connectedWallets[0]]);
        setCardanoAddress(connectedWallets[0].walletAddress);
        setCardanoWalletConnected(true);
        setConnectedWalletName(connectedWallets[0].walletName);
        setCardanoPubKeyAddress(connectedWallets[0].pubKeyAddress);
      }
      return connectedWallets;
    } catch (error) {
      toast('Error loading connected wallets');
    }
  };

  useEffect(() => {
    if (auth) {
      loadCryptoInfo();
      loadBankInfo();
      orderCandidateAPI
        .getMyCart()
        .then((data) => {
          setOrderId(data._id);
          setCart(data.items);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [auth]);

  const accounts = useMemo(() => {
    let userAccounts = [];
    bankAccounts?.map((account) => {
      userAccounts.push({
        type: 'bank',
        name: `${account.institution_name} (...${account.last4})`,
        id: account.id,
      });
      return account;
    });
    cryptoAccounts.map((account) => {
      userAccounts.push({
        type: 'crypto',
        name: account.walletName,
        id: account._id,
        pubKeyAddress: account.pubKeyAddress,
        walletAddress: account.walletAddress,
      });
      return account;
    });
    return userAccounts;
  }, [bankAccounts, cryptoAccounts]);

  useEffect(() => {
    checkValidCart(cart);
  }, [cart]);

  const checkValidCart = (cart) => {
    const isValidCart =
      cart.length > 0 &&
      cart.every((item) => {
        return item.id && item.payment && Number(item.amount) > 0;
      });
    setValidCart(isValidCart);
  };

  // current qvf only allows one donation from a single donor at once ,
  // so we need to make sure that the cart only has one item, the previous cart code
  // is commented out
  const addItem = (item) => {
    // const isInCart = cart.find((element) => element.id === item._id);
    // if (!isInCart) {
    //   setCart((prevState) => [
    //     ...prevState,
    //     {
    //       id: item._id,
    //       amount: 0,
    //       grant: item,
    //     },
    //   ]);
    // }
    setCart([
      {
        id: item._id,
        amount: 0,
        grant: item,
      },
    ]);
    navigate('/view-cart-page');
  };

  const removeItem = async (item) => {
    setCart(cart.filter((e) => e.id !== item.id));
    await orderCandidateAPI.removeItemFromCart(item.id);
  };

  const updateItem = (item, data) => {
    setCart(
      cart.map((obj) => {
        if (obj.id === item.id) {
          return {
            ...obj,
            ...data,
          };
        }
        return obj;
      })
    );
  };

  const clearCartItems = () => {
    setCart([]);
  };

  const saveCart = async () => {
    try {
      await orderCandidateAPI.addToCart({ items: cart });
    } catch (error) {
      // toast(error.message);
    }
  };

  const purchase = async (redirect) => {
    try {
      const oldCartId = orderId;
      await orderCandidateAPI.addToCart({ items: cart });
      await orderCandidateAPI.confirmOrder(orderId);
      await orderCandidateAPI.getMyCart().then((data) => {
        setOrderId(data._id);
        setCart(data.items);
      });
      if (redirect) {
        navigate(`/orders/${oldCartId}`);
      }
      return oldCartId;
    } catch (error) {
      // toast(error.message);
    }
  };

  const disconnectCardanoWallet = async () => {
    await removeCardanoWallet();
    setCryptoAccounts([]);
    setCardanoWalletConnected(false);
    setConnectedWalletName('');
    setCardanoPubKeyAddress('');
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        isStaging,
        addItem,
        removeItem,
        updateItem,
        setCart,
        cartModal,
        setCartModal,
        connectBankAccount,
        loadBankInfo,
        linkToken,
        accounts,
        bankAccounts,
        cryptoAccounts,
        isValidCart,
        orderId,
        saveCart,
        connectCardanoWallet,
        cashOut,
        setCashOut,
        loadCryptoInfo,
        purchase,
        clearCartItems,
        disconnectCardanoWallet,
        attachBankToAccount,
        cardanoAddress,
        cardanoWalletConnected,
        connectedWalletName,
        cardanoPubKeyAddress,
        canCashOut,
        setCanCashOut,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
