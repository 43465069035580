import React from 'react';
import Container from 'react-bootstrap/Container';
import Processing from '../../assets/Processing.png';
import { Link } from '@reach/router';
import './css/notFound.css';

const NotFound = ({
  text = 'The page you were looking for does not exist.',
}) => {
  return (
    <Container  
      style={{
        overflow: 'hidden',
      }}
      className=" vh-80"
    >
      <div className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center py-5">
      <img
          src={Processing}
          alt="processingImage"
          style={{ float: 'center' }}
          height={'50%'}
          width={'50%'}
        />
          <h1 className="display-1">404</h1>
          <h2>{text}</h2>
          <Link to="/" className="link-home">
            Go Home
          </Link>
        </div>
      </div>
    </Container>
  );
};
export default NotFound;
