import { Modal, Button } from 'react-bootstrap';

const ConstructingTransactionModal = ({
  showModal,
  networkBusy,
  handleTryLater,
  handleTransactionQueue,
  queueModalLoading,
}) => {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
    >
      <Modal.Body>
        {networkBusy ? (
          <>
            <h4>Network Busy</h4>
            <p>
              The network is busy at the moment. You can try again later and we
              will save a draft of your current grant information for you.
              Alternatively, you can send ADA to a custodial wallet and we will
              create and send the transaction on your behalf.
            </p>
            {queueModalLoading ? (
              <>
                <div className="spinner-border match-pool-color" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%',
                }}
              >
                <Button onClick={handleTryLater}>Try Again Later</Button>
                <Button onClick={handleTransactionQueue}>
                  Custodial Wallet
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <h4>Creating Transaction</h4>
            <div className="spinner-border match-pool-color" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ConstructingTransactionModal;
