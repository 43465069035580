import React, { useContext, Fragment } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../css/inviteFunders.css';
import 'bootstrap/dist/css/bootstrap.css';
import Close from '../../assets/icon/snapbrillia_close_red_icon.svg';
import { GrantSetupContext } from './grantSetup';
import { getHashCode } from '../../shared/utils';

const InviteFunders = ({ isEdit }) => {
  const { funders, setFunders } = useContext(GrantSetupContext);
  return (
    <Container className="invite-funders-container" fluid>
      <Row className="invite-funders-title">
        <div>Invite Funders</div>
      </Row>
      {funders.map((funder, index) => {
        return (
          <Fragment key={funder._id}>
            <Row className="invite-funders-row1 d-flex justify-content-between">
              <div htmlFor="name" className="grant-setup-tag">
                Funder&apos;s Name
              </div>
              {(!isEdit || funder.isNew) && funders.length > 1 && (
                <div
                  onClick={() => {
                    setFunders([...funders.filter(_founder => _founder._id !== funder._id)]);
                  }}
                  className="grant-setup-close-icon"
                >
                  <img src={Close} alt="close-icon" />
                </div>
              )}
              <div className="input-group">
                <input
                  readOnly={isEdit && !funder.isNew}
                  value={funder.fullName}
                  onChange={(e) => {
                    let copy = [...funders];
                    copy[index].fullName = e.target.value;
                    if (e.target.value === '') delete copy[index].fullName;
                    setFunders(copy);
                  }}
                  type="text"
                  className="form-control invite-funders-control"
                />
              </div>
            </Row>
            <Row className="invite-funders-row2">
              <label htmlFor="name" className="grant-setup-tag">
                Funder&apos;s Email
              </label>
              <div className="input-group">
                <input
                  readOnly={isEdit && !funder.isNew}
                  value={funder.email}
                  onChange={(e) => {
                    let copy = [...funders];
                    copy[index].email = e.target.value;
                    if (e.target.value === '') delete copy[index].email;
                    setFunders(copy);
                  }}
                  type="text"
                  className="form-control invite-funders-control"
                />
              </div>
            </Row>
          </Fragment>
        )
      })}
      <Row className="d-flex justify-content-end">
        <div
          className="invite-funders-add"
          onClick={() => setFunders([...funders, { isNew: true, _id: getHashCode() }])}
        >
          Add Another Funder
        </div>
      </Row>
    </Container>
  );
};

export default InviteFunders;
