import React, { useState, useEffect } from 'react';

//COMPONENTS
import { Row, Col } from 'react-bootstrap';
import BountyIllustratoin from '../assets/illustrations/snapbrillia_bounty_page_illustration.png';
import NavBar from '../shared/menus/navBar';
import AllBounties from './components/allBounties';
// import FeaturedBounties from './components/featuredBounties';
import BountySideMenu from '../shared/menus/bountySideMenu';
import CreateNewBountyButton from '../shared/button/createNewBounty';

//CSS
import '../shared/css/sharedStyles.css';
// import { Link } from '@reach/router';

export default function Bounties() {
  const [bountiesFilters, setBountiesFilters] = useState({
    search: '',
    status: null,
    skills: '',
  });
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  window.addEventListener('scroll', handleScroll, { passive: true });

  return (
    <>
      <NavBar />
      <Row>
        <Col xs={12} lg={7}>
          <Row>
            <div data-testid="pageTitle" className="h1 px-5 py-2">
              Opportunities
            </div>
          </Row>
          <Row className="px-5" data-testid="pageDesc">
            <span className="p-0" style={{ color: '#808080' }}>
              Snapbrillia’s <span style={{ fontWeight: 'bold' }}>Gigs and Job Postings</span> are excellent opportunities to
              learn, grow, and earn some dough. Flex your skills and support the
              community by fixing bugs and helping the best and brightest build
              the solutions of tomorrow. <br />
              <br />
              <span style={{ fontWeight: 'bold' }}>Gigs</span> are competitive contract projects. Participants work on a project and submit their work. The organization chooses the winner whose submission works best with the requirements and who receives payment.
              <br />
              Apply as a <span style={{ fontWeight: 'bold' }}>Mentor</span> if
              you feel like you’re ready to lead a team; a{' '}
              <span style={{ fontWeight: 'bold' }}>{'Mentee'}</span> if you’re
              looking to learn more; or as an{' '}
              <span style={{ fontWeight: 'bold' }}>{'Individual'}</span> if you
              want to tackle a problem on your own. <br />
              <br />
              For <span style={{ fontWeight: 'bold' }}>Job Postings,</span> explore full-time and part-time roles that suit
              your expertise and career goals. Check out the exciting gigs and
              job opportunities that await you below! Check out the exciting
              projects that await you below!
              <br />
            </span>
            <div align="right" className="pt-3">
              <CreateNewBountyButton />
            </div>
          </Row>
        </Col>
        <Col lg={5} className="d-none d-lg-block">
          <div className="px-3">
            <img className="w-100" src={BountyIllustratoin} alt="bounti-img" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={3} className="side-menu">
          <BountySideMenu
            scrollPosition={scrollPosition}
            bountiesFilters={bountiesFilters}
            setBountiesFilters={setBountiesFilters}
          />
        </Col>
        <Col>
          {/* <Row>
            <FeaturedBounties />
          </Row> */}
          <Row>
            <AllBounties bountiesFilters={bountiesFilters} />
          </Row>
          {scrollPosition > 443 && (
            <div
              onClick={() => {
                topFunction();
              }}
              style={{
                position: 'fixed',
                bottom: 10,
                right: 15,
                cursor: 'pointer',
                zIndex:100,
              }}
            >
              <i
                className="fa fa-solid fa-arrow-up"
                style={{
                  color: '#fff',
                  backgroundColor: '#a900a6',
                  fontSize: 20,
                  width: 40,
                  height: 40,
                  borderRadius: '30%',
                  alignItems: 'center',
                  textAlign: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              ></i>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
