import React from 'react';
import '../css/policyComponents.css'

const height = window.innerHeight;
const width = window.innerWidth;

const MSAPolicy = () => {
  return (
    <div className="policy-wrapper policy-typography">
      <div className="main-policy-container" data-testid='msaPolicyComponent'>
      <iframe
          className='msa-frame'
          style={{
            height: height,
            width: width,
          }}
          title="MSA"
          src="https://docs.google.com/document/d/e/2PACX-1vQcnPXMu-r7Z-MMx9wSUziQcc2hCJP2D774VkYSbsbMBhmgcElRyYMe1aLf6IHEKOhJPmI9PJxVCMkQ/pub?embedded=true"
        ></iframe>
      </div>
    </div>
  );
};

export default MSAPolicy;
