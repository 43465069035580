export const valueAreaList = [
    {
        "name": "Afghanistan",
        "dial_code": "+93",
        "code": "AF",
        "masks": "+93-##-###-####"
    },
    {
        "name": "Aland Islands",
        "dial_code": "+358",
        "code": "AX"
    },
    {
        "name": "Albania",
        "dial_code": "+355",
        "code": "AL",
        "masks": "+355(###)###-###"
    },
    {
        "name": "Algeria",
        "dial_code": "+213",
        "code": "DZ",
        "masks": "+213-##-###-####"
    },
    {
        "name": "AmericanSamoa",
        "dial_code": "+1684",
        "code": "AS",
        "masks": "+1(684)###-####"
    },
    {
        "name": "Andorra",
        "dial_code": "+376",
        "code": "AD",
        "masks": "+376-###-###"
    },
    {
        "name": "Angola",
        "dial_code": "+244",
        "code": "AO",
        "masks": "+244(###)###-###"
    },
    {
        "name": "Anguilla",
        "dial_code": "+1264",
        "code": "AI",
        "masks": "+1(264)###-####"
    },
    {
        "name": "Antarctica",
        "dial_code": "+672",
        "code": "AQ",
        "masks": "+672-1##-###"
    },
    {
        "name": "Antigua and Barbuda",
        "dial_code": "+1268",
        "code": "AG",
        "masks": "+1(268)###-####"
    },
    {
        "name": "Argentina",
        "dial_code": "+54",
        "code": "AR",
        "masks": "+54(###)###-####"
    },
    {
        "name": "Armenia",
        "dial_code": "+374",
        "code": "AM",
        "masks": "+374-##-###-###"
    },
    {
        "name": "Aruba",
        "dial_code": "+297",
        "code": "AW",
        "masks": "+297-###-####"
    },
    {
        "name": "Australia",
        "dial_code": "+61",
        "code": "AU",
        "masks": "+61-#-####-####"
    },
    {
        "name": "Austria",
        "dial_code": "+43",
        "code": "AT",
        "masks": "+43(###)###-####"
    },
    {
        "name": "Azerbaijan",
        "dial_code": "+994",
        "code": "AZ",
        "masks": "+994-##-###-##-##"
    },
    {
        "name": "Bahamas",
        "dial_code": "+1242",
        "code": "BS",
        "masks": "+1(242)###-####"
    },
    {
        "name": "Bahrain",
        "dial_code": "+973",
        "code": "BH",
        "masks": "+973-####-####"
    },
    {
        "name": "Bangladesh",
        "dial_code": "+880",
        "code": "BD",
        "masks": "+880-##-###-###"
    },
    {
        "name": "Barbados",
        "dial_code": "+1246",
        "code": "BB",
        "masks": "+1(246)###-####"
    },
    {
        "name": "Belarus",
        "dial_code": "+375",
        "code": "BY",
        "masks": "+375(##)###-##-##"
    },
    {
        "name": "Belgium",
        "dial_code": "+32",
        "code": "BE",
        "masks": "+32(###)###-###"
    },
    {
        "name": "Belize",
        "dial_code": "+501",
        "code": "BZ",
        "masks": "+501-###-####"
    },
    {
        "name": "Benin",
        "dial_code": "+229",
        "code": "BJ",
        "masks": "+229-##-##-####"
    },
    {
        "name": "Bermuda",
        "dial_code": "+1441",
        "code": "BM",
        "masks": "+1(441)###-####"
    },
    {
        "name": "Bhutan",
        "dial_code": "+975",
        "code": "BT",
        "masks": "+975-#-###-###"
    },
    {
        "name": "Bolivia, Plurinational State of",
        "dial_code": "+591",
        "code": "BO",
        "masks": "+591-#-###-####"
    },
    {
        "name": "Bosnia and Herzegovina",
        "dial_code": "+387",
        "code": "BA",
        "masks": "+387-##-####"
    },
    {
        "name": "Botswana",
        "dial_code": "+267",
        "code": "BW",
        "masks": "+267-##-###-###"
    },
    {
        "name": "Brazil",
        "dial_code": "+55",
        "code": "BR",
        "masks": "+55(##)9####-####"
    },
    {
        "name": "British Indian Ocean Territory",
        "dial_code": "+246",
        "code": "IO",
        "masks": "+246-###-####"
    },
    {
        "name": "Brunei Darussalam",
        "dial_code": "+673",
        "code": "BN",
        "masks": "+673-###-####"
    },
    {
        "name": "Bulgaria",
        "dial_code": "+359",
        "code": "BG",
        "masks": "+359(###)###-###"
    },
    {
        "name": "Burkina Faso",
        "dial_code": "+226",
        "code": "BF",
        "masks": "+226-##-##-####"
    },
    {
        "name": "Burundi",
        "dial_code": "+257",
        "code": "BI",
        "masks": "+257-##-##-####"
    },
    {
        "name": "Cambodia",
        "dial_code": "+855",
        "code": "KH",
        "masks": "+855-##-###-###"
    },
    {
        "name": "Cameroon",
        "dial_code": "+237",
        "code": "CM",
        "masks": "+237-####-####"
    },
    {
        "name": "Canada",
        "dial_code": "+1",
        "code": "CA",
        "masks": "+1(###)###-####"
    },
    {
        "name": "Cape Verde",
        "dial_code": "+238",
        "code": "CV",
        "masks": "+238(###)##-##"
    },
    {
        "name": "Cayman Islands",
        "dial_code": "+ 345",
        "code": "KY",
        "masks": "+1(345)###-####"
    },
    {
        "name": "Central African Republic",
        "dial_code": "+236",
        "code": "CF",
        "masks": "+236-##-##-####"
    },
    {
        "name": "Chad",
        "dial_code": "+235",
        "code": "TD",
        "masks": "+235-##-##-##-##"
    },
    {
        "name": "Chile",
        "dial_code": "+56",
        "code": "CL",
        "masks": "+56-#-####-####"
    },
    {
        "name": "China",
        "dial_code": "+86",
        "code": "CN",
        "masks": "+86-##-#####-#####"
    },
    {
        "name": "Christmas Island",
        "dial_code": "+61",
        "code": "CX"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "dial_code": "+61",
        "code": "CC"
    },
    {
        "name": "Colombia",
        "dial_code": "+57",
        "code": "CO",
        "masks": "+57(###)###-####"
    },
    {
        "name": "Comoros",
        "dial_code": "+269",
        "code": "KM",
        "masks": "+269-##-#####"
    },
    {
        "name": "Congo",
        "dial_code": "+242",
        "code": "CG",
        "masks": "+242-##-###-####"
    },
    {
        "name": "Congo, The Democratic Republic of the Congo",
        "dial_code": "+243",
        "code": "CD",
        "masks": "+243(###)###-###"
    },
    {
        "name": "Cook Islands",
        "dial_code": "+682",
        "code": "CK",
        "masks": "+682-##-###"
    },
    {
        "name": "Costa Rica",
        "dial_code": "+506",
        "code": "CR",
        "masks": "+506-####-####"
    },
    {
        "name": "Cote d'Ivoire",
        "dial_code": "+225",
        "code": "CI",
        "masks": "+225-##-###-###"
    },
    {
        "name": "Croatia",
        "dial_code": "+385",
        "code": "HR",
        "masks": "+385-##-###-###"
    },
    {
        "name": "Cuba",
        "dial_code": "+53",
        "code": "CU",
        "masks": "+53-#-###-####"
    },
    {
        "name": "Cyprus",
        "dial_code": "+357",
        "code": "CY",
        "masks": "+357-##-###-###"
    },
    {
        "name": "Czech Republic",
        "dial_code": "+420",
        "code": "CZ",
        "masks": "+420(###)###-###"
    },
    {
        "name": "Denmark",
        "dial_code": "+45",
        "code": "DK",
        "masks": "+45-##-##-##-##"
    },
    {
        "name": "Djibouti",
        "dial_code": "+253",
        "code": "DJ",
        "masks": "+253-##-##-##-##"
    },
    {
        "name": "Dominica",
        "dial_code": "+1767",
        "code": "DM",
        "masks": "+1(767)###-####"
    },
    {
        "name": "Dominican Republic",
        "dial_code": "+1849",
        "code": "DO",
        "masks": "+1(849)###-####"
    },
    {
        "name": "Ecuador",
        "dial_code": "+593",
        "code": "EC",
        "masks": "+593-#-###-####"
    },
    {
        "name": "Egypt",
        "dial_code": "+20",
        "code": "EG",
        "masks": "+20(###)###-####"
    },
    {
        "name": "El Salvador",
        "dial_code": "+503",
        "code": "SV",
        "masks": "+503-##-##-####"
    },
    {
        "name": "Equatorial Guinea",
        "dial_code": "+240",
        "code": "GQ",
        "masks": "+240-##-###-####"
    },
    {
        "name": "Eritrea",
        "dial_code": "+291",
        "code": "ER",
        "masks": "+291-#-###-###"
    },
    {
        "name": "Estonia",
        "dial_code": "+372",
        "code": "EE",
        "masks": "+372-###-####"
    },
    {
        "name": "Ethiopia",
        "dial_code": "+251",
        "code": "ET",
        "masks": "+251-##-###-####"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "dial_code": "+500",
        "code": "FK",
        "masks": "+500-#####"
    },
    {
        "name": "Faroe Islands",
        "dial_code": "+298",
        "code": "FO",
        "masks": "+298-###-###"
    },
    {
        "name": "Fiji",
        "dial_code": "+679",
        "code": "FJ",
        "masks": "+679-##-#####"
    },
    {
        "name": "Finland",
        "dial_code": "+358",
        "code": "FI",
        "masks": "+358(###)###-##-##"
    },
    {
        "name": "France",
        "dial_code": "+33",
        "code": "FR",
        "masks": "+590(###)###-###"
    },
    {
        "name": "French Guiana",
        "dial_code": "+594",
        "code": "GF",
        "masks": "+594-#####-####"
    },
    {
        "name": "French Polynesia",
        "dial_code": "+689",
        "code": "PF",
        "masks": "+689-##-##-##"
    },
    {
        "name": "Gabon",
        "dial_code": "+241",
        "code": "GA",
        "masks": "+241-#-##-##-##"
    },
    {
        "name": "Gambia",
        "dial_code": "+220",
        "code": "GM",
        "masks": "+220(###)##-##"
    },
    {
        "name": "Georgia",
        "dial_code": "+995",
        "code": "GE",
        "masks": "+995(###)###-###"
    },
    {
        "name": "Germany",
        "dial_code": "+49",
        "code": "DE",
        "masks": "+49-###-###"
    },
    {
        "name": "Ghana",
        "dial_code": "+233",
        "code": "GH",
        "masks": "+233(###)###-###"
    },
    {
        "name": "Gibraltar",
        "dial_code": "+350",
        "code": "GI",
        "masks": "+350-###-#####"
    },
    {
        "name": "Greece",
        "dial_code": "+30",
        "code": "GR",
        "masks": "+30(###)###-####"
    },
    {
        "name": "Greenland",
        "dial_code": "+299",
        "code": "GL",
        "masks": "+299-##-##-##"
    },
    {
        "name": "Grenada",
        "dial_code": "+1473",
        "code": "GD",
        "masks": "+1(473)###-####"
    },
    {
        "name": "Guadeloupe",
        "dial_code": "+590",
        "code": "GP"
    },
    {
        "name": "Guam",
        "dial_code": "+1671",
        "code": "GU",
        "masks": "+1(671)###-####"
    },
    {
        "name": "Guatemala",
        "dial_code": "+502",
        "code": "GT",
        "masks": "+502-#-###-####"
    },
    {
        "name": "Guernsey",
        "dial_code": "+44",
        "code": "GG"
    },
    {
        "name": "Guinea",
        "dial_code": "+224",
        "code": "GN",
        "masks": "+224-##-###-###"
    },
    {
        "name": "Guinea-Bissau",
        "dial_code": "+245",
        "code": "GW",
        "masks": "+245-#-######"
    },
    {
        "name": "Guyana",
        "dial_code": "+595",
        "code": "GY",
        "masks": "+592-###-####"
    },
    {
        "name": "Haiti",
        "dial_code": "+509",
        "code": "HT",
        "masks": "+509-##-##-####"
    },
    {
        "name": "Holy See (Vatican City State)",
        "dial_code": "+379",
        "code": "VA",
        "masks": "+39-6-698-#####"
    },
    {
        "name": "Honduras",
        "dial_code": "+504",
        "code": "HN",
        "masks": "+504-####-####"
    },
    {
        "name": "Hong Kong",
        "dial_code": "+852",
        "code": "HK",
        "masks": "+852-####-####"
    },
    {
        "name": "Hungary",
        "dial_code": "+36",
        "code": "HU",
        "masks": "+36(###)###-###"
    },
    {
        "name": "Iceland",
        "dial_code": "+354",
        "code": "IS",
        "masks": "+354-###-####"
    },
    {
        "name": "India",
        "dial_code": "+91",
        "code": "IN",
        "masks": "+91(####)###-###"
    },
    {
        "name": "Indonesia",
        "dial_code": "+62",
        "code": "ID",
        "masks": "+62(8##)###-##-###"
    },
    {
        "name": "Iran, Islamic Republic of Persian Gulf",
        "dial_code": "+98",
        "code": "IR",
        "masks": "+98(###)###-####"
    },
    {
        "name": "Iraq",
        "dial_code": "+964",
        "code": "IQ",
        "masks": "+964(###)###-####"
    },
    {
        "name": "Ireland",
        "dial_code": "+353",
        "code": "IE",
        "masks": "+353(###)###-###"
    },
    {
        "name": "Isle of Man",
        "dial_code": "+44",
        "code": "IM"
    },
    {
        "name": "Israel",
        "dial_code": "+972",
        "code": "IL",
        "masks": "+972-#-###-####"
    },
    {
        "name": "Italy",
        "dial_code": "+39",
        "code": "IT",
        "masks": "+39(###)####-###"
    },
    {
        "name": "Jamaica",
        "dial_code": "+1876",
        "code": "JM",
        "masks": "+1(876)###-####"
    },
    {
        "name": "Japan",
        "dial_code": "+81",
        "code": "JP",
        "masks": "+81(###)###-###"
    },
    {
        "name": "Jersey",
        "dial_code": "+44",
        "code": "JE"
    },
    {
        "name": "Jordan",
        "dial_code": "+962",
        "code": "JO",
        "masks": "+962-#-####-####"
    },
    {
        "name": "Kazakhstan",
        "dial_code": "+77",
        "code": "KZ",
        "masks": "+7(7##)###-##-##"
    },
    {
        "name": "Kenya",
        "dial_code": "+254",
        "code": "KE",
        "masks": "+254-###-######"
    },
    {
        "name": "Kiribati",
        "dial_code": "+686",
        "code": "KI",
        "masks": "+686-##-###"
    },
    {
        "name": "Korea, Democratic People's Republic of Korea",
        "dial_code": "+850",
        "code": "KP",
        "masks": "+850-####-#############"
    },
    {
        "name": "Korea, Republic of South Korea",
        "dial_code": "+82",
        "code": "KR",
        "masks": "+82-##-###-####"
    },
    {
        "name": "Kuwait",
        "dial_code": "+965",
        "code": "KW",
        "masks": "+965-####-####"
    },
    {
        "name": "Kyrgyzstan",
        "dial_code": "+996",
        "code": "KG",
        "masks": "+996(###)###-###"
    },
    {
        "name": "Laos",
        "dial_code": "+856",
        "code": "LA",
        "masks": "+856-##-###-###"
    },
    {
        "name": "Latvia",
        "dial_code": "+371",
        "code": "LV",
        "masks": "+371-##-###-###"
    },
    {
        "name": "Lebanon",
        "dial_code": "+961",
        "code": "LB",
        "masks": "+961-#-###-###"
    },
    {
        "name": "Lesotho",
        "dial_code": "+266",
        "code": "LS",
        "masks": "+266-#-###-####"
    },
    {
        "name": "Liberia",
        "dial_code": "+231",
        "code": "LR",
        "masks": "+231-##-###-###"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "dial_code": "+218",
        "code": "LY",
        "masks": "+218-21-###-####"
    },
    {
        "name": "Liechtenstein",
        "dial_code": "+423",
        "code": "LI",
        "masks": "+423(###)###-####"
    },
    {
        "name": "Lithuania",
        "dial_code": "+370",
        "code": "LT",
        "masks": "+370(###)##-###"
    },
    {
        "name": "Luxembourg",
        "dial_code": "+352",
        "code": "LU",
        "masks": "+352(###)###-###"
    },
    {
        "name": "Macao",
        "dial_code": "+853",
        "code": "MO",
        "masks": "+853-####-####"
    },
    {
        "name": "Macedonia",
        "dial_code": "+389",
        "code": "MK",
        "masks": "+389-##-###-###"
    },
    {
        "name": "Madagascar",
        "dial_code": "+261",
        "code": "MG",
        "masks": "+261-##-##-#####"
    },
    {
        "name": "Malawi",
        "dial_code": "+265",
        "code": "MW",
        "masks": "+265-#-####-####"
    },
    {
        "name": "Malaysia",
        "dial_code": "+60",
        "code": "MY",
        "masks": "+60-#-###-###"
    },
    {
        "name": "Maldives",
        "dial_code": "+960",
        "code": "MV",
        "masks": "+960-###-####"
    },
    {
        "name": "Mali",
        "dial_code": "+223",
        "code": "ML",
        "masks": "+223-##-##-####"
    },
    {
        "name": "Malta",
        "dial_code": "+356",
        "code": "MT",
        "masks": "+356-####-####"
    },
    {
        "name": "Marshall Islands",
        "dial_code": "+692",
        "code": "MH",
        "masks": "+692-###-####"
    },
    {
        "name": "Martinique",
        "dial_code": "+596",
        "code": "MQ",
        "masks": "+596(###)##-##-##"
    },
    {
        "name": "Mauritania",
        "dial_code": "+222",
        "code": "MR",
        "masks": "+222-##-##-####"
    },
    {
        "name": "Mauritius",
        "dial_code": "+230",
        "code": "MU",
        "masks": "+230-###-####"
    },
    {
        "name": "Mayotte",
        "dial_code": "+262",
        "code": "YT"
    },
    {
        "name": "Mexico",
        "dial_code": "+52",
        "code": "MX",
        "masks": "+52-##-##-####"
    },
    {
        "name": "Micronesia, Federated States of Micronesia",
        "dial_code": "+691",
        "code": "FM",
        "masks": "+691-###-####"
    },
    {
        "name": "Moldova",
        "dial_code": "+373",
        "code": "MD",
        "masks": "+373-####-####"
    },
    {
        "name": "Monaco",
        "dial_code": "+377",
        "code": "MC",
        "masks": "+377-##-###-###"
    },
    {
        "name": "Mongolia",
        "dial_code": "+976",
        "code": "MN",
        "masks": "+976-##-##-####"
    },
    {
        "name": "Montenegro",
        "dial_code": "+382",
        "code": "ME",
        "masks": "+382-##-###-###"
    },
    {
        "name": "Montserrat",
        "dial_code": "+1664",
        "code": "MS",
        "masks": "+1(664)###-####"
    },
    {
        "name": "Morocco",
        "dial_code": "+212",
        "code": "MA",
        "masks": "+212-##-####-###"
    },
    {
        "name": "Mozambique",
        "dial_code": "+258",
        "code": "MZ",
        "masks": "+258-##-###-###"
    },
    {
        "name": "Myanmar",
        "dial_code": "+95",
        "code": "MM",
        "masks": "+95-###-###"
    },
    {
        "name": "Namibia",
        "dial_code": "+264",
        "code": "NA",
        "masks": "+264-##-###-####"
    },
    {
        "name": "Nauru",
        "dial_code": "+674",
        "code": "NR",
        "masks": "+674-###-####"
    },
    {
        "name": "Nepal",
        "dial_code": "+977",
        "code": "NP",
        "masks": "+977-##-###-###"
    },
    {
        "name": "Netherlands",
        "dial_code": "+31",
        "code": "NL",
        "masks": "+31-##-###-####"
    },
    {
        "name": "Netherlands Antilles",
        "dial_code": "+599",
        "code": "AN",
        "masks": "+599-9###-####"
    },
    {
        "name": "New Caledonia",
        "dial_code": "+687",
        "code": "NC",
        "masks": "+687-##-####"
    },
    {
        "name": "New Zealand",
        "dial_code": "+64",
        "code": "NZ",
        "masks": "+64(###)###-####"
    },
    {
        "name": "Nicaragua",
        "dial_code": "+505",
        "code": "NI",
        "masks": "+505-####-####"
    },
    {
        "name": "Niger",
        "dial_code": "+227",
        "code": "NE",
        "masks": "+227-##-##-####"
    },
    {
        "name": "Nigeria",
        "dial_code": "+234",
        "code": "NG",
        "masks": "+234(###)###-####"
    },
    {
        "name": "Niue",
        "dial_code": "+683",
        "code": "NU",
        "masks": "+683-####"
    },
    {
        "name": "Norfolk Island",
        "dial_code": "+672",
        "code": "NF",
        "masks": "+672-3##-###"
    },
    {
        "name": "Northern Mariana Islands",
        "dial_code": "+1670",
        "code": "MP",
        "masks": "+1(670)###-####"
    },
    {
        "name": "Norway",
        "dial_code": "+47",
        "code": "NO",
        "masks": "+47(###)##-###"
    },
    {
        "name": "Oman",
        "dial_code": "+968",
        "code": "OM",
        "masks": "+968-##-###-###"
    },
    {
        "name": "Pakistan",
        "dial_code": "+92",
        "code": "PK",
        "masks": "+92(###)###-####"
    },
    {
        "name": "Palau",
        "dial_code": "+680",
        "code": "PW",
        "masks": "+680-###-####"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "dial_code": "+970",
        "code": "PS",
        "masks": "+970-##-###-####"
    },
    {
        "name": "Panama",
        "dial_code": "+507",
        "code": "PA",
        "masks": "+507-###-####"
    },
    {
        "name": "Papua New Guinea",
        "dial_code": "+675",
        "code": "PG",
        "masks": "+675(###)##-###"
    },
    {
        "name": "Paraguay",
        "dial_code": "+595",
        "code": "PY",
        "masks": "+595(###)###-###"
    },
    {
        "name": "Peru",
        "dial_code": "+51",
        "code": "PE",
        "masks": "+51(###)###-###"
    },
    {
        "name": "Philippines",
        "dial_code": "+63",
        "code": "PH",
        "masks": "+63(###)###-####"
    },
    {
        "name": "Pitcairn",
        "dial_code": "+872",
        "code": "PN"
    },
    {
        "name": "Poland",
        "dial_code": "+48",
        "code": "PL",
        "masks": "+48(###)###-###"
    },
    {
        "name": "Portugal",
        "dial_code": "+351",
        "code": "PT",
        "masks": "+351-##-###-####"
    },
    {
        "name": "Puerto Rico",
        "dial_code": "+1939",
        "code": "PR"
    },
    {
        "name": "Qatar",
        "dial_code": "+974",
        "code": "QA",
        "masks": "+974-####-####"
    },
    {
        "name": "Romania",
        "dial_code": "+40",
        "code": "RO",
        "masks": "+40-##-###-####"
    },
    {
        "name": "Russia",
        "dial_code": "+7",
        "code": "RU",
        "masks": "+7(###)###-##-##"
    },
    {
        "name": "Rwanda",
        "dial_code": "+250",
        "code": "RW",
        "masks": "+250(###)###-###"
    },
    {
        "name": "Reunion",
        "dial_code": "+262",
        "code": "RE",
        "masks": "+262-#####-####"
    },
    {
        "name": "Saint Barthelemy",
        "dial_code": "+590",
        "code": "BL"
    },
    {
        "name": "Saint Helena, Ascension and Tristan Da Cunha",
        "dial_code": "+290",
        "code": "SH",
        "masks": "+290-####"
    },
    {
        "name": "Saint Kitts and Nevis",
        "dial_code": "+1869",
        "code": "KN",
        "masks": "+1(869)###-####"
    },
    {
        "name": "Saint Lucia",
        "dial_code": "+1758",
        "code": "LC",
        "masks": "+1(758)###-####"
    },
    {
        "name": "Saint Martin",
        "dial_code": "+590",
        "code": "MF"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "dial_code": "+508",
        "code": "PM"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "dial_code": "+1784",
        "code": "VC",
        "masks": "+1(784)###-####"
    },
    {
        "name": "Samoa",
        "dial_code": "+685",
        "code": "WS",
        "masks": "+685-##-####"
    },
    {
        "name": "San Marino",
        "dial_code": "+378",
        "code": "SM",
        "masks": "+378-####-######"
    },
    {
        "name": "Sao Tome and Principe",
        "dial_code": "+239",
        "code": "ST",
        "masks": "+239-##-#####"
    },
    {
        "name": "Saudi Arabia",
        "dial_code": "+966",
        "code": "SA",
        "masks": "+966-#-###-####"
    },
    {
        "name": "Senegal",
        "dial_code": "+221",
        "code": "SN",
        "masks": "+221-##-###-####"
    },
    {
        "name": "Serbia",
        "dial_code": "+381",
        "code": "RS",
        "masks": "+381-##-###-####"
    },
    {
        "name": "Seychelles",
        "dial_code": "+248",
        "code": "SC",
        "masks": "+248-#-###-###"
    },
    {
        "name": "Sierra Leone",
        "dial_code": "+232",
        "code": "SL",
        "masks": "+232-##-######"
    },
    {
        "name": "Singapore",
        "dial_code": "+65",
        "code": "SG",
        "masks": "+65-####-####"
    },
    {
        "name": "Slovakia",
        "dial_code": "+421",
        "code": "SK",
        "masks": "+421(###)###-###"
    },
    {
        "name": "Slovenia",
        "dial_code": "+386",
        "code": "SI",
        "masks": "+386-##-###-###"
    },
    {
        "name": "Solomon Islands",
        "dial_code": "+677",
        "code": "SB",
        "masks": "+677-#####"
    },
    {
        "name": "Somalia",
        "dial_code": "+252",
        "code": "SO",
        "masks": "+252-#-###-###"
    },
    {
        "name": "South Africa",
        "dial_code": "+27",
        "code": "ZA",
        "masks": "+27-##-###-####"
    },
    {
        "name": "South Sudan",
        "dial_code": "+211",
        "code": "SS",
        "masks": "+211-##-###-####"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "dial_code": "+500",
        "code": "GS"
    },
    {
        "name": "Spain",
        "dial_code": "+34",
        "code": "ES",
        "masks": "+34(###)###-###"
    },
    {
        "name": "Sri Lanka",
        "dial_code": "+94",
        "code": "LK",
        "masks": "+94-##-###-####"
    },
    {
        "name": "Sudan",
        "dial_code": "+249",
        "code": "SD",
        "masks": "+249-##-###-####"
    },
    {
        "name": "Suriname",
        "dial_code": "+597",
        "code": "SR",
        "masks": "+597-###-###"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "dial_code": "+47",
        "code": "SJ"
    },
    {
        "name": "Swaziland",
        "dial_code": "+268",
        "code": "SZ",
        "masks": "+268-##-##-####"
    },
    {
        "name": "Sweden",
        "dial_code": "+46",
        "code": "SE",
        "masks": "+46-##-###-####"
    },
    {
        "name": "Switzerland",
        "dial_code": "+41",
        "code": "CH",
        "masks": "+41-##-###-####"
    },
    {
        "name": "Syrian Arab Republic",
        "dial_code": "+963",
        "code": "SY",
        "masks": "+963-##-####-###"
    },
    {
        "name": "Taiwan",
        "dial_code": "+886",
        "code": "TW",
        "masks": "+886-####-####"
    },
    {
        "name": "Tajikistan",
        "dial_code": "+992",
        "code": "TJ",
        "masks": "+992-##-###-####"
    },
    {
        "name": "Tanzania, United Republic of Tanzania",
        "dial_code": "+255",
        "code": "TZ",
        "masks": "+255-##-###-####"
    },
    {
        "name": "Thailand",
        "dial_code": "+66",
        "code": "TH",
        "masks": "+66-##-###-###"
    },
    {
        "name": "Timor-Leste",
        "dial_code": "+670",
        "code": "TL",
        "masks": "+670-78#-#####"
    },
    {
        "name": "Togo",
        "dial_code": "+228",
        "code": "TG",
        "masks": "+228-##-###-###"
    },
    {
        "name": "Tokelau",
        "dial_code": "+690",
        "code": "TK",
        "masks": "+690-####"
    },
    {
        "name": "Tonga",
        "dial_code": "+676",
        "code": "TO",
        "masks": "+676-#####"
    },
    {
        "name": "Trinidad and Tobago",
        "dial_code": "+1868",
        "code": "TT",
        "masks": "+1(868)###-####"
    },
    {
        "name": "Tunisia",
        "dial_code": "+216",
        "code": "TN",
        "masks": "+216-##-###-###"
    },
    {
        "name": "Turkey",
        "dial_code": "+90",
        "code": "TR",
        "masks": "+90(###)###-####"
    },
    {
        "name": "Turkmenistan",
        "dial_code": "+993",
        "code": "TM",
        "masks": "+993-#-###-####"
    },
    {
        "name": "Turks and Caicos Islands",
        "dial_code": "+1649",
        "code": "TC",
        "masks": "+1(649)###-####"
    },
    {
        "name": "Tuvalu",
        "dial_code": "+688",
        "code": "TV",
        "masks": "+688-2####"
    },
    {
        "name": "Uganda",
        "dial_code": "+256",
        "code": "UG",
        "masks": "+256(###)###-###"
    },
    {
        "name": "Ukraine",
        "dial_code": "+380",
        "code": "UA",
        "masks": "+380(##)###-##-##"
    },
    {
        "name": "United Arab Emirates",
        "dial_code": "+971",
        "code": "AE",
        "masks": "+971-#-###-####"
    },
    {
        "name": "United Kingdom",
        "dial_code": "+44",
        "code": "GB"
    },
    {
        "name": "United States",
        "dial_code": "+1",
        "code": "US",
        "masks": "+1(###)###-####"
    },
    {
        "name": "Uruguay",
        "dial_code": "+598",
        "code": "UY",
        "masks": "+598-#-###-##-##"
    },
    {
        "name": "Uzbekistan",
        "dial_code": "+998",
        "code": "UZ",
        "masks": "+998-##-###-####"
    },
    {
        "name": "Vanuatu",
        "dial_code": "+678",
        "code": "VU",
        "masks": "+678-#####"
    },
    {
        "name": "Venezuela, Bolivarian Republic of Venezuela",
        "dial_code": "+58",
        "code": "VE",
        "masks": "+58(###)###-####"
    },
    {
        "name": "Vietnam",
        "dial_code": "+84",
        "code": "VN",
        "masks": "+84(###)####-###"
    },
    {
        "name": "Virgin Islands, British",
        "dial_code": "+1284",
        "code": "VG",
        "masks": "+1(284)###-####"
    },
    {
        "name": "Virgin Islands, U.S.",
        "dial_code": "+1340",
        "code": "VI",
        "masks": "+1(340)###-####"
    },
    {
        "name": "Wallis and Futuna",
        "dial_code": "+681",
        "code": "WF",
        "masks": "+681-##-####"
    },
    {
        "name": "Yemen",
        "dial_code": "+967",
        "code": "YE",
        "masks": "+967-##-###-###"
    },
    {
        "name": "Zambia",
        "dial_code": "+260",
        "code": "ZM",
        "masks": "+260-##-###-####"
    },
    {
        "name": "Zimbabwe",
        "dial_code": "+263",
        "code": "ZW",
        "masks": "+263-#-######"
    }
];

export const codeAreaList = [
    {
        "name": "United States",
        "dial_code": "+1",
        "code": "US",
        "masks": [
            "(##)####-####",
        ]
    },
    {
        "name": "Brazil",
        "dial_code": "+55",
        "code": "BR",
        "masks": [
            "(##)####-####",
            "(##)7###-####",
            "(##)9####-####"
        ]
    },
    {
        "name": "China",
        "dial_code": "+86",
        "code": "CN",
        "masks": [
            "-##-#####-#####",
            "(###)####-###",
            "(###)####-####"
        ]
    },
    {
        "name": "Germany",
        "dial_code": "+49",
        "code": "DE",
        "masks": [
            "-###-###",
            "(###)##-##",
            "(###)##-###",
            "(###)##-####",
            "(###)###-####",
            "(####)###-####"
        ]
    },
    {
        "name": "Indonesia",
        "dial_code": "+62",
        "code": "ID",
        "masks": [
            "-##-###-##",
            "-##-###-###",
            "-##-###-####",
            "(8##)###-###",
            "(8##)###-####",
            "(8##)###-##-###"
        ]
    },
    {
        "name": "Israel",
        "dial_code": "+972",
        "code": "IL",
        "masks": [
            "-#-###-####",
            "-5#-###-####"
        ]
    }
]

export const otherList = {
    "+55": {
        "countryCode": "BR",
        "masks": ["(##)####-####", "(##)7###-####", "(##)9####-####"],
        "name": "Brazil",
        "original": ["+55(##)####-####", "+55(##)7###-####", "+55(##)9####-####"]
    },
    "+86": {
        "countryCode": "CN",
        "masks": ["-##-#####-#####", "(###)####-###", "(###)####-####"],
        "name": "China",
        "original": ["+86-##-#####-#####", "+86(###)####-###", "+86(###)####-####"]
    },
    "+49": {
        "countryCode": "DE",
        "masks": ["-###-###", "(###)##-##", "(###)##-###", "(###)##-####", "(###)###-####", "(####)###-####"],
        "name": "Germany",
        "original": ["+49-###-###", "+49(###)##-##", "+49(###)##-###", "+49(###)##-####", "+49(###)###-####", "+49(####)###-####"]
    },
    "+62": {
        "countryCode": "ID",
        "masks": ["-##-###-##", "-##-###-###", "-##-###-####", "(8##)###-###", "(8##)###-####", "(8##)###-##-###"],
        "name": "Indonesia",
        "original": ["+62-##-###-##", "+62-##-###-###", "+62-##-###-####", "+62(8##)###-###", "+62(8##)###-####", "+62(8##)###-##-###"]
    },
    "+972": {
        "countryCode": "IL",
        "masks": ["-#-###-####", "-5#-###-####"],
        "name": "Israel",
        "original": ["+972-#-###-####", "+972-5#-###-####"]
    }
}