import { Dropdown, Row, Col, Form, Spinner, Button } from 'react-bootstrap';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import { useAuth } from '../../context/authContext';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import Gmail from '../../assets/icon/gmail.svg';
import Yahoo from '../../assets/icon/yahoo.svg';
import Outlook from '../../assets/icon/outlook.svg';
import { GithubLoginButton } from 'react-social-login-buttons';
import { navigate, useLocation } from '@reach/router';
import qs from 'query-string';
import { getGitHubUrl } from '../../utils/getGithubUrl';

export default function DropDownLogin(props) {
  const { generateLink, login } = useAuth();
  const [step, setStep] = useState(1);

  const requestDemoText = 'Request Demo';
  let from = useLocation().state?.from?.pathname || '/';
  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Email is not valid!')
      .required('Email is required!'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await generateLink(values.email);
        setStep(2);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Dropdown.Menu className="dropdown-menu-nav-bar dropdown-menu-end drop-downs-sm-right-auto-profile">
      <Dropdown.Header className="dropdown-header-nav-bar">
        <Row className="sign-in-heading-row w-100 mx-auto justify-content-center  md:mt-0 mb-4">
          {step === 2 ? (
            <>
              <Col className="d-flex flex-column justify-content-center p-0">
                <h2 className="h4 bold my-4 w-100">Check your email</h2>
                <p>An email was sent to {formik.values.email}</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="p h6 "
                  href="https://mail.google.com/mail/u/0/#search/%40snapbrillia"
                >
                  <span className="sort ">
                    <img src={Gmail} alt=" Open in Gmail" height={22} />
                  </span>
                  <span className="sort px-3">Open in Gmail</span>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="p h6 "
                  href="https://mail.yahoo.com/d/search/keyword=snapbrillia.com"
                >
                  <span className="sort ">
                    <img src={Yahoo} alt=" Open in Gmail" height={22} />
                  </span>
                  <span className="sort px-3">Open in Yahoo</span>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="p h6 "
                  href="https://outlook.office.com/mail/0/inbox"
                >
                  <span className="sort ">
                    <img src={Outlook} alt=" Open in Gmail" height={22} />
                  </span>
                  <span className="sort px-3">Open in Outlook</span>
                </a>
                <input
                  className="bg-primary text-white border-0 my-3 py-2 w-100"
                  value="Try again"
                  type="button"
                  onClick={() => {
                    setStep(1);
                  }}
                />
              </Col>
            </>
          ) : (
            <>
              <Col className="d-flex flex-column justify-content-center p-0 w-100">
                <Form style={{ padding: '0' }} onSubmit={formik.handleSubmit}>
                  <h3 className="h4 bold my-4 w-100">Sign up or log in</h3>
                  <Form.Group className="position-relative bounties-time-fields my-4">
                    <input
                      {...formik.getFieldProps('email')}
                      className="border border-dark bg-white w-100"
                      name="email"
                      type="email"
                      placeholder="example@email.com"
                    />
                  </Form.Group>
                  {formik.touched.name && formik.errors.name && (
                    <p className="bounties-form-error">{formik.errors.name}</p>
                  )}
                  <Form.Group className="position-relative bounties-time-fields my-2">
                    {formik.isSubmitting ? (
                      <>
                        <Button
                          disabled
                          className="bg-primary text-white w-100 light"
                        >
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </Button>
                      </>
                    ) : (
                      <input
                        className="bg-primary text-white w-100 light"
                        value="Continue with email"
                        type="button"
                        onClick={formik.handleSubmit}
                      />
                    )}
                  </Form.Group>
                </Form>
                <div className="dividerLogin d-flex align-items-center my-4">
                  <p className="text-center fw-bold mx-3 mb-0">OR</p>
                </div>
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                >
                  <GoogleLogin
                    theme="filled_blue"
                    width={window.innerWidth * 0.2 + 45}
                    size="large"
                    onSuccess={(credentialResponse) => {
                      login(credentialResponse.credential, 'oauth');
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </GoogleOAuthProvider>
                <GithubLoginButton
                  className="mx-0 w-100"
                  onClick={() => navigate(getGitHubUrl(from))}
                />
              </Col>
            </>
          )}
        </Row>
      </Dropdown.Header>
    </Dropdown.Menu>
  );
}
