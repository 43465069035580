import React from 'react';
import {
  Container,
  Row,
  Col,
  // Spinner,
  // Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
// import { toast } from 'react-toastify';

//COMPONENT
import { BountyImage } from '../../shared/images';
import { formatCurrency, formatGigOrJobSkills} from '../../shared/utils';
//CSS
import '../css/improveToolTip.css';
import '../../shared/css/buttonStyles.css';
import '../../shared/css/bgColors.css';
import { Link } from '@reach/router';
// import * as teamApi from '../../api/teams';
import { useAuth } from '../../context/authContext';
// import { getAllTeams } from '../../api/opportunities';

const ImproveToolTip = ({ opportunity }) => {
  const { auth } = useAuth();
  const { opportunityId } = opportunity;

  const tooltip = (item) => {
    return (
      <Tooltip id="tooltip">
        <ul>
          {item?.skillIds?.map((skill) => (
            <li key={skill}>
              <strong>{skill?.name || skill}</strong>
            </li>
          ))}
        </ul>
      </Tooltip>
    );
  };

  return (
    <Container fluid className="p-6">
      <Row className="cardbg py-2">
        <Row className="align-items-center mb-5">
          <Col xs={12} md={10}>
            <h1 className="h1">{opportunityId.title}</h1>
            <img
              src={BountyImage(opportunityId)}
              className="company-logo"
              alt="company logo"
            />
            <span className="link pointer ms-2">
              {opportunityId.companyId?.companyName}
            </span>
          </Col>
          <Col>
            <Row>
              <h3 className="opportunityId-reward-text">Gig Reward</h3>
            </Row>
            <Row>
              <h3 className="money-text">{formatCurrency(opportunityId)}</h3>
            </Row>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} md={2}>
            <span className="text-nowrap tool-tip-teampreference top-text">
              {opportunityId.teamPreference === true
                ? 'Teams Only'
                : 'Open to All'}
            </span>
            <div className="text-nowrap bottom-sub-text">Team Preference</div>
          </Col>
          <Col xs={12} md={12} lg={4} className="d-sm-block">
            <OverlayTrigger
              placement="top"
              overlay={tooltip({ skillIds: opportunityId.skillIds })}
            >
              <Row className="w-100 mt-0">
                <Row>
                  <Col xs={1} md={1} lg={1}>
                    <i class="fa-solid fa-circle-info"></i>
                  </Col>
                  <Col>
                    <span className="text-black-50">Skills</span>
                  </Col>
                </Row>
                <span className="main-language">
                  {formatGigOrJobSkills({ skillIds: opportunityId.skillIds })}
                </span>
              </Row>
            </OverlayTrigger>
          </Col>
          {/* <Col xs={12} md={2}>
            <div className="triangle">
              <p className="top-text text-nowrap translate-middle-y">
                <span
                  className="d-inline-block text-truncate"
                  style={{ maxWidth: '300px' }}
                >
                  {formatSkills(opportunity?.opportunityId?.skillIds)}
                </span>
              </p>
            </div>
            <div className="text-nowrap bottom-sub-text">Skills</div>
          </Col> */}
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={2}>
            <p className="m-0 medium">
              {opportunityId.timeEstimation} {opportunityId.timeEstimationType}
            </p>
            <span className="grey-title">Time Estimation</span>
          </Col>
          {/* <Col xs={12} md={2}>
            <p className="m-0 medium">
              {format(
                opportunityId.createdAt
                  ? new Date(opportunityId.createdAt)
                  : new Date(),
                'MM/dd/yyyy'
              )}
            </p>
            <span className="grey-title">Date</span>
          </Col> */}
          <Col xs={12} md={2}>
            <p className="m-0 medium">{opportunityId.type}</p>
            <span className="grey-title">Opportunity Type</span>
          </Col>
          <Col xs={12} md={2}>
            <p className="m-0 medium">{opportunity.status}</p>
            <span className="grey-title">Work Status</span>
          </Col>
          <Col>
            {opportunity.status !== 'Submitted' &&
              opportunity.status !== 'Completed' &&
              (opportunity.position !== 'Mentee' ? (
                <Link
                  style={{ textDecoration: 'none' }}
                  className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                  as={Link}
                  to={`/opportunity/${opportunity.opportunityId._id}/eeo-form`}
                >
                  <p
                    style={{ paddingTop: '8px' }}
                    className="active_team_button_go_to_team_page_text"
                  >
                    Submit Work
                  </p>
                </Link>
              ) : (
                <button
                  style={{
                    backgroundColor: 'grey',
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{ paddingTop: '8px', color: 'white' }}
                    className="active_team_button_go_to_team_page_text"
                  >
                    Wait For Mentor To Submit Work
                  </p>
                </button>
              ))}
            {(opportunity.status === 'Submitted' ||
              opportunity.status === 'Completed') && (
              <Link
                style={{ textDecoration: 'none' }}
                className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                as={Link}
                to={`/teams`}
              >
                <p
                  style={{ paddingTop: '8px' }}
                  className="active_team_button_go_to_team_page_text"
                >
                  Go to work page
                </p>
              </Link>
            )}
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default ImproveToolTip;
