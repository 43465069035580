import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../shared/css/buttonStyles.css';
import '../../bounties/css/similarBounty.css';
import { formatCurrency } from '../../shared/utils';
import BountyLogo from '../../assets/snapbrillia_no_company_logo.png';
import { BountyImage } from '../../shared/images';
import { navigate } from '@reach/router';

const SimilarJobs = ({ jobs }) => {
  const [similarJobs, setSimilarJobs] = useState([]);

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  useEffect(() => {
    setSimilarJobs(jobs);
  }, [jobs]);

  return (
    <>
      <Row className="bold">
        <Col>Similar Jobs:</Col>
      </Row>
      <Container fluid className="my-5">
        <Row className="gap-5">
          {/* Column 1 */}
          {similarJobs.map((job, index) => {
            return (
              <Col className="my_similar_bounties_bg" key={job._id}>
                <Row style={{ paddingLeft: '16px' }}>
                  <Col className="my_similar_bounties_task_name">
                    <Row
                      style={{ paddingTop: '24px', boxSizing: 'content-box' }}
                    >
                      {job.title}
                    </Row>
                  </Col>
                  <Col>
                    <Row
                      style={{ paddingTop: '24px', boxSizing: 'content-box' }}
                      className="my_similar_bounties_reward_title"
                    >
                      <div className="pull-right">Job Reward</div>
                    </Row>
                    <Row className="my_similar_bounties_reward_cash">
                      <div className="pull-right">{formatCurrency(job)}</div>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: '16px' }}>
                  <Col>
                    <Row style={{ height: '20px' }}>
                      <Col className="my_similar_bounties_company_name">
                        <img
                          src={BountyImage(job)}
                          className="company-logo"
                          alt="company logo"
                        />
                        {job.companyId?.companyName}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row
                      style={{
                        paddingBottom: '13px',
                        boxSizing: 'content-box',
                      }}
                      className="my_similar_bounties_view_bounty"
                    >
                      <div
                        className="float-end"
                        onClick={() => {
                          navigate(`/jobs/${job._id}`);
                          topFunction();
                        }}
                      >
                        {'View Job'}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default SimilarJobs;
