import axios from 'axios';

export async function getGrant(id) {
  const response = await axios.get(`/api/grants/${id}`);
  return response.data;
}

export async function createGrant(data) {
  const response = await axios.post('/api/grants/create', data);
  return response.data;
}

export async function saveGrantDraft(grantInfo) {
  const response = await axios.post('/api/grants/save-grant-draft', {
    grantInfo,
  });
  return response.data;
}

export async function getGrantDraft() {
  const response = await axios.get('/api/grant/get-grant-pending-draft');
  return response.data;
}

export async function updateGrant(id, data) {
  const response = await axios.put(`/api/grants/${id}`, data);
  return response.data;
}

export async function addFunder(data) {
  const response = await axios.post('/api/grants/add-funder', data);
  return response.data;
}

export async function addMember(data) {
  const response = await axios.post('/api/grants/add-member', data);
  return response.data;
}

export async function removeMember(grantId, memberId) {
  const response = await axios.post(`/api/grants/remove-member`, {
    grantId,
    memberId,
  });
  return response.data;
}

export async function getAllGrants(fundingRound) {
  try {
    const response = await axios.get(
      `/api/grants/get-grants?&requestedFundingRound=${fundingRound}`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getFeaturedGrants(limit) {
  try {
    const response = await axios.get(`/api/grants?limit=${limit}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
