import React, { useState, useEffect } from 'react';

//COMPONENTS
import { Button, Col, Row } from 'react-bootstrap';
import CloseIcon from '../../assets/icon/white_close_icon.svg';

//CSS
import './css/bountySideMenu.css';
import '../css/bgColors.css';
import '../css/sharedStyles.css';
import '../css/typography.css';
import { Link } from '@reach/router';

import * as skillAPI from '../../api/opportunities';

const BountySideMenu = ({
  setBountiesFilters,
  bountiesFilters,
  scrollPosition,
}) => {
  const [existingSkills, setExistingSkills] = useState([]);
  const [quickSelectItems, setQuickSelectItems] = useState([]);

  const getAllSkills = async () => {
    try {
      const docs = await skillAPI.getAllSkills();
      if (docs) {
        setExistingSkills(docs);
      }
    } catch (error) {}
  };
  function unCheck() {
    setQuickSelectItems([]);
    setBountiesFilters({
      search: '',
      status: null,
      type: null,
      skills: '',
    });
    var x = document.getElementsByClassName('checkbox');
    for (let i = 0; i <= x.length - 1; i++) {
      x[i].checked = false;
    }
    var y = document.getElementsByClassName('radio');
    for (let i = 0; i <= y.length - 1; i++) {
      y[i].checked = false;
    }
  }
  useEffect(() => {
    getAllSkills();
  }, []);
  useEffect(() => {
    setBountiesFilters({
      ...bountiesFilters,
      skills: quickSelectItems,
    });
  }, [quickSelectItems]);

  const handleBountiesFilters = (e, filter) => {
    let { value, checked } = e.target;
    if (filter === 'search') {
      setBountiesFilters({
        ...bountiesFilters,
        search: e.target.value.toLowerCase(),
      });
      return;
    }

    if (filter === 'skills') {
      let { value } = e.target;
      setBountiesFilters({
        ...bountiesFilters,
        skills: value === 'All' ? '' : value,
      });
      return;
    }

    if (filter === 'type') {
      setBountiesFilters({
        ...bountiesFilters,
        [filter]: value,
      });
      return;
    }
    if (filter === 'status') {
      setBountiesFilters({
        ...bountiesFilters,
        [filter]: value,
      });
      return;
    }

    if (!bountiesFilters[filter].includes(value) && checked) {
      setBountiesFilters({
        ...bountiesFilters,
        [filter]: [...bountiesFilters[filter], value],
      });
    }
    if (bountiesFilters[filter].includes(value) && !checked) {
      setBountiesFilters({
        ...bountiesFilters,
        [filter]: [...bountiesFilters[filter].filter((e) => e !== value)],
      });
    }
  };

  const addQuickSelectItem = (item, action) => {
    if (action === 'add') {
      return (
        !quickSelectItems.includes(item) &&
        setQuickSelectItems([...quickSelectItems, item])
      );
    }
    setQuickSelectItems(quickSelectItems.filter((e) => e !== item));
  };

  const removeSearchInput = (item) => {
    const newList = quickSelectItems.filter((el) => el.value !== item.value);
    setQuickSelectItems(newList);
  };

  const handleOptions = (item) => {
    const newItem = { value: item };
    const alreadyExist = quickSelectItems.some(
      (value) => value.value === newItem.value
    );

    if (!alreadyExist) {
      setQuickSelectItems([...quickSelectItems, newItem]);
    } else {
      removeSearchInput(newItem);
    }
  };

  return (
    <div
      className="bounty-sideMenu-container px-3"
      style={{
        position: scrollPosition > 433 ? 'fixed' : 'absolute',
        top: scrollPosition > 433 && 0,
        left: scrollPosition > 433 && 0,
        width: '25%',
      }}
    >
      <Row>
        <Col className="py-2 my-3">
          <input
            placeholder="Search"
            type="text"
            className="bounty-sideMenu-search"
            onChange={(e) => handleBountiesFilters(e, 'search')}
          />
        </Col>
        <div
          className="grant-side-menu-searched-pills"
          data-testid="quick-select"
        >
          {quickSelectItems.map((item, index) => (
            <span
              key={item.value}
              className="grant-side-menu-pill"
              data-testid="child"
            >
              {item.value}
              <span
                onClick={() => addQuickSelectItem(item)}
                className="grant-side-menu-close-icon"
              >
                <img src={CloseIcon} alt="close-icon" height={8} width={8} />
              </span>
            </span>
          ))}
        </div>
      </Row>
      <Row>
        {/* DropDown List */}
        <Col xs={12} className="mt-4 px-3">
          <h5 className="regular">Skills</h5>
        </Col>
        <Col>
          <select
            id="skills"
            data-testid="select-skill"
            className="bounty-sideMenu-dropDown"
            name="skills"
            onChange={(e) => handleBountiesFilters(e, 'skills')}
          >
            <option>All</option>
            {existingSkills.map((skill, index) => (
              <option
                data-testid="option-skill"
                key={skill + index}
                value={skill._id}
              >
                {skill.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        {/*     Tu comment because temporate all opportunites is auto accept     
        <Col>
          <Col className="mt-4">
            <h5 className="regular">Status</h5>
          </Col>
          <Col>
            <span className="grey-title fs-6">
              <input
                data-testid="radio"
                onChange={(e) => handleBountiesFilters(e, 'status')}
                value="open"
                type="radio"
                name="status"
                className="checkbox"
                id="status-open"
              />{' '}
              Accept Application
            </span>
          </Col>
        </Col> */}
        <Col>
          <Col className="mt-4">
            <h5 className="regular">Opportunities</h5>
          </Col>
          <Col>
            <span className="grey-title fs-6">
              <input
                data-testid="radio"
                onChange={(e) => handleBountiesFilters(e, 'type')}
                value={"Job"}
                type="radio"
                name="isJob"
                className="checkbox"
                id="permissionless-false"
              />{' '}
              Jobs
            </span>
          </Col>
          <Col>
            <span className="grey-title fs-6">
              <input
                data-testid="radio"
                onChange={(e) => handleBountiesFilters(e, 'type')}
                value={"Gig"}
                type="radio"
                name="isJob"
                className="checkbox"
                id="permissionless-true"
              />{' '}
              Gigs
            </span>
          </Col>
        </Col>
      </Row>
      <Row className="my-2">
        <Row className="my-4">
          <Col>
            <Link to="/policy">Use Policies</Link>
          </Col>
          <Col>
            <Button data-testid="rf-button" onClick={unCheck}> Reset</Button>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default BountySideMenu;
