import React, { useState } from 'react';
import { Modal, Col, Row, Spinner } from 'react-bootstrap';
import { UserImage } from '../../shared/images';
import OnChainIcon from '../../assets/icon/snapbrillia_on_chain_icon.png';
import ResumeIcon from '../../assets/icon/snapbrillia_resume_icon.svg';
import { snapbrilliaFile } from '../../api/files';
import { useParams } from '@reach/router';
import LinkedInIcon from '../../assets/icon/snapbrillia_linkedin_actual_icon.png';
import DiscordIcon from '../../assets/icon/snapbrillia_discord_actual_icon.png';
import GitHubIcon from '../../assets/icon/snapbrillia_github_actual_icon.png';
import { toast } from 'react-toastify';
import { navigate } from '@reach/router';
import { useEffect } from 'react';
import { expressInterestOpportunity } from '../../api/opportunities';

function TeamsSelectionModal({ setSelectedTeam, user, team }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(Object.keys(team).length > 0 ?? true);

  const expressInterest = async (teamId) => {
    if (!user || !user.resume) {
      toast('Please upload your resume.');
      return;
    }
    try {
      setLoading(true);
      await expressInterestOpportunity(id, {
        teamId,
        position: 'Mentee',
      });
      setLoading(false);
      handleClose();
      toast('Join team successfully');
      navigate('/teams');
    } catch (error) {
      toast(error.message);
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectedTeam({});
  };

  useEffect(() => {
    setShow(Object.keys(team).length > 0 ?? true);
  }, [team]);
  return (
    <Modal show={show} size="xl" onHide={handleClose}>
      <Modal.Body className="pagemainbg rounded">
        <Modal.Header closeButton className="border-bottom-0">
          <h2 className="primary bold m-0">Summary</h2>
        </Modal.Header>
        <Row className="px-3">
          <Col md={3} className="bg-white py-4">
            <img
              className="w-25 rounded-circle me-2"
              src={UserImage(user)}
              alt="..."
            />
            <span className="primary bold h3">{user?.fullName}</span>
          </Col>
          <Col className="d-flex align-items-center bg-white rounded ms-4">
            <div style={{ width: '70%' }}>
              <div className="mb-4">
                <img src={ResumeIcon} alt="resume-icon" />
                <a
                  className="mentor-conf-modal-resume-btn-fs border-0 primary"
                  href={snapbrilliaFile(team.mentor?.resume)}
                  download="resume"
                  target="_blank"
                  rel="noreferrer"
                >
                  Resume
                </a>
              </div>

              <img src={OnChainIcon} alt="onchain-icon" width="16" />
              <a
                className="mentor-conf-modal-resume-btn-fs border-0  primary"
                href={snapbrilliaFile(team.mentor?.resume)}
                download="resume"
                target="_blank"
                rel="noreferrer"
              >
                On-Chain-Resume
              </a>
            </div>

            <div className="underline semi-bold text-nowrap">
              <img
                src={LinkedInIcon}
                alt="LinkedInIcon-icon"
                className="mentor-conf-modal-icon-sm"
              />
              <span className="mentor-conf-modal-cursor-pointer mentor-conf-modal-btn-link-fs ms-1">
                LinkedIn
              </span>
            </div>
            <div className="underline semi-bold text-nowrap">
              <img
                src={DiscordIcon}
                alt="DiscordIcon-icon"
                className="mentor-conf-modal-icon-sm"
              />
              <span className="mentor-conf-modal-cursor-pointer mentor-conf-modal-btn-link-fs ms-1">
                Discord
              </span>
            </div>
            <div className="underline semi-bold text-nowrap">
              <img
                src={GitHubIcon}
                alt="github-icon"
                className="mentor-conf-modal-icon-sm"
              />
              <a href={user?.github} rel="noreferrer" target="_blank">
                <span className="mentor-conf-modal-cursor-pointer mentor-conf-modal-btn-link-fs ms-1">
                  GitHub
                </span>
              </a>
            </div>
          </Col>
        </Row>
        <h3 className="bold primary mt-4">Your Team Select to Join</h3>
        <Row className="px-2">
          <Row>
            <Col
              xs="12"
              lg="3"
              className="mentor-conf-modal-border-radius-1 bg-white p-3 m-2"
            >
              <div className="mentor-conf-modal-height-100 d-flex flex-column justify-content-center">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <img
                      className="w-25 rounded-circle me-1"
                      src={UserImage(team.mentor)}
                      alt="..."
                    />
                    <div className="d-flex flex-column px-2 pb-5">
                      <span className="mentor-conf-modal-username-text primary bold">
                        {team.mentor?.fullName}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-1"></div>
                </div>
                <div className="d-flex align-items-center mt-4">
                  <img src={ResumeIcon} alt="resume-icon" />
                  <a
                    className="mentor-conf-modal-resume-btn-fs border-0 bg-white primary semi-bold text-wrap me-2"
                    href={snapbrilliaFile(team.mentor?.resume)}
                    download="resume"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Resume
                  </a>
                  <img src={OnChainIcon} alt="onchain-icon" width="16" />
                  <input
                    className="mentor-conf-modal-resume-btn-fs border-0 bg-white primary semi-bold text-wrap"
                    type="button"
                    value="On-Chain Reputation"
                  />
                </div>
              </div>
            </Col>
            <Col xs="12" lg="5" className="p-2 m-2">
              <Row className="my-3">
                <Col>
                  <Row>
                    <p className="grey-title mb-0">Completed bounties</p>
                  </Row>
                  <Row>
                    <p className="bold">3</p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <p className="grey-title mb-0">Days with office hours</p>
                  </Row>
                  <Row className="mt-0">
                    <p className="bold">{team.officeHours?.length}</p>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg="7" className="">
                  <div className="mt-5 d-flex justify-content-around p-1">
                    <div className="underline semi-bold text-nowrap">
                      <img
                        src={GitHubIcon}
                        alt="github-icon"
                        className="mentor-conf-modal-icon-sm"
                      />
                      <a
                        href={team.mentor?.github}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <span className="mentor-conf-modal-cursor-pointer mentor-conf-modal-btn-link-fs ms-1">
                          GitHub
                        </span>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="p-2 m-2">
              <Row className="my-2">
                <Col className="current-team-section">
                  <Row>
                    <p className="small-text mb-3">
                      Current Mentees ({team.mentees?.length})
                    </p>
                  </Row>
                  <Row>
                    {team.mentees?.length > 0 &&
                      team.mentees.map((mentee, id) => {
                        return (
                          <Col
                            md={4}
                            key={mentee._id}
                            className="select-team-mentee-info"
                          >
                            <img
                              src={UserImage(mentee)}
                              height={40}
                              width={40}
                              className="active_team_profile_img"
                              alt="profile"
                            />
                            <p className="active_team_content_current_team_name">
                              {mentee.fullName}
                            </p>
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
                {loading ? (
                  <div align="right">
                    <p className="h3 primary bold pointer px-3">
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </p>
                  </div>
                ) : (
                  <div align="right" onClick={() => expressInterest(team?._id)}>
                    <p className="h3 primary bold pointer px-3">Confirm</p>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default TeamsSelectionModal;
