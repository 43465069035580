import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { uploadFile } from '../../api/files';
import GrantDetails from './grantDetails';
import { GrantSetupContext } from './grantSetup';
import GrantSetupStep from './grantSetupStep';
import InviteFunders from './inviteFunders';
import InviteTeamMembers from './inviteTeamMembers';
import * as grantAPI from '../../api/grant';
import { toast } from 'react-toastify';
import '../../shared/css/buttonStyles.css';

const GrantEditModal = ({ grant, setGrant, show, setShow}) => {
    const [ step, setStep ] = useState(1)
    const [throttle, setThrottle] = useState([-1, -1, -1, -1]);
    const [companyLogoChanged, setCompanyLogoChanged] = useState(false);
    const [stepTracker, setStepTracker] = useState(0);
    const [teamMember, setTeamMember] = useState([{}]);
    const [funders, setFunders] = useState([{}]);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        
    });

    const grantInitialState = useMemo(() => ({
        name: '',
        description: '',
        fundraisingAmount: 0,
        matchPool: '',
        officeHour: '2022-01-01',
        officeLink: '',
        companyName: "",
        website: '',
        url: '',
        status: 'pending',
        projectLogo: null,
        tags: [],
        payment: {
            walletName: '',
            pubKeyAddress: '',
            _id: '',
            walletAddress: '',
        },
    }), [])

    useEffect(() => {
        return () => {
            setData({ ...grantInitialState })
        }
    }, [])

    useEffect(() => {
        setData({
            ...grant
            , payment: {
                walletName: 'n/a',
                pubKeyAddress: 'n/a',
                _id: '',
                walletAddress: 'n/a',
            } 
        })

        setMembersAndFounders();
        
    }, [grant])

    const setMembersAndFounders = useCallback(() => {
        if (grant && grant.memberIds?.length > 0) {
            setTeamMember(grant?.memberIds?.map(member => ({ isNew: false, _id: member._id, email: member.email, fullName: member.fullName })))
        } else {
            setTeamMember([{ isNew: true }])
        }

        if (grant && grant.funderIds?.length > 0) {
            setFunders(grant?.funderIds?.map(founder => ({ isNew: false, _id: founder._id, email: founder.email, fullName: founder.fullName })))
        } else {
            setFunders([{ isNew: true }])
        }
    }, [grant]);
    
    const handleClose = useCallback(() => {
        setShow(false);
        setData({ ...grant })
        setStep(1)

        setMembersAndFounders()
        setCompanyLogoChanged(false)
    }, [grant]);

    const throttleSetter = () => {
        let temp = [...throttle];
        if (stepTracker === 0) {
            if (data.name === '' || data.url === '' || data.tags.length === 0) {
                temp[0] = 1;
                setThrottle(temp);
                // return true;
            } else {
                temp[0] = 0;
                setThrottle(temp);
                // return temp;
            }
        } else if (stepTracker === 1) {
            if (
                data.description === '' ||
                data.matchPool === '' ||
                data.fundraisingAmount === 0
            ) {
                temp[1] = 1;
                setThrottle(temp);
                // return temp;
            } else {
                temp[1] = 0;
                setThrottle(temp);
                // return temp;
            }
        } else if (stepTracker === 2) {
            temp[2] = 0;
            setThrottle(temp);
        }
        // }
        return temp;
    };

    const saveGrant = async () => {
        setLoading(true);
        let grantInfo = {
            ...data,
        };

        if (companyLogoChanged) {
            if (data.projectLogo){
                const formData = new FormData();
                formData.append('type', 'grant_image');
                formData.append('file', data.projectLogo[0]);
                try {
                    const fileInfo = await uploadFile(formData);
                    if (fileInfo.url) {
                        grantInfo.projectLogo = fileInfo.url;
                    }
                } catch (err) {
                    console.log('Error uploading the image: ', err)
                }
            }else{
                grantInfo.projectLogo = null;
            }
            
        }else{
            grantInfo.projectLogo = grant.projectLogo;
        }

        try {
            
            let editedGrant;
            editedGrant = await grantAPI.updateGrant(grantInfo._id, grantInfo);

            if (editedGrant) {
                await Promise.all(teamMember.filter((x) => x.fullName && x.email).map(async (localMember) => {
                    const cloudMember = grant?.memberIds.find(_cloudMember => _cloudMember._id === localMember._id);
                    if (!cloudMember){
                        await grantAPI.addMember({
                            ...localMember,
                            grantId: editedGrant._id,
                        });
                    }
                }));

                teamMember.filter((x) => x.fullName && x.email).map(member => ({ ...member, isNew: false }));
                grantInfo.memberIds = teamMember;

                await Promise.all(grant?.memberIds.map(async (cloudMember) => {
                    const localMember = teamMember.find(_localMember => _localMember._id === cloudMember._id);
                    if (!localMember){
                        await grantAPI.removeMember(grant._id, cloudMember._id);
                    }
                }))

                await Promise.all(funders.filter((x) => x.fullName && x.email).map(async (localFunder) => {
                    const cloudFunder = grant?.funderIds.find(_cloudFunder => _cloudFunder._id === localFunder._id);
                    if (!cloudFunder) {
                        await grantAPI.addFunder({
                            ...localFunder,
                            grantId: editedGrant._id,
                        });
                    }
                }));

                funders.filter((x) => x.fullName && x.email).map(founder => ({...founder, isNew: false}))
                grantInfo.funderIds = funders;
                
            }
        } catch (err) {
            setLoading(false);
            return toast('Error updating grant');
        }

        setCompanyLogoChanged(false)
        setLoading(false);
        setShow(false)
        setStep(1)
        setGrant(grantInfo)
        return toast('Grant updated successfully!');
    };

    
    return (
        <GrantSetupContext.Provider
            value={{ data, setData, throttle, setThrottle, companyLogoChanged, setCompanyLogoChanged, teamMember, setTeamMember, throttleSetter, funders, setFunders }}
        >
        <Modal show={show} onHide={handleClose} size="xl" fullscreen="md-down">
            <Modal.Header closeButton>
                <Modal.Title>Edit Grant Stepper</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{minHeight: '700px'}}>
                    <GrantEditionStepper step={step} />
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <button className="btn-underline" disabled={loading} onClick={() => saveGrant()}>
                    {loading ? 'loading...' : 'Save'}
                </button>
                <div>
                    {step !== 1 && <Button style={{marginRight: 5}} variant="secondary" onClick={() => setStep(step - 1)}>
                        Back
                    </Button>}
                    {step !== 4 && <Button variant="primary" onClick={() => setStep(step + 1)}>
                        Next
                    </Button>}
                </div>
            </Modal.Footer>
        </Modal>
        </GrantSetupContext.Provider>
    );
};

const GrantEditionStepper = React.memo(({ step }) => {
    return (
        <div>
            {step === 1 && <GrantSetupStep isEdit={true}/>}
            {step === 2 && <GrantDetails isEdit={true} />}
            {step === 3 && <InviteTeamMembers isEdit={true} />}
            {step === 4 && <InviteFunders isEdit={true} />}
        </div>
    )
});

export default React.memo(GrantEditModal);
