import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Loading = () => {
  return (
    <div>
      <Col sm={12} md={3} lg={2} xl={2} xs={2} className="mr">
        <div className="skeleton skeleton-text"></div>
      </Col>
      <Row className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold pointer">
        <Col>
          <Row>
            <Col sm={12} md={3} lg={2} xl={2} xs={2}>
              <div className="skeleton skeleton-text"></div>
            </Col>
            <Col sm={12} md={6} lg={8} xl={8} xs={8}></Col>
            <Col sm={12} md={3} lg={2} xl={2} xs={2}>
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3} lg={2} xl={2} xs={2}>
              <div className="skeleton-row">
                <div className="skeleton skeleton-img company-logo"></div>
                <div className="skeleton skeleton-text-img"></div>
              </div>
            </Col>
            <Col
              sm={12}
              md={3}
              lg={2}
              xl={2}
              xs={2}
              className="d-inline-flex align-items-center"
            >
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3} lg={3} xl={3} xs={3}>
              <div className="skeleton skeleton-text"></div>
            </Col>
            <Col sm={12} md={3} lg={3} xl={3} xs={3}>
              <div className="skeleton skeleton-text"></div>
            </Col>
            <Col sm={12} md={3} lg={3} xl={3} xs={3}>
              <div className="skeleton skeleton-text"></div>
            </Col>
            <Col sm={12} md={3} lg={3} xl={3} xs={3}>
              <div className="skeleton skeleton-text"></div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3} lg={3} xl={3} xs={3}>
              <div className="skeleton skeleton-text"></div>
            </Col>
            <Col sm={12} md={3} lg={3} xl={3} xs={3}>
              <div className="skeleton skeleton-text"></div>
            </Col>
            <Col sm={12} md={3} lg={3} xl={3} xs={3}>
              <div className="skeleton skeleton-text"></div>
            </Col>
            <Col sm={12} md={3} lg={3} xl={3} xs={3}>
              <div className="skeleton skeleton-button"></div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Col className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold pointer">
        <Col sm={12} md={12} lg={12} xl={12} xs={12}>
          <div className="skeleton skeleton-text"></div>
        </Col>
        <Col sm={12} md={12} lg={12} xl={12} xs={12}>
          <div className="skeleton skeleton-text"></div>
        </Col>
        <Col sm={12} md={12} lg={12} xl={12} xs={12}>
          <div className="skeleton skeleton-text"></div>
        </Col>
        <Col sm={12} md={12} lg={12} xl={12} xs={12}>
          <div className="skeleton skeleton-text"></div>
        </Col>
      </Col>
    </div>
  );
};

export default Loading;
