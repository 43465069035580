import axios from 'axios';

export async function getOrder(id) {
  const response = await axios.get(`/api/my-orders/${id}`);
  return response.data;
}

export async function getPayout(id) {
  const response = await axios.get(`/api/my-payouts/${id}`);
  return response.data;
}

export async function getMyCart() {
  const response = await axios.get(`/api/my-cart`);
  return response.data;
}

export async function addToCart(data) {
  const response = await axios.put('/api/add-grant-donation-cart', data);
  return response.data;
}

export async function postStripeIntents(orderid, account) {
  try {
    const response = await axios.post('/api/cart-stripe-intents', {
      orderId: orderid,
      account: account,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function confirmOrder(intentid) {
  try {
    const response = await axios.post('/api/confirm-order', {
      intent: intentid,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function removeItemFromCart(id) {
  try {
    const response = await axios.put('/api/remove-item-from-cart', { id });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getGrantsContributions(grantId) {
  try {
    const response = await axios.get(`/api/get-grant-contributions/${grantId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
