import React, { useEffect, useState } from 'react';
import '../../shared/css/typography.css';
import '../css/teamSummaryProfile.css';
import { Row } from 'react-bootstrap';
import ReviewSubmitted from './reviewSubmitted';
import ReviewCompleted from './reviewCompleted';
import { useAuth } from '../../context/authContext';

const ReviewTeam = ({ team, opportunity }) => {
  const [isMentee, setIsMentee] = useState(false);
  const { auth } = useAuth();
  useEffect(() => {
    if (team?.mentees?.length > 0) {
      const mentor = team?.mentees?.find((user) => user._id === auth.user._id);
      if (mentor) {
        setIsMentee(true);
      }
    }
  }, [auth?.user?._id, isMentee, team?.mentees]);
  return (
    <Row>
      {team.status === 'Completed' ? (
        <ReviewCompleted
          opportunity={team}
        />
      ) : (
        <ReviewSubmitted
          opportunity={opportunity}
        />
      )}
    </Row>
  );
};

export default ReviewTeam;
