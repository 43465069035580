import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import '../css/profileLayout.css';
import 'bootstrap/dist/css/bootstrap.css';
// import Flag from '../../assets/icon/snapbrillia_flag_icon.svg';
import Edit from '../../assets/icon/snapbrillia_edit_icon.svg';
import '../../shared/css/textColors.css';
import '../css/profileInfo.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Avatar from '../../teams/components/avatar';
import { toast } from 'react-toastify';
import LocationSearchInput from './LocationSearchInput';
import CreatableSelect from 'react-select/creatable';
import OverlayWithChildren from '../../shared/Overlays/OverlayWithChildren';
import PhoneNumber from './PhoneNumber/PhoneNumber';
import axios from 'axios';
import { valueAreaList } from '../../utils/codeAreaList';
import * as jobApi from '../../api/job';
import _ from 'lodash';
import { formatGigOrJobSkills } from '../../shared/utils';

const ProfileInfo = ({ user, updateProfile, error }) => {
  const { codeArea, flagCode, dob, location, phone } = user;
  // console.log(user);
  const [editedUser, setEditedUser] = useState(user);
  const [_location, setLocation] = useState(location ? location : '');
  const [startDate, setStartDate] = useState(dob ? new Date(dob) : new Date());
  const [saveValidationError, setSaveValidationError] = useState('');
  const [edit, setEdit] = useState(false);
  const [skills, setSkills] = useState([]);
  const [flagIcon, setFlagIcon] = useState('');
  const [loading, setLoading] = useState(false);

  const [countryCallingCode, setCountryCallingCode] = useState(
    codeArea ? codeArea : '+1'
  );
  const [countryFlagCode, setCountryFlagCode] = useState(
    flagCode ? flagCode : 'US'
  );
  const [phoneNumber, setPhoneNumber] = useState(user?.phone);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [displaySkills, setDisplaySkills] = useState(user.skills || []);

  const styles = {
    text: {
      textAlign: 'left',
    },
  };

  const handleInputChange = (e) => {
    setSaveValidationError('');
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date) => {
    setSaveValidationError('');
    setStartDate(date);
  };

  const getCountryFlag = async () => {
    const data = await axios.get(
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/index.json'
    );
    const dialCode = valueAreaList.filter(
      (value) => value.code === countryFlagCode
    );
    const flagIcon = data?.data?.filter(
      (flag) => flag?.code === dialCode[0]?.code
    );
    setFlagIcon(flagIcon[0]?.image);
  };

  const onCreateSkillSelected = async (skill) => {
    setSkills(skill);
  };

  useEffect(() => {
    const loadSkills = async () => {
      try {
        const skills = await jobApi.getAllSkills();
        if (_.isArray(skills) && !_.isEmpty(skills)) {
          setSkills(skills);
        }
      } catch (err) {
        toast(err.message);
      }
    };
    loadSkills();
  }, []);

  useEffect(() => {
    getCountryFlag();
  }, [user]);

  const options = useMemo(() => {
    let result = [];
    if (_.isArray(skills) && !_.isEmpty(skills)) {
      const count = skills.length;
      for (let i = 0; i < count; i++) {
        const skill = skills[i];
        if (!_.isNil(skill)) {
          result.push({ value: skill._id, label: skill.name });
        }
      }
    }
    return result;
  }, [skills]);

  const onSkillSelected = (e) => {
    setSelectedSkills([
      {
        value: e.map((x) => {
          return skills.find((val) => val.name === x.label)._id;
        }),
        label: e.map((x) => x.label),
      },
    ]);
    setDisplaySkills(e.map((x) => x.label));
  };
  const onCreateSkillOption = (e) => {
    if (onCreateSkillSelected) {
      onCreateSkillSelected(e);
    }
  };

  const handleSaveClick = useCallback(async () => {
    const { fullName, email } = editedUser;

    let EMAIL_REGEX = new RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );

    if (
      startDate === null ||
      fullName === '' ||
      email === '' ||
      startDate === ''
    ) {
      setSaveValidationError('You must complete the required fields.');
      toast.error('You must complete the required fields.');
      return false;
    }

    const diffDays = Math.ceil(
      Math.abs(new Date() - new Date(startDate)) /
        (1000 * 60 * 60 * 24 * 30 * 12)
    );
    if (diffDays - 1 <= 13) {
      setSaveValidationError('You must be older than 13 years.');
      toast.error('You must be older than 13 years.');
      return false;
    }

    if (!phoneNumber) {
      setSaveValidationError('Please provide a valid phone number.');
      toast.error('Please provide a valid phone number.');
      return false;
    }

    if (email.match(EMAIL_REGEX) == null) {
      setSaveValidationError('Please provide a valid email.');
      toast.error('Please provide a valid email.');
      return false;
    }

    if (editedUser.fullName === '' || editedUser.fullName.length === 0) {
      setSaveValidationError('Please provide a valid fullname.');
      toast.error('Please provide a valid fullname.');
      return false;
    }

    const updatedSkillIds = selectedSkills.map((skill) => skill.value);
    const mergeSkillIds = updatedSkillIds.flat(1);

    const result = await updateProfile({
      ...editedUser,
      dob: startDate,
      // codeArea: _codeArea.code,
      codeArea: countryCallingCode,
      flagCode: countryFlagCode,
      location: _location,
      phone: phoneNumber,
      skills: displaySkills,
      skillIds: mergeSkillIds,
    });

    if (!result.success) {
      setSaveValidationError(result.errorMessage);
      return false;
    }
    setLoading(false);
    setEdit(false);
  }, [editedUser, startDate, phoneNumber, selectedSkills, updateProfile, countryCallingCode, countryFlagCode, _location, displaySkills]);

  const toggleEdit = () => {
    setSaveValidationError('');
    if (!startDate) {
      setStartDate(new Date(dob));
    }
    setEdit(!edit);
  };

  const handleCancel = useCallback(() => {
    toggleEdit();
  }, [toggleEdit]);

  // const presentedSkills = useMemo(() =>
  //   skills.length > 7 ? [...skills.slice(0,7), { value: '...', label: '...' }] : skills
  // , [skills]);

  const onFormSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    handleSaveClick();
  };

  const tooltip = (item) => {
    return (
      <Tooltip id="tooltip">
        <ul>
          {item?.map((skill) => (
            <li key={skill}>
              <strong>{skill}</strong>
            </li>
          ))}
        </ul>
      </Tooltip>
    );
  };

  return (
    <Container
      className="profile-info-component-bg"
      style={{ height: '60%', width: '92%' }}
    >
      <Form onSubmit={onFormSubmit}>
        <Row className="profile-info-row px-4 pt-3 pb-5">
          <Col className="profile-info-column profile-info-title">
            <span>
              My Profile
              {!edit && (
                <img
                  onClick={() => toggleEdit()}
                  src={Edit}
                  alt="edit-icon"
                  className="profile-info-edit_icon"
                />
              )}
            </span>
          </Col>
        </Row>

        <Row
          className="d-flex px-4 w-100 user-avatar-layout"
          style={{ columnGap: '100px' }}
        >
          <div style={{ flex: 1 }}>
            <Avatar user={user} />
          </div>
          <div style={{ flex: 3, flexBasis: '300px' }}>
            <Row>
              <Col xs={6} sm={4} md={4}>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column small-text profile-info-subtitle"
                    style={styles.text}
                  >
                    Full Name {edit && '*'}
                  </Col>
                </Row>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column primary profile-info-subtitle"
                    style={styles.text}
                  >
                    {edit ? (
                      <Form.Control
                        data-testid="account-full-name"
                        type="text"
                        name="fullName" //key
                        value={editedUser.fullName}
                        onChange={(e) => handleInputChange(e)}
                        required
                      ></Form.Control>
                    ) : (
                      editedUser.fullName || ''
                    )}
                  </Col>
                </Row>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column small-text profile-info-subtitle"
                    style={styles.text}
                  >
                    Email {edit && '*'}
                  </Col>
                </Row>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column primary profile-info-subtitle"
                    style={styles.text}
                  >
                    {edit ? (
                      <Form.Control
                        data-testid="account-email"
                        type="text"
                        name="email" //key
                        value={editedUser.email}
                        onChange={(e) => handleInputChange(e)}
                        required
                      ></Form.Control>
                    ) : (
                      editedUser.email
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xs={6} sm={4} md={4}>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column small-text profile-info-subtitle"
                    style={styles.text}
                  >
                    Date Of Birth
                  </Col>
                </Row>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column primary profile-info-subtitle"
                    style={styles.text}
                  >
                    {/* date of birth change here */}
                    {edit ? (
                      <DatePicker
                        className="account-date-picker"
                        selected={startDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(date) => handleDateChange(date)}
                      />
                    ) : (
                      `${
                        startDate.getMonth() + 1
                      }/${startDate.getDate()}/${startDate.getFullYear()}`
                    )}
                  </Col>
                </Row>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column small-text profile-info-subtitle"
                    style={styles.text}
                  >
                    Phone Number
                  </Col>
                </Row>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column primary profile-info-subtitle"
                    style={styles.text}
                  >
                    {edit ? (
                      <InputGroup>
                        <PhoneNumber
                          countryFlagCode={countryFlagCode}
                          setCountryFlagCode={setCountryFlagCode}
                          countryCallingCode={countryCallingCode}
                          setCountryCallingCode={setCountryCallingCode}
                          phoneNumber={phoneNumber}
                          setPhoneNumber={setPhoneNumber}
                        />
                      </InputGroup>
                    ) : (
                      <div>
                        <img
                          src={flagIcon}
                          alt="flag-icon"
                          style={{
                            width: '30px',
                            height: '30px',
                            border: 'none',
                          }}
                        />
                        {/* <span className="extra-small-text p-1">{_codeArea.code}</span> */}
                        <span className="extra-small-text p-1">
                          {countryCallingCode}
                        </span>
                        {phone}
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={4} md={4}>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column small-text profile-info-subtitle"
                    style={styles.text}
                  >
                    Location
                  </Col>
                </Row>
                <Row className="profile-info-row">
                  <Col
                    className="profile-info-column primary profile-info-subtitle"
                    style={styles.text}
                  >
                    {edit ? (
                      <LocationSearchInput
                        location={_location}
                        setLocation={setLocation}
                      />
                    ) : (
                      _location || ''
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Row>
        <Row className="px-4 skills-row-section">
          <Row className="profile-info-row">
            {!edit && (
              <Col
                lg={1}
                xl={1}
                className="profile-info-column small-text profile-info-subtitle"
                style={styles.text}
              >
                Skills:
              </Col>
            )}
            <Col lg={11} xl={11}>
              <Row className="profile-info-row">
                <Col
                  className="profile-info-column primary profile-info-subtitle"
                  style={styles.text}
                >
                  {edit ? (
                    <Col className="mentors-your-resume-line-below">
                      <div className="my-3">
                        {'Skills'}
                        <CreatableSelect
                          isMulti
                          defaultValue={displaySkills?.map((x) => ({
                            value: x,
                            label: x,
                          }))}
                          options={options}
                          onChange={onSkillSelected}
                          onCreateOption={onCreateSkillOption}
                        />
                      </div>
                    </Col>
                  ) : displaySkills?.length === 0 ? (
                    <div className="profile-info-column extra-small-text profile-info-subtitle">
                      You haven't added any skills
                    </div>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={tooltip(displaySkills)}
                    >
                      <Row className="w-100 mt-0">
                        <Row>
                          <Col xs={1} md={1} lg={1}>
                            <i class="fa-solid fa-circle-info"></i>
                          </Col>
                          <Col xs={11} md={11} lg={11}>
                            <span className="main-language">
                              {formatGigOrJobSkills({ skills: displaySkills })}
                            </span>
                          </Col>
                        </Row>
                      </Row>
                    </OverlayTrigger>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>

        {/* {edit && (
          <Row className="px-4 mt-4 skills-row-section">
            <Col className="profile-info-column extra-small-text profile-info-subtitle">* required fields</Col>
          </Row>
        )} */}

        <div className="d-flex justify-content-end">
          {edit && (
            <div className="form-buttons">
              <div className="d-flex">
                <button
                  className="profile_cancel_button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                {loading ? (
                  <Button disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <button type="submit" className="profile_save_button">
                    Save
                  </button>
                )}
              </div>

              {saveValidationError !== '' && (
                <span className="error-message">{saveValidationError}</span>
              )}
            </div>
          )}
        </div>
      </Form>
    </Container>
  );
};

// const SkillComponent = ({ skill }) => {
//   return (
//     <li>
//       <Col xs={12} key={`key-${skill.value}`}>
//         <span className="skill-tag">{skill}</span>
//       </Col>
//     </li>
//   );
// };

// const OverlayOther = ({ skill, text }) => {
//   return (
//     <OverlayWithChildren {...{ text }}>
//       <li>
//         <Col xs={12} key={`key-${skill}`}>
//           <span className="skill-tag">{skill}</span>
//         </Col>
//       </li>
//     </OverlayWithChildren>
//   );
// };

export default ProfileInfo;
