import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner, Button } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import * as OpportunityApi from '../../api/opportunities';
import { uploadFile, uploadLogo } from '../../api/files';
import { navigate } from '@reach/router';

const fileTypes = ['JPEG', 'PNG', 'GIF', 'PDF', 'ZIP'];

export default function SubmitWorkModal({
  show,
  setSubmitWorkModal,
  id,
  setIsSubmited,
  reLoad,
}) {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [file, setFile] = useState('');
  const [name, setName] = useState('');

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleChange = (file) => {
    setType(file[0].type);
    setName(file[0].name);
    // getBase64(file[0]);
  };

  const submitWork = async () => {
    if (!file) {
      await OpportunityApi.submitOpportunity(id, {
        comment: content,
        files: [],
      });
      toast('Completed opportunity submitted successfully!');
      reLoad();
    } else {
      try {
        setLoading(true);
        const filesUrl = await uploadLogo(file);
        await OpportunityApi.submitOpportunity(id, {
          comment: content,
          files: filesUrl.fullUrl,
        });
        toast('Completed opportunity submitted successfully!');
        reLoad();
      } catch (error) {
        toast(error.error);
      }
    }
    setSubmitWorkModal(false);
    setIsSubmited(false);
    const assessment = null;
    reLoad();
    navigate(`/teams/`, { state: assessment });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setSubmitWorkModal(false);
        setIsSubmited(false);
      }}
    >
      <Modal.Header closeButton>
        Please describe what you did and where below and upload a file
      </Modal.Header>
      <Modal.Body>
        <textarea
          className="text-box-entry"
          placeholder="Example: I submitted my pull request within the github account for review"
          value={content}
          rows="4"
          onChange={(e) => {
            setContent(e.target.value);
          }}
        ></textarea>
        <div className="App">
          <h1>Upload any relevant files</h1>
          <FileUploader
            multiple={true}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
          <p>{file ? `File name: ${name}` : 'no files uploaded yet'}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <Button disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Button>
        ) : (
          <button
            className="btn-primary-medium"
            onClick={submitWork}
            type="button"
          >
            Confirm
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
