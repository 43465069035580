import { ReactComponent as TextUs } from '../../assets/icon/snapbrillia_text_us_icon.svg';


export default function TextUsButton() {
  // eslint-disable-next-line no-restricted-globals
  let width = screen.width / 1.5;
   // eslint-disable-next-line no-restricted-globals
  let height = screen.height / 1.5;
   // eslint-disable-next-line no-restricted-globals
  let left = screen.width / 2 - width / 2;
   // eslint-disable-next-line no-restricted-globals
  let top = screen.height / 2 - height / 2;
  return (
    <button
      className="btn-primary text-us mb-0"
      onClick={() =>
        window.open(
          'https://discord.gg/vJuPXu86vS',
          '_blank',
          `width=${width}, height=${height}, left=${left}, top=${top}`
        )
      }
    >
      <TextUs />
      <span className="textUsIcon">Text Us</span>
    </button>
  );
}
