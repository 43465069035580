export const SUPPORT_LANGUAGES = [
  'JavaScript',
  'Haskell',
  'C++',
  'C',
  'Python',
  'Java',
  'TypeScript',
  'HTML',
  'C#',
  'SQL',
  'Rust',
  'Go',
  'Lua',
  'PHP',
  'Haskell',
  'React',
  'Angular',
  'Ruby',
  'R',
  'Shell',
  'Scala',
  'Nix',
  'Swift',
  'Elixir',
  'Clojure',
  'Perl',
  'Kotlin',
  'Dart',
  'Erlang',
  'Julia',
  'Visual Basic .NET',
  'Assembly',
  'WebAssembly',
  'Fortran',
  'Objective-C',
  'Groovy',
  'SystemVerilog',
  'DM',
  'Emacs Lisp',
  'CodeQL',
  'PowerShell',
  'F#',
];

export const lovelaceInOneADA = 1000000;
