import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
//CSS
import '../css/grantsList.css';
import { timeAgo } from '../../shared/utils.js';
import { UserImage } from '../../shared/images';

//API
import { getGrantsContributions } from '../../api/order';

//Context
import { useCart } from '../../context/cartContext';

const ContributorItem = ({ grant }) => {
  const { isStaging } = useCart()
  const [contributions, setContributions] = useState([]);

  let viewTransactionUrl = '';
  if (isStaging) {
    viewTransactionUrl = `https://preprod.cardanoscan.io/transaction`;
  } else {
    viewTransactionUrl = `https://cardanoscan.io/transaction`;
  }

  useEffect(() => {
    (async () => {
      const contributions = await getGrantsContributions(grant._id);
      setContributions(contributions);
    })();
  }, []);

  return (
    <Container>
      {contributions.map((contribution, i) => {
        return (
          <Row
            key={i}
            style={{ position: 'relative', left: '12px' }}
            className="align-items-center contributor-bg"
          >
            <Col>
              <Row className="align-items-center">
                <Col xs={2}>
                  <img
                    className="contributor-item"
                    src={UserImage(contribution.candidateId)}
                    alt="profile-logo"
                  />
                </Col>
                <Col>
                  <h4 className="h3 ms-3 bold m-0">
                    {contribution.candidateId.fullName}
                  </h4>
                  <span className="ms-3 grey-title">Contributor</span>
                </Col>
              </Row>
            </Col>
            <Col className="text-center">
              <h5 className="bold m-0">
                {contribution.totalAmount} <span>ADA</span>
              </h5>
              <span className="grey-title">Contribution</span>
            </Col>
            <Col className="text-center">
              <h5 className="bold m-0">{timeAgo(contribution.updatedAt)}</h5>
              <span className="grey-title">Time</span>
            </Col>
            <Col className="text-center">
              <a
                href={`${viewTransactionUrl}/${contribution.transactionHash}`}
                className="bold m-0"
                target="_blank"
              >
                View Transaction Details
              </a>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default ContributorItem;
