import React from 'react';
import './css/menteePage.css';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import NavBar from '../shared/menus/navBar';
import MenteeIntro from './components/menteeIntro';
import MentorsPersonalInfo from './components/mentorsPersonalInfo';
import MentorsLinkedAccounts from './components/mentorsLinkedAccounts';
import SaveAndContinue from '../shared/button/saveAndContinue';
import { useAuth } from '../context/authContext';
import { useState, useEffect } from 'react';
import { Link, navigate } from '@reach/router';
import { useParams } from '@reach/router';
import { uploadFile } from '../api/files';
import { useBounty } from '../hooks/useBounty';
import { toast } from 'react-toastify';
import NotFound from '../shared/errors/404';
import Loading from '../shared/Overlays/loading';
import useViewModel from '../jobsApplicant/viewModels/individualJobViewModel';
import _ from 'lodash';
const MenteePage = () => {
  const { id } = useParams();
  const { auth, updateProfile } = useAuth();
  const [resumeFile, setResumeFile] = useState(null);
  const [newLocation, setNewLocation] = useState(auth.user?.location || '');
  const [Running, setRunning] = useState(false);
  const { bounty, loading } = useBounty(id);
  const [skills, setSkills] = useState([]);
  const [skillsFromDb, setSkillsFromDb] = useState(false);
  const [newFullName, setNewFullName] = useState(auth.user?.fullName);
  const { validation } = useViewModel();

  useEffect(() => {
    if (auth) {
      if (auth.user && auth.user.skills && auth.user.skills.length > 0) {
        setSkills(
          auth.user.skills.map((skill) => ({ label: skill, value: skill }))
        );
        setSkillsFromDb(true);
      } else {
        setSkillsFromDb(false);
      }
      setNewLocation(auth.user.location);
    }
  }, [auth]);

  const updateUserProfile = async () => {
    const updateInfo = {};

    if (!newFullName || newFullName === '') {
      return toast('Please add your fullname.');
    }

    if (!newLocation || newLocation === '') {
      return toast('Please add your location.');
    }

    if (skills.length === 0) {
      return toast('Please Complete your skills.');
    }

    if ((!auth.user || !auth.user.githubId) && bounty.useGithubApp) {
      toast('Please link your github account');
      return;
    }
    setRunning(true);
    if (resumeFile) {
      const formData = new FormData();
      formData.append('type', 'resume');
      formData.append('file', resumeFile[0]);
      try {
        const fileInfo = await uploadFile(formData);
        if (fileInfo.url) {
          updateInfo.resume = fileInfo.url;
        }
      } catch (err) {}
    }
    if (newLocation !== auth.user?.location) {
      updateInfo.location = newLocation;
    }

    if (!skillsFromDb && skills.length > 0) {
      updateInfo.skills = skills.map((skill) => skill.label);
    }

    if (Object.keys(updateInfo).length) {
      try {
        await updateProfile(updateInfo);
      } catch (err) {}
    }
    setRunning(false);
    navigate(`/bounties/${id}/jointeam`);
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <Loading />
      ) : !_.isEmpty(bounty) && bounty ? (
        <Container fluid className="px-5 pb-5">
          <Row>
            <MenteeIntro bounty={bounty} />
          </Row>
          <Row className="mt-4"></Row>
          <Row style={{ marginTop: '50px' }}>
            <Col xs={12} md={6}>
              <MentorsPersonalInfo
                user={auth.user}
                {...{
                  skills,
                  setSkills,
                  skillsFromDb,
                  newLocation,
                  setNewLocation,
                  resumeFile,
                  setResumeFile,
                  newFullName,
                  setNewFullName,
                  validation,
                }}
              />
            </Col>
            <Col xs={12} md={6} className="mentee_page_linked_accounts_col">
              <MentorsLinkedAccounts user={auth.user} bounty={bounty} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ marginTop: '100px' }} className="float-end d-flex">
                <button
                  className="mentee_page_cancel_button"
                  style={{ fontSize: '12px' }}
                >
                  <Link to={`/bounties/${id}`} style={{ fontSize: '14px' }}>
                    Cancel
                  </Link>
                </button>
                {Running ? (
                  <Button disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <SaveAndContinue
                    onClick={() => updateUserProfile()}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default MenteePage;
