import GrantLeaderboardBody from './grantLeaderboardBody';
import { Container, Row } from 'react-bootstrap';
import Back from '../../assets/icon/snapbrillia_softwareengineerback_icon.png';
import { useState, useEffect } from 'react';
import LeaderboardOverviewData from './leaderboardOverviewData';
import { useInterval } from '../../hooks/useGrant';
import * as grantAPI from '../../api/grant';
import { toast } from 'react-toastify';
import '../css/grantLeaderboard.css';
import { useGrant } from '../../context/grantsContext';
import NavBar from '../../shared/menus/navBar';
import { lovelaceInOneADA } from '../../shared/constants';

const GrantsLeaderboard = () => {
  const { listOfFundingRounds, currentFundingRound } = useGrant();
  const [grantsLeaderboardList, setGrantsLeaderboardList] = useState([]);
  const [sortBy, setSortBy] = useState('ratio');
  const [fundingRound, setFundingRound] = useState();
  const [showAfterFees, setShowAfterFees] = useState(false);
  const [previousGrantsLeaderboardList, setPreviousGrantsLeaderboardList] =
    useState([]);
  const [lastUpdated, setLastUpdated] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [matchPoolAmount, setMatchPoolAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalDonationCount, setTotalDonationCount] = useState(0);
  const [largestMatchPoolReward, setLargestMatchPoolReward] = useState(0);
  const [projectEligiability, setProjectEligibility] = useState(0);
  const [totalRaised, setTotalRaised] = useState(0);
  const [refreshSpinner, setRefreshSpinner] = useState(false);

  const getGrants = async (fundingRound) => {
    setPreviousGrantsLeaderboardList(grantsLeaderboardList);
    const { grants, grantsOverviewData } = await grantAPI.getAllGrants(
      fundingRound
    );
    const matchPoolAmountInADA =
      grantsOverviewData.matchPoolAmount / lovelaceInOneADA;
    formatOverviewData(grants, matchPoolAmountInADA);
    sortGrantsList(grants, sortBy);
    setGrantsLeaderboardList(grants);
    updateLastUpdated();
  };

  const updateLastUpdated = () => {
    const date = new Date();
    const time = date.toLocaleTimeString();
    const day = date.toLocaleDateString();
    setLastUpdated(`${day} ${time}`);
  };

  // If the current funding round is not the same as the funding round
  // then dont get the matchPool amount from the API , just calculate it
  const formatOverviewData = (grantsList, matchPoolAmount) => {
    if (grantsList.length === 0) {
      setTotalRaised(matchPoolAmount);
      setMatchPoolAmount(matchPoolAmount);
      setTotalDonationCount(0);
      setProjectEligibility(0);
      setLargestMatchPoolReward(0);
      return;
    }
    const totalDonationCount = grantsList.reduce(
      (acc, curr) => acc + curr.currentAmount,
      0
    );
    const projectEligiability = grantsList.reduce((acc, curr) => {
      const ratio =
        (curr.currentAmount + curr.matchPoolAmount) / curr.fundraisingAmount;
      if (ratio >= 1) {
        return acc + 1;
      }
      return acc;
    }, 0);
    const largestMatchPoolReward =
      grantsList.sort((a, b) => {
        return b.matchPoolAmount - a.matchPoolAmount;
      })[0]?.matchPoolAmount || 0;
    const totalAmountRaised = matchPoolAmount + totalDonationCount;
    setMatchPoolAmount(matchPoolAmount);
    setTotalRaised(totalAmountRaised);
    setTotalDonationCount(totalDonationCount);
    setProjectEligibility(projectEligiability);
    setLargestMatchPoolReward(largestMatchPoolReward);
    setTotalRaised(totalAmountRaised);
  };

  const { reset } = useInterval(getGrants, 30000);

  const refreshDataWithLoading = async () => {
    try {
      reset();
      setLoadingButton(true);
      await getGrants();
      setLoadingButton(false);
    } catch (err) {
      setLoadingButton(false);
      toast('Could not load grants');
    }
  };

  const sortGrantsList = (grantsLeaderboardList, e) => {
    let sorted = [];
    switch (e) {
      case 'fundingAmount':
        sorted = [...grantsLeaderboardList].sort((a, b) => {
          return b.fundraisingAmount - a.fundraisingAmount;
        });
        break;
      case 'raisedDonationAmount':
        sorted = [...grantsLeaderboardList].sort((a, b) => {
          return b.currentAmount - a.currentAmount;
        });
        break;
      case 'matchPoolAmount':
        sorted = [...grantsLeaderboardList].sort((a, b) => {
          return b.matchPoolAmount - a.matchPoolAmount;
        });
        break;
      case 'totalRaised':
        sorted = [...grantsLeaderboardList].sort((a, b) => {
          const aAmount = a.matchPoolAmount + a.currentAmount;
          const bAmount = b.matchPoolAmount + b.currentAmount;
          return bAmount - aAmount;
        });
        break;
      case 'ratio':
        sorted = [...grantsLeaderboardList].sort((a, b) => {
          const bRatio =
            (b.currentAmount + b.matchPoolAmount) / b.fundraisingAmount;
          const aRatio =
            (a.currentAmount + a.matchPoolAmount) / a.fundraisingAmount;
          return bRatio - aRatio;
        });
        break;
      default:
    }
    setSortBy(e);
    setGrantsLeaderboardList(sorted);
  };

  const handleFundingRoundChange = async (e) => {
    setRefreshSpinner(true);
    setLoading(true);
    setSortBy('ratio');
    setFundingRound(e);
    const { grants, grantsOverviewData } = await grantAPI.getAllGrants(e);
    const matchPoolAmountInADA =
      grantsOverviewData.matchPoolAmount / lovelaceInOneADA;
    reset();
    formatOverviewData(grants, matchPoolAmountInADA);
    setGrantsLeaderboardList(grants);
    setLoading(false);
    updateLastUpdated();
    setRefreshSpinner(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getGrants(currentFundingRound);
      setLoading(false);
    })();
  }, []);

  return (
    <Container fluid className="grant-review-container">
      <NavBar />
      <div className="px-5 pb-5 grant-review-main">
        <Row className="grant-review-back">
          <div className="grant-review-back-button">
            <a href="/grants" className="back-to-grants-link">
              <img src={Back} alt="back-icon" />
              <p className="back-to-grants-link d-inline">Back to Grants</p>
            </a>
          </div>
        </Row>
        <LeaderboardOverviewData
          dataLoading={loading}
          setMatchPoolAmount={setMatchPoolAmount}
          grantsList={grantsLeaderboardList}
          fundingRound={fundingRound}
          matchPoolAmount={matchPoolAmount}
          totalDonationCount={totalDonationCount}
          largestMatchPoolReward={largestMatchPoolReward}
          totalRaised={totalRaised}
          projectEligiability={projectEligiability}
        />
        <div className="mt-5 py-4 d-flex justify-content-between">
          <div>
            <div>
              <span>Show Funds: </span>
              <select
                id="skills"
                className="bounty-sideMenu-dropDown w-100"
                name="skills"
                disabled={loading}
                onChange={(e) => {
                  handleFundingRoundChange(e.target.value);
                }}
              >
                {listOfFundingRounds.map((fund, index) => {
                  return (
                    <option value={fund} key={index}>
                      Fund {fund}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mt-2">
              <span>Sort by: </span>
              <select
                id="skills"
                className="bounty-sideMenu-dropDown w-100"
                name="skills"
                value={sortBy}
                onChange={(e) => {
                  sortGrantsList(grantsLeaderboardList, e.target.value);
                  setSortBy(e.target.value);
                }}
              >
                <option value="ratio">Ratio</option>
                <option value="totalRaised">Total Raised</option>
                <option value="fundingAmount">Requested Funding Amount</option>
                <option value="raisedDonationAmount">Donations Recieved</option>
                <option value="matchPoolAmount">Match Pool Amount</option>
              </select>
            </div>
          </div>
          <div className="form-check form-switch">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={() => setShowAfterFees(!showAfterFees)}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              >
                Show After Fees
              </label>
            </div>
          </div>
          <div className="text-center">
            <p> Last Updated: {lastUpdated}</p>
            {loadingButton ? (
              <div className="spinner-border match-pool-color" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className="pt-1 m-1 d-flex justify-content-around align-items-center">
                <div
                  className={`${refreshSpinner ? '' : 'spinner-30-seconds'}`}
                />
                <button
                  className="btn-primary"
                  onClick={refreshDataWithLoading}
                >
                  Refresh
                </button>
              </div>
            )}
          </div>
        </div>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr className="table-header">
              <th scope="col">#</th>
              <th scope="col">Grant</th>
              <th scope="col">Requested Funding Amount</th>
              <th scope="col">Donations Recieved</th>
              <th scope="col">Match Pool Amount</th>
              <th scope="col">Match Pool Eligibility</th>
              <th scope="col">Total Raised</th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              <GrantLeaderboardBody
                grants={grantsLeaderboardList}
                showAfterFees={showAfterFees}
                prevGrants={previousGrantsLeaderboardList}
              />
            </tbody>
          )}
        </table>
      </div>
    </Container>
  );
};

export default GrantsLeaderboard;
