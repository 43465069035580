import _ from 'lodash';
import React, { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import JobPosting from '../jobsApplicant/components/jobPosting';
import ActiveGig from './activeGig';
// import CompletedGig from './completedGig';
import TeamsHeader from './components/teamsHeader';
import ActiveTeam from './components/active-team';
import NoTeam from './components/noTeam';
import NavBar from '../shared/menus/navBar';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/authContext';
import ChatModal from './components/chatModal';
import SubmitWorkModal from '../bounties/components/submitWorkModal.jsx';
import './css/chat.css';
import { getOpportunitiesByStatus } from '../api/opportunities';
import WorkLoader from './workLoader';
import '../bounties/components/css/allBounties.css';

const Teams = (props) => {
  const assessemntId = props?.location?.state?.assessment;
  const [showSubmitWorkModal, setSubmitWorkModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('All');
  const [close, setClose] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [submittedJobs, setSubmittedJobs] = useState([]);
  const [submittedGigs, setSubmittedGigs] = useState([]);
  const [invited, setInvitedJobs] = useState([]);
  const [invitedGigs, setInvitedGigs] = useState([]);
  const [progressGig, setProgressGig] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [completedGigs, setCompletedGigs] = useState([]);
  const [progressJob, setProgressJob] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);

  const { auth } = useAuth();
  const getMyJobs = async () => {
    try {
      const jobs = await getOpportunitiesByStatus('All');
      if (_.isArray(jobs) && !_.isEmpty(jobs)) {
        const submittedJob = _.filter(
          jobs,
          (x) => x.status === 'Submitted' && x.opportunityId.type === 'Job'
        );
        if (_.isArray(submittedJob) && !_.isEmpty(submittedJob)) {
          setSubmittedJobs(submittedJob);
        }
        const submittedGig = _.filter(
          jobs,
          (x) => x.status === 'Submitted' && x.opportunityId.type === 'Gig'
        );
        if (_.isArray(submittedGig) && !_.isEmpty(submittedGig)) {
          setSubmittedGigs(submittedGig);
        }
        const progressGig = _.filter(
          jobs,
          (x) => x.status === 'In-Progress' && x.opportunityId.type === 'Gig'
        );
        if (_.isArray(progressGig) && !_.isEmpty(progressGig)) {
          setProgressGig(progressGig);
        }
        const progressJob = _.filter(
          jobs,
          (x) => x.status === 'In-Progress' && x.opportunityId.type === 'Job'
        );
        if (_.isArray(progressJob) && !_.isEmpty(progressJob)) {
          setProgressJob(progressJob);
        }
        // const invitedJob = _.filter(
        //   jobs,
        //   (x) => x.status === 'Invited' && x.opportunityId.type === 'Job'
        // );
        // if (_.isArray(invitedJob) && !_.isEmpty(invitedJob)) {
        //   setInvitedJobs(invitedJob);
        // }
        // const invitedGig = _.filter(
        //   jobs,
        //   (x) => x.status === 'Invited' && x.opportunityId.type === 'Gig'
        // );
        // if (_.isArray(invitedGig) && !_.isEmpty(invitedGig)) {
        //   setInvitedGigs(invitedGig);
        // }
        const completedJob = _.filter(
          jobs,
          (x) => x.status === 'Completed' && x.opportunityId.type === 'Job'
        );
        if (_.isArray(completedJob) && !_.isEmpty(completedJob)) {
          setCompletedJobs(completedJob);
        }
        const completedGig = _.filter(
          jobs,
          (x) => x.status === 'Completed' && x.opportunityId.type === 'Gig'
        );
        if (_.isArray(completedGig) && !_.isEmpty(completedGig)) {
          setCompletedGigs(completedGig);
        }
      }
    } catch (err) {
      setLoaded(true);
    }
  };

  const initLocation = () => {
    setSubmitWorkModal(true);
    setIsSubmited(true);
  };

  const onOpenChat = () => {
    setClose(!close);
  };

  const getData = async () => {
    await getMyJobs();
    setLoaded(true);
  };
  useEffect(() => {
    if (assessemntId !== null && assessemntId !== undefined) {
      initLocation();
    }
    getData();
  }, []);

  return (
    <Container fluid>
      <Row>
        <NavBar />
      </Row>
      <Row>
        <TeamsHeader />
        <Row>
          {close && (
            <div
              className="new"
              style={{
                cursor: 'pointer',
                left: close ? window.innerWidth - 120 : window.innerWidth - 150,
              }}
            >
              <span onClick={() => onOpenChat()}>
                <i className="fa fa-comments"></i>
              </span>
            </div>
          )}
        </Row>
        <Row
          style={{
            marginBottom: 50,
          }}
        >
          <Col
            data-testid="label"
            sm={3}
            className="bounties-sortby-dropDown pe-0 active_team_main_container pt-3"
          >
            <Row></Row>
            <label>Sort By:</label>{' '}
            <select
              data-testid="select"
              className="bg-light"
              onChange={(e) => {
                setSelectedOption(e.target.value);
              }}
            >
              <option value="All">Select an option...</option>
              <optgroup label="Opportunities">
                <option value="All">All Opportunities</option>
                {/* <option value="Invited">Invited Opportunities</option> */}
                <option value="In-Progress">In-progress Opportunities</option>
                <option value="Submitted">Submitted Opportunities</option>
                <option value="Completed">Completed Opportunities</option>
              </optgroup>
            </select>
          </Col>
        </Row>
      </Row>
      {!loaded ? (
        <WorkLoader />
      ) : (
        selectedOption === 'All' &&
        (progressGig.length > 0 ||
        progressJob.length > 0 ||
        submittedGigs.length > 0 ||
        submittedJobs.length > 0 ||
        completedJobs.length > 0 ||
        completedGigs.length > 0 ? (
          <>
            {(progressJob.length > 0 || progressGig.length > 0) && (
              <Row>
                <p className="active_team_title ps-5">Your Active Work(s)</p>
                {progressGig.map((opportunity, idx) => (
                  <ActiveTeam key={idx} opportunity={opportunity} />
                ))}
                {progressJob.map((opportunity, idx) => (
                  <JobPosting key={idx} application={opportunity} />
                ))}
              </Row>
            )}
            {(submittedJobs.length > 0 || submittedGigs.length > 0) && (
              <Row>
                <p className="active_team_title ps-5">Your Submitted Work(s)</p>
                {submittedGigs.map((opportunity, idx) => (
                  <ActiveGig
                    key={'individual-' + idx}
                    opportunity={opportunity}
                  />
                ))}
                {submittedJobs.map((opportunity, idx) => (
                  <JobPosting
                    key={'individual-' + idx}
                    application={opportunity}
                  />
                ))}
              </Row>
            )}
            {/* {(invitedGigs.length > 0 || invited.length > 0) && (
              <Row>
                <h1 className="big-header ps-5">Invited Works</h1>
                <Container className="active_team_main_container" fluid>
                  <Row>
                    {invitedGigs.map((opportunity, idx) => (
                      <ActiveTeam key={idx} opportunity={opportunity} />
                    ))}
                    {invited.map((opportunity, idx) => (
                      <JobPosting key={idx} application={opportunity} />
                    ))}
                  </Row>
                </Container>
              </Row>
            )} */}
            {(completedGigs.length > 0 || completedJobs.length > 0) && (
              <Row>
                <h1 className="big-header ps-5">Completed Works</h1>
                <Container className="active_team_main_container" fluid>
                  <Row>
                    {completedGigs.map((opportunity, idx) => (
                      <ActiveTeam key={idx} opportunity={opportunity} />
                    ))}
                    {completedJobs.map((opportunity, idx) => (
                      <JobPosting key={idx} application={opportunity} />
                    ))}
                  </Row>
                </Container>
              </Row>
            )}
          </>
        ) : (
          <NoTeam clicked={selectedOption} />
        ))
      )}

      {/* {selectedOption === 'Invited' &&
        (invitedGigs?.length > 0 || invited?.length > 0 ? (
          <>
            <Row>
              <p className="active_team_title ps-5">Your Invited Work(s)</p>
              {invitedGigs.map((opportunity, idx) => (
                <ActiveTeam key={idx} opportunity={opportunity} />
              ))}
              {invited.map((opportunity, idx) => (
                <JobPosting key={idx} application={opportunity} />
              ))}
            </Row>
          </>
        ) : (
          <NoTeam clicked={selectedOption} />
        ))} */}

      {selectedOption === 'In-Progress' &&
        (progressGig?.length > 0 || progressJob?.length > 0 ? (
          <>
            <Row>
              <p className="active_team_title ps-5">Your Active Work(s)</p>
              {progressGig.map((opportunity, idx) => (
                <ActiveTeam key={idx} opportunity={opportunity} />
              ))}
              {progressJob.map((opportunity, idx) => (
                <JobPosting key={idx} application={opportunity} />
              ))}
            </Row>
          </>
        ) : (
          <NoTeam clicked={selectedOption} />
        ))}

      {selectedOption === 'Submitted' &&
        (submittedGigs.length > 0 || submittedJobs.length > 0 ? (
          <>
            <Row>
              <p className="active_team_title ps-5">Your Submitted Work(s)</p>
              {submittedGigs.map((opportunity, idx) => (
                <ActiveGig
                  key={'individual-' + idx}
                  opportunity={opportunity}
                />
              ))}
              {submittedJobs.map((opportunity, idx) => (
                <JobPosting
                  key={'individual-' + idx}
                  application={opportunity}
                />
              ))}
            </Row>
          </>
        ) : (
          <NoTeam clicked={selectedOption} />
        ))}
      {selectedOption === 'Completed' &&
        (completedGigs.length > 0 || completedJobs.length > 0 ? (
          <Row>
            <h1 className="big-header ps-5">Completed Works</h1>
            <Container className="active_team_main_container" fluid>
              <Row>
                {completedGigs.map((opportunity, idx) => (
                  <ActiveTeam key={idx} opportunity={opportunity} />
                ))}
                {completedJobs.map((opportunity, idx) => (
                  <JobPosting key={idx} application={opportunity} />
                ))}
              </Row>
            </Container>
          </Row>
        ) : (
          <NoTeam clicked={selectedOption} />
        ))}

      {showSubmitWorkModal && isSubmited && (
        <div>
          <SubmitWorkModal
            id={assessemntId}
            setSubmitWorkModal={setSubmitWorkModal}
            setIsSubmited={setIsSubmited}
            show={showSubmitWorkModal}
            reLoad={() => {
              setProgressJob([]);
              setProgressGig([]);
              getMyJobs();
            }}
          />
        </div>
      )}
      {!close && (
        <Row>
          <div>
            <ChatModal setClose={setClose} />
          </div>
        </Row>
      )}
    </Container>
  );
};

export default Teams;
