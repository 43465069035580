import '../css/typography.css';
import './css/dropDownMenu.css';
import Dropdown from 'react-bootstrap/Dropdown';
import Profile from '../../assets/icon/snapbrillia_profile_personal_icon.svg';
import Settings from '../../assets/icon/snapbrillia_settings_icon.svg';
import SignOut from '../../assets/icon/snapbrillia_signout_icon.svg';
import { useAuth } from '../../context/authContext';
import { useState } from 'react';
import { Link } from '@reach/router';
import SupportModal from '../modals/SupportModal';
import { ReactComponent as Feedback } from '../../assets/icon/snapbrillia_feedback_icon.svg';
import { ReactComponent as Help } from '../../assets/icon/snapbrillia_help_icon.svg';

import FeedbackModal from '../modals/feedbackModal';

export default function DropDownMenu(props) {
  const { auth } = useAuth();
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  return (
    <Dropdown.Menu className="dropdown-menu-nav-bar dropdown-menu-end drop-downs-sm-right-auto-profile">
      <Dropdown.Header className="dropdown-header-nav-bar">
        Welcome back, {auth.user.fullName}
      </Dropdown.Header>
      <div className="align-center">
        <div className="container-dropdown-nav-bar">
          <Dropdown.Item
            className="dropdown-item-nav-bar"
            eventKey="1"
            as={Link}
            to="/profile-page"
          >
            <img
              src={Profile}
              alt="profile-icon"
              className="dropdown-profile-icon-mute"
            />
            <span className="text-muted p-3">My account</span>
          </Dropdown.Item>
          <Dropdown.Item
            className="dropdown-item-nav-bar"
            eventKey="2"
            as={Link}
            to="/settings-page"
          >
            <img
              src={Settings}
              alt="settings-icon"
              className="dropdown-settings-icon-mute"
            />
            <span className="text-muted p-3">Settings</span>
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item-nav-bar" eventKey="3">
            <Feedback
              className="search-bar-icon-clickable"
              onClick={() => setShowFeedbackModal(!showFeedbackModal)}
            />
            <span
              className="text-muted p-3"
              onClick={() => setShowFeedbackModal(!showFeedbackModal)}
            >
              Feedback
            </span>
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item-nav-bar" eventKey="3">
            <Help
              className="search-bar-icon-clickable"
              onClick={() => setShowSupportModal(!showSupportModal)}
            />
            <span
              className="text-muted p-3"
              onClick={() => setShowSupportModal(!showSupportModal)}
            >
              Help
            </span>
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item-nav-bar" eventKey="3">
            <img
              src={SignOut}
              alt="signout-icon"
              className="dropdown-signout-icon-mute"
            />
            <span className="text-muted p-3" onClick={props.toggleLoggedIn}>
              Sign Out
            </span>
          </Dropdown.Item>
          {showSupportModal && (
            <SupportModal
              showModal={showSupportModal}
              setShowModal={setShowSupportModal}
            />
          )}
          {showFeedbackModal && (
            <FeedbackModal
              showModal={showFeedbackModal}
              setShowModal={setShowFeedbackModal}
            />
          )}
        </div>
      </div>
    </Dropdown.Menu>
  );
}
