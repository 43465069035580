import { isNil } from 'lodash';
export const timeAgo = function (time) {
  var date = new Date(time);
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + ' Years Ago';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' Months Ago';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' Days Ago';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' Hours Ago';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' Minutes Ago';
  }
  return Math.floor(seconds) + ' Seconds Ago';
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  // As Base64 string
  return canvas;
}

export function formatCurrency(bounty) {
  let amount = bounty.rewardAmount || bounty.minCompensation;
  let result = parseInt(amount).toLocaleString('en-US');
  if (amount < 1) {
    result = Number(amount).toFixed(3);
  }
  if (bounty.rewardType) {
    switch (bounty.rewardType) {
      case 'USD':
        result = `$${result}`;
        break;
      default:
        result = `${result} ${bounty.rewardType}`;
    }
  } else {
    result = `$${result}`;
  }
  return result;
}

export function formatCurrencyRange(bounty) {
  let result = parseInt(bounty.minCompensation).toLocaleString('en-US');
  let resultMax = parseInt(bounty.maxCompensation).toLocaleString('en-US');
  if (bounty.maxCompensation < 1 && bounty.minCompensation < 1) {
    result = Number(bounty.minCompensation).toFixed(3);
    resultMax = Number(bounty.maxCompensation).toFixed(3);
  }
  if (bounty.rewardType) {
    switch (bounty.rewardType) {
      case 'USD':
        result = `$${result} - $${resultMax}`;
        break;
      default:
        result = `$${result} - $${resultMax}`;
    }
  } else {
    result = `$${result}`;
  }
  return result;
}

export function formatGrantCurrentlyRaised(grant) {
  let formatted;
  let amount = parseInt(grant.currentAmount).toLocaleString('en-US');
  if (grant?.currencyType === '₳DA') {
    formatted = `${amount} ${grant.currencyType}`;
  } else {
    formatted = `$${amount}`;
  }
  return formatted;
}

export function formatPrefix(grant) {
  let formatted;
  if (grant?.currencyType === '₳DA') {
    formatted = ``;
  } else {
    formatted = `$`;
  }
  return formatted;
}

export function formatTrailingCurrency(grant) {
  let formatted;
  if (grant?.currencyType === '₳DA') {
    formatted = `₳DA`;
  } else {
    formatted = `USD`;
  }
  return formatted;
}

export const getHashCode = () => {
  const textList = [
    'blue',
    'red',
    'pink',
    'black',
    'purple',
    'orange',
    'grey',
    'white',
    'yellow',
  ];
  const randomFirstNumber = Math.floor(
    Math.random() * (textList.length - 1 - 0 + 1) + 0
  );
  const randomSecondNumber = Math.floor(
    Math.random() * (textList.length - 1 - 0 + 1) + 0
  );

  const string = textList[randomFirstNumber] + textList[randomSecondNumber];
  var hash = 0,
    i,
    chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export function isInactiveGrants(lastUpdated) {
  const currentDate = new Date();
  const lastUpdatedDate = new Date(lastUpdated);
  const diffTime = Math.abs(currentDate - lastUpdatedDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays > 30;
}

export function getGrantsRatio(grant) {
  const ratio =
    (grant.currentAmount + grant.matchPoolAmount) / grant.fundraisingAmount;
  return ratio;
}

export function checkIfWalletInstalled(wallet) {
  return !!window?.cardano?.[wallet];
}

export function formatWalletName(wallet) {
  switch (wallet) {
    case 'typhoncip30':
      return 'Typhon';
    case 'gerowallet':
      return 'Gero';
    case 'nufi':
      return 'NuFi';
    default:
      return wallet.charAt(0).toUpperCase() + wallet.slice(1);
  }
}

export function preventMinus(e) {
  if (e.code === 'Minus') {
    e.preventDefault();
  }
}
export function formatSkills(listOfSkills) {
  let result = '';
  if (
    listOfSkills &&
    listOfSkills.constructor.toString().indexOf('Array') > -1 &&
    listOfSkills.length > 0
  ) {
    let skills = listOfSkills[0];
    if (listOfSkills < 2) {
      return skills;
    }
    result = `${skills.name}, +${listOfSkills.length - 1} Others`;
  }
  return result;
}
const _isArray = (list) => {
  return list.constructor.toString().indexOf('Array') > -1 && list.length > 0;
};

const _hasSkills = (item) => {
  let result = false;
  if (item && item?.skills && item?.skills?.length > 0) {
    result = true;
  }
  if (item && item?.skillIds && item?.skillIds?.length > 0) {
    result = true;
  }
  return result;
};

export function formatGigOrJobSkills(item) {
  let result = '';
  if (_hasSkills(item)) {
    let skills = item?.skills ? item?.skills : item?.skillIds;
    if (skills.length < 2) {
      result = item?.skills ? `${skills[0]}` : `${skills[0].name}`;

      return result;
    }
    result = item?.skills
      ? `${skills[0]}, +${skills.length - 1} Others`
      : `${skills[0].name}, +${skills.length - 1} Others`;
  }
  return result;
}

export function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
}

export function truncateOnWord(str, limit) {
  if (!isNil(str) && str.length > 55) {
    var trimmable =
      '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
    var reg = new RegExp('(?=[' + trimmable + '])');
    var words = str.split(reg);
    var count = 0;
    return (
      words
        .filter(function (word) {
          count += word.length;
          return count <= limit;
        })
        .join('') + ' ...'
    );
  } else {
    return str;
  }
}

export function compensation(arr) {
  if (arr?.compensationCycle) {
    if (arr?.compensationCycle === 'Annually') {
      return 'yr';
    }
    if (arr?.compensationCycle === 'Hourly') {
      return 'hr';
    }
    if (arr?.compensationCycle === 'Monthly') {
      return 'mth';
    }
  } else {
    return 'yr';
  }
}
