import React, { useContext, Fragment, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../css/inviteTeamMembers.css';
import 'bootstrap/dist/css/bootstrap.css';
import Close from '../../assets/icon/snapbrillia_close_red_icon.svg';
import { GrantSetupContext } from './grantSetup';
import { getHashCode } from '../../shared/utils';

const InviteTeamMembers = ({isEdit}) => {
  const { teamMember, setTeamMember, throttle, setThrottle, throttleSetter } =
    useContext(GrantSetupContext);

  return (
    <Container className="invite-team-members" fluid>
      <Row className="invite-team-members-title">
        <div>Invite Team Members</div>
      </Row>
      {teamMember.map((member, index) => (
        <Fragment key={member._id}>
          <Row className="invite-funders-row1 d-flex justify-content-between">
            <div htmlFor="name" className="grant-setup-tag">
              Team Member&apos;s Name
            </div>
            {teamMember.length > 1 && (
              <div
                onClick={() => {
                  setTeamMember([...teamMember.filter(_member => _member._id !== member._id)]);
                }}
                className="grant-setup-close-icon"
              >
                <img src={Close} alt="close-icon" />
              </div>
            )}
            <div className="input-group">
              <input
                readOnly={isEdit && !member.isNew}
                value={member.fullName}
                onChange={(e) => {
                  let copy = [...teamMember];
                  copy[index].fullName = e.target.value;
                  if (e.target.value === '') delete copy[index].fullName;
                  setTeamMember(copy);
                }}
                type="text"
                className="form-control invite-team-members-control"
              />
            </div>
          </Row>
          <Row className="invite-team-members-row2">
            <label htmlFor="name" className="grant-setup-tag">
              Team Member&apos;s Email
            </label>
            <div className="input-group">
              <input
                readOnly={isEdit && !member.isNew}
                value={member.email}
                onChange={(e) => {
                  let copy = [...teamMember];
                  copy[index].email = e.target.value;
                  if (e.target.value === '') delete copy[index].email;
                  setTeamMember(copy);
                }}
                type="text"
                className="form-control invite-team-members-control"
              />
            </div>
          </Row>
        </Fragment>
      ))}
      <Row className="d-flex justify-content-end">
        <div
          className="invite-team-members-add"
          onClick={() => {
            // setThrottle([0, 0, 0, 0]);
            setTeamMember([...teamMember, { isNew: true, _id: getHashCode()}]);
          }}
        >
          Add Another Team Member
        </div>
      </Row>
    </Container>
  );
};

export default InviteTeamMembers;
