import React from 'react';
import { Form } from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import "../css/placesAutocomplete.css"

const LocationSearchInput = ({location, setLocation}) => {
    
    const handleChange = address => {
        setLocation(address);
    };

    const handleSelect = address => {
        setLocation(address);
    };

    return (
        <PlacesAutocomplete
            value={location}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                    <div>
                        <>
                            <Form.Control value={location}
                                data-testid="place-autocomplete"
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                            />
                        </>
                        {
                            suggestions.length > 0 &&
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div key={suggestion.placeId}
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        } 
                    </div>
                )
            }}
        </PlacesAutocomplete>
    );
}

export default LocationSearchInput;