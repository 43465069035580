import axios from 'axios';

export async function getAllBounty({ page = 1, filter = '' }) {
  try {
    const response = await axios.get(
      `/api/v1/opportunites?page=${page}${filter}`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getFeatureBounties(limit) {
  try {
    const response = await axios.get(`/api/v1/bounties/feature?limit=${limit}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getBounty(id) {
  try {
    const response = await axios.get(`/api/v1/bounty/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function expressInterest(id, body) {
  try {
    const response = await axios.post(
      `/api/bounties/${id}/express-interest`,
      body
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getMyAssessment(id, isStart = false) {
  try {
    const response = await axios.get(
      `/api/bounties/${id}/my-assessment/${isStart}`
    );

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getCompanyLogo(id) {
  try {
    const response = await axios.get(`/api/companies/${id}/get-logo`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getAllMyAssessments({ status = '' }) {
  try {
    const response = await axios.get(
      `/api/my-bounty-assessments?status=${status}`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getSimilarBounties(limit) {
  try {
    const response = await axios.get(`/api/v1/bounties/feature?limit=${limit}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function submitWork(id, result) {
  try {
    const response = await axios.post(`/api/bounty-assessment/${id}/submit`, {
      result,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function submitAssessment(id) {
  try {
    const response = await axios.put(
      `/api/bounty-assessment/${id}/submit-assessment`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function rewardIndividual(id) {
  try {
    const response = await axios.post(
      `/api/bounty-assessment/${id}/b2c-reward`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getPaginationBounties(page) {
  try {
    const response = await axios.get(`/api/v1/bounties?page=${page}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getPaginationOpportunites({ page = 1, filter = '' }) {
  try {
    const response = await axios.get(
      `/api/v1/opportunites?page=${page}${filter}`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function payoutBountyReward(data) {
  try {
    const response = await axios.post(
      '/api/bounty-assessment/payout/bank',
      data
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function feedBack(data) {
  try {
    const response = await axios.post('/api/user/feedback-create', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
