import '../css/typography.css';
import './css/dropDownWallet.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeModal from './stripeModal';
import { Button } from 'react-bootstrap';
import CartModal from './cartModal';
import { getRewardSummary } from '../../api/bountyCandidates';
import Bank from '../../assets/icon/snapbrillia_wallet_bank_icon.svg';
import CryptoWallet from '../../assets/icon/snapbrillia_crypto_wallet_icon_new.svg';
import { useCart } from '../../context/cartContext';
import { useAuth } from '../../context/authContext';
import { useEffect } from 'react';
import { checkIfWalletInstalled, formatWalletName } from '../utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
export default function DropDownWallet() {
  const {
    linkToken,
    bankAccounts,
    connectBankAccount,
    loadBankInfo,
    cryptoAccounts,
    disconnectCardanoWallet,
    attachBankToAccount,
  } = useCart();
  const { auth } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const { connectCardanoWallet } = useCart();
  const [wallet, setWallet] = useState({
    USD: 0,
    '₳DA': 0,
  });

  const wallets = [
    {
      url: 'https://namiwallet.io/',
      value: 'nami',
    },
    {
      url: 'https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka',
      value: 'eternl',
    },
    {
      url: 'https://flint-wallet.com/',
      value: 'flint',
    },
    {
      url: 'https://nu.fi/',
      value: 'nufi',
    },
    {
      url: 'https://typhonwallet.io/#/',
      value: 'typhoncip30',
    },
    {
      url: 'https://gerowallet.io/',
      value: 'gerowallet',
    },
  ];

  const init = async () => {
    if (auth) {
      const summary = await getRewardSummary();
      setWallet(summary);
    }
  };

  useEffect(() => {
    init();
  }, [auth]);

  return (
    <>
      <Dropdown.Menu className="wallet-dropdown-container dropdown-box-shadow dropdown-menu-end drop-downs-sm-right-auto-wallet p-0">
        {auth && auth.user && (
          <>
            <Dropdown.Header className="wallet-dropdown-header">
              Rewards Available
            </Dropdown.Header>
            <Dropdown.Header className="wallet-dropdown-header">
              $ {(wallet['USD'] || 0).toFixed(2)}
              <br />
              {(wallet['₳DA'] || 0).toFixed(2)} ₳DA
              <Button
                className="float-end"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Cash out
              </Button>
            </Dropdown.Header>
          </>
        )}
        <Dropdown.Header className="wallet-dropdown-header">
          Select Wallet
        </Dropdown.Header>
        {bankAccounts.length === 0 && (
          <Dropdown.Item
            eventKey="1"
            style={{ textAlign: 'left' }}
            className="wallet-dropdown-item"
            onClick={connectBankAccount}
          >
            <img src={Bank} alt="bank-icon" className="Bank_icon" />
            <span className="wallet-dropdown-text">Connect your bank</span>
          </Dropdown.Item>
        )}
        {bankAccounts.length > 0 &&
          bankAccounts.map((account, index) => {
            return (
              <Dropdown.Item
                key={`bank-${index}`}
                eventKey="1"
                style={{ textAlign: 'left' }}
                className="wallet-dropdown-item"
              >
                <img src={Bank} alt="bank-icon" className="Bank_icon" />
                <span className="wallet-dropdown-text">
                  {`${account.institution_name} (...${account.last4})`}
                </span>
              </Dropdown.Item>
            );
          })}
        {cryptoAccounts.length > 0 && (
          <Dropdown.Item
            eventKey="2"
            style={{ textAlign: 'left' }}
            className="wallet-dropdown-item"
            onClick={disconnectCardanoWallet}
          >
            <img
              src={CryptoWallet}
              alt="crypto-wallet-icon"
              className="crypto_wallet_icon"
            />
            <span className="wallet-dropdown-text">
              Disconnect Cardano Wallet
            </span>
          </Dropdown.Item>
        )}
        {!cryptoAccounts.length > 0 &&
          wallets.map((wallet, index) => {
            return (
              <div key={index}>
                <Dropdown.Item
                  eventKey="1"
                  style={{ textAlign: 'left' }}
                  className="wallet-dropdown-item"
                  onClick={() => {
                    checkIfWalletInstalled(wallet.value)
                      ? connectCardanoWallet(wallet.value)
                      : window.open(wallet.url);
                  }}
                >
                  <img
                    src={CryptoWallet}
                    alt="crypto-wallet-icon"
                    className="crypto_wallet_icon"
                  />
                  <span className="wallet-dropdown-text">
                    Connect Your {formatWalletName(wallet.value)} Wallet
                  </span>
                </Dropdown.Item>
              </div>
            );
          })}
        {cryptoAccounts.length > 0 && (
          <Dropdown.Item eventKey="1" className="wallet-dropdown-item">
            {' '}
            <span className="wallet-dropdown-text">
              {cryptoAccounts[0].walletAddress.slice(0, 4)}.....
              {cryptoAccounts[0].walletAddress.slice(-10)}
            </span>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
      <Elements stripe={stripePromise}>
        {linkToken && (
          <StripeModal
            clientSecret={linkToken}
            attachBank={attachBankToAccount}
            reloadAccount={loadBankInfo}
          />
        )}
      </Elements>
      {showModal && (
        <div className="cart-modal">
          <CartModal showModal={showModal} setShowModal={setShowModal} />
        </div>
      )}
    </>
  );
}
