import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import * as sessionAPI from '../api/session';
import * as candidateAPI from '../api/bountyCandidates';

const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [company, setCompany] = useState();
  const [auth, setAuth] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loginBoxOpen, setLoginDropOpen] = useState(false);
  const [jobApplicantProfile, setJobApplicantProfile] = useState(null);

  const login = (token, tokenType) => {
    setLoading(true);
    sessionAPI
      .loginWithMagicToken(token, tokenType)
      .then(({ user, bountyCandidate, company }) => {
        setCompany(company);
        setAuth({ user: bountyCandidate });
        let lastUrl = document.referrer;
        if (!lastUrl) {
          navigate('/opportunities');
        } else {
          navigate(lastUrl);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const logout = () => {
    sessionAPI.logout().then((user) => {
      setAuth(undefined);
      setCompany(undefined);
    });
  };

  const getUserInfo = () => {
    sessionAPI
      .getCurrentUser()
      .then(({ user, bountyCandidate, company }) => {
        setCompany(company);
        setAuth({ user: bountyCandidate });
      })
      .catch((_error) => {})
      .finally(() => setLoadingInitial(false));
  };

  const generateLink = async (email) => {
    setLoading(true);
    const data = await sessionAPI.generateMagicLink(email);
    toast(data.message);
    setLoading(false);
    return true;
  };

  function linkAccount(token, tokenType, redirect) {
    setLoading(true);
    return sessionAPI
      .linkAccount(token, tokenType)
      .then(({ user, bountyCandidate, company }) => {
        setCompany(company);
        setAuth({ user: bountyCandidate });
        if (redirect) {
          navigate(redirect);
        }
      })
      .catch((error) => {
        toast(error.error);
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  async function updateProfile(values) {
    try {
      setLoading(true);
      setError(null);
      await candidateAPI.editProfile(values);
      getUserInfo();
      setLoading(false);
      toast('Profile updated');

      return { success: true };
    } catch (error) {
      setError(error);
      toast(error.response.data.error);
      setLoading(false);

      return { success: false, errorMessage: error.response.data.error };
    }
  }

  function generateTwoFactor(callback) {
    setLoading(true);
    candidateAPI
      .generateTwoFactor()
      .then((data) => {
        if (callback) {
          callback(data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }
  function sendTotp(callback) {
    setLoading(true);
    candidateAPI
      .sendTotp()
      .then((data) => {
        if (callback) {
          callback(data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }
  function disableTwoFactor(callback) {
    setLoading(true);
    candidateAPI
      .disableTwoFactor()
      .then((data) => {
        if (callback) {
          callback(data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }
  function enableTwoFactor(token, callback) {
    setLoading(true);
    candidateAPI
      .enableTwoFactor(token)
      .then((data) => {
        if (callback) {
          callback(data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }
  function validateTotp(token, callback) {
    setLoading(true);
    candidateAPI
      .validateTotp(token)
      .then((data) => {
        if (callback) {
          callback(data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }
  useEffect(() => {
    getUserInfo();
  }, []);

  function changeEmail(email, callback) {
    setLoading(true);
    sessionAPI
      .changeEmail(email)
      .then(() => {
        if (typeof callback !== 'undefined') {
          callback();
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  // useEffect(() => {
  //   if (auth) {
  //     bountyCandidateAPI
  //       .getProfile(auth._id)
  //       .then((data) => setUser(data))
  //       .catch((err) => {});
  //   }
  // }, [auth]);

  const memoedValue = useMemo(
    () => ({
      auth,
      loading,
      error,
      login,
      logout,
      updateProfile,
      linkAccount,
      generateTwoFactor,
      sendTotp,
      disableTwoFactor,
      enableTwoFactor,
      validateTotp,
      company,
      setCompany,
      changeEmail,
      loginBoxOpen,
      setLoginDropOpen,
      jobApplicantProfile,
      generateLink,
    }),
    [
      auth,
      loading,
      error,
      updateProfile,
      company,
      loginBoxOpen,
      jobApplicantProfile,
    ]
  );
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider, useAuth };
