import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
// import OpenSource from '../assets/openSource.png';
import '../teams/css/activeTeam.css';
import { formatCurrency } from '../shared/utils';
import { BountyImage, UserImage } from '../shared/images';
import { navigate } from '@reach/router';

const ActiveGig = ({ opportunity }) => {
  const formatSkills = (listOfSkills) => {
    let skills = listOfSkills[0];
    if (listOfSkills.length < 2) {
      return skills;
    }
    return `${skills}, +${listOfSkills.length - 1} Others`;
  };
  function capitolize(string) {
    const str = string.substring(1);
    return string[0].toUpperCase() + str;
  }
  return (
    <Container className="rounded-3 bold active_team_main_container" fluid>
      <div className="active_team_bg">
        <Row>
          <Col sm={3} md={2} lg={2} xl={2} className="px-3">
            <div>
              <Card.Img
                 src={BountyImage(opportunity.opportunityId)}
                // onError={(e) => BountyImageErrorHandler(e)}
                alt="Card image"
                style={{
                  borderRadius: '50% !important',
                }}
              />
            </div>
            {/* </Card> */}
          </Col>
          <Col>
            <Row className="">
              <Col>
                <h5 className="primary semi-bold d-inline my-2 me-2 fs-4">
                  {opportunity.opportunityId.title}
                </h5>
              </Col>
              <Col>
                <Row>
                  <span
                    className="text-black-50 fs-5"
                    style={{ display: 'block', textAlign: 'right' }}
                  >
                    Reward
                  </span>
                  <span
                    className="fs-5 brand-green"
                    style={{ display: 'block', textAlign: 'right' }}
                  >
                    {formatCurrency(opportunity.opportunityId)}
                  </span>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <Row>
                  <span className="text-black-50">Name</span>
                  <span>{opportunity.candidateId.fullName}</span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span className="text-black-50">Opportunity Category</span>
                  <span>Gig</span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span className="text-black-50">Status</span>
                  <span>{capitolize(opportunity.status)}</span>
                </Row>
              </Col>

              <Col>
                <button
                  className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                  onClick={() =>
                    navigate(`/work/${opportunity.opportunityId._id}`, {state: opportunity})
                  }
                >
                  <p
                    style={{ paddingTop: '8px' }}
                    className="active_team_button_go_to_team_page_text"
                  >
                    View
                  </p>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ActiveGig;
