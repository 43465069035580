import Etrnl from '../../assets/icon/gero.jpg';
import { Row, Col } from 'react-bootstrap';

export default function AddGeroWalletButton({ handleClick }) {
  return (
    <button onClick={handleClick} className="btn-light wallet-button">
      <Row className="align-items-center">
        <Col xs={12} md={3}>
          <img src={Etrnl} alt="etrnl-icon" className="smaller-img-on-button" />
        </Col>
        <Col xs={12} md={9}>
          <span className="fs-7 align-middle">Connect</span>
        </Col>
      </Row>
    </button>
  );
}
