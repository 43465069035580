import { useEffect, useRef, useState } from 'react';

//COMPONENTS
import { Col, Container, Row } from 'react-bootstrap';
import { navigate } from '@reach/router';

//CSS
import './css/notifications.css';
import * as NotificationAPI from '../../api/notification';
import { useAuth } from '../../context/authContext';
import { timeAgo } from '../utils';

const Notifications = ({
  showNotifications,
  setShowNotifications,
  loadNotify,
}) => {
  const { auth } = useAuth();
  const componentRef = useRef();
  const [notifications, setNotifications] = useState([]);
  const [candidate, setCandidate] = useState([]);
  const [allNoti, setAllNoti] = useState([]);

  const handleOutsideClick = (componentRef, e) => {
    if (e.target.classList.contains('nav-bar-icon')) return;
    return componentRef.current && !componentRef.current.contains(e.target)
      ? setShowNotifications(false)
      : null;
  };

  const loadNotifications = async (id) => {
    try {
      const { docs } = await NotificationAPI.getListNotifications();
      const notiNotRead = docs.filter((user) => user.isRead === false && user.typeUser !== 'b2b');
      setNotifications(notiNotRead);
      const notReadId = notiNotRead.map((noti) => noti._id);
      setCandidate(notReadId);
    } catch (err) {}
  };

  const loadOlderNotifications = async (id) => {
    try {
      const { docs } = await NotificationAPI.getOlderListNotifications();
      const notiNotRead = docs.filter((user) => user.typeUser !== 'b2b');
      setNotifications(notiNotRead);
      const notReadId = notiNotRead.map((noti) => noti._id);
      setCandidate(notReadId);
    } catch (err) {}
  };

  const readNotification = async (id) => {
    try {
      await NotificationAPI.readNotification(id);
      loadNotifications();
      loadNotify();
    } catch (err) {}
  };

  const readAllNotification = async () => {
    try {
      await NotificationAPI.readAllNotifications(auth.user._id);
      loadNotifications();
      loadNotify();
    } catch (err) {}
  };

  const getAllNotification = async () => {
    try {
      const { docs } = await NotificationAPI.getAllUserNotifications(auth.user._id);
      const notiNotRead = docs.filter((user) => user.typeUser !== 'b2b');
      setAllNoti(notiNotRead)

    } catch (err) {}
  };

  useEffect(() => {
    loadNotifications();
    getAllNotification();
    document.addEventListener('mousedown', (e) =>
      handleOutsideClick(componentRef, e)
    );
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  return (
    <Container
      ref={componentRef}
      style={{ display: `${showNotifications ? 'block' : 'none'}` }}
    >
      <Row
        className="notifications-modal primarybg"
        style={{
          height:
            notifications.length > 2
              ? 500
              : notifications.length <= 0
              ? 200
              : 300,
        }}
      >
        <Row>
          <Col>
            <h5 className="text-start primary mb-3 bold">Notifications</h5>
          </Col>
        </Row>
        {notifications.length <= 0 && (
          <Row>
            <Col className="p-2 mb-4 text-center">
              <span className="d-block h4 bold">
                You have no new notifications.
              </span>
              <span className="grey-title">Check in later!</span>
            </Col>
          </Row>
        )}
   
         {allNoti.length !== 0 && (
            <Row>
              <Col
                className="text-center"
                onClick={() => {
                  loadOlderNotifications();
                }}
              >
                <span className="pointer link">Show Older</span>
              </Col>
              <Col
                className="text-center"
                onClick={() => {
                  readAllNotification();
                  loadNotifications();
                }}
              >
                <span className="pointer link">Mark All As Read</span>
              </Col>
            </Row>
         )}
        
        {notifications.length > 0 &&
          notifications.map((noti, idx) => {
            if (noti.isRead === true) {
              return (
                <Row key={idx}>
                  <Col
                    className="inputbg notification rounded-3 text-start p-2 mb-4 pointer"
                    onClick={() => {
                      readNotification(noti._id);
                      navigate(noti.url);
                    }}
                  >
                    <span className="d-block text-start primary medium mb-2">
                      {noti.title}
                    </span>
                    <span>{noti.content}</span>
                    <span className="d-block text-end mt-2">
                      {timeAgo(noti.createdAt)}
                    </span>
                  </Col>
                </Row>
              );
            } else {
              return (
                <Row key={idx}>
                  <Col
                    className="inputbg notification rounded-3 text-start p-2 mb-4 pointer"
                    onClick={() => {
                      readNotification(noti._id);
                      navigate(noti.url);
                    }}
                  >
                    <span className="new-notify" />
                    <span className="d-block text-start primary medium mb-2">
                      {noti.title}
                    </span>
                    <span>{noti.content}</span>
                    <span className="d-block text-end mt-2">
                      {timeAgo(noti.createdAt)}
                    </span>
                  </Col>
                </Row>
              );
            }
          })}
      </Row>
    </Container>
  );
};

export default Notifications;
