import React, { useCallback, useEffect, useState } from 'react';
import GrantDefaultLogo from '../../assets/snapbrillia_no_company_logo.png';
import Swal from 'sweetalert2';
import '../../shared/css/sharedStyles.css';
import ProfileImageModal from '../../shared/modals/profileImageModal';

const GrantLogo = ({
  handleSave,
  grantLogo,
  setGrantLogo,
  handleRemoveLogo,
}) => {
  const [source, setSource] = useState(grantLogo);
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleUpdateGrant = async (data) => {
    handleSave(data.url);
  };

  const handleRemove = useCallback(() => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
    }).then((result) => {
      if (result.isConfirmed) {
        setSource(GrantDefaultLogo);
        handleRemoveLogo();
        Swal.fire('Removed!', 'Your image has been removed.', 'success');
      }
    });
  }, []);

  useEffect(() => {
    setSource(grantLogo);
  }, [grantLogo]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="shadow p-0 mb-3 bg-white rounded-circle profile">
        <img
          src={grantLogo}
          alt="profile"
          height={200}
          width={200}
          style={{ borderRadius: '50%' }}
        />
        <div className="flex-column justify-content-center align-items-center edit-profile">
          <span className="flex" onClick={() => handleRemove()}>
            Remove
          </span>
          <span className="flex" onClick={() => setOpenEditModal(true)}>
            Replace
          </span>
        </div>
      </div>
      <ProfileImageModal
        show={openEditModal}
        closeModal={() => setOpenEditModal(false)}
        handleRemove={handleRemove}
        source={grantLogo}
        handleSave={handleSave}
        setSource={setGrantLogo}
        //isCompany={true}
        handleUpdateGrant={handleUpdateGrant}
      />
    </div>
  );
};
export default React.memo(GrantLogo);
