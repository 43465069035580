import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';

function CustomListGroup({ groupKey, items, numbered }) {
    return (
        <ListGroup key={groupKey} as="ol" {...{numbered}}>
            {
                items.map(item => (
                    <ListGroup.Item
                        as="li"
                        key={item.key}
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">{item.text}</div>
                            {item.subText}
                        </div>
                        {item.badge &&
                            <Badge bg="primary" pill>
                                item.badgeText
                            </Badge>
                        }
                    </ListGroup.Item>
                ))
            }
        </ListGroup>
    );
}

export default CustomListGroup;