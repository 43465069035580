import { useState, useEffect } from 'react';
import {Form} from 'react-bootstrap';

const PhoneNumberField = ({schema, setPhoneNumber}) => {
    const [caretPosition, setCaretPosition] = useState(0);
    const [currPhoneValue, setCurrPhoneValue] = useState('');

    useEffect(() => {
        setCurrPhoneValue('');
        setPhoneNumber('');
    }, [schema]);

    const handleInput = e => {
        if (schema) {
            const [mask, cursorPosition] = maskPhoneNumber(e.target.value);

            setCurrPhoneValue(mask);

            if (validPhone(mask)) {
                setPhoneNumber(mask);
            } else {
                setPhoneNumber('');
            }

            e.target.selectionStart = cursorPosition;
            e.target.selectionEnd = cursorPosition;

            setCaretPosition(cursorPosition);
        }
    }

    const maskPhoneNumber = currentValue => {
        const rawMask = schema.split('');

        const phoneNumber = currentValue?.replace(/[^\d]/g, '');

        if (!phoneNumber) return ['', 0];

        let lastDigitIndex = 0;

        phoneNumber.split('').forEach(digit => {
            const firstEmptyPlaceholderIndex = rawMask.indexOf('#');

            if (firstEmptyPlaceholderIndex >= 0) {
                rawMask[firstEmptyPlaceholderIndex] = digit;
                lastDigitIndex = firstEmptyPlaceholderIndex;
            }
        });

        const mask = rawMask.join('').slice(0, lastDigitIndex + 1);

        return [mask, lastDigitIndex + 1];
    }

    const validPhone = currPhoneValue => {
        const phoneNumber = currPhoneValue?.replace(/[^\d]/g, '');
        const pureSchema = schema.replace(/[^#]/g, '');

        if (phoneNumber.length === pureSchema.length) {
            return true;
        }
        return false;
    }

    const handleClick = e => {
        e.target.selectionStart = caretPosition;
        e.target.selectionEnd = caretPosition;
    }

    return (
        <Form.Control 
            type='text'
            placeholder={schema.replace(/#/g, 5)}
            value={currPhoneValue}
            onChange={handleInput}
            onClick={handleClick}
            required
        />
    );
}

export default PhoneNumberField;