import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import '../../bounties/css/tooltip.css';
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { BountyImage, getImageByUrl } from '../../shared/images';
import {
  formatCurrency,
  formatCurrencyRange,
  formatGigOrJobSkills,
  formatSkills,
  compensation,
} from '../../shared/utils';
import { useAuth } from '../../context/authContext';
import { navigate } from '@reach/router';
import ApplyJobModal from '../../shared/modals/applyJobModal';
import JobReviewModal from '../components/jobReview';
import BountyLogo from '../../assets/snapbrillia_no_company_logo.png';
import { toast } from 'react-toastify';

export default function Tooltip_clickable({
  job,
  loading,
  assessment,
  setShowReview,
  showReview,
}) {
  const [logo, setLogo] = useState('');
  const { auth, company, setLoginDropOpen, loginBoxOpen } = useAuth();
  const [canApply, setCanApply] = useState(false);
  const [canReview, setCanReview] = useState(false);
  const [applied, setApplied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const { companyId } = job;

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleApplyJob = () => {
    navigate(`/opportunity/${job._id}/individual`);
  };

  useEffect(() => {
    const _isFromSameCompany = (jobData) => {
      let result = false;
      if (!_.isNil(company)) {
        result = jobData.companyId._id === company._id;
      }
      return result;
    };
    const _hasNotAppliedOrIsInvited = (application) => {
      return _.isNil(application) || application.status === 'Invited';
    };
    const _jobIsOpen = (jobData) => {
      return !_.isNil(jobData) && jobData.status === 'Open';
    };
    const _jobExists = (jobData) => {
      return !_.isNil(jobData) && !_.isEmpty(jobData);
    };
    if (_jobIsOpen(job) && !_isFromSameCompany(job)) {
      if (!_.isNil(auth) && !_.isNil(auth.user)) {
        const application = job?.assessmentIds?.filter(
          (x) => x === auth.user._id
        );
        if (job?.applicationIds?.length > 0) {
          if (_hasNotAppliedOrIsInvited(application[0])) {
            setCanApply(true);
          }
        } else {
          setCanApply(true);
        }
        if (job?.isAcceptapplicant === false) {
          setCanApply(false);
        }
      }
    }

    if (_jobIsOpen(job) && _isFromSameCompany(job)) {
      setCanReview(true);
    }

    if (_jobExists(job) && !_isFromSameCompany(job)) {
      if (!_.isNil(auth) && !_.isNil(auth.user)) {
        const application = job?.assessmentIds?.filter(
          (x) => x === auth.user._id
        );
        if (job?.applicationIds?.length > 0) {
          if (!_hasNotAppliedOrIsInvited(application[0])) {
            setApplied(true);
          }
        } else {
          setApplied(false);
        }
        if (job?.isAcceptapplicant === false) {
          setCanApply(false);
        }
      }
    }
  }, [assessment, auth, company, job]);

  useEffect(() => {
    if (companyId) {
      const { companyLogo } = companyId;
      setLogo(getImageByUrl(companyLogo, 'bounty'));
    }
  }, [companyId]);

  function _getApplicantsNumber(data) {
    let result = 0;
    if (
      !_.isNil(data.applicantsIds) &&
      _.isArray(data.applicantsIds) &&
      !_.isEmpty(data.applicantsIds)
    ) {
      result = data.applicantsIds.length;
    }
    return result;
  }
  const tooltip = (item) => {
    return (
      <Tooltip id="tooltip">
        <ul>
          {item?.skill?.map((skill) => (
            <li key={skill}>
              <strong>{skill?.name || skill}</strong>
            </li>
          ))}
        </ul>
      </Tooltip>
    );
  };
  return (
    <div className="tooltip-box px-2">
      <Col sm={12} xs={12} md={12} lg={12} className="tooltip-content">
        <Row>
          <Col xs={6} md={6} lg={2}>
            <div className="company-name px-4 mt-4 mt-sm-0">
              <img src={logo} className="company-logo" alt="..." />

              {job.companyId?.companyName}
            </div>
          </Col>
          <Col xs={6} md={6} lg={4} className="tooltip-box-title-section">
            <h3 className="tooltip-box-title">{job.title}</h3>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <div className="tooltip-box-title text-end">
              <h4>
                Compensation:{' '}
                {!job.maxCompensation || job.maxCompensation < 1
                  ? formatCurrency(job)
                  : formatCurrencyRange(job)}
                /{compensation(job)}
              </h4>
            </div>
          </Col>
        </Row>
      </Col>

      <Col sm={12} xs={12} md={12} lg={12} className="tooltip-content">
        <Row>
          <Col xs={6} md={6} lg={3} className="d-sm-block">
            <span className="text-black-50 position-relative">
              Status
              <span className={`status-tag status-${job.status}`}>
                {job.status}
              </span>
            </span>
            <p className="status-text bounty-status mb-0">
              {(() => {
                if (job.status === 'Open' && job.isAcceptapplicant)
                  return 'Hiring';
                if (job.status === 'Done') return 'Complete/Close';
                if (job.status === 'NoApplications' || !job.isAcceptapplicant)
                  return 'Not Actively Reviewing Applicants';
              })()}
            </p>
          </Col>
          <Col xs={6} md={6} lg={3} className="d-sm-block">
            <span className="text-black-50">Job Type</span>
            <Row>
              <Col xs={1} md={1} lg={1}>
                <i class="fa-solid fa-briefcase"></i>
              </Col>
              <Col xs={11} md={11} lg={11}>
                <span>{job.jobType}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} lg={3} className="d-sm-block">
            <OverlayTrigger
              placement="top"
              overlay={tooltip({ skill: job.skillIds })}
            >
              <Row className="w-100 mt-0">
                <Row>
                  <Col xs={1} md={1} lg={1}>
                    <i class="fa-solid fa-circle-info"></i>
                  </Col>
                  <Col>
                    <span className="text-black-50">Skills</span>
                  </Col>
                </Row>
                <span className="main-language">
                  {formatGigOrJobSkills({ skillIds: job.skillIds })}
                </span>
              </Row>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          {/* <Col xs={12} md={12} lg={3} className="d-sm-block">
            <span className="text-black-50">Application</span>
            <Row>
              <Col xs={1} md={1} lg={1}>
                <i class="fa-solid fa-user"></i>
              </Col>
              <Col xs={1} md={1} lg={1}>
                <span>{_getApplicantsNumber(job)}</span>
              </Col>
            </Row>
          </Col> */}
          <Col xs={6} md={6} lg={3} className="d-sm-block">
            <span className="text-black-50">Workplace Type</span>

            <Row>
              <Col xs={1} md={1} lg={1}>
                <i class="fa-solid fa-building-circle-check"></i>
              </Col>
              <Col>
                <span className="text-black-50">{job.workplaceType}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={6} md={6} lg={3} className="d-sm-block">
            <span className="text-black-50">Location</span>
            <Row>
              <Col xs={1} md={1} lg={1}>
                <i class="fa-solid fa-location-dot"></i>
              </Col>
              <Col xs={10} md={10} lg={10}>
                <span>{job?.location || 'Globe'}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} lg={6} className="express-box">
            <div className="text-end me-2">
              {canApply && (
                <button
                  className={'express-interest-button'}
                  onClick={() => setShowModal(true)}
                >
                  {'Apply'}
                </button>
              )}
              {canReview && (
                <button
                  className={'express-interest-button'}
                  onClick={() => setShowReviewModal(true)}
                >
                  {'Review'}
                </button>
              )}
              {applied && (
                <button
                  className={'express-interest-button'}
                  onClick={() => navigate(`/teams`)}
                >
                  {'Go to work page'}
                </button>
              )}
              {!auth?.user?.email && (
                <button
                  onClick={() => {
                    toast(
                      `Please login to continue applying for the '${job.title}' job.`
                    );
                    setLoginDropOpen(!loginBoxOpen);
                  }}
                  className="express-interest-button"
                >
                  {'Apply'}
                </button>
              )}
            </div>
          </Col>
        </Row>
      </Col>
      {showModal && (
        <ApplyJobModal
          show={showModal}
          handleApply={handleApplyJob}
          closeModal={handleCloseModal}
        />
      )}
      {showReviewModal && (
        <JobReviewModal
          job={job}
          showModal={showReviewModal}
          handleClose={() => setShowReviewModal(false)}
        ></JobReviewModal>
      )}
    </div>
  );
}
