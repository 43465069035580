import { useEffect, useState } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { Link } from '@reach/router';
import {
  formatCurrency,
  formatCurrencyRange,
  formatSkills,
  compensation,
} from '../../shared/utils';
import { getImageByUrl } from '../../shared/images';
import BountyLogo from '../../assets/snapbrillia_no_company_logo.png';

// Things are hard coded, replace hard coded value with job. And delete
// the OpenSource image later when you put in the real data.
const JobPosting = ({ application }) => {
  const [logo, setLogo] = useState('');
  const { companyId } = application.opportunityId;

  useEffect(() => {
    if (companyId) {
      const { companyLogo } = companyId;
      setLogo(getImageByUrl(companyLogo, 'bounty'));
    }
  }, [companyId]);

  return (
    <Container className="bounties-bg-color rounded-3 p-3 mt-2 mb-4 bold">
      <Row>
        <Col sm={3} md={2} lg={2} xl={2} className="px-3">
          <Card className="rounded-3 text-white">
            {logo ? (
              <Card.Img
                src={logo}
                // onError={(e) => BountyImageErrorHandler(e)}
                alt="Card image"
                style={{
                  borderRadius: '50% !important',
                }}
              />
            ) : (
              <Card.Img
                src={BountyLogo}
                // onError={(e) => BountyImageErrorHandler(e)}
                alt="Card image"
                style={{
                  borderRadius: '50% !important',
                }}
              />
            )}
          </Card>
        </Col>
        <Col>
          <Row className="">
            {/* <h5 className="primary semi-bold d-inline my-2 me-2">{item.name}</h5> */}
            <Col>
              <h5 className="primary semi-bold d-inline my-2 me-2 fs-4">
                {application.opportunityId.title}
              </h5>
            </Col>
            <Col>
              <Row>
                <span
                  className="text-black-50 fs-5"
                  style={{ display: 'block', textAlign: 'right' }}
                >
                  Compensation
                </span>
                <span
                  className="fs-5 brand-green"
                  style={{ display: 'block', textAlign: 'right' }}
                >
                  {`${
                    !application.opportunityId.maxCompensation ||
                    application.opportunityId.maxCompensation < 1
                      ? formatCurrency(application.opportunityId)
                      : formatCurrencyRange(application.opportunityId)
                  }/${compensation(application.opportunityId)}`}
                </span>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Row>
                <span className="text-black-50">Name</span>
                <span>{application.candidateId.fullName}</span>
              </Row>
            </Col>
            <Col>
              <Row>
                <span className="text-black-50">Skills</span>
                <span>{formatSkills(application.opportunityId.skillIds)}</span>
              </Row>
            </Col>
            <Col>
              <Row>
                <span className="text-black-50">Status</span>
                <span>{application.status}</span>
              </Row>
            </Col>
            <Col>
              {application.status !== 'Submitted' &&
              application.status !== 'Completed' ? (
                <Link
                  style={{ textDecoration: 'none' }}
                  className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                  as={Link}
                  to={`/opportunity/${application.opportunityId._id}/eeo-form`}
                >
                  <p
                    style={{ paddingTop: '8px' }}
                    className="active_team_button_go_to_team_page_text"
                  >
                    Submit Work
                  </p>
                </Link>
              ) : (
                <Link
                  style={{ textDecoration: 'none' }}
                  className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                  as={Link}
                  to={`/teams`}
                >
                  <p
                    style={{ paddingTop: '8px' }}
                    className="active_team_button_go_to_team_page_text"
                  >
                    Go to work page
                  </p>
                </Link>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default JobPosting;
