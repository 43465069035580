import React, { useState } from 'react';
import PopoutSelect, { ChevronDown } from '../../../shared/select/popoutSelect';
import Button from '@atlaskit/button';

import {phoneMasks} from '../../../utils/phoneMasks';

const SelectCodeArea = ({ setCallingCode, defaultValue, setFlagCode }) => {
    const [isSelectPopoutOpen, setIsSelectPopoutOpen] = useState(false);

    const sortedAreaCodesByCountryName = Object.keys(phoneMasks).sort((a, b) => {
        const maskA = phoneMasks[a].name;
        const maskB = phoneMasks[b].name;
        return `${maskA}`.localeCompare(`${maskB}`);
    });

    const codeAreaOptions = sortedAreaCodesByCountryName.map(code => {
        return {
            label: `${code} ${phoneMasks[code].name}`,
            value: [code, phoneMasks[code].countryCode]
        }
    });

    return (
        <>
            <PopoutSelect
                stateOptions={codeAreaOptions}
                isOpen={isSelectPopoutOpen}
                setIsOpen={setIsSelectPopoutOpen}
                setValue={(option) => {
                    setCallingCode(option.value[0]);
                    setFlagCode(option.value[1]);
                }}
                buttonComponent={
                    <Button
                        style={{height: 39, paddingTop: 3}}
                        iconAfter={<ChevronDown />}
                        onClick={() => setIsSelectPopoutOpen((prev) => !prev)}
                        isSelected={isSelectPopoutOpen}
                    >
                        {defaultValue}
                    </Button>
                } 
            />
        </>
    );
}

export default React.memo(SelectCodeArea)