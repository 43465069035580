import '../css/buttonStyles.css';
// TODO: Remove this comment
export default function saveAndContinue(props) {
  const { className, ...others } = props;
  return (
    <div>
      <button {...others} className={`${className} btn-primary`}>
        {props?.title || "Save And Continue"}
      </button>
    </div>
  );
}
