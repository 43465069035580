import _ from 'lodash';
import { useState } from 'react';
import {
  Modal,
  Col,
  Container,
  Row,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'react-bootstrap';

const JobReviewModal = ({ job, showModal, handleClose }) => {
  const [totalApplications] = useState(() => {
    let result = 0;
    if (!_.isNil(job)) {
      result = job?.assessmentIds?.length;
    }
    return result;
  });
  const [totalApplied] = useState(() => {
    let result = 0;
    if (!_.isNil(job)) {
      const applied = job?.assessmentIds?.filter((x) => x.status === 'Applied');
      if (_.isArray(applied) && !_.isEmpty(applied)) {
        result = applied.length;
      }
    }
    return result;
  });
  const [totalAccepted] = useState(() => {
    let result = 0;
    if (!_.isNil(job)) {
      const applied = job?.assessmentIds?.filter(
        (x) => x.status === 'Accepted'
      );
      if (_.isArray(applied) && !_.isEmpty(applied)) {
        result = applied.length;
      }
    }
    return result;
  });
  const [totalRejected] = useState(() => {
    let result = 0;
    if (!_.isNil(job)) {
      const applied = job?.assessmentIds?.filter(
        (x) => x.status === 'Rejected'
      );
      if (_.isArray(applied) && !_.isEmpty(applied)) {
        result = applied.length;
      }
    }
    return result;
  });
  const [totalSubmitted] = useState(() => {
    let result = 0;
    if (!_.isNil(job)) {
      const applied = job?.assessmentIds?.filter(
        (x) => x.status === 'Submitted'
      );
      if (_.isArray(applied) && !_.isEmpty(applied)) {
        result = applied.length;
      }
    }
    return result;
  });
  const [totalInvited] = useState(() => {
    let result = 0;
    if (!_.isNil(job)) {
      const applied = job?.assessmentIds?.filter((x) => x.status === 'Invited');
      if (_.isArray(applied) && !_.isEmpty(applied)) {
        result = applied.length;
      }
    }
    return result;
  });
  function _getSkills(data) {
    let result = '';
    if (
      !_.isNil(data?.skillIds) &&
      _.isArray(data?.skillIds) &&
      !_.isEmpty(data?.skillIds)
    ) {
      result = data?.skillIds.map((x) => x.name).join(', ');
    }
    return result;
  }
  return (
    <Modal
      contentClassName="modal-main-container"
      onHide={handleClose}
      show={showModal}
      centered
    >
      <ModalHeader closeButton>
        <Modal.Title>
          <span className="h2">{`${job.title} Review`}</span>
        </Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row className="p-3">
            <Container>
              <Row>
                <span className="bounty-title font-weight-bold">
                  {`Overview`}
                </span>
              </Row>
              <Row>
                <Col>
                  <label className="bold">Type</label>: {job.jobType}
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="bold">Skills</label>: {_getSkills(job)}
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="bold">Location</label>: {job.location}
                </Col>
              </Row>
            </Container>
          </Row>
          <Row className="p-3">
            <Container>
              <Row>
                <span className="bounty-title font-weight-bold">
                  {`Applicants Overview`}
                </span>
              </Row>
              <Row>
                <Col>
                  <label className="bold">Total Applications</label>:{' '}
                  {totalApplications}
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="bold">Applied</label>: {totalApplied}
                </Col>
                <Col>
                  <label className="bold">Invited</label>: {totalInvited}
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="bold">Accepted</label>: {totalAccepted}
                </Col>
                <Col>
                  <label className="bold">Rejected</label>: {totalRejected}
                </Col>
                <Col>
                  <label className="bold">Submitted</label>: {totalSubmitted}
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <button
          className={'express-interest-button'}
          onClick={() => {
            const url = `${process.env.REACT_APP_B2B_URL}/opportunities/${job._id}`;
            window.open(url, '_blank');
          }}
        >
          Edit this job
        </button>
      </ModalFooter>
    </Modal>
  );
};
export default JobReviewModal;
