/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import '../css/chatModal.css';
import BountyLogo from '../../assets/snapbrillia_no_company_logo.png';
import { Accordion } from 'react-bootstrap';
import { useAuth } from '../../context/authContext';
import _ from 'lodash';
import Moment from 'react-moment';
import { getChats, sendChat, getOwners } from '../../api/chat';
// import OpenSource from '../../assets/openSource.png';
import noMsgIcon from '../../assets/group.png';
import { UserImage } from '../../shared/images';

const ChatModal = ({ setClose }) => {
  const [message, setMsg] = useState('');
  const bottomRef = useRef(null);
  const [ownerId, setCurrent] = useState(1);
  const [bountyName, setBountyName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const { auth } = useAuth();
  const [bountyOwners, setBountyOwners] = useState([]);
  const [bountyId, setBountyId] = useState('');
  const [curentMessages, setCurrentMessages] = useState([]);
  const [files, setFiles] = useState([]);
  const [ownersLoaded, setOwnersLoaded] = useState(false);
  const [interval, setTimeInterval] = useState(null);
  const [reply, setReply] = useState(false);
  const [replyId, setReplyId] = useState('');
  const [replyTo, setReplyTo] = useState([]);
  const [clicked, setClicked] = useState(false);

  var nowDate = new Date();
  var date =
    nowDate.getFullYear() +
    '/' +
    (nowDate.getMonth() + 1) +
    '/' +
    nowDate.getDate();

  const getLastRead = () => {
    let result = new Date(Date.now()).getTime();
    if (curentMessages.length > 0) {
      const last = curentMessages[curentMessages.length - 1];
      if (!_.isNil(last)) {
        result = new Date(last.createdAt).getTime();
      }
    }
    return result;
  };
  const fetchNewChats = (bounty, participant) => {
    const lastRead = getLastRead();
    getChats(bounty, participant).then((chats) => {
      if (_.isArray(chats) && !_.isEmpty(chats)) {
        setCurrentMessages(chats);
      }
    });
  };

  const destroyTimer = () => {
    if (!_.isNil(interval)) {
      clearInterval(interval);
      setTimeInterval(null);
    }
  };
  const setUpTimeInterval = (bounty, participant) => {
    setTimeInterval(
      setInterval(() => {
        fetchNewChats(bounty, participant);
      }, 10000)
    );
  };
  const getSelectedBountyChats = (bounty, participant) => {
    destroyTimer();
    getChats(bounty, participant).then((chats) => {
      if (_.isArray(chats) && !_.isEmpty(chats)) {
        setCurrentMessages(chats);
        if (_.isNil(interval)) {
          setUpTimeInterval(bounty, participant);
        }
      }
    });
  };
  const setFields = (dataset) => {
    setCurrent(dataset.ownerId);
    setBountyName(dataset.bountyName);
    setOwnerName(dataset.ownerName);
    setBountyId(dataset.bountyId);
  };
  const inflateChat = () => {
    return {
      senderId: auth.user._id,
      sender: auth.user.fullName,
      message: message,
      recepientId: ownerId,
      recepient: ownerName,
      isReply: true,
      opportunityId: bountyId,
      bountyName: bountyName,
      attachments: files,
      group_date: date,
      chatReply: reply,
      chatReplyMessage: replyTo[0]?.message || '',
      chatReplyRecepient: 'You' || '',
    };
  };
  //UI event handlers
  const sendMessage = (e) => {
    e.preventDefault();
    sendChat(inflateChat()).then((chat) => {
      setCurrentMessages(() => [...curentMessages, chat]);
      setMsg('');
    });
    e.stopPropagation();
  };
  const onOwnerClicked = (e) => {
    e.preventDefault();
    setCurrentMessages([]);
    setFields(e.currentTarget.dataset);
    getSelectedBountyChats(e.currentTarget.dataset.bountyId, auth.user._id);
    e.stopPropagation();
    setClicked(true);
  };
  /*
  useEffect(() => {
    getSelectedBountyChats(bountyId, auth.user._id);
  }, [auth.user._id, bountyId, clicked, curentMessages]);
*/
  const onUploadClicked = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const lastDot = file.name.lastIndexOf('.');
        //TODO:Upload file
      };
    }
  };
  const onCloseClicked = (e) => {
    destroyTimer();
    setClose(true);
  };
  useEffect(() => {
    if (!ownersLoaded) {
      getOwners().then((owners) => {
        if (_.isArray(owners) && !_.isEmpty(owners)) {
          setBountyOwners(owners);
          setOwnersLoaded(true);
        }
      });
    }
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [ownersLoaded]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [curentMessages]);

  return (
    <div className="modal-chat out">
      <div className="modal-chat__dialog">
        <div className="modal-chat__close">
          <a
            href="#"
            className="modal-chat__icon"
            onClick={(e) => {
              onCloseClicked(e);
            }}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
          <span className="modal-chat__note"></span>
        </div>
        <div className="modal-chat__content chat">
          <div className="modal-chat__sidebar">
            <div className="chat__search search">
              <div className="search">
                <div className="search__icon">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </div>
                <input
                  type="search"
                  className="search__input"
                  placeholder="Search..."
                />
                <div className="search__icon search__icon_right">
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            {/* chat sidebar where gig name and user that applied to the gig is shown */}
            <div className="chat__users">
              {bountyOwners && (
                <Accordion defaultActiveKey="0" className="side-user">
                  {bountyOwners.map((value) => (
                    <Accordion.Item key={value.id} eventKey={value.id}>
                      <Accordion.Header>
                        <ul className="users">
                          <li className="users__item users__item_group">
                            <div className="users__avatar avatar">
                              <a href="#" className="avatar__wrap">
                                <img
                                  className="avatar__img"
                                  src={BountyLogo}
                                  width="35"
                                  height="35"
                                  alt="avatar"
                                />
                              </a>
                            </div>
                            <span className="users__note">
                              <p>{value.name}</p>
                            </span>
                          </li>
                        </ul>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="users">
                          {value?.owners?.map((user) => (
                            <li
                              key={user._id}
                              data-owner-id={user.id}
                              data-bounty-id={value.id}
                              data-owner-name={user.fullName}
                              data-bounty-name={value.name}
                              className={
                                ownerId === user.id
                                  ? 'users__item selected users__item_group'
                                  : 'users__item users__item_group'
                              }
                              onClick={(e) => onOwnerClicked(e)}
                            >
                              <div className="users__avatar avatar">
                                <a href="#" className="avatar__wrap">
                                  <img
                                    className="avatar__img"
                                    src={UserImage(user)}
                                    width="35"
                                    height="35"
                                    alt="avatar"
                                  />
                                </a>
                              </div>
                              <span className="users__note">
                                <p>{user.fullName}</p>
                              </span>
                              {value.counter > 0 && (
                                <div className="users__counter">
                                  <span className="counter">
                                    <p>+{value.counter}</p>
                                  </span>
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              )}
            </div>
          </div>
          {/* chat main body */}
          <div className="modal-chat__main">
            <div className="chatbox">
              <div className="chatbox__row">
                {(curentMessages && curentMessages.length > 0) || clicked ? (
                  <div className="head">
                    <div className="head__avatar avatar avatar_larger">
                      <a href="#" className="avatar__wrap">
                        <img
                          className="avatar__img"
                          src={BountyLogo}
                          width="50"
                          height="50"
                          alt="avatar"
                        />
                      </a>
                    </div>
                    <div className="head__title">
                      {bountyName} - {ownerName}
                    </div>
                    {/* <div className="head__note">
                <span>chat Modal-chat</span>
                <span className="head__icon"></span>
              </div> */}
                  </div>
                ) : (
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <img
                      src={noMsgIcon}
                      alt="no chat"
                      style={{
                        backgroundSize: 'cover',
                        width: 100,
                        height: 100,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                    <div
                      style={{
                        width: '100%',
                        position: 'absolute',
                        top: '70%',
                        left: '50%',
                        transform: 'translate(-50%, -70%)',
                      }}
                    >
                      <h5
                        style={{
                          marginLeft: '37%',
                        }}
                      >
                        No Chat User Selected yet
                      </h5>
                      <p
                        style={{
                          padding: 0,
                          marginTop: -10,
                          marginLeft: '37%',
                        }}
                      >
                        {' '}
                        select a user to begin chatting
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {(curentMessages && curentMessages.length > 0) || clicked ? (
                <>
                  <div className="chatbox__row chatbox__row_fullheight">
                    <div className="chatbox__content">
                      {curentMessages.map((value, index) =>
                        value.isReply === false ? (
                          <div key={value._id + index}>
                            <div className="message client">
                              <div className="message__head">
                                <span>{value.sender}</span>
                              </div>
                              <div className="message__base-right">
                                <div className="message__avatar avatar">
                                  <a href="#" className="avatar__wrap">
                                    <img
                                      className="avatar__img-right"
                                      src={BountyLogo}
                                      width="35"
                                      height="35"
                                      alt="avatar"
                                    />
                                  </a>
                                </div>
                                <div className="message__textbox-right">
                                  <span className="message__text">
                                    {value.message}
                                  </span>
                                  <p className="date">
                                    <Moment fromNow>{value.createdAt}</Moment>
                                  </p>
                                </div>
                              </div>
                              <div>
                                {value?.attachments?.map((file, index) => (
                                  <a
                                    href="#"
                                    key={index - file}
                                    data-id={value._id}
                                  >
                                    {file}
                                  </a>
                                ))}
                              </div>
                            </div>
                            <div ref={bottomRef} />
                          </div>
                        ) : (
                          <div key={value._id + index}>
                            <div className="message client">
                              <div className="message__head-right">
                                <span>{value.sender}</span>
                              </div>
                              <div className="message__base">
                                <div className="message__textbox">
                                  <span className="message__text">
                                    {value.message}
                                  </span>
                                  <p className="date">
                                    <Moment fromNow>{value.createdAt}</Moment>
                                  </p>
                                </div>
                                <div className="message__avatar-right avatar">
                                  <a href="#" className="avatar__wrap">
                                    <img
                                      className="avatar__img"
                                      src={BountyLogo}
                                      width="35"
                                      height="35"
                                      alt="avatar"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div>
                                {value?.attachments?.map((file, index) => (
                                  <a
                                    href="#"
                                    key={index - file}
                                    data-id={value._id}
                                  >
                                    {file}
                                  </a>
                                ))}
                              </div>
                            </div>
                            <div ref={bottomRef} />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="chatbox__row">
                    <div className="enter">
                      <div className="enter__submit">
                        <button
                          id="btnSubmitMessage"
                          onClick={sendMessage}
                          className="button button_id_submit"
                          type="submit"
                        >
                          <i
                            className="fa fa-paper-plane"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                      <div className="enter__textarea">
                        <input
                          type="text"
                          name="enterMessage"
                          id="chatTxtMessage"
                          cols="30"
                          rows="2"
                          value={message}
                          placeholder="Say something..."
                          onChange={(e) => {
                            setMsg(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="enter__icons">
                    <a href="#" className="enter__icon">
                      <i className="fa fa-paperclip" aria-hidden="true"></i>
                      <input type="file" onChange={(e) => onUploadClicked(e)} />
                    </a>
                    <a href="#" className="enter__icon">
                      <i className="fa fa-smile-o" aria-hidden="true"></i>
                    </a>
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatModal;
