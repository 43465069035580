import { useState } from 'react';
//COMPONENTS
import { Container, Row } from 'react-bootstrap';

//CSS
import '../../shared/css/tabs.css';
import JobDescription from './jobDescription';
import JobReview from './jobReview';
import JobsInProgress from './JobsInProgress';
import _ from 'lodash';

const JobInfoTab = ({ application }) => {
  const [component, setComponent] = useState(0);

  const changeComponent = (index) => {
    setComponent(index);
  };

  const tabs = [
    {
      title: 'Review',
      disabled: _.isNil(application),
      component: (
        <JobReview
          company={application.opportunityId.companyId}
          applicant={application.candidateId}
          status={application.status}
          job={application.opportunityId}
        />
      ),
      className: 'tabs-one',
    },
    {
      title: 'Description',
      disabled: false,
      component: <JobDescription job={application.opportunityId} />,
      className: 'tabs-two',
    },
    // {
    //   title: 'Jobs In progress',
    //   disabled: false,
    //   component: <JobsInProgress jobsInProgress={jobsInProgress} />,
    //   className: 'tabs-three',
    // },
  ];

  return (
    <Container className="pt-5">
      <Row>
        <ul className="tabs-general">
          {tabs.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                return tabs[index].disabled ? null : changeComponent(index);
              }}
              className={
                `${
                  tabs[component].title === item.title && !item.disabled
                    ? 'active-tab-'
                    : item.disabled
                    ? 'tab-disabled'
                    : ''
                }` + item.className
              }
            >
              <a className={item.disabled ? 'disabled-cursor' : undefined}>
                {item.title}
              </a>
            </li>
          ))}
          <div className="tabs-underline"></div>
          <hr className="tabs-hr" />
        </ul>
      </Row>
      <Row>{!tabs[component].disabled && tabs[component].component}</Row>
    </Container>
  );
};

export default JobInfoTab;
