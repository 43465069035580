import _ from 'lodash';
import { useState } from 'react';
import * as jobApi from '../../api/job';
import { toast } from 'react-toastify';

const IndividualJobViewModel = () => {
  const [job, setJob] = useState({});
  const [applicant, setJobApplicant] = useState({});
  const [application, setApplication] = useState({});
  const [skills, setSkills] = useState([]);
  const [fields, setFields] = useState([]);
  const [validation, setErrors] = useState({});

  const _validateApplicant = () => {
    let errors = {};
    if (_.isNil(applicant.fullName) || _.isEmpty(applicant.fullName.trim())) {
      errors.fullName = 'Full name is a required field';
    }
    if (_.isNil(applicant.location) || _.isEmpty(applicant.location.trim())) {
      errors.location = 'Location is a required field';
    }
    if (!_.isArray(applicant.skills) || _.isEmpty(applicant.skills)) {
      errors.skills = 'Skills is a required field. Please select a skill';
    }
    if (_.isNil(applicant.resume) || _.isEmpty(applicant.resume)) {
      errors.resume = 'Resume is a required field. Please upload a resume';
    }
    return errors;
  };

  const loadJobApplicant = async (id) => {
    try {
      const response = await jobApi.getJobApplicant(id);
      if (!_.isNil(response)) {
        setJobApplicant(response);
      }
    } catch (error) {
      toast(error.message);
    }
  };
  const loadJobInfo = async (id) => {
    try {
      const job = await jobApi.getJob(id);
      if (!_.isNil(job)) {
        setJob(job);
      }
    } catch (err) {
      toast(err.message);
    }
  };
  const loadSkills = async () => {
    try {
      const skills = await jobApi.getAllSkills();
      if (_.isArray(skills) && !_.isEmpty(skills)) {
        setSkills(skills);
      }
    } catch (err) {
      toast(err.message);
    }
  };
  const updateJobApplicant = async (id) => {
    let result = false;
    try {
      const validationErrs = _validateApplicant();
      if (_.isNil(validationErrs) || _.isEmpty(validationErrs)) {
        const response = await jobApi.updateJobApplicant(id, applicant);
        if (!_.isNil(response)) {
          setJobApplicant(response);
          result = true;
        }
      } else {
        setErrors(validationErrs);
      }
    } catch (error) {
      toast(error.message);
    }
    return result;
  };
  const applyForJob = async (job) => {
    try {
      const response = await jobApi.applyForjob(job);
      if (!_.isNil(response)) {
        setApplication(response);
      }
    } catch (error) {
      toast(error.message);
    }
  };
  const loadFields = async () => {
    try {
      const fields = await jobApi.getAllFields();
      if (_.isArray(fields) && !_.isEmpty(fields)) {
        setFields(fields);
      }
    } catch (err) {
      toast(err.message);
    }
  };
  const createSkill = async (name, fieldId) => {
    let result = _.noop();
    try {
      const skill = await jobApi.createSkill(fieldId, { name: name });
      if (!_.isNil(skill)) {
        setSkills([...skills, skill]);
        result = skill;
      }
    } catch (err) {
      toast(err.message);
    }
    return result;
  };
  const createField = async (name, techRelated) => {
    let result = null;
    try {
      const field = await jobApi.createField({
        name: name,
        techField: techRelated,
      });
      if (!_.isNil(field)) {
        setFields([...fields, field]);
        result = field;
      }
    } catch (err) {
      toast(err.message);
    }
    return result;
  };
  return {
    applicant,
    job,
    skills,
    application,
    fields,
    validation,
    loadJobApplicant,
    loadJobInfo,
    loadSkills,
    setJobApplicant,
    updateJobApplicant,
    applyForJob,
    loadFields,
    createSkill,
    createField,
  };
};
export default IndividualJobViewModel;
