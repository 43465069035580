import React from "react";
import { Modal } from "react-bootstrap"

const CustomModal = ({ size, show, setShow, title, body, fullscreen }) => {
    return (<Modal
        {...{ show, size }}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
    >
        <Modal.Header closeButton>
            <Modal.Title>
                {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
    </Modal>)
}

export default React.memo(CustomModal);