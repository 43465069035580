import React, { useEffect, useState } from 'react';

//Components
import Dropdown from 'react-bootstrap/Dropdown';
import NavMenuBeforeLogin from './NavMenuBeforeLogin';
import NavMenuAfterLogin from './NavMenuAfterLogin';
import Notifications from './notifications';
import { useAuth } from '../../context/authContext';
import { Nav, Navbar, NavLink } from 'react-bootstrap';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from '@reach/router';

//Icons And Images
import SnapbrilliaLogo from '../../assets/SnapBetaLogo.png';
import Notification from '../../assets/icon/snapbrillia_notifications_icon.png';
import Wallet from '../../assets/icon/snapbrillia_wallet_icon.svg';
// import { ReactComponent as Help } from '../../assets/icon/snapbrillia_help_icon.svg';
// import { ReactComponent as Feedback } from '../../assets/icon/snapbrillia_feedback_icon.svg';
import DropDownWallet from './dropDownWallet';
import * as NotificationAPI from '../../api/notification';

//CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/navBar.css';
// import SupportModal from '../modals/SupportModal';
// import FeedbackModal from '../modals/feedbackModal';

export default function NavBar({ setGrantId }) {
  const getNavClass = ({ isCurrent }) => {
    const navClassName = 'nav-link-text';
    return isCurrent
      ? { className: `${navClassName} active` }
      : { className: `${navClassName}` };
  };
  const { auth, logout, loginBoxOpen, setLoginDropOpen } = useAuth();

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notification, setNotification] = useState([]);
  const [notificationNumber, setNotificationNumber] = useState(0);
  // const [showSupportModal, setShowSupportModal] = useState(false);
  // const [showFeedbackModal, setShowFeedbackModal] = useState(false);



  const toggleLoggedIn = () => {
    setIsLoggedIn(!isLoggedIn);
    logout();
  };

  const loadNotifications = async (id) => {
    try {
      const { docs } = await NotificationAPI.getListNotifications();
      // setTimeout(() => {
      //   const num = localStorage.getItem('notificationNumber');
      //   if(num === null){
      //     setNotificationNumber(Number(docs.length));
      //   }else{
      //     const notifyNum = Number(docs.length) - Number(num)
      //     console.log('num'+notifyNum)
      //   setNotificationNumber(notifyNum);
      //   }
      // }, 3000)
      const read = docs.filter((noti) => noti.isRead === false && noti.typeUser !== 'b2b')
      setNotification(read);
      setNotificationNumber(read.length)
    } catch (err) {}
  };

  useEffect(() => {
    if (auth?.user) {
      loadNotifications();
    }
  }, [auth]);

  // useEffect(() => {
  //   setNotificationNumber(notification.length);
  //   console.log(notificationNumber)
  // }, []);

  const handleLogoClick = () => {
    window.open('https://www.snapbrillia.com', '_self');
  };

  // setInterval(function () {
  //   const num = localStorage.getItem('notificationNumber');
    
  //   if(num === null){
  //     setNotificationNumber(notification.length);
  //   }else{
  //     const notifyNum = Number(notification.length) - Number(num)
  //   setNotificationNumber(notifyNum);
  //   }
    
  // }, 10000);
  


  const getWalletInfo = () => {
    setShowNotifications(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      className="nav-bar-box-shadow nav-bar-border-radius flex-wrap justify-content-between ps-3 pe-4 mb-4"
    >
      <Navbar.Toggle
        aria-controls="navbarScroll"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
      />
      <img
        onClick={handleLogoClick}
        src={SnapbrilliaLogo}
        alt="snapbrillia-logo"
        className="nav-logo image-fluid pointer"
      />

      <div className="nav-bar-menus d-flex">
        <Navbar.Collapse
          id="navbarScroll"
          className="nav-link-list m-0 align-items-center"
        >
          <div className="d-flex align-items-end align-items-sm-center">
            <Nav className="text-start-sm border-right-light">
              <NavLink
                style={{ marginRight: '60px' }}
                eventKey="1"
                as={Link}
                getProps={getNavClass}
                to="/opportunities"
              >
                Opportunities
              </NavLink>
              <NavLink
                style={{ marginRight: '60px' }}
                eventKey="2"
                as={Link}
                getProps={getNavClass}
                to="/grants"
              >
                Grants
              </NavLink>
              <NavLink
                style={{ marginRight: '60px' }}
                eventKey="3"
                as={Link}
                getProps={getNavClass}
                to="/teams"
              >
                Work
              </NavLink>
            </Nav>
            <div className="d-flex align-items-center nav-icon-container">
              <div className="messages-notifications">
                {notificationNumber !== 0 && (
                  <div className='notify-counter'>{notificationNumber > 9 ? '9+': notificationNumber}</div>
                )}
                <img
                  src={Notification}
                  alt="notification"
                  onClick={() => {
                    setShowNotifications(!showNotifications);
                    
                    // localStorage.setItem('notificationNumber', notification.length);
                    
                  }}
                  className="nav-bar-icon pointer"
                />
                {/* {notificationNumber > 0 && <div className="messages-count" />} */}
                
                <Notifications
                  showNotifications={showNotifications}
                  setShowNotifications={setShowNotifications}
                  loadNotify={loadNotifications}
                />
                
              </div>
       
              {/* <Button variant="outline-light" className="border-0 p-2">
                  <Help
                    className="search-bar-icon-clickable"
                    onClick={() => setShowSupportModal(!showSupportModal)}
                  />
                </Button>

                <Button variant="outline-light" className="border-0 p-2">
                  <Feedback
                    className="search-bar-icon-clickable"
                    onClick={() => setShowFeedbackModal(!showFeedbackModal)}
                  />
                </Button> */}
              <Dropdown drop="down">
                <Dropdown.Toggle
                  variant="outline-light"
                  className="nav-bar-dropdown-toggle nav-bar-btn-outline-light border-0 p-0"
                >
                  <img
                    src={Wallet}
                    alt="wallet-icon"
                    onClick={() => {
                      getWalletInfo();
                    }}
                    className="nav-bar-icon"
                  />
                </Dropdown.Toggle>
                <DropDownWallet />
              </Dropdown>
              <div data-testid="dropdown">
              {auth ? (
                <NavMenuAfterLogin toggleLoggedIn={toggleLoggedIn} />
                ) : (
                  <NavMenuBeforeLogin {...{ loginBoxOpen, setLoginDropOpen, toggleLoggedIn }} />
                  )}
              </div>  
            </div>
          </div>
        </Navbar.Collapse>
        {/* {showSupportModal && (
          <SupportModal
            showModal={showSupportModal}
            setShowModal={setShowSupportModal}
          />
        )}
        {showFeedbackModal && (
          <FeedbackModal
            showModal={showFeedbackModal}
            setShowModal={setShowFeedbackModal}
          />
        )} */}
      </div>
    </Navbar>
  );
}
