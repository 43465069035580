import { navigate } from '@reach/router';
import { useEffect } from 'react';

//Components
import { Col, Container, Row } from 'react-bootstrap';
import SimilarJob from './components/similarJob';
import Tooltip from './components/tooltip';
import JobDescription from './components/jobDescription';
import NavBar from '../shared/menus/navBar';
import './css/loading.css';

//Icons
import BackIcon from '../assets/icon/snapbrillia_softwareengineerback_icon.png';

//CSS
import useViewModel from './viewModels/jobDetailViewModel';
import NotFound from '../shared/errors/404';
import { useState } from 'react';
import { BountyImage } from '../shared/images';
import Loading from './components/loading';
// import JobReviewModal from './components/JobReviewModal';

const Job = ({ id }) => {
  const { job, loaded, loadJobInfo, similarjobs, loadSimilarJobs } =
    useViewModel();
  const [showReview, setShowReview] = useState(false);

  useEffect(() => {
    loadJobInfo(id);
    loadSimilarJobs(id);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <NavBar />
      {loaded ? (
        job ? (
          <Container fluid className="px-3 pb-3">
            <Row>
              <Col xs={12} className="mb-3">
                <span
                  className="pointer"
                  onClick={() => navigate('/opportunities')}
                >
                  <img src={BackIcon} alt="back-icon" />
                  <span className="fs-5 primary medium ms-2">
                    <u>Back to Opportunities</u>
                  </span>
                </span>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12}>
                <Tooltip
                  job={job}
                  loading={loaded}
                  setShowReview={setShowReview}
                  showReview={showReview}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={12}>
                <JobDescription job={job} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {similarjobs && <SimilarJob jobs={similarjobs} />}
              </Col>
            </Row>
          </Container>
        ) : (
          <NotFound />
        )
      ) : (
        <Container fluid className="px-2 pb-2">
          <Loading />
        </Container>
      )}
    </>
  );
};

export default Job;
