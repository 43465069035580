import { useState, useEffect } from 'react';
import * as bountyAPI from '../api/bounty';
import * as teamAPI from '../api/teams';
import * as OpportunityAPI from '../api/opportunities';

import { toast } from 'react-toastify';

export const useBounty = (id) => {
  const [bounty, setBounty] = useState({});
  const [assessment, setAssessment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState(null);
  const loadBountyInfo = async (id) => {
    setLoading(true);
    try {
      const currentBounty = await OpportunityAPI.getOpportunity(id);
      setBounty(currentBounty);
      const assessment = await OpportunityAPI.getAssessments(id);
      setAssessment(assessment);
      // if (assessment.teamId) {
      //   const teamInfo = await teamAPI.getOneTeam(assessment.teamId);
      //   setTeam(teamInfo);
      // }
    } catch (err) {
      toast(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadBountyInfo(id);
  }, [id]);

  return {
    bounty,
    loading,
    assessment,
    // team,
  };
};
