import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../css/mentorsPersonalInfo.css';
import MentorsYourResume from './mentorsYourResume';
import OnChainIcon from '../../assets/icon/snapbrillia_on_chain_icon.png';
import Avatar from './avatar';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';
import OnChainResumeModal from '../../profilePages/components/onChainResumeModal';

const MentorsPersonalInfo = ({
  user,
  resumeFile,
  setResumeFile,
  newLocation,
  setNewLocation,
  newFullName,
  setNewFullName,
  skills,
  setSkills,
  skillsFromDb,
  validation,
  onCreateSkillSelected,
  selectedSkills,
  setSelectedSkills,
}) => {
  const [isLocation, setIsLocation] = useState(false);
  const [isSkill, setIsSkill] = useState(false);
  const [showOnChainResume, setShowOnChainResume] = useState(false);
  const [displaySkills, setDisplaySkills] = useState(user.skills || []);

  const options = useMemo(() => {
    let result = [];
    if (_.isArray(skills) && !_.isEmpty(skills)) {
      const count = skills.length;
      for (let i = 0; i < count; i++) {
        const skill = skills[i];
        if (!_.isNil(skill)) {
          result.push({ value: skill._id, label: skill.name });
        }
      }
    }
    return result;
  }, [skills]);

  const onSkillSelected = (e) => {
    setSelectedSkills([
      {
        value: e.map((x) => {
          return skills.find((val) => val.name === x.label)._id;
        }),
        label: e.map((x) => x.label),
      },
    ]);
    setDisplaySkills(e.map((x) => x.label));
  };
  const onCreateSkillOption = (e) => {
    if (onCreateSkillSelected) {
      onCreateSkillSelected(e);
    }
  };

  useEffect(() => {
    if (newLocation === '') setIsLocation(true);
    else setIsLocation(false);

    if (skills.length > 0) setIsSkill(false);
    else setIsSkill(true);
  }, [newLocation, skills]);

  return (
    <Container fluid className="p-0">
      <Row>
        <Col xs={12} sm={12} md={12} className="mentors-personal-info-title">
          {'Personal information'}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} className="mentors-personal-info-subtitle">
          {
            'The forms you fill out below will be seen by potential opportunities posters.'
          }
        </Col>
      </Row>
      <Row>
        <div className="mentors-your-resume-line-below">
          Profile Information
        </div>
      </Row>
      <Row className="mentors-personal-info-undertitle ml-2 mt-1 p-4">
        <Col sm={12} lg={12} xl={6} xs={12} xxl={6}>
          <div class="d-flex justify-content-center">
            <div>
              <Avatar />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={12} xl={6} xs={12} xxl={6}>
          <Row className="mentors_personal_information_content_title_row_name mt-2 mx-1">
            <Col>{'Full Name*'}</Col>
          </Row>
          <Row className="mentors_personal_information_content_profile mt-2 mx-1">
            <Col>
              <input
                style={{
                  height: '39px',
                  borderRadius: '5px',
                  border: 'none',
                  background: '#F4F4F4F4',
                  width: '100%',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
                onChange={(e) => setNewFullName(e.target.value)}
                value={newFullName}
                name="fullname"
              />
            </Col>
          </Row>
          <Row className="mentors_personal_information_content_title_row_location mt-4 mx-1">
            <Col>{'Location'}</Col>
          </Row>
          <Row className="mentors_personal_information_content_profile mt-2 mx-1">
            <Col>
              <input
                style={{
                  height: '39px',
                  borderRadius: '5px',
                  border: isLocation ? '2px solid red' : '2px solid green',
                  background: '#F4F4F4F4',
                  width: '100%',
                  justifyContent: 'center',
                  textAlign: 'center',
                  marginBottom: 10,
                  outline: 'none',
                }}
                onChange={(e) => setNewLocation(e.target.value)}
                value={newLocation}
                name="location"
              />
              {isLocation && (
                <span
                  style={{
                    color: 'red',
                    fontSize: 16,
                  }}
                >
                  Location is required
                </span>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Col xs={12} sm={12} md={8} className="mentors-your-resume-line-below">
        {'Your On-Chain Reputation'}
      </Col>
      <Col
        xs={12}
        sm={12}
        md={8}
        className="mentors-personal-info-view-resume"
        onClick={() => {
          setShowOnChainResume(true);
        }}
      >
        <img src={OnChainIcon} alt="onchain-icon" width={25} />{' '}
        {'View On-Chain Reputation'}
      </Col>
      <Col className="mentors-your-resume-line-below">
        <div className="my-3">
          {'Skills'}
          <CreatableSelect
            isMulti
            defaultValue={displaySkills?.map((x) => ({
              value: x,
              label: x,
            }))}
            options={options}
            onChange={onSkillSelected}
            onCreateOption={onCreateSkillOption}
          />
        </div>
        {validation.skills && (
          <span
            style={{
              color: 'red',
              fontSize: 16,
            }}
          >
            {validation.skills}
          </span>
        )}
      </Col>
      <Col xs={12} md={12}>
        <MentorsYourResume
          user={user}
          resumeFile={resumeFile}
          setResumeFile={setResumeFile}
        />
        {showOnChainResume && (
          <OnChainResumeModal
            id={user._id}
            handleClose={() => setShowOnChainResume(false)}
            showModal={showOnChainResume}
          />
        )}
      </Col>
    </Container>
  );
};

export default MentorsPersonalInfo;
