import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Save from '../button/save.jsx';
import '../../shared/css/typography.css';
import * as fileApi from '../../api/files';
import Webcam from 'react-webcam';
import Cropper from 'react-easy-crop';
import { getImageByUrl, getCroppedImg } from '../images.js';

export default function ProfileImageModal({
    show,
    closeModal,
    source,
    setSource,
    handleSave,
    isCompany,
    handleUpdateUserInfo,
    handleUpdateGrant,
}) {
    const [isUpload, setIsUpload] = useState(false);
    const [selectedFile, setSelectedFile] = useState(source);
    const [avatar, setAvatar] = useState(source);
    const [step, setStep] = useState(0);
    const hiddenFileInput = useRef(null);
    const [pictureFrame, setPictureFrame] = useState('50%');
    const webcamRef = React.useRef(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setSelectedFile(imageSrc);
        setStep(3);
      }, [webcamRef]);

    useEffect(() => {
        setAvatar(source);
        setSelectedFile(source);
        console.log("source"+ source);
    }, [source]);

    useEffect(() => {
        setAvatar(source);
        setIsUpload(false);
        backStep();
        setSelectedFile(source);
    }, [!show]);

    const handleFileSelect = (e) => {
        setSelectedFile(e.target.files[0]);
        let reader = new FileReader();
        reader.onload = function (e) {
            setAvatar(e.target.result);
            setStep(1);
            setPictureFrame('5%');
        };
        reader.readAsDataURL(e.target.files[0]);
        setIsUpload(true);
    };

    const handleFileUpload = async () => {
        const canvas = await getCroppedImg(avatar, croppedArea);
        canvas.toBlob(
        async (blob) => {
            const formData = new FormData();
            if (handleUpdateUserInfo) {
            formData.append('avatar', blob);
            if (isCompany) {
                formData.append('isCompany', 'true');
            }

            const { filename } = await fileApi.uploadAvatar(formData, isUpload);
            handleSave(filename);
            setSource(avatar);
            handleUpdateUserInfo();
            }
            if (handleUpdateUserInfo) {
                formData.append('avatar', blob);
                if (isCompany) {
                  formData.append('isCompany', 'true');
                }
      
                const { filename } = await fileApi.uploadAvatar(formData, isUpload);
                handleSave(filename);
                setSource(avatar);
                handleUpdateUserInfo();
              }
            if (handleUpdateGrant) {
            formData.append('type', 'grant_image');
            formData.append('file', blob);
            const fileInfo = await fileApi.uploadFile(formData);
            handleUpdateGrant(fileInfo);
            setSource(getImageByUrl(fileInfo.url));
            console.log("check source"+ source);
            }
            setAvatar(null);
            closeModal();
            console.log("check source"+ source);
        },
        'image/jpeg',
        0.9
    );
    };
    const handleButtonUpload = () => {
        hiddenFileInput.current.click();
    };
    // const handleTakePhoto = () => {
    //   // console.log('handleTakePhoto');
    // };
    const handleLabelClick = () => {
        if (step === 1) {
          handleButtonUpload();
        }
        if (step === 3) {
          setStep(2);
        }
      };
      const backStep = () => {
        setSelectedFile(null);
        setAvatar(source);
        setStep(0);
        setPictureFrame('50%');
      };

    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton className="h1">
                {' '}
                Replace Grant Logo{' '}
            </Modal.Header>
            <Modal.Body className="d-flex flex-column modal-dialog-centered">
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileSelect}
                    onClick={(e) => (e.target.value = null)}
                    style={{ display: 'none' }}
                    accept={'image/jpeg, image/png, image/jpg'}
                />
                <label className="paragraph mb-md-5">
                    Your Snapbrillia profile picture will display at 32 x 32 pixels with
                    an aspect ratio of 1:1. However, in order to maintain a high quality
                    profile picture, we recommend uploading at a higher resolution.
                </label>
                {(step < 2 || step === 3) && (
                <>
            <div className="cropper-container">
              <Cropper
                image={avatar}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                showGrid={false}
                objectFit={'auto-cover'}
                onCropComplete={onCropComplete}
                classes={{
                  containerClassName: 'cropper-container-mask',
                }}
              />
              <div className="cropper-mask"></div>
              <div className="cropper-controls">
                <span
                  onClick={() => {
                    if (Number(zoom) > 1.1) {
                      setZoom(`${Number(zoom) - 0.1}`);
                    }
                  }}
                >
                  -
                </span>
                <Form.Range
                  className="cropper-slider"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e) => setZoom(e.target.value)}
                />
                <span
                  onClick={() => {
                    if (Number(zoom) < 3) {
                      setZoom(`${Number(zoom) + 0.1}`);
                    }
                  }}
                >
                  +
                </span>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <Webcam
            audio={false}
            height={400}
            width={400}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              height: 720,
            }}
          />
        )}

        <label className="link pointer" onClick={handleLabelClick}>
          {' '}
          {step === 0
            ? ' '
            : step === 1
            ? ' Use a different image'
            : step === 2
            ? ' '
            : 'Retake'}{' '}
        </label>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        {step === 0 && (
          <>
            <button className="btn-primary" onClick={handleButtonUpload}>
              Upload{' '}
            </button>
            <button
              className="signinbuttons"
              onClick={() => {
                setStep(2);
                setPictureFrame('5%');
              }}
            >
              {' '}
              Take Photo{' '}
            </button>
          </>
        )}
        {(step === 1 || step === 3 || step === 4) && (
          <>
            <button className="btn-secondary" onClick={backStep}>
              Cancel{' '}
            </button>
            <Save handleClick={handleFileUpload} />
          </>
        )}
        {step === 2 && (
          <>
            <button className="btn-secondary" onClick={backStep}>
              Cancel{' '}
            </button>
            <button className="btn-primary" onClick={capture}>
              {' '}
              Capture{' '}
            </button>
          </>
        )}
            </Modal.Footer>
        </Modal>
    );
}
