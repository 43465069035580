import React from 'react';
import '../../shared/css/typography.css';
import '../css/teamSummaryProfile.css';
import { Row } from 'react-bootstrap';
import ReviewSubmitted from './reviewSubmitted';
import ReviewCompleted from './reviewCompleted';

const IndividualReview = ({ opportunity }) => {
  return (
    <Row>
      {opportunity.status === 'Completed' ? (
        <ReviewCompleted opportunity={opportunity} />
      ) : (
        <ReviewSubmitted opportunity={opportunity} />
      )}
    </Row>
  );
};

export default IndividualReview;
