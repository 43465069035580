import { useAuth } from '../../context/authContext';
import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { navigate } from '@reach/router';
import {
  formatCurrency,
  formatCurrencyRange,
  formatSkills,
  compensation,
} from '../../shared/utils';
import { BountyImage, UserImage } from '../../shared/images';

// Things are hard coded, replace hard coded value with job. And delete
// the OpenSource image later when you put in the real data.
const JobPosting = ({ application }) => {
  const { auth } = useAuth();
  return (
    <Container className="rounded-3 bold active_team_main_container" fluid>
      <div className="active_team_bg">
        <Row>
          <Col sm={3} md={2} lg={2} xl={2} className="px-3">
            <Card className="rounded-3 text-white">
              <Card.Img
                src={BountyImage(application.opportunityId)}
                // onError={(e) => BountyImageErrorHandler(e)}
                alt="Card image"
                style={{
                  borderRadius: '50% !important',
                }}
              />
            </Card>
          </Col>
          <Col>
            <Row className="">
              {/* <h5 className="primary semi-bold d-inline my-2 me-2">{item.name}</h5> */}
              <Col>
                <h5 className="primary semi-bold d-inline my-2 me-2 fs-4">
                  {application.opportunityId.title}
                </h5>
              </Col>
              <Col>
                <Row>
                  <span
                    className="text-black-50 fs-5"
                    style={{ display: 'block', textAlign: 'right' }}
                  >
                    Compensation
                  </span>
                  <span
                    className="fs-5 brand-green"
                    style={{ display: 'block', textAlign: 'right' }}
                  >
                    {`${
                      !application.opportunityId.maxCompensation ||
                      application.opportunityId.maxCompensation < 1
                        ? formatCurrency(application.opportunityId)
                        : formatCurrencyRange(application.opportunityId)
                    }/${compensation(application.opportunityId)}`}
                  </span>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <Row>
                  <span className="text-black-50">Name</span>
                  <span>{application.candidateId.fullName}</span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span className="text-black-50">Skills</span>
                  <span>
                    {formatSkills(application?.opportunityId?.skillIds)}
                  </span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span className="text-black-50">Status</span>
                  <span>{application.status}</span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span className="text-black-50">Opportunity Category</span>
                  <span>{application.opportunityId.type}</span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span className="text-black-50">Company</span>
                  <span>
                    {application.opportunityId.companyId?.companyName}
                  </span>
                </Row>
              </Col>
              <Col>
                {(application.status === 'Applied' ||
                  application.status === 'In-Progress') && (
                  <button
                    className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                    onClick={() =>
                      navigate(`/jobwork/${application.opportunityId._id}`, {
                        state: application,
                      })
                    }
                  >
                    <p
                      style={{ paddingTop: '8px' }}
                      className="active_team_button_go_to_team_page_text"
                    >
                      Start
                    </p>
                  </button>
                )}
                {application.status === 'Invited' && (
                  <button
                    className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                    onClick={() => {
                      navigate(`/jobs/${application.opportunityId._id}`, {
                        state: application,
                      });
                    }}
                  >
                    <p
                      style={{ paddingTop: '8px' }}
                      className="active_team_button_go_to_team_page_text"
                    >
                      View
                    </p>
                  </button>
                )}
                {application.status === 'Completed' && (
                  <button
                    className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                    onClick={() =>
                      navigate(`/jobwork/${application.opportunityId._id}`, {
                        state: application,
                      })
                    }
                  >
                    <p
                      style={{ paddingTop: '8px' }}
                      className="active_team_button_go_to_team_page_text"
                    >
                      View Application
                    </p>
                  </button>
                )}
                {application.status === 'Submitted' && (
                  <button
                    className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                    onClick={() =>
                      navigate(`/jobwork/${application.opportunityId._id}`, {
                        state: application,
                      })
                    }
                  >
                    <p
                      style={{ paddingTop: '8px' }}
                      className="active_team_button_go_to_team_page_text"
                    >
                      View Application
                    </p>
                  </button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default JobPosting;
