import '../css/sharedStyles.css';
import { Link } from '@reach/router';
import Navbar from 'react-bootstrap/Navbar';
import '../css/copyright.css'
import React from 'react';

export default function CopyRight({ freeSpace }) {
  return (
    <div className={freeSpace ? "copyright-div-abs-position" : "copyright-div"}>
      <Navbar relative='bottom'>
        <div className="copyright">
          <span>Copyright</span>
          <span className="icon">&nbsp;</span>
          <span>Snapbrillia 2023&nbsp;</span>
          <span className="icon">&nbsp;</span>
          <Link to="/policy">Use Policies</Link>
        </div>
      </Navbar>
    </div>
  );
}