import { Tabs, Tab } from 'react-bootstrap';
import TeamSummaryDescription from './teamSummaryDescription';
import TeamList from './teamList';
import TeamProgress from './teamProgress';

//INSERT COMPONENTS HERE

//CSS
import '../../shared/css/tabs.css';
import ReviewTeam from './reviewTeam';

const TeamTabs = ({team, opportunity}) => {
  return (
    <>
      <Tabs
        defaultActiveKey="review"
        className="mb-3"
      >
        <Tab eventKey="review" title="Review">
          <ReviewTeam team={team} opportunity={opportunity} />
        </Tab>
        <Tab eventKey="description" title="Description">
          <TeamSummaryDescription team={opportunity} />
        </Tab>
        <Tab eventKey="team" title="Team">
          <TeamList team={team}/>
        </Tab>
        {/* <Tab eventKey="discussion" title="Discussion">
          <TeamTopic bounty={team?.bountyId}/>
        </Tab> */}
        <Tab eventKey="Team Progress" title="Team Progress">
          <TeamProgress team={team}/>
        </Tab>
      </Tabs>
    </>
  );
};

export default TeamTabs;
