import { Row, Col } from 'react-bootstrap';

import '../css/jobDescription.css';
const JobReview = ({ company, applicant, status, job }) => {
  return (
    <>
      {status && status === 'Submitted' && (
        <>
          <Row className="my-3">
            <Col>
              <h5 className="primary semi-bold d-inline my-2 me-2 fs-4">
                Your submission is pending review
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                You will be notified when the job poster has finished reviewing
                your submission for <strong>{job.title}</strong>
              </span>
            </Col>
          </Row>
        </>
      )}
      {status && status === 'In-Progress' && (
        <>
          <Row className="my-3">
            <Col>
              <h5 className="primary semi-bold d-inline my-2 me-2 fs-4">
                You have not submitted anything yet.
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                Once you submit your application,{' '}
                <strong>{company.companyName}</strong> will review it and get
                back to you.
              </span>
            </Col>
          </Row>
        </>
      )}
      {status && status === 'Completed' && (
        <>
          <Row className="my-3">
            <Col>
              <h5 className="primary semi-bold d-inline my-2 me-2 fs-4">
                Your application was reviewed!
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                <strong>{company.companyName}</strong> has sent their decision
                to <strong>{applicant.email}</strong>.
              </span>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default JobReview;
