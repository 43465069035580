import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useAuth } from '../../context/authContext';

const ReviewSubmitted = ({ opportunity }) => {
  const { auth } = useAuth();
  return (
    <Row>
      {opportunity.status === 'Rejected' ? (
        <Col className="col-md-9 col-8">
          <h1 className="h1 fs-3">
            {opportunity.position !== 'Mentee' ? (
              <span>Your submission was not approved</span>
            ) : (
              <span>Your team&quot;s submission was not approved</span>
            )}
          </h1>
          <span className="grey-title mb-2" style={{ fontSize: 16 }}>
            If the gig is still open you can speak with{' '}
            <span className="primary medium">gig poster </span>
            {''}
            to see where you can improve and resubmit
          </span>
        </Col>
      ) : opportunity.status === 'Submitted' ? (
        <Col className="col-md-9 col-8">
          <h1 className="h1 fs-3">
            {' '}
            {opportunity.position !== 'Mentee'
              ? 'Your submission is pending review'
              : 'Your team submission is pending review'}
          </h1>
          <span className="grey-title mb-2" style={{ fontSize: 16 }}>
            {opportunity.position !== 'Mentee' ? (
              <span>
                You will be notified when the gig poster has finished reviewing
                your submission for
              </span>
            ) : (
              <span>
                Your team will be notified when the gig poster has finished
                reviewing your team&quot;s submission for
              </span>
            )}{' '}
            <span className="primary medium">{opportunity.opportunityId.title} </span>
            {''}
          </span>
        </Col>
      ) : (
        <Col className="col-md-9 col-8">
          <h1 className="h1 fs-3">
            {' '}
            {opportunity.position !== 'mentee'
              ? 'You have not submitted anything yet'
              : 'Your team have not submitted anything yet'}
          </h1>
          <span className="grey-title mb-2" style={{ fontSize: 16 }}>
            {opportunity.position !== 'mentee'
              ? 'Once you submit work'
              : 'Once your team submit work'}{' '}
            <span className="primary medium">the gig poster </span>
            {''}
            will review it and get back to you
          </span>
        </Col>
      )}
    </Row>
  );
};

export default ReviewSubmitted;
