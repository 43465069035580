import { useState } from 'react';
import { payoutBountyReward } from '../../api/bounty';
import { listCryptoAccounts, listStripeAccounts } from '../../api/session';

const CashoutViewModel = () => {
  const [walletAdress, setWalletAddress] = useState('');
  const [rewardAmount, setReward] = useState(0.0);
  const [sharedAmount, setSharedAmount] = useState(0.0);
  const [assessmentId, setAssessmentId] = useState('');
  const [wallets, setWallets] = useState([]);
  const [bankAddress, setBankAddress] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);

  const _mapToPayoutData = () => {
    const data = {
      wallet: walletAdress,
      reward: rewardAmount,
      shared: sharedAmount,
      assessment: assessmentId,
      bankAdress: bankAddress,
    };
    return data;
  };
  const loadConnectedWallets = async () => {
    try {
      const wallets = await listCryptoAccounts();
      setWallets(wallets);
    } catch (error) {
      console.log(error);
    }
  };
  const loadBankInfo = async () => {
    try {
      const accounts = await listStripeAccounts();
      setBankAccounts(accounts);
    } catch (error) {
      console.log(error);
    }
  };
  const payoutReward = async () => {
    const data = _mapToPayoutData();
    return await payoutBountyReward(data);
  };

  return {
    setWalletAddress,
    setReward,
    setSharedAmount,
    setAssessmentId,
    setBankAddress,
    loadConnectedWallets,
    loadBankInfo,
    payoutReward,
    wallets,
    bankAccounts,
  };
};
export default CashoutViewModel;
