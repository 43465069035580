import React, { useState } from 'react';
// import { navigate } from '@reach/router';

//COMPONENTS
import { Row, Container } from 'react-bootstrap';
import GrantsMatching from './grantsMatching';
import GrantsTabs from './grantsTabs';
import Back from '../../assets/icon/snapbrillia_softwareengineerback_icon.png';
import NavBar from '../../shared/menus/navBar';

//CSS
import '../css/grantReview.css';
import { useParams } from '@reach/router';
import { useEffect } from 'react';
import { getGrant } from '../../api/grant';

const GrantReview = () => {
  const { id } = useParams();
  const [grant, setGrant] = useState({});

  const fetchData = async () => {
    const grant = await getGrant(id);
    setGrant(grant);
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      <NavBar />
      <Container fluid className="grant-review-container">
        <div className="px-5 pb-5 grant-review-main">
          <Row className="grant-review-back">
            <div className="grant-review-back-button">
              <a href="/grants" className="back-to-grants-link">
                <img src={Back} alt="back-icon" />
                <p className="back-to-grants-link d-inline">Back to Grants</p>
              </a>
            </div>
          </Row>
          {grant && (
            <>
              <Row>
                <GrantsMatching user={grant.candidateId} grant={grant} />
              </Row>
              <Row>{grant && <GrantsTabs grant={grant} />}</Row>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default GrantReview;
