import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from '../../shared/menus/navBar';
import CompletedTeamTab from './completedTeamTab';
import { navigate } from '@reach/router';
import { useParams } from '@reach/router';
import * as teamApi from '../../api/opportunities';
import BackIcon from '../../assets/icon/snapbrillia_softwareengineerback_icon.png';
import Tooltip from '../../bounties/components/tooltip';
import NotFound from '../../shared/errors/404';
import Loading from '../../shared/Overlays/loading';
function TeamPage() {
  const { id } = useParams();
  const [team, setTeam] = useState({
    bountyId: {},
  });
  const [loading, setLoading] = useState(true);
  const [reviewData, setReviewData] = useState([]);

  const loadTeamInfo = async () => {
    try {
      const teamInfo = await teamApi.getAllTeams({opportunityId:id, status: "In-Progress"});
      setTeam(teamInfo);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // const getReview = async () => {
  //   try {
  //     const data = await teamApi.getPeerReview(id);
  //     setReviewData(data);
  //   } catch (err) {}
  // };

  useEffect(() => {
    setLoading(true);
    loadTeamInfo();
    // getReview();
  }, []);

  return (
    <>
      <NavBar />
      {loading? <Loading/> :
      team ? (
        <Container fluid className="px-5 pb-5">
          <Row className="mb-3">
            <Col xs={12} lg={12}>
              <span className="pointer link" onClick={() => navigate('/teams')}>
                <img src={BackIcon} alt="back-icon" className="mb-1" />
                <span className="primary ms-1">Back to Teams</span>
              </span>
            </Col>
          </Row>
          {/* <Row>
          <CompletedTeamDetails bounty={team.bountyId}/>
        </Row> */}
          <Row className="mb-2">
            <Col xs={12}>
              <Tooltip bounty={team[0].opportunityId} team={team[0]} />
            </Col>
          </Row>
          <Row className="mt-5">
            <CompletedTeamTab team={team} />
          </Row>
        </Container>
      ) : (
        <NotFound />
      )}
    </>
  );
}

export default TeamPage;
