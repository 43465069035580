import axios from 'axios';

export async function generateGrantTX(
  grantInfo,
  browserWallet,
  rawUtxos,
  selectedWalletPKH,
  isStaging
) {
  try {
    const response = await axios.post('/api/generate-grant-tx', {
      grantInfo,
      browserWallet,
      rawUtxos,
      selectedWalletPKH,
      isStaging,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function createGrantTX(
  vKeyWitness,
  transaction,
  grantInfo,
  projectTokenName,
  isStaging,
  browserWallet
) {
  try {
    const response = await axios.post('/api/create-grant-tx', {
      vKeyWitness,
      transaction,
      grantInfo,
      projectTokenName,
      isStaging,
      browserWallet,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function generateDonationTX(
  cart,
  browserWallet,
  rawUtxos,
  isStaging
) {
  try {
    const response = await axios.post('/api/generate-donation-tx', {
      cart,
      browserWallet,
      rawUtxos,
      isStaging,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function submitDonateTx(
  vKeyWitness,
  transaction,
  cart,
  isStaging
) {
  try {
    const response = await axios.post('/api/submit-donation-tx', {
      vKeyWitness,
      transaction,
      cart,
      isStaging,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function sendAdaToCustodialWallet(
  lovelaceAmount,
  rawUTxOs,
  pubKeyAddress,
  browserWallet,
  isStaging
) {
  try {
    const response = await axios.post('/api/send-ada-to-custodial-wallet', {
      lovelaceAmount,
      pubKeyAddress,
      rawUTxOs,
      browserWallet,
      isStaging,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function injectSignature(vKeyWitness, transaction) {
  try {
    const response = await axios.post('/api/inject-signature', {
      vKeyWitness,
      transaction,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function addToProjectRegistrationQueue(
  grantInfo,
  browserWallet,
  isStaging
) {
  try {
    const response = await axios.post('/api/register-project-to-queue', {
      grantInfo,
      browserWallet,
      isStaging,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function addToDonationQueue(cart, isStaging) {
  try {
    const response = await axios.post('/api/register-donation-to-queue', {
      cart,
      isStaging,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function contributeToMatchPool(
  browserWallet,
  rawUtxos,
  contributeADAAmount,
  isStaging
) {
  try {
    const response = await axios.post('/api/contribute-to-match-pool', {
      browserWallet,
      rawUtxos,
      contributeADAAmount,
      isStaging,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function sendContributeToMatchPool(
  transaction,
  vKeyWitness,
  contributeADAAmount,
  isStaging,
  currentFundingRound
) {
  try {
    const response = await axios.post('/api/send-contribute-to-match-pool', {
      transaction,
      vKeyWitness,
      contributeADAAmount,
      isStaging,
      currentFundingRound,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const addToMatchPoolQueue = async (
  contributeADAAmount,
  browserWallet,
  currentFundingRound,
  isStaging
) => {
  try {
    const response = await axios.post(
      '/api/register-contribute-to-match-pool-to-queue',
      {
        contributeADAAmount,
        browserWallet,
        currentFundingRound,
        isStaging,
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
