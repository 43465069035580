import { Modal, Button } from 'react-bootstrap';
import BackButton from '../button/back';
import { preventMinus } from '../utils';
import '../css/supportmodal.css';

const ContributeMatchPoolModal = ({
  showModal,
  networkBusy,
  setContributeAmount,
  handleTryLater,
  sendQueuedMatchPoolContribution,
  loading,
  handleContributeToMatchPool,
}) => {
  return (
    <Modal centered show={showModal}>
      <Modal.Body>
        {networkBusy && !loading && (
          <>
            <h4>Network Busy</h4>
            <p>
              The network is busy at the moment. You can try again later and we
              will save a draft of your current grant information for you.
              Alternatively, you can send ADA to a custodial wallet and we will
              create and send the transaction on your behalf.
            </p>
            <div className="d-flex justify-content-between w-100">
              <Button onClick={handleTryLater}>Try Again Later</Button>
              <Button onClick={sendQueuedMatchPoolContribution}>
                Custodial Wallet
              </Button>
            </div>
          </>
        )}
        {!networkBusy && !loading && (
          <>
            <h4>Contribute To Match Pool</h4>
            <div className="w-100 d-flex flex-end">
              <div>
                <div className="grant-setup-tag">Contribute ADA Amount</div>
                <input
                  className="match-pool-input"
                  type="number"
                  min={0}
                  onKeyPress={preventMinus}
                  onChange={(e) => {
                    setContributeAmount(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between w-100">
              <BackButton btntext="Cancel" onClick={handleTryLater} />
              <Button onClick={handleContributeToMatchPool}>Confirm</Button>
            </div>
          </>
        )}
        {loading && (
          <>
            <h4>Creating Transaction</h4>
            <div className="spinner-border match-pool-color" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContributeMatchPoolModal;
