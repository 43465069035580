import React from 'react';
// import { Link } from '@reach/router';
import { Col, Container, Row, Card } from 'react-bootstrap';

import '../../shared/css/buttonStyles.css';
import '../css/activeTeam.css';
import { navigate } from '@reach/router';
import { BountyImage } from '../../shared/images';
import { formatCurrency, formatSkills, timeAgo } from '../../shared/utils';

// need to use teamsSummary style to the component
function capitolize(string = '') {
  const str = string.substring(1);
  return string[0]?.toUpperCase() + str;
}

const ActiveTeam = ({ opportunity }) => {
  return (
    <Container className="rounded-3 bold active_team_main_container" fluid>
      <div className="active_team_bg">
        <Row>
          <Col sm={3} md={2} lg={2} xl={2} className="px-3">
            <div>
              <Card.Img
                src={BountyImage(opportunity.opportunityId)}
                // onError={(e) => BountyImageErrorHandler(e)}
                alt="Card image"
                style={{
                  borderRadius: '50% !important',
                }}
              />
            </div>
          </Col>
          <Col>
            <Row className="">
              <Col>
                <h5 className="primary semi-bold d-inline my-2 me-2 fs-4">
                  {opportunity.opportunityId.title}
                </h5>
              </Col>
              <Col>
                <Row className="">
                  <span
                    className="text-black-50 fs-5"
                    style={{ display: 'block', textAlign: 'right' }}
                  >
                    Reward
                  </span>
                  <span
                    className="fs-5 brand-green"
                    style={{ display: 'block', textAlign: 'right' }}
                  >
                    {formatCurrency(opportunity.opportunityId)}
                  </span>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <Row>
                  <span className="text-black-50">Opportunity Category</span>
                  <span>Gig</span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span className="text-black-50">Opportunity Position</span>
                  <span>{opportunity.position}</span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span className="text-black-50">Status</span>
                  <span>{capitolize(opportunity.status)}</span>
                </Row>
              </Col>
              <Col>
                <Col>
                  <Row>
                    <span className="text-black-50">Application Date</span>
                    <span>{timeAgo(opportunity.updatedAt)}</span>
                  </Row>
                </Col>
              </Col>
              <Col>
                <Row>
                  <span className="text-black-50">Skills</span>
                  <span>
                    {formatSkills(opportunity?.opportunityId?.skillIds)}
                  </span>
                </Row>
              </Col>
              <Col>
                {opportunity.status !== 'Requested' &&
                  opportunity.status !== 'Completed' && (
                    <button
                      className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                      onClick={() =>
                        navigate(`/work/${opportunity.opportunityId._id}`, {
                          state: opportunity,
                        })
                      }
                    >
                      <p
                        style={{ paddingTop: '8px' }}
                        className="active_team_button_go_to_team_page_text"
                      >
                        View
                      </p>
                    </button>
                  )}
              </Col>
              <Col>
                {opportunity.status === 'Completed' && (
                  <button
                    className="active_team_button_go_to_team_page col-md-12 active_team_launch_btn ml-5"
                    onClick={() =>
                      navigate(`/work/${opportunity.opportunityId._id}`, {
                        state: opportunity,
                      })
                    }
                  >
                    <p
                      style={{ paddingTop: '8px' }}
                      className="active_team_button_go_to_team_page_text"
                    >
                      View
                    </p>
                  </button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ActiveTeam;
