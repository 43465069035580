import React from 'react';
import '../css/policyComponents.css';

const VulnerabilityPolicyText = () => {
  return (
    <div className="policy-wrapper policy-typography">
      <div className="main-policy-container" data-testid='vulPolicyComponent'>
        <div>
          <p className="v9">
            <span className="v2"></span>
          </p>
        </div>
        <span className="v6">Vulnerability Disclosure Program</span>
        <p className="v10">
          <span className="v7">Effective Date: [Nov 18, 2021]</span>
        </p>
        <p className="v9">
          <span className="v2"></span>
        </p>
        <p className="v1">
          <span className="v0">
            The information on this page is intended for security researchers
            interested in responsibly reporting security vulnerabilities to
            Snapbrillia’s security team.
          </span>
        </p>
        <p className="v1">
          <span className="v0">
            At Snapbrillia, we take the security of our users very seriously. If
            you believe you&#39;ve discovered a security vulnerability on or
            within Snapbrillia’s product, service, or application, we strongly
            encourage you to inform us immediately. Publicly disclosing a
            vulnerability can put the entire community at risk, so we request
            that such vulnerability reports be kept in confidence and
            researchers not make those public until we have resolved the issue.
            We appreciate your assistance, and we review all reports and will
            make every effort to resolve the issue as fast as we can.
          </span>
        </p>
        <p className="v1">
          <span className="v0">
            If you believe you have discovered a potential security
            vulnerability on any of Snapbrillia domains, please immediately
            report your findings to us in accordance with our Guidelines for
            Responsible Disclosure listed below.
          </span>
        </p>
        <p className="v1">
          <span className="v6">Guidelines for Responsible Disclosure</span>
        </p>
        <ul className="v4">
          <li className="v1  ">
            <span className="v0">
              Notify Snapbrillia and provide us details of the vulnerability.
              Please provide us 72 hours, a commercially reasonable time period
              to address the issue before public disclosure.
            </span>
          </li>
          <li className="v1  ">
            <span className="v0">
              Provide an appropriate level of detail on the vulnerability to
              allow us to identify and reproduce the issue. Details should
              include target URLs, request/response pairs, screenshots, and/or
              other information. We will confirm your email and evaluate the
              validity and reproducibility of the issue. For valid issues, we
              will work to fix the issue and keep you updated of our progress
              through the email you provided to us.
            </span>
          </li>
          <li className="v1  ">
            <span className="v0">
              Make a reasonable effort to avoid service disruption (e.g. DoS),
              privacy issues (i.e. accessing a Snapbrillia customer’s data, or
              our customers&#39; users&#39; data), and data destruction when
              performing vulnerability research.
            </span>
          </li>
          <li className="v1  ">
            <span className="v0">
              Do not request compensation for security vulnerability reports
              either from Snapbrillia or external vulnerability marketplaces.
            </span>
          </li>
          <li className="v1  ">
            <span className="v0">
              Do not phish or social engineer employees or customers of
              Snapbrillia (this will change later).
            </span>
          </li>
          <li className="v1  ">
            <span className="v0">
              Do not run automated scanning tools and send us the output without
              confirming the issue is present. Security tools often output false
              positives that should be confirmed by the reporter.
            </span>
          </li>
        </ul>
        <p className="v1">
          <span className="v6">Vulnerability Categories We Encourage</span>
        </p>
        <p className="v1">
          <span className="v0">
            We are primarily interested in hearing about the following
            vulnerability categories:
          </span>
        </p>
        <ul className="v4 lst-kix_e6e54v1emk09-0 start">
          <li className="v1  ">
            <span className="v0">
              Authorization and Authentication related issues
            </span>
          </li>
          <li className="v1  ">
            <span className="v0">Data Exposure and Redirection Attacks</span>
          </li>
          <li className="v1  ">
            <span className="v0">Remote Code Execution</span>
          </li>
          <li className="v1  ">
            <span className="v0">
              Sensitive Data Exposure – Cross Site Scripting (XSS) Stored, SQL
              Injection (SQLi), etc.
            </span>
          </li>
          <li className="v1  ">
            <span className="v0">
              Particularly clever vulnerabilities or unique issues that do not
              fall into explicit categories
            </span>
          </li>
        </ul>
        <p className="v1">
          <span className="v6">How to Report a Security Vulnerability</span>
        </p>
        <p className="v1">
          <span className="v8">
            Our public program currently does not provide any monetary reward
            beyond Snapbrillia’s eternal gratitude. Security vulnerabilities may
            be reported via email to{' '}
          </span>
          <span className="v3">
            <a className="v11" href="mailto:support@snapbrillia.com">
              support@snapbrillia.com
            </a>
          </span>
          <span className="v0">
            . We are also happy to accept anonymous vulnerability reports. The
            validity of a vulnerability will be judged at the sole discretion of
            Snapbrillia.
          </span>
        </p>
      </div>
    </div>
  );
};

export default VulnerabilityPolicyText;
