import React, { useState, createContext, useEffect, useContext } from 'react';
import * as grantAPI from '../api/grant';
import { toast } from 'react-toastify';
import { isInactiveGrants } from '../shared/utils';

const GrantContext = createContext();

const useGrant = () => useContext(GrantContext);

const GrantsProvider = ({ children }) => {
  const [grantFilterQuery, setGrantFilterQuery] = useState({
    search: '',
    matchPools: [],
    sort: '',
  });
  const [grantId, setGrantId] = useState(null);
  const [grantsList, setGrantsList] = useState([]);
  const [featuredData, setFeaturedData] = useState([]);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentFundingRound, setCurrentFundingRound] = useState();
  const [listOfFundingRounds, setListOfFundingRounds] = useState([]);

  const getAllGrants = async () => {
    try {
      const { grants, grantsOverviewData } = await grantAPI.getAllGrants();
      let listOfPreviousFunds = [];
      for (let i = 1; i <= grantsOverviewData.fundingRound; i++) {
        listOfPreviousFunds.push(i);
      }
      const currentFundingRound = grantsOverviewData.fundingRound;
      setCurrentFundingRound(currentFundingRound);
      setListOfFundingRounds(listOfPreviousFunds.reverse());
      const activeGrants = grants.filter((e) => !isInactiveGrants(e.updatedAt));
      const inactiveGrants = grants.filter((e) =>
        isInactiveGrants(e.updatedAt)
      );
      const formatedGrants = [...activeGrants, ...inactiveGrants];
      setLoading(false);
      setGrantsList(formatedGrants);
      setFeaturedData(grants.slice(-2));
    } catch (err) {
      setLoading(false);
      toast('Could not load grants');
    }
  };

  useEffect(() => {
    (async () => {
      await getAllGrants();
    })();
  }, []);

  return (
    <GrantContext.Provider
      value={{
        grantId,
        setGrantId,
        grantFilterQuery,
        setGrantsList,
        getAllGrants,
        setShowLeaderboard,
        grantsList,
        featuredData,
        showLeaderboard,
        loading,
        setGrantFilterQuery,
        currentFundingRound,
        listOfFundingRounds,
      }}
    >
      {children}
    </GrantContext.Provider>
  );
};

export { GrantsProvider, useGrant };
