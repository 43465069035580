import React, { useState } from 'react';
import '../css/mentorsPersonalInfo.css';
import CapturePicture from '../../profilePages/components/capturePicture';
import { useAuth } from '../../context/authContext';
import { UserImage } from '../../shared/images';

const Avatar = () => {
  const { auth, updateProfile } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  const removeImage = async () => {
    if (
      window.confirm(
        'Are you sure you want to remove the current profile photo?'
      )
    ) {
      await updateProfile({ avatar: null});
    }
  };
  const openReplaceImage = () => {
    setModalOpen(true);
  };

  return (
    <div className="shadow p-0 mb-3 bg-white rounded-circle personal_information_picture_profile">
      <img
        src={UserImage(auth.user)}
        alt="profile"
        height={170}
        width={170}
        style={{ borderRadius: '50%' }}
      />
      <div
        className="d-flex flex-row justify-content-center align-items-center personal_information_picture_edit_profile"
        style={{ background: '#A900A699' }}
      >
        <div style={{borderRight: '1px solid #fff'}} className='d-flex flex-column align-item-start py-1 mb-2'>
          <button 
            className='border-0 bg-transparent' 
            style={{fontSize: '0.75rem', color: auth.user.avatar === '' ? '#ccc' : '#fff'}} 
            disabled={auth.user.avatar === null}
            onClick={removeImage}
          >
              Remove
          </button>
        </div>
        <div className='d-flex flex-column align-item-start py-1 mb-2'>
          <button 
            className='border-0 bg-transparent text-white' 
            style={{fontSize: '0.75rem'}}
            onClick={openReplaceImage}
          >
            Replace
          </button>
        </div>
      </div>
      <CapturePicture {...{ modalOpen, setModalOpen }} />
    </div>
  );
};

export default Avatar;
