import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { inititateOnboarding } from '../../api/session';
import CashoutViewModal from '../../cashOut/cashoutModal';
import { useAuth } from '../../context/authContext';

const ReviewCompleted = ({ opportunity }) => {
  const [showCashoutModal, setShowCashoutModal] = useState(false);
  const [showCashout, setShowCashout] = useState(false);
  const [showOnboard, setShowOnboard] = useState(false);
  const [accountUrl, setAccountUrl] = useState(undefined);
  const { auth } = useAuth();

  const cashout = () => {
    setShowCashoutModal(true);
  };
  const closeCashout = () => {
    setShowCashoutModal(false);
  };
  const onboardAccount = (e) => {
    e.preventDefault();
    window.location.assign(accountUrl.url);
  };
  useEffect(() => {
    inititateOnboarding(window.location.href).then((result) => {
      setAccountUrl(result);
      if (typeof result === 'undefined' || result == null || !result.url) {
        setShowCashout(true);
      }
      if (typeof result !== 'undefined' && result != null && result.url) {
        setShowOnboard(true);
      }
    });
  });
  return (
    <Row className="align-items-center pt-5">
      {opportunity.position === 'Mentee' ? (
        <>
          <Col className="col-md-9 col-8">
            <h1 className="h1 fs-3">
              Congratulation your team has completed the bounty
            </h1>
            <span className="grey-title mb-2" style={{ fontSize: 16 }}>
              Your teams's submission for{' '}
              <span className="primary medium">{opportunity.opportunityId.title} </span>
              has been approved by the gig poster
              {''}
            </span>
          </Col>
        </>
      ) : (
        <>
          <Col className="col-md-9 col-8">
            <h1 className="h1 fs-3">Congratulations, {auth.user.fullName}!</h1>
            <span className="grey-title mb-2" style={{ fontSize: 16 }}>
              Your submission for{' '}
              <span className="primary medium">{opportunity.opportunityId.title} </span>
              {''}
              was accepted
            </span>

            <span className="grey-title mb-2" style={{ fontSize: 16 }}>
              <br></br>
              {opportunity.position === 'Individual' ? (
                <p>You can cash out when you are ready</p>
              ) : (
                <p>
                  You can allocate some of the reward to your teammates and cash
                  out when you are ready
                </p>
              )}
            </span>
          </Col>
          <Col className=" text-center">
            <span className="grey-title d-block ms-4">
              The reward for the bounty is...
            </span>
            {opportunity.opportunityId.rewardType === '₳DA' && (
              <span
                style={{
                  color: '#957B94',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                {parseInt(opportunity.opportunityId.rewardAmount).toLocaleString('en-US')} ₳DA
              </span>
            )}
            {opportunity.opportunityId.rewardType === 'USD' && (
              <span
                className="h2 my-1 d-block fs-3"
                style={{ color: '#450044' }}
              >
                ${parseInt(opportunity.opportunityId.rewardAmount).toLocaleString('en-US')}
              </span>
            )}
            {showCashout && (
              <div className="mt-4">
                <button
                  className="teams_review_button col-md-12"
                  style={{
                    border: 'none',
                    alignItems: 'center',
                    marginLeft: 'auto',
                  }}
                  onClick={cashout}
                >
                  <span className="teams_review_button_text">Cash Out</span>
                </button>
              </div>
            )}
            {showOnboard && (
              <div className="mt-4">
                <button
                  className="teams_review_button col-md-12"
                  style={{
                    border: 'none',
                    alignItems: 'center',
                    marginLeft: 'auto',
                  }}
                  onClick={onboardAccount}
                >
                  <span className="teams_review_button_text">
                    Stripe Connect Onboarding
                  </span>
                </button>
              </div>
            )}
            {showCashoutModal && (
              <CashoutViewModal
                opportunity={opportunity}
                showModal={showCashoutModal}
                onClose={closeCashout}
              />
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

export default ReviewCompleted;
