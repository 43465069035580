//COMPONENTS
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

//CSS
import '../css/grantDescription.css';
import '../../shared/css/typography.css';

import parse from 'html-react-parser';
import { UserImage } from '../../shared/images';
import { useAuth } from '../../context/authContext';

function GrantDescription({ grant, teamMember, funders }) {
  const { auth } = useAuth();
  return (
    <Container>
      <div className="grant-descr-container">
        {parse(grant?.description || '')}{' '}
      </div>
      <Row>
        <Col xs={12} key={'-1'}>
          {auth?.user && (
            <Row>
              <Col xs={1}>
                <img
                  src={UserImage(grant.candidateId)}
                  alt="team member profile"
                  className="team-img"
                ></img>
              </Col>
              <Col xs={11}>
                {/* <p className="member-name">{auth.user.fullName}</p> */}
                <p className="member-title">Creator</p>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        {teamMember?.length > 0 && (
          <Col xs={6}>
            <div>
              <Row>
                <p className="bold">Team:</p>
              </Row>
              <Row></Row>
              {teamMember?.map((item, i) => {
                return (
                  <Row>
                    <Col xs={12} key={i}>
                      <Row>
                        <Col xs={3}>
                          <img
                            src={UserImage(item)}
                            alt="team member profile"
                            className="team-img"
                          ></img>
                        </Col>
                        <Col xs={9}>
                          <p className="member-name">
                            {item.fullName} (Member)
                          </p>
                          <p className="member-title">{item.email}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Col>
        )}
        {funders?.length > 0 && (
          <Col xs={6}>
            <div>
              <Row>
                <p className="bold">Funders</p>
              </Row>
            </div>
            <Row>
              {funders?.map((item, i) => {
                return (
                  <Row>
                    <Col xs={12} key={i}>
                      <Row>
                        <Col xs={3}>
                          <img
                            src={UserImage(item)}
                            alt="team member profile"
                            className="team-img"
                          ></img>
                        </Col>
                        <Col xs={9}>
                          <p className="member-name">
                            {item.fullName} (Funder)
                          </p>
                          <p className="member-title">{item.email}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default GrantDescription;
