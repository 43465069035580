import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GrantSetupContext } from './grantSetup';
import { EditorState, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import '../css/grantDetails.css';
import 'bootstrap/dist/css/bootstrap.css';
import { preventMinus } from '../../shared/utils';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

const GrantDetails = ({ isEdit }) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const { data, setData, throttle } = useContext(GrantSetupContext);

  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setData({ ...data, description: content });
  };

  useEffect(() => {
    if (data.description === '' || data.fundraisingAmount <= 5) {
      window.scroll(0, 0);
    }
  }, [throttle]);

  useEffect(() => {
    const contentBlock = stateFromHTML(data.description);
    if (contentBlock) {
      const initialEditorState = EditorState.createWithContent(contentBlock);
      setEditorState(initialEditorState);
    }
  }, []);

  return (
    <Container className="grant-details-component" fluid>
      {/* <CustomModal
        fullscreen={true}
        setShow={setFullScreen}
        show={isFullScreen}
        body={
          <textarea
            style={{
              border:
                throttle[1] === 1 && data.description === ''
                  ? '2px solid #b33a3a'
                  : 'none',
            }}
            value={data.description}
            onChange={(e) => {
              setData({ ...data, description: e.target.value });
            }}
            className="col-md-12 grant-details-textarea"
            rows="30"
          />
        }
        title={'Description'}
      ></CustomModal> */}
      <Row className="grant-details-title">
        <div>Grant Details and Funding</div>
      </Row>
      <Row className="grant-details-component-row1">
        <Col md={12} xs={12}>
          <label htmlFor="name" className="grant-setup-tag">
            Description*
          </label>

          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            // toolbar={{
            //   inline: { inDropdown: true },
            //   list: { inDropdown: true },
            //   textAlign: { inDropdown: true },
            //   link: { inDropdown: true },
            //   history: { inDropdown: true },
            //   image: {
            //      uploadCallback: uploadImageCallback,
            //      alt: { present: true, mandatory: true },
            //    },
            // }}
          />
          {/* <textarea
              style={{
                border:
                  throttle[1] === 1 && data.description === ''
                    ? '2px solid #b33a3a'
                    : 'none',
              }}
              value={data.description}
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
              }}
              className="col-md-12 grant-details-textarea"
              rows="8"
            /> */}
          {/* <div
              className="grant-details-expand-icon"
              onClick={(e) => setFullScreen(true)}
            >
              <img src={Expand} alt="expand-icon" />
            </div> */}

          {throttle[1] === 1 && data.description === '' ? (
            <Row className="grant-warning-text mx-1">
              Description is required for the created grant.
            </Row>
          ) : null}
        </Col>
      </Row>
      <Row className="grant-details-component-row2 gx-xs-2 gx-md-5">
        <Col md={4} xs={12}>
          <label htmlFor="name" className="grant-setup-tag">
            Grant Fund Raising Amount*
          </label>
          <div className="input-group">
            <input
              disabled={isEdit}
              style={{
                border:
                  throttle[1] === 1 && data.fundraisingAmount === 0
                    ? '2px solid #b33a3a'
                    : 'none',
                MozAppearance: 'textfield',
              }}
              value={data.fundraisingAmount.toLocaleString('en-US', {
                style: 'decimal',
                maximumFractionDigits: 2,
              })}
              onChange={(e) => {
                setData({
                  ...data,
                  fundraisingAmount:
                    e.target.value === ''
                      ? 0
                      : parseFloat(e.target.value.replace(/,/g, '')),
                });
              }}
              onKeyPress={preventMinus}
              input="number"
              className="grant-details-grant-amount form-control grant-details-control"
            />
            <span className="input-number-suffix-usd">₳DA</span>
          </div>
          {throttle[1] === 1 && data.fundraisingAmount <= 5 ? (
            <Row className="grant-warning-text mx-1">
              Grant Amount must be greater than 5.
            </Row>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default GrantDetails;
