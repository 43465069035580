import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  Col,
  Row,
  Container,
  Form,
  InputGroup,
  Button,
  Overlay,
} from 'react-bootstrap';
import '../../shared/css/textColors.css';
import '../css/openOfficeHoursFields.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';
import defaultTimezones from '../data/timezone.json';
import PopoutSelect from '../../shared/select/popoutSelect';
import Edit from '../../assets/icon/snapbrillia_edit_icon.svg';
import { getHashCode } from '../../shared/utils';
import { format } from 'date-fns';

const MentorApplication = ({ events, setEvents }) => {
  const initTimeState = {
    hour: '01',
    minute: '00',
    period: 'AM',
  };
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const selectDateButton = useRef();
  const [date, setDate] = useState(new Date());
  const [event, setEvent] = useState({});
  const [meetingName, setMeetingName] = useState('');
  const [meetingDescription, setMeetingDescription] = useState('');
  const [fromTime, setFromTime] = useState(initTimeState);
  const [toTime, setToTime] = useState(initTimeState);
  const [startTime, setStartTime] = useState(null);

  // const [repeat, setRepeat] = useState(1);
  const [timezone, setTimezone] = useState(currentTimeZone);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isSelectPopoutOpen, setIsSelectPopoutOpen] = useState(false);

  const handleChange = (value) => {
    setDate(value);
    setShowCalendar(false);
  };

  const showDate = date == null ? 'Select Date' : date.toLocaleDateString();

  const resetData = () => {
    setToTime(initTimeState);
    setFromTime(initTimeState);
    // setRepeat(1);
    setTimezone(currentTimeZone);
    setMeetingName('');
    setMeetingDescription('');
    setDate(new Date());
    setEvent({})
  };

  useEffect(() => {
    if (isTimeFromLessThanTimeTo(fromTime, toTime)){
      setToTime(fromTime)
    }
    setStartTime(format(setTime(date, convertTime(fromTime)), "hh:mm aaaaa'm'"))
  }, [fromTime, timezone]);

  useEffect(() => {
    if (isTimeFromLessThanTimeTo(fromTime, toTime)) {
      setFromTime(toTime)
    }
  }, [toTime]);


  const isTimeFromLessThanTimeTo = (timeFrom, timeTo) => {
    const from = new Date().setHours(timeFrom.hour, timeFrom.minute, 0, 0);
    const to = new Date().setHours(timeTo.hour, timeTo.minute, 0, 0);

    if (timeFrom.period === timeTo.period){
      return (from - to) > 0
    }else{
      return timeFrom.period === "PM" && timeTo.period === "AM"
    }

  };
  

  const formatHour = (setFunc) => {
    return (e) => {
      if (e.target.value === '00') {
        setFunc((p) => ({
          ...p,
          hour: '01',
        }));
      }
    };
  };
  const formatInput = (min, max, setFunc, field) => {
    return (e) => {
      if (!isNaN(e.target.value) && `${Number(e.target.value)}`.length === 1) {
        if (Number(e.target.value) < min) {
          setFunc((p) => ({
            ...p,
            [field]: '0' + min,
          }));
        } else {
          setFunc((p) => ({
            ...p,
            [field]: '0' + Number(e.target.value),
          }));
        }
      } else {
        if (Number(e.target.value) > max) {
          setFunc((p) => ({
            ...p,
            [field]: max,
          }));
        } else if (Number(e.target.value) < min) {
          setFunc((p) => ({
            ...p,
            [field]: '0' + min,
          }));
        } else if (e.target.value === '00') {
          setFunc((p) => ({
            ...p,
            [field]: '00',
          }));
        } else {
          setFunc((p) => ({
            ...p,
            [field]: `${Number(e.target.value)}`,
          }));
        }
      }
    };
  };

  function convertTime(time) {
    return `${time.hour}:${time.minute}${time.period}`;
  }

  function setTime(dateTime, str) {
    var newDate = new Date(dateTime);
    var s = /(\d+):(\d+)(.+)/.exec(str);
    newDate.setHours(
      s[3] === 'PM' ? 12 + parseInt(s[1], 10) : parseInt(s[1], 10)
    );
    newDate.setMinutes(parseInt(s[2], 10));

    let changeTzDate = new Date(
      newDate.toLocaleString('en-US', { timeZone: timezone })
    );

    let diff = newDate.getTime() - changeTzDate.getTime();
    return new Date(newDate.getTime() - diff);
  }

  const generate = () => {
    if (!date) {
      return toast('Please select date');
    }
    if (!meetingName) {
      return toast('Please add meeting name');
    }

    const fromDate = setTime(date, convertTime(fromTime));
    const toDate = setTime(date, convertTime(toTime));

    const event = {
      key: getHashCode().toString(),
      from: fromTime,
      to: toTime,
      title: meetingName,
      description: meetingDescription,
      startTime: fromDate,
      endTime: toDate,
      timezone: timezone,
      recurring: {
        repeat: 'weekly',
        weekStart: 'SU',
        // count: Number(repeat) <= 0 ? 1 : repeat,
        days: [fromDate],
      },
    };

    groupEvents(event);
  };
  const compareDateTime = (x, y) => {
    const dateX = new Date(x);
    const dateY = new Date(y);
    return dateX.getHours() === dateY.getHours() && dateX.getMinutes() === dateY.getMinutes();
  };

  //const compareTime = (x, y) => {
  //  return x.hour === y.hour && x.minute === y.minute && x.period === y.period;
  //};

  const groupEvents = (event) => {
    const sameEventIndex = events.findIndex((x) => {
      return (
        event.startTime === x.startTime ||
        (event.startTime.getDay() === x.startTime.getDay() &&
          compareDateTime(x.startTime, event.startTime))
      );
    });

    //const similarEventIndex = events.findIndex((x) => {
    //  return (
    //    compareTime(x.from, event.from) &&
    //    compareTime(x.to, event.to) &&
    //    event.recurring.count === x.recurring.count
    //  );
    //});

    //if (similarEventIndex > -1 && sameEventIndex === -1) {
    //  setEvents((prev) =>
    //    prev.map((x, i) => {
    //      if (i === similarEventIndex) {
    //        x.recurring.days.push(event.startTime);
    //      }
    //      return x;
    //    })
    //  );
    //} else 

    if (sameEventIndex === -1) {
      setEvents((prev) => [...prev, event]);
    }else{
      return toast('There is already a meeting at this date time.');
    }

    resetData();
  };

  const timeZoneLabel = useMemo(() => {
    const timeZoneText = defaultTimezones.find(_timezone => _timezone.value === timezone);
    return timeZoneText ? timeZoneText.label : timezone;
  }, [defaultTimezones, timezone])

  const web = (
    <>
      <Container fluid className="open-office-hours-fields-bg">
        {/* add title and description */}
        <h1 className="open-office-hours-fields-title">Open Office Hours</h1>
        {/* <p className="small-text open-office-hours-fields-paragraph">
          Press the check marks for the information you want to include for your
          mentees to see. Mentors with office hours are 3x more likely to
          succeed.
        </p> */}
        <br></br>
        {/* add the first two input lines */}
        <Form.Group>
          <Form.Label className="small-text open-office-hours-fields-subtitle">
            Meeting Name*
          </Form.Label>
          <Form.Control
            md={12}
            className="open-office-hours-fields-input"
            value={meetingName}
            onChange={(e) => { setMeetingName(e.target.value); }}
          />
        </Form.Group>
        <br></br>
        <br></br>
        <Form.Group>
          <Form.Label className="small-text open-office-hours-fields-subtitle">
            Description
          </Form.Label>
          <Form.Control
            md={12}
            className="open-office-hours-fields-input"
            value={meetingDescription}
            onChange={(e) => { setMeetingDescription(e.target.value); }}
          />
        </Form.Group>
        <br></br>
        <br></br>
        {/* add the last input line */}
        <Row>
          <Overlay
            target={selectDateButton.current}
            show={showCalendar}
            placement="top-start"
          >
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  position: 'absolute',
                  color: 'white',
                  borderRadius: 3,
                  ...props.style,
                }}
              >
                <Calendar value={date} onChange={handleChange} />
              </div>
            )}
          </Overlay>
          <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
            <p className="small-text open-office-hours-fields-subtitle">
              Pick Dates*
            </p>
            {/* create onclick Date Select Button */}
            <Button
              variant="outline-light"
              className="btn-calendar primary"
              ref={selectDateButton}
              onClick={() => { setShowCalendar(!showCalendar); }}
            >
              {showDate}
            </Button>
          </Col>

          {/* create time selection input */}
          <Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}>
            <Row>
              <Form.Label className="small-text open-office-hours-fields-subtitle">
                Time*
              </Form.Label>
            </Row>
            <Row style={{ paddingLeft: '5px' }}>
              <Col
                style={{ paddingLeft: '5px', paddingRight: '5px' }}
                xs={5} sm={5} md={5} lg={12} xl={12} xxl={5}
              >
                <InputGroup size="sm">
                  <Form.Control
                    className="input-text-small open-office-hours-fields-input"
                    type="number"
                    max={12}
                    min={0}
                    value={fromTime.hour}
                    onChange={formatInput(0, 12, setFromTime, 'hour')}
                    onBlur={formatHour(setFromTime)}
                  />
                  <InputGroup.Text className="input-text-small open-office-hours-fields-input">
                    :
                  </InputGroup.Text>
                  <Form.Control
                    className="input-text-small open-office-hours-fields-input"
                    type="number"
                    max={59}
                    min={0}
                    value={fromTime.minute}
                    onChange={formatInput(0, 59, setFromTime, 'minute')}
                  />
                  <Form.Select
                    aria-label="AM"
                    className="small-text open-office-hours-fields-input"
                    size="sm"
                    value={fromTime.period}
                    onChange={(e) => { setFromTime((prev) => ({ ...prev, period: e.target.value })); }}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col
                style={{ paddingLeft: '5px', paddingRight: '5px' }}
                md={'auto'}
                className="primary"
              >
                &#8722;
              </Col>
              <Col
                style={{ paddingLeft: '5px', paddingRight: '5px' }}
                xs={5} sm={5} md={5} lg={12} xl={12} xxl={5}
              >
                <InputGroup size="sm">
                  <Form.Control
                    className="input-text-small open-office-hours-fields-input"
                    type="number"
                    max={12}
                    min={0}
                    value={toTime.hour}
                    onChange={formatInput(0, 12, setToTime, 'hour')}
                    onBlur={formatHour(setToTime)}
                  />
                  <InputGroup.Text className="input-text-small open-office-hours-fields-input">
                    :
                  </InputGroup.Text>
                  <Form.Control
                    className="input-text-small open-office-hours-fields-input"
                    type="number"
                    max={59}
                    min={0}
                    value={toTime.minute}
                    onChange={formatInput(0, 59, setToTime, 'minute')}
                  />
                  <Form.Select
                    aria-label="AM"
                    className="small-text open-office-hours-fields-input"
                    size="sm"
                    value={toTime.period}
                    onChange={(e) => { setToTime((prev) => ({ ...prev, period: e.target.value })); }
                    }
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <PopoutSelect
              stateOptions={defaultTimezones}
              isOpen={isSelectPopoutOpen}
              setIsOpen={setIsSelectPopoutOpen}
              setValue={(option) => {
                setTimezone(option.value);
              }}
              buttonComponent={
                <Row>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                    <img className="edit-timezone-image" src={Edit} onClick={() => setIsSelectPopoutOpen((prev) => !prev)} />
                  </Col>
                  <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                    <p className="small-text open-office-hours-text-paragraph">
                      Timezone: {`${timeZoneLabel}`}
                      {currentTimeZone !== timezone && 
                        ` (${startTime} your time)`
                      }
                    </p>
                  </Col>
                </Row>
              } />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <p
              className="primary open-office-hours-fields-link"
              onClick={generate}
            >
              Create Link
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
  return web;
};

export default MentorApplication;
