import React, { useState, useEffect } from 'react';
import { Container, Row, Col, InputGroup, Form } from 'react-bootstrap';
import { ReactComponent as Edit } from '../../assets/icon/snapbrillia_edit_icon.svg';
import { useAuth } from '../../context/authContext';
import { navigate } from '@reach/router';
import Submitbutton from '../../shared/button/submit';
import { ListGroup } from 'react-bootstrap';

const ChangeEmail = ({ updatableEmail }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [email, setEmail] = useState('');
  const { changeEmail } = useAuth();

  const onEditEmailClicked = (e) => {
    e.preventDefault();
    setShowEdit(true);
  };
  const onEditEmailFinished = (e) => {
    e.preventDefault();
    setEmail(updatableEmail);
    setShowEdit(false);
    //changeEmail(email, () => {
    //    setShowEdit(false);
    //    navigate('/signin');
    //});
  };

  useEffect(() => {
    setEmail();
    setShowEdit(false);
  }, []);

  return (
    <Container className="mx-auto" data-testid='edit-box'>
      <ListGroup>
        {!showEdit && (
          <ListGroup.Item>
            <Row>
              <Col sm={10}>{updatableEmail}</Col>
              <Col sm={2}>
                <div
                  className="edit-email"
                  onClick={(e) => onEditEmailClicked(e)}
                  data-testid = 'penEdit'
                >
                  <Edit />
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        )}
        {showEdit && (
          <Row>
            <Col sm={8}>
              <InputGroup className="mt-3">
                <Form.Control
                  type="text col-md-12 text-center"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={2}>
              <div
                className="col-md-12 text-center save-edit-email"
                onClick={(e) => onEditEmailFinished(e)}
                data-testid='inputBox'
              >
                <Submitbutton />
              </div>
            </Col>
          </Row>
        )}
      </ListGroup>
    </Container>
  );
};
export default ChangeEmail;
