import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TeamMentorIllustration from '../../assets/illustrations/snapbrillia_mentor_personal info_3D.svg';

import '../css/teamMentorHeader.css';

export default function JobHeader({ job }) {
  return (
    <Container fluid="lg">
      <Row>
        <Col xs={12} sm={12} md={7}>
          <Row>
            <div className="mentor-page-header">
              Applying for the job opportunity: <br />
            </div>
          </Row>
          <Row>
            <div className="team-mentor-note">
              Take charge in seeking and applying for job opportunities that
              align with your skills and interests. Demonstrate your initiative
              and self-motivation by showcasing your potential to contribute
              meaningfully to your future employer. Stand out among other
              candidates in the job market and make a lasting impression.
            </div>
          </Row>
          <Row>
            <div className="bounty-note">
              You are creating an application for the job titled:
              <span> </span>
              <a className="bounty-title font-weight-bold" href="#">
                {job?.title}
              </a>
            </div>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={5} className="d-none d-md-block ">
          <div>
            <img
              src={TeamMentorIllustration}
              alt="team_mentor_illustration"
              className="mentor-page-illus"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
