import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FeaturedGrantDes from './components/featuredGrantDes';
import Featured from './components/featured';
import './css/featuredGrantDes.css';
import './css/introGrants.css';
import GrantsList from './components/grantsList';
import NavBar from '../shared/menus/navBar';
import GrantSideMenu from './components/grantSideMenu';

const IntroGrants = () => {
  return (
    <div className="position-relative">
      <NavBar />
      <Container fluid>
        <FeaturedGrantDes />
        <Row>
          <Col md={3}>
            <GrantSideMenu />
          </Col>
          <Col>
            <Row>
              <Featured />
            </Row>
            <Row>
              <GrantsList />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IntroGrants;
