import React, { useState, useEffect, useMemo } from 'react';

//COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';

import AccessibilityImage from '../../assets/icon/snapbrillia_profile_accessibility_icon.svg';
import EmailIcon from '../../assets/icon/snapbrillia_email_us_icon.svg';
import Security from '../../assets/icon/snapbrillia_profile_security_icon.svg';
import Illustration from '../../assets/illustrations/snapbrillia_accessibility_illustration.svg';
import Close from '../../assets/icon/snapbrillia_close_icon.svg';

import ProfileAccessibilityText from '../profileAccessibilityText';
import NavBar from '../../shared/menus/navBar';
import SettingsSideMenu from '../../shared/menus/settingsSideMenu';

//CSS
import '../../shared/css/sharedStyles.css';
import '../css/profileSettings.css';
import '../../shared/css/bgColors.css';
import { useAuth } from '../../context/authContext';
import ChangeEmail from './ChangeEmail';
import { Link } from '@reach/router';
import CustomModal from '../../shared/modals/CustomModal';

export default function ProfileSettings() {
  const [component, setComponent] = useState('security-and-login');
  const [userFields, setUserFields] = useState({});

  const { auth } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setUserFields(auth.user);
  }, [auth]);

  const options = useMemo(
    () => [
      { label: 'Security and Login', icon: Security },
      { label: 'Accessibility', icon: AccessibilityImage },
    ],
    []
  );

  const accessibilityText =
    'Snapbrillia is committed to ensuring that all persons who may have one or more disabilities have easy and complete access to all the services which are available to all potential and existing...';
  const editEmailText =
    'This section is to allow the user to perform the activities of editing email, configuring multifactor authentication and viewing the session history on different devices.';

  return (
    <>
      <Container className="primarybg" fluid>
        <NavBar />
        <div className="container p-0">
          <Row>
            <div className="container mt-2">
              <h2 className="h2">User Settings</h2>
            </div>
            <Col>
              <BoxComponent
                title="Security and Login"
                boxText={editEmailText}
                modalBody={<ChangeEmail updatableEmail={auth.user.email}/>}
                actionText="Click the button to open edit Email modal:"
                buttonText="Edit your Email"
              />
            </Col>
            <Col>
              <BoxComponent
                title="Accessibility"
                boxText={accessibilityText}
                modalSize="lg"
                modalFullscreen={true}
                modalBody={<AccessibilityComponent />}
                actionText="Click the button to read model about accessibility:"
                buttonText="Read more..."
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

const BoxComponent = ({
  title,
  modalSize,
  modalBody,
  modalFullscreen,
  boxText,
  actionText,
  buttonText,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <Container className="accessibility-box bg-light">
      <Row>
        <Col className="off-chain-resume-box-title-section">
          <span className="h1 off-chain-resume-box-title">{title}</span>
        </Col>
      </Row>
      <Row className="accessibility-note">{boxText}</Row>
      <Row className="accessibility-action">
          <Col sm md={8} className="accessibility-current-file-text">{actionText}</Col>
          <Row sm md={4}>
            <button
              className="btn-primary accessibility-button"
              onClick={() => setIsOpenModal(true)}
            >
              {' '}
              {buttonText}
            </button>
          </Row>
      </Row>
      <CustomModal
        size={modalSize}
        show={isOpenModal}
        setShow={setIsOpenModal}
        title={title}
        body={modalBody}
        fullscreen={modalFullscreen}
      />
    </Container>
  );
};

// const SecurityAndLogin = ({ userFields }) => {
//   return (
//     <div>
//       <label className="h1 security_title pb-5 pt-3">
//         {' '}
//         Security and Login{' '}
//       </label>
//       <Row>
//         <Col xs={12} md={8} className="px-5">
//           <Row>
//             <Col xs={5} md={5}>
//               <Row>
//                 <ChangeEmail updatableEmail={userFields.email} />
//               </Row>
//             </Col>
//           </Row>
//         </Col>
//         <Col xs={12} md={4} className="security_right px-4">
//           {/* <ProfileSignedInDevices /> */}
//         </Col>
//       </Row>
//     </div>
//   );
// };

const AccessibilityComponent = () => {
  return (
    <div>
      <div className="h1">Accessibility</div>
      <div className="h4">
        SNAPBRILLIA&apos;S ACCESSIBILITY STATEMENT
      </div>
      <div>
        <div className="accessibility_text">
          <ProfileAccessibilityText />
        </div>
        <div className="accessibility_illustration">
          <img src={Illustration} />
        </div>
      </div>
    </div>
  );
};
