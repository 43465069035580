import _ from 'lodash';
import { useState } from 'react';
import * as OpportunityAPI from '../../api/opportunities';
import { toast } from 'react-toastify';

const JobDetailViewModel = () => {
  const [job, setJob] = useState({});
  const [similarjobs, setSimilarJobs] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const loadJobInfo = async (id) => {
    try {
      const job = await OpportunityAPI.getOpportunity(id);
      if (!_.isNil(job)) {
        setJob(job);
      }
    } catch (err) {
      toast(err.message);
    }
    setLoaded(true);
  };
  const loadSimilarJobs = async (id) => {
    try {
      const jobs = await OpportunityAPI.getSimilarOpportunity(id);
      if (_.isArray(jobs) && !_.isEmpty(jobs)) {
        setSimilarJobs(jobs);
      }
    } catch (err) {
      toast(err.message);
    }
  };
  return {
    job,
    loaded,
    loadJobInfo,
    similarjobs,
    loadSimilarJobs,
  };
};
export default JobDetailViewModel;
