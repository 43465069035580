import axios from 'axios';

export async function getAllJobs() {
  try {
    const response = await axios.get('/api/v1/jobs/allJobs');
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getPaginationJobs(page) {
  try {
    const response = await axios.get(`/api/v1/jobs/allJobs?page=${page}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getFeatureJobs(limit) {
  try {
    const response = await axios.get(
      `/api/v1/jobs/featuredJobs?limit=${limit}`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getJob(id) {
  try {
    const response = await axios.get(`/api/v1/jobs/${id}/details`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
export async function getSimilarJobs(id) {
  try {
    const response = await axios.get(`/api/v1/jobs/${id}/similar`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function jobExpressInterest(id, body) {
  try {
    const response = await axios.post(`/api/jobs/${id}/express-interest`, body);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getJobsByStatus(applicant, status) {
  try {
    const query = encodeURIComponent(JSON.stringify(status.join(',')));
    const response = await axios.get(
      `/api/v1/jobapplicant/${applicant}/applications?status=${query}`
    );

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getCompanyLogo(id) {
  try {
    const response = await axios.get(`/api/companies/${id}/get-logo`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getAllMyJobAssessments({ status = '' }) {
  try {
    const response = await axios.get(
      `/api/my-job-assessments?status=${status}`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function submitJobWork(id, result) {
  try {
    const response = await axios.post(`/api/job-assessment/${id}/submit`, {
      result,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function submitJobAssessment(id) {
  try {
    const response = await axios.put(
      `/api/job-assessment/${id}/submit-assessment`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function rewardIndividual(id) {
  try {
    const response = await axios.post(`/api/job-assessment/${id}/b2c-reward`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function payoutJobReward(data) {
  try {
    const response = await axios.post('/api/job-assessment/payout/bank', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function feedBack(data) {
  try {
    const response = await axios.post('/api/user/feedback-create', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
export async function getJobApplicant(id) {
  try {
    const response = await axios.get(`/api/v1/jobapplicant/${id}`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getAllSkills() {
  try {
    const response = await axios.get(`/api/v1/skills`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function updateJobApplicant(id, data) {
  try {
    const response = await axios.put(`/api/v1/jobapplicant/${id}`, data);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function applyForjob(jobId) {
  try {
    const response = await axios.post(
      `/api/v1/jobapplicant/${jobId}/application/`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getAllFields() {
  try {
    const response = await axios.get(`/api/v1/skills/fields`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function createField(data) {
  try {
    const response = await axios.post(`/api/v1/skills/field`, data);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function createSkill(fieldId, data) {
  try {
    const response = await axios.post(`/api/v1/skills/field/${fieldId}`, data);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getJobApplication(jobId, applicantId) {
  try {
    const response = await axios.get(
      `/api/v1/jobapplicant/${jobId}/application/${applicantId}`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function submitEEOForm(applicant, eeoForm) {
  try {
    const response = await axios.post(
      `/api/v1/jobapplicant/${applicant}/eeo/`,
      eeoForm
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function editEEOForm(applicant, eeoFormId, eeoForm) {
  try {
    const response = await axios.put(
      `/api/v1/jobapplicant/${applicant}/eeo/${eeoFormId}`,
      eeoForm
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getEEOForm(applicant) {
  try {
    const response = await axios.get(`/api/v1/jobapplicant/${applicant}/eeo`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function changeApplicationStatus(jobId, applicationId, status) {
  ///:job/application/:id
  try {
    const response = await axios.put(
      `/api/v1/jobapplicant/${jobId}/application/${applicationId}`,
      { status: status }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function searchSkills(searchString) {
  const response = await axios.get('/api/skills?q=' + searchString);
  return response.data;
}
