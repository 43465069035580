import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../shared/css/textColors.css';
import '../css/teamList.css';
import { UserImage } from '../../shared/images';
import * as teamApi from '../../api/teams';
import { useParams } from '@reach/router';
import { timeAgo } from '../../shared/utils.js';

function TeamProgress({ team }) {
  const [mentees, setMentees] = useState([]);
  const { id } = useParams();
  const loadTeamSummary = async () => {
    try {
      const teamInfo = await teamApi.getTeamSummary(id);
      setMentees(teamInfo);
    } catch (err) {}
  };

  useEffect(() => {
    loadTeamSummary();
  }, []);

  const openIDE = (assessment) => {
    window.open(`${assessment?.ideUrl}`, '_blank');
  };

  return (
    <Container fluid className="team-list-component">
      <Row className="mentee-list-container-large">
        {mentees?.length > 0 &&
          mentees.map((mentee, idx) => {
            return (
              <Row className="mentee-list-container" key={idx}>
                <Col md={4} className="mentee-list-col-spaceing">
                  <img
                    src={UserImage(mentee)}
                    className="mentee-list-logo align-self-center"
                    alt="MentorImg"
                  ></img>
                </Col>
                <Col md={4}>
                  <p className="name">{mentee?.fullName}</p>
                  <p className="title">Mentee</p>
                </Col>
                <Col>
                  <span>{mentee?.assessment?.push || 0}</span>
                  <p className="title">Push</p>
                </Col>
                <Col>
                  <span>{timeAgo(mentee?.assessment?.updatedAt)}</span>
                  <p className="title">Last Update</p>
                </Col>
                <Col>
                  {mentee?.assessment?.ideUrl && (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => openIDE(mentee?.assessment)}
                    >
                      Open IDE
                    </button>
                  )}
                </Col>
              </Row>
            );
          })}
      </Row>
    </Container>
  );
}

export default TeamProgress;
