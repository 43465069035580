import React, { useState } from 'react';
import {
  sendAdaToCustodialWallet,
  injectSignature,
  addToProjectRegistrationQueue,
} from '../../api/crypto';
import { navigate } from '@reach/router';

//COMPONENTS
import { Row, Col, Container } from 'react-bootstrap';
import GrantSetupMatching from './grantSetupMatching';
import GrantSetupTabs from './grantSetupTabs';
import Back from '../../assets/icon/snapbrillia_softwareengineerback_icon.png';
import NextBtn from '../../shared/button/next';
import { useAuth } from '../../context/authContext';
import { useCart } from '../../context/cartContext';
import BackButton from '../../shared/button/back';

//CSS
import '../css/grantReview.css';
import ConstructingGrantModal from '../../shared/modals/constructingGrantPaymentModal';
import { toast } from 'react-toastify';

//API
import { saveGrantDraft } from '../../api/grant';

const GrantReview = ({
  grant,
  review,
  setReview,
  createGrant,
  networkBusy,
  setNetworkBusy,
  queueModalLoading,
  setQueueModalLoading,
  projectLogoSerialized,
  teamMember,
  funders,
}) => {
  const { auth } = useAuth();
  const { connectedWalletName, cardanoPubKeyAddress, isStaging } = useCart();
  const [showModal, setShowModal] = useState(false);

  // should save to draft
  const handleTryLater = () => {
    setShowModal(false);
    setNetworkBusy(false);
  };

  const handleGrantBackButton = () => {
    return setReview(false);
  };

  const handleSaveAsDraftButton = async () => {
    try {
      await saveGrantDraft(grant);
      navigate(`/grants`);
      toast('Draft saved');
    } catch (error) {
      toast('Failed to save draft');
    }
  };

  // 3 ada for onchain registration , .47 ada for facilatating transaction
  const handleRegistrationQueue = async () => {
    try {
      let grantInfo = {
        ...grant,
      };
      grantInfo.projectLogo = projectLogoSerialized;
      setQueueModalLoading(true);
      const walletApi = await window.cardano[
        connectedWalletName.toLowerCase()
      ].enable();
      const rawUTxOs = await walletApi.getUtxos();
      const changeAddress = await walletApi.getChangeAddress();
      const builtTransaction = await sendAdaToCustodialWallet(
        3470000,
        rawUTxOs,
        cardanoPubKeyAddress,
        changeAddress,
        isStaging
      );
      const vKeyWitness = await walletApi.signTx(builtTransaction, true);
      const signedTx = await injectSignature(vKeyWitness, builtTransaction);
      await walletApi.submitTx(signedTx);
      await addToProjectRegistrationQueue(grantInfo, changeAddress, isStaging);
      navigate(`/grants`);
      toast('Adding to queue');
    } catch (error) {
      setQueueModalLoading(false);
      toast('Failed to add to queue');
    }
  };

  return (
    <>
      <ConstructingGrantModal
        showModal={showModal}
        networkBusy={networkBusy}
        handleTryLater={handleTryLater}
        handleTransactionQueue={handleRegistrationQueue}
        queueModalLoading={queueModalLoading}
        createGrant={createGrant}
      />
      <Container fluid className="grant-review-container">
        <div className="px-5 pb-5 grant-review-main">
          <Row onClick={handleGrantBackButton} className="grant-review-back">
            <div className="grant-review-back-button w-auto">
              <img src={Back} alt="back-icon" />
            </div>
            Back To Grant Creation
          </Row>
          <Row>
            <GrantSetupMatching user={auth.user} grant={grant} />
          </Row>
          <div className="grant-set-review-buttons-container">
            <BackButton
              btnText={'Save as draft'}
              onClick={handleSaveAsDraftButton}
            />
            <NextBtn text={'Confirm'} onClick={() => setShowModal(true)} />
          </div>
          <Row>
            <Col xs={9}>
              <GrantSetupTabs
                review={review}
                grant={grant}
                teamMember={teamMember}
                funders={funders}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default GrantReview;
