import axios from 'axios';

export async function getChats(opportunityId, participant) {
  const response = await axios.get(
    `/api/v1/chats/customer?opportunityId=${opportunityId}&participant=${participant}`
  );
  return response.data;
}
export async function getLatestChats(opportunityId, participant, lastRead) {
  const response = await axios.get(
    `/api/v1/chats/customer?opportunityId=${opportunityId}&participant=${participant}`
  );
  return response.data;
}
export async function sendChat(chatData) {
  const response = await axios.post('/api/v1/chats/customer', chatData);
  return response.data;
}
export async function getOwners() {
  const response = await axios.get(`/api/v1/chats/owners`);
  return response.data;
}
