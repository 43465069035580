import { GrantImage } from '../../shared/images';
import { getGrantsRatio } from '../../shared/utils';

const GrantLeaderboardBody = ({ grants, showAfterFees, prevGrants }) => {
  const previousDonationChanged = (grant) => {
    const prevGrant = prevGrants.find((x) => x._id === grant._id);
    if (!prevGrant) {
      return;
    }
    return prevGrant.currentAmount !== grant.currentAmount;
  };

  const previousMatchPoolChanged = (grant) => {
    const prevGrant = prevGrants.find((x) => x._id === grant._id);
    if (!prevGrant) {
      return;
    }
    return prevGrant.matchPoolAmount !== grant.matchPoolAmount;
  };

  const previousTotalChanged = (grant) => {
    const prevGrant = prevGrants.find((x) => x._id === grant._id);
    if (!prevGrant) {
      return;
    }
    const grantTotal = grant.matchPoolAmount + grant.currentAmount;
    const prevGrantTotal = prevGrant.matchPoolAmount + prevGrant.currentAmount;
    return prevGrantTotal !== grantTotal;
  };

  return (
    <>
      {grants.map((grant, index) => {
        return (
          <tr key={grant._id} className="bold" role="button">
            <th scope="row" className="align-middle text-bold">
              <a
                href={`/grants/${grant._id}`}
                className="d-block text-decoration-none link-dark"
              >
                {index + 1}
              </a>
            </th>
            <td>
              <a
                href={`/grants/${grant._id}`}
                className="d-block text-decoration-none link-dark"
              >
                <img
                  className="img-fluid rounded align-middle"
                  src={GrantImage(grant)}
                  style={{ width: '40px' }}
                />{' '}
                {grant.name}
              </a>
            </td>
            <td className="align-middle">
              <a
                href={`/grants/${grant._id}`}
                className="d-block text-decoration-none link-dark"
              >
                {grant.fundraisingAmount} ₳DA
              </a>
            </td>
            <td
              className={`align-middle ${
                previousDonationChanged(grant) ? 'blink' : ''
              }`}
            >
              <a
                href={`/grants/${grant._id}`}
                className="d-block text-decoration-none link-dark"
              >
                {showAfterFees
                  ? (grant.currentAmount * 0.95).toFixed(2)
                  : grant.currentAmount}{' '}
                ₳DA
              </a>
            </td>
            <td
              className={`align-middle ${
                previousMatchPoolChanged(grant) ? 'blink' : ''
              }`}
            >
              <a
                href={`/grants/${grant._id}`}
                className="d-block text-decoration-none link-dark"
              >
                {showAfterFees
                  ? (grant.matchPoolAmount * 0.95).toFixed(2)
                  : grant.matchPoolAmount}{' '}
                ₳DA
              </a>
            </td>
            <td className="align-middle">
              <a
                href={`/grants/${grant._id}`}
                className="d-block text-decoration-none link-dark"
              >
                {getGrantsRatio(grant) >= 1 ? 'Eligible' : 'Not Eligible'}
              </a>
            </td>
            {getGrantsRatio(grant) >= 1 && (
              <td
                className={`align-middle ${
                  previousTotalChanged(grant) ? 'blink' : ''
                }`}
              >
                <a
                  href={`/grants/${grant._id}`}
                  className="d-block text-decoration-none link-dark"
                >
                  {showAfterFees
                    ? (
                        (grant.matchPoolAmount + grant.currentAmount) *
                        0.95
                      ).toFixed(2)
                    : grant.matchPoolAmount + grant.currentAmount}{' '}
                  ₳DA
                </a>
              </td>
            )}
            {getGrantsRatio(grant) < 1 && (
              <td
                className={`align-middle ${
                  previousTotalChanged(grant) ? 'blink' : ''
                }`}
              >
                <a
                  href={`/grants/${grant._id}`}
                  className="d-block text-decoration-none link-dark"
                >
                  {showAfterFees
                    ? (grant.currentAmount * 0.95).toFixed(2)
                    : grant.currentAmount}{' '}
                  ₳DA
                </a>
              </td>
            )}
          </tr>
        );
      })}
    </>
  );
};

export default GrantLeaderboardBody;
