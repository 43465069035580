import Modal from 'react-bootstrap/Modal';
import CashoutView from './components/cashoutView';
import React from 'react';
import { Container } from 'react-bootstrap';

const CashoutViewModal = ({ opportunity, showModal, onClose }) => {
  return (
    <Modal backdrop="static" show={showModal} onHide={onClose} centered>
      <Container>
        <Modal.Header className="modalHeader" closeButton>
          <Modal.Title>
            <span className="h2">Cash out</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CashoutView opportunity={opportunity} />
        </Modal.Body>
      </Container>
    </Modal>
  );
};
export default CashoutViewModal;
