import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImproveToolTip from '../../bounties/components/improveToolTip';
import NavBar from '../../shared/menus/navBar';
import IndividualWorkTabs from './individualWorkTabs';
import { navigate } from '@reach/router';
import { useParams } from '@reach/router';
import BackIcon from '../../assets/icon/snapbrillia_softwareengineerback_icon.png';
import Loading from '../../jobs/components/loading';
import _ from 'lodash';
import { getAssessments } from '../../api/opportunities';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/authContext';

function IndividualWorkPage(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [opportunity, setOpportunity] = useState([]);
  const { auth } = useAuth();

  useEffect(() => {
    setLoading(true)
    if(!_.isNull(props.location.state)){
      setOpportunity(props.location.state);
      setLoading(false);
    }else{
      setLoading(true)
      getAssessment();
    }
  }, [])

  const getAssessment = async () => {
    setLoading(true);
    try {
      const assessment = await getAssessments(id);
      if (assessment?.length > 0) {
        const filteredAssessment = assessment.filter(
          (val) => val.candidateId._id === auth.user._id
        );
        setOpportunity(filteredAssessment[0])
      }else{
        navigate('/opportunities');
      }
      setLoading(false);
    } catch (error) {
      toast('something went wrong');
      navigate('/opportunities');
    }
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <Loading />
      ) : (
        <Container fluid className="px-5 pb-5">
          <Row className="mb-3">
            <Col xs={12} lg={12}>
              <span className="pointer link" onClick={() => navigate('/teams')}>
                <img
                  src={BackIcon}
                  alt="back-icon"
                  className="mb-1 me-1"
                  width={10}
                />
                <span className="primary ms-1">Back to Work</span>
              </span>
            </Col>
          </Row>
          <Row>
            <ImproveToolTip opportunity={opportunity} />
          </Row>
          <Row className="mt-5">
            <IndividualWorkTabs opportunity={opportunity} />
          </Row>
        </Container>
      )}
    </>
  );
}

export default IndividualWorkPage;
