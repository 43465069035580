import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from '@reach/router';

import '../css/noTeam.css';
import '../../shared/css/textColors.css';

export default function NoTeam({ clicked }) {
  return (
    <Container className="no-team-container">
      <Row className="primary no-team-title">
        <div>{`You don't have any ${
          clicked === 'In-Progress'
            ? 'In-Progress'
            : clicked === 'Submitted'
            ? 'Submitted'
            : clicked === 'Completed'
            ? 'Completed'
            : ''
        } work yet!`}</div>
      </Row>

      <Row>
        <div className="no-team-content small-text">
          Take one of our{' '}
          <Link to="/opportunities">
            <span className="no-team-bounties ui-text-black">
              gigs or apply for jobs
            </span>
          </Link>{' '}
          to join work
        </div>
      </Row>
    </Container>
  );
}
