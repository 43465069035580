/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import image from '../../assets/icon/snapbrillia_team_page_illustration.png';

import '../../shared/css/buttonStyles.css';
import '../css/teamsHeader.css';

const teamsHeader = () => (
  <Container fluid>
    <Row data-testid="intro">
      <Col xs={12} md={7}>
        <Row className="teams_header_title">Work</Row>
        <Row className="teams_header_context">
          Welcome to your Work hub! This is where you collaborate on projects
          from gigs or job opportunities, making a lasting impact. 
          <br/>
          <br/>
          Explore
          projects you’re part of, connect with team members, and share
          insights. Your contributions shape the future of innovation, whether
          as a Mentor, Mentee, or an Individual. 
          <br/>
          <br/>
          Stay updated on project
          developments, dive into your opportunities, and collaborate with
          others, ready to make a significant impact!
          {/* {
            'Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah '
          } */}
        </Row>
      </Col>
      <Col xs={12} md={5} className="d-none d-sm-block text-center">
        <img src={image} data-testid="desc-img" />
      </Col>
    </Row>
  </Container>
);

export default teamsHeader;
