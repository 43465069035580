import { useState } from 'react';
import '../css/leaderboardOverviewData.css';
import ContributeMatchPoolModal from '../../shared/modals/matchPoolModal';
import {
  contributeToMatchPool,
  sendContributeToMatchPool,
  sendAdaToCustodialWallet,
  injectSignature,
  addToMatchPoolQueue,
} from '../../api/crypto';
import { useCart } from '../../context/cartContext';
import { toast } from 'react-toastify';
import { lovelaceInOneADA } from '../../shared/constants';
import { useGrant } from '../../context/grantsContext';

const LeaderboardOverviewData = ({
  dataLoading,
  grantsList,
  matchPoolAmount,
  totalDonationCount,
  largestMatchPoolReward,
  totalRaised,
  projectEligiability,
}) => {
  const {
    connectedWalletName,
    isStaging,
    cardanoPubKeyAddress,
    cardanoWalletConnected,
  } = useCart();
  const { currentFundingRound } = useGrant();
  const [showMatchPoolModal, setShowMatchPoolModal] = useState(false);
  const [contributeAmount, setContributeAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [networkBusy, setNetworkBusy] = useState(false);
  const registeredGrants = grantsList.length;

  const handleContributeToMatchPool = async () => {
    try {
      setNetworkBusy(false);
      if (contributeAmount <= 1) {
        toast('Minimum contribution to match pool amount is 1 ADA');
        return;
      }
      setLoading(true);
      const walletApi = await window.cardano[
        connectedWalletName.toLowerCase()
      ].enable();
      const rawUTxOs = await walletApi.getUtxos();
      const changeAddress = await walletApi.getChangeAddress();
      const transaction = await contributeToMatchPool(
        changeAddress,
        rawUTxOs,
        contributeAmount,
        isStaging
      );
      if (transaction.networkBusy) {
        setNetworkBusy(true);
        setLoading(false);
        return;
      }
      const vKeyWitness = await walletApi.signTx(transaction, true);
      const txHash = await sendContributeToMatchPool(
        transaction,
        vKeyWitness,
        contributeAmount,
        isStaging,
        currentFundingRound
      );
      if (txHash.networkBusy) {
        setNetworkBusy(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      toast('Successfully contributed to match pool');
      setShowMatchPoolModal(false);
    } catch (err) {
      toast(err.error || 'Failed to contribute to match pool');
      setLoading(false);
    }
  };

  const sendQueuedMatchPoolContribution = async () => {
    try {
      setLoading(true);
      const walletApi = await window.cardano[
        connectedWalletName.toLowerCase()
      ].enable();
      const rawUTxOs = await walletApi.getUtxos();
      const changeAddress = await walletApi.getChangeAddress();
      const sendToAddressAmount = contributeAmount * lovelaceInOneADA + 430000;
      const builtTransaction = await sendAdaToCustodialWallet(
        sendToAddressAmount,
        rawUTxOs,
        cardanoPubKeyAddress,
        changeAddress,
        isStaging
      );
      const vKeyWitness = await walletApi.signTx(builtTransaction, true);
      const signedTx = await injectSignature(vKeyWitness, builtTransaction);
      await walletApi.submitTx(signedTx);
      await addToMatchPoolQueue(
        contributeAmount,
        changeAddress,
        currentFundingRound,
        isStaging
      );
      toast('Adding to queue');
      setLoading(false);
      setShowMatchPoolModal(false);
    } catch (err) {
      toast(err.err || 'Failed to send queued match pool contribution');
      setLoading(false);
    }
  };

  const handleShowModal = () => {
    if (!cardanoWalletConnected) {
      toast('Please connect your wallet to contribute to match pool');
      return;
    }
    setShowMatchPoolModal(true);
    setNetworkBusy(false);
  };

  const handleTryLater = () => {
    setNetworkBusy(false);
    setLoading(false);
    setShowMatchPoolModal(false);
  };

  return (
    <div>
      <ContributeMatchPoolModal
        showModal={showMatchPoolModal}
        handleTryLater={handleTryLater}
        setContributeAmount={setContributeAmount}
        handleContributeToMatchPool={handleContributeToMatchPool}
        loading={loading}
        networkBusy={networkBusy}
        sendQueuedMatchPoolContribution={sendQueuedMatchPoolContribution}
      />
      <div
        className="card-header h3 bold border-0 leaderboard-grey-bg d-flex justify-content-between"
        style={{ borderRadius: '10px 10px 0px 0px' }}
      >
        Snapbrillia Quadratic Voting and Funding Overview
        <button className="btn-primary" onClick={handleShowModal}>
          Contribute To Match Pool
        </button>
      </div>
      <div className="card-body py-5 leaderboard-grey-bg round-bottom-corners">
        <div className="row row-eq-height">
          <div className="col-md-4">
            <div className="card mb-3 border-0 card-box-shadow">
              <div className="card-body">
                <h5 className="card-title leaderboard-info-color">
                  {dataLoading ? (
                    <div
                      className="spinner-border leaderboard-info-color"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    `${totalRaised} ₳DA`
                  )}
                </h5>
                <span className="pull-right text-muted">
                  Total Raised This Fund
                </span>
              </div>
            </div>
            <div className="card mb-3 border-0 card-box-shadow">
              <div className="card-body">
                <h5 className="card-title leaderboard-info-color">
                  {dataLoading ? (
                    <div
                      className="spinner-border leaderboard-info-color"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    `${largestMatchPoolReward} ₳DA`
                  )}
                </h5>
                <span className="pull-right text-muted">
                  Largest Match Pool Reward
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card mb-3 border-0 card-box-shadow">
              <div className="card-body">
                <h5 className="card-title leaderboard-info-color">
                  {dataLoading ? (
                    <div
                      className="spinner-border leaderboard-info-color"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    `${totalDonationCount} ₳DA`
                  )}
                </h5>
                <span className="pull-right text-muted">
                  Total Donations Amount
                </span>
              </div>
            </div>
            <div className="card mb-3 border-0 card-box-shadow">
              <div className="card-body">
                <h5 className="card-title leaderboard-info-color">
                  {dataLoading ? (
                    <div
                      className="spinner-border leaderboard-info-color"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    `${projectEligiability}`
                  )}
                </h5>
                <span className="pull-right text-muted">
                  Projects Elgible for Match Pool
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card mb-3 border-0 card-box-shadow">
              <div className="card-body">
                <h5 className="card-title leaderboard-info-color">
                  {dataLoading ? (
                    <div
                      className="spinner-border leaderboard-info-color"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    `${matchPoolAmount} ₳DA`
                  )}
                </h5>
                <span className="pull-right text-muted">
                  Match Pool Amount Availiable
                </span>
              </div>
            </div>
            <div className="card mb-3 border-0 card-box-shadow">
              <div className="card-body">
                <h5 className="card-title leaderboard-info-color">
                  {dataLoading ? (
                    <div
                      className="spinner-border leaderboard-info-color"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    `${registeredGrants}`
                  )}
                </h5>
                <span className="pull-right text-muted">Registered Grants</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardOverviewData;
