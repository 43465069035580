import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import _ from 'lodash';
import NavBar from '../shared/menus/navBar';
import JoinTeamIntro from './components/joinTeamIntro';
import SearchFilter from './components/searchFilter';
import SelectATeam from './components/selectATeam';
import '../shared/css/typography.css';
import '../shared/css/sharedStyles.css';
import '../shared/css/buttonStyles.css';
import { useParams } from '@reach/router';
import { useBounty } from '../hooks/useBounty';
import { toast } from 'react-toastify';
import { useAuth } from '../context/authContext';
import TeamsSelectionModal from './components/teamsSelectionModal';
import Loading from '../shared/Overlays/loading';
import { getAllTeams } from '../api/opportunities';

export default function JoinTeam() {
  const { auth } = useAuth();
  const { id } = useParams();
  const { bounty, assessment, loading } = useBounty(id);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [teamFilter, setTeamFilter] = useState({
    search: '',
    rating: 0,
    linkedin: '',
    discord: '',
    officeHours: '',
  });

  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);

  const loadTeams = async () => {
    try {
      const teams = await getAllTeams({
        opportunityId: id,
        status: 'In-Progress',
      });
      setTeams(teams);
    } catch (err) {
      toast(err.message);
    }
  };

  useEffect(() => {
    loadTeams();
  }, []);

  useEffect(() => {
    let filteredData = [...teams];
    for (let key in teamFilter) {
      if (key === 'search' && teamFilter.search) {
        filteredData = filteredData.filter(
          (team) =>
            team.mentor && team.mentor.fullName.includes(teamFilter.search)
        );
      } else if (teamFilter[key]) {
        filteredData = filteredData.filter((team) => team[key]);
      }
    }
    setFilteredTeams(filteredData);
  }, [teams, teamFilter]);
  return (
    <>
      <NavBar />
      {loading? <Loading/>
      : <>
      <TeamsSelectionModal
        setSelectedTeam={setSelectedTeam}
        team={selectedTeam}
        user={auth.user}
      />
      <Container fluid className="px-5 pb-5">
        <Row>
          <JoinTeamIntro bounty={bounty} />
        </Row>
        <Row>
          <Col lg={2} className="p-0">
            <SearchFilter
              teamFilter={teamFilter}
              setTeamFilter={setTeamFilter}
            />
          </Col>
          <Col lg={10}>
            <Row>
              <SelectATeam
                setSelectedTeam={setSelectedTeam}
                teams={filteredTeams}
                assessment={assessment}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </>}
    </>
  );
}
