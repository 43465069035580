import axios from 'axios';

// opportunities page api requests
export async function getAllOpportunities({
  page = 1,
  limit = 10,
  filter,
}) {
  const response = await axios.get(
    `/api/v1/opportunities/explore?page=${page}&limit=${limit}${filter}`
  );
  return response.data;
}

export async function featuredAllOpportunities() {
  const response = await axios.get(`/api/v1/opportunities/featured`);
  return response.data;
}

export async function getOpportunity(id) {
  const response = await axios.get(`/api/v1/opportunities/${id}/details`);
  return response.data;
}

export async function getSimilarOpportunity(id) {
  const response = await axios.get(
    `/api/v1/opportunities/similar/${id}`
  );
  return response.data;
}

// skills request routes

export const getAllSkills = async () => {
  try {
    const response = await axios.get('/api/v1/opportunities/skills');
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// opportunities application process requests

export const expressInterestOpportunity = async (id, data) => {
  try {
    const response = await axios.post(`/api/v1/opportunities/${id}/express`, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export async function getAllTeams(query) {
  // query = {bountyId: 'stringId', status: 'string'} status = new, missed, in-progress, completed, reviewed
  let filter = `opportunityId=${query.opportunityId}`;
  if (query.status) {
    filter += `&status=${query.status}`
  }
  const response = await axios.get(
    `/api/v1/opportunities/teams?${filter}`
  );
  return response.data;
}

export async function getAssessments(id) {
  try {
    const response = await axios.get(`/api/v1/opportunities/${id}/assessments`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

// get work opportunities list

export async function getOpportunitiesByStatus(status) {
  try {
    const response = await axios.get(
      `/api/v1/opportunities/work?status=${status}`
    );

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

// EEO Form

export async function submitEEOForm(applicant, eeoForm) {
  try {
    const response = await axios.post(
      `/api/v1/opportunities/${applicant}/eeo/`,
      eeoForm
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function editEEOForm(applicant, eeoFormId, eeoForm) {
  try {
    const response = await axios.put(
      `/api/v1/opportunities/${applicant}/eeo/${eeoFormId}`,
      eeoForm
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getOpportunityEEOForm(applicant) {
  try {
    const response = await axios.get(`/api/v1/opportunities/${applicant}/eeo`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function submitOpportunity(id, data) {
  try {
    const response = await axios.post(`/api/v1/opportunities/${id}/submit`, data);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}