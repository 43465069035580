import {useState, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import '../../css/maskDropdown.css';

import SelectCodeArea from "./SelectCodeArea";
import PhoneNumberField from "./PhoneNumberField";

import {phoneMasks} from '../../../utils/phoneMasks';

const PhoneNumber = ({countryCallingCode, setCountryCallingCode, phoneNumber, setPhoneNumber, countryFlagCode, setCountryFlagCode}) => {

    const [schema, setSchema] = useState('');
    const [showMaskDropdown, setShowMaskDropdown] = useState(false);
    const [masksList, setMaskList] = useState(null);
    

    useEffect(() => {
        if (phoneMasks[countryCallingCode]?.mask.length === 1) {
            setSchema(phoneMasks[countryCallingCode].mask[0]);
            setShowMaskDropdown(false);
        } else if (phoneMasks[countryCallingCode]?.mask.length > 1) {
            setShowMaskDropdown(true);
            setSchema('');
            createMasksList();
        }
    }, [countryCallingCode, countryFlagCode]);

    const handleMaskSelect = value => {
        setSchema(value);
    }

    const createMasksList = () => {
        const masks = phoneMasks[countryCallingCode]?.mask.map(mask => {
            return (
                <Dropdown.Item eventKey={mask} key={mask} >
                    {mask.replace(/#/g, 5)}
                </Dropdown.Item>
            );
        });
        setMaskList(masks);
    };

    return (
        <>
            <SelectCodeArea 
                defaultValue={countryCallingCode}
                setCallingCode={setCountryCallingCode}
                setFlagCode={setCountryFlagCode}
            />
            
            {showMaskDropdown && 
            (<Dropdown onSelect={handleMaskSelect}>
                <Dropdown.Toggle className="mask-dropdown"></Dropdown.Toggle>
                <Dropdown.Menu>
                    {masksList}
                </Dropdown.Menu>
            </Dropdown>)}
            
            <PhoneNumberField 
                schema={schema} 
                phoneNumber={phoneNumber}  
                setPhoneNumber={setPhoneNumber}
            />
        </>
    );
}

export default PhoneNumber;