import React from 'react';
import '../css/policyComponents.css';

/*eslint-disable max-lines*/

const CookiePolicyText = () => {
  return (
    <div className="policy-wrapper policy-typography">
      <div className="main-policy-container" data-testid='cookiePolicyComponent'>
        <div>
          <p className="ck32 ck33">
            <span className="ck8 ck28" />
          </p>
        </div>
        <p className="ck15">
          <span className="ck13 ">Cookie Policy</span>
        </p>
        <p className="ck16">
          <span className=" ">
            This website uses cookies and similar technologies such as HTML
            cookies and pixel tags (together “cookies”). We use cookies to help
            deliver the content of our Sites for authentication purposes (to
            keep track of the fact that you have logged in) and to analyze use
            of and improve the Sites and Services. We also use cookies to
            personalize content and ads, to provide social media features. You
            can learn more about how we use Personal Information in our Privacy
            Policy.
          </span>
        </p>
        <p className="ck16">
          <span className=" ">
            We also allow certain social media, advertising and analytics
            partners to set cookies and collect information about your use of
            the Sites and Services. These third parties may collect your
            browsing and click history, including the functions you use and
            searches you perform using the Services; browser agent name and
            version; web pages you view; links you click; your IP address; the
            length of time you visit our Sites and or use our Services; and the
            referring URL, or the webpage that led you to our Sites. These third
            parties may combine information they collect from our Sites and
            Services with other information that you’ve provided to them, or
            that they’ve collected from your use of other websites and services.
          </span>
        </p>
        <p className="ck16">
          <span className=" ">
            This Site uses different types of cookies (Necessary, Preferences,
            Statistics, Marketing). You can change or withdraw your consent to
            our use of cookies at any time, or opt-out of Preferences,
            Statistics, and Marketing cookies.
          </span>
        </p>
        <p className="ck25">
          <span className=" ">
            Your current state: Use necessary cookies only.{' '}
          </span>
        </p>
        <p className="ck25">
          <span className="ck29">
            <a
              target="_blank"
              rel="noreferrer"
              className="ck24 "
              href="https://www.google.com/url?q=https://snapbrillia.com/%23&sa=D&source=editors&ust=1631060500130000&usg=AOvVaw1JzB6nD1J0A_Xf9tPhEjYM"
            >
              Change your consent
            </a>
          </span>
        </p>
        <p className="ck16 ck32">
          <span className="ck8 ck7" />
        </p>
        <p className="ck16">
          <span className=" ">
            You can also set your web browser to refuse all cookies or to
            indicate when a cookie is being sent. The “help” feature of the menu
            bar on most browsers will tell you how to stop accepting new
            cookies, how to receive notification of new cookies and how to
            disable existing cookies. However, certain features of the Sites or
            Services may not work if you delete or disable cookies.
          </span>
        </p>
        <p className="ck16">
          <span className=" ">
            The cookies we use are explained further below.
          </span>
        </p>
        <p className="ck16">
          <span className=" ">
            Cookie declaration last updated on 5/12/21 by{' '}
          </span>
          <span className="ck7">
            <a
              target="_blank"
              rel="noreferrer"
              className="ck24 "
              href="https://www.google.com/url?q=https://www.cookiebot.com/&sa=D&source=editors&ust=1631060500130000&usg=AOvVaw0IBtKHkb8sb2IxbnkyufPx"
            >
              Cookiebot
            </a>
          </span>
          <span
            style={{
              overflow: 'hidden',
              display: 'inline-block',
              margin: '0.00px 0.00px',
              border: '0.00px solid #000000',
              transform: 'rotate(0.00rad) translateZ(0px)',
              WebkitTransform: 'rotate(0.00rad) translateZ(0px)',
              width: '16.00px',
              height: '16.00px',
            }}
          >
            <img
              alt=""
              src="images/image1.png"
              style={{
                width: '16.00px',
                height: '16.00px',
                marginLeft: '0.00px',
                marginTop: '0.00px',
                transform: 'rotate(0.00rad) translateZ(0px)',
                WebkitTransform: 'rotate(0.00rad) translateZ(0px)',
              }}
              title
            />
          </span>
          <span className=" ">:</span>
        </p>
        <p className="ck9">
          <span className=" ck13 ">Necessary (19)</span>
        </p>
        <p className="ck9">
          <span className=" ">
            Necessary cookies help make a website usable by enabling basic
            functions like page navigation and access to secure areas of the
            website. The website cannot function properly without these cookies.
          </span>
        </p>
        <a id="t.872effb078646033b12e124aeedfeck9961af264f" />
        <a id="t.0" />
        <table className="ck2">
          <tbody>
            <tr className="ck17">
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className="">Name</span>
                </p>
              </td>
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className="">Provider</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className="">Purpose</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className="">Expiry</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className="">Type</span>
                </p>
              </td>
            </tr>
            <tr className="ck22">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">__cf_bm [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">g2.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">g2crowd.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    This cookie is used to distinguish between humans and bots.
                    This is beneficial for the website, in order to make valid
                    reports on the use of their website.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">__cfduid</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by the content network, Cloudflare, to identify trusted
                    web traffic.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">29 days</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck31">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">__stripe_mid</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    This cookie is necessary for making credit card transactions
                    on the website. The service is provided by Stripe.com which
                    allows online transactions without storing any credit card
                    information.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 year</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck31">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">__stripe_sid</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    This cookie is necessary for making credit card transactions
                    on the website. The service is provided by Stripe.com which
                    allows online transactions without storing any credit card
                    information.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck3">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_wp_session</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Preserves users states across page requests.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">CookieConsent [x3]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">help.snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Stores the user&aposs cookie consent state for the current
                    domain
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 year</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck21">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">intercom.played-notifications</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by the website to determine which support notifications
                    the user has been shown.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck21">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">intercom-id-???????? [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">help.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Intercom is a service we use to provide consumer support
                    through a popup window in our website.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">270 days</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck21">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">intercom-session-???????? [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">help.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Intercom is a service we use to provide consumer support
                    through a popup window in our website.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">6 days</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck3">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">JSESSIONID</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">nr-data.net</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Preserves users states across page requests.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck21">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">m</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">m.stripe.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Determines the device used to access the website. This
                    allows the website to be formatted accordingly.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">2 years</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck22">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">rc::a</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">google.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    This cookie is used to distinguish between humans and bots.
                    This is beneficial for the website, in order to make valid
                    reports on the use of their website.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">rc::c</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">google.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    This cookie is used to distinguish between humans and bots.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck3">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">visitorId</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">ws.zoominfo.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Preserves users states across page requests.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 year</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="ck9">
          <span className="ck13 ">Preferences (4)</span>
        </p>
        <p className="ck20">
          <span className=" ">
            Preference cookies enable a website to remember information that
            changes the way the website behaves or looks, like your preferred
            language or the region that you are in.
          </span>
        </p>
        <a id="t.14f93be7cedcck842e16107 5ck29635cfe737286" />
        <a id="t.1" />
        <table className="ck2">
          <tbody>
            <tr className="ck17">
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Name</span>
                </p>
              </td>
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Provider</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Purpose</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Expiry</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Type</span>
                </p>
              </td>
            </tr>
            <tr className="ck3">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">CookieConsentBulkSetting-#</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">consentcdn.cookiebot.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Enables cookie consent across multiple websites
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck3">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">lang [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">ads.linkedin.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">linkedin.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Remembers the user&aposs selected language version of a
                    website
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck21">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">loglevel</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Maintains settings and outputs when using the Developer
                    Tools Console on current session.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="ck9">
          <span className="ck13 ">Statistics (26)</span>
        </p>
        <p className="ck20">
          <span className=" ">
            Statistic cookies help website owners to understand how visitors
            interact with websites by collecting and reporting information about
            how users interact with the Site.
          </span>
        </p>
        <a id="t.1ck1a06e3667d1496345cf9a010d174d6033751cd" />
        <a id="t.2" />
        <table className="ck2">
          <tbody>
            <tr className="ck17">
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Name</span>
                </p>
              </td>
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Provider</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Purpose</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Expiry</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Type</span>
                </p>
              </td>
            </tr>
            <tr className="ck21">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_ga [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Registers a unique ID that is used to generate statistical
                    data on how the visitor uses the website.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">2 years</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck3">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_gat [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by Google Analytics to throttle request rate
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck21">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_gid [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Registers a unique ID that is used to generate statistical
                    data on how the visitor uses the website.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck22">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_hjAbsoluteSessionInProgress</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    This cookie is used to count how many times a website has
                    been visited by different visitors - this is done by
                    assigning the visitor an ID, so the visitor does not get
                    registered twice.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck18">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_hjFirstSeen</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    This cookie is used to determine if the visitor has visited
                    the website before, or if it is a new visitor on the
                    website.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck18">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_hjid [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Sets a unique ID for the session. This allows the website to
                    obtain data on visitor behaviour for statistical purposes.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 year</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_hjIncludedInPageviewSample [x3]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">explore.snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by hotjar to check a user behavior on website.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_hjIncludedInSessionSample [x3]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">explore.snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by hotjar to check a user behavior on website.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck18">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_hjRecordingLastActivity [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Sets a unique ID for the session. This allows the website to
                    obtain data on visitor behaviour for statistical purposes.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck18">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_hjTLDTest</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Registers statistical data on users&apos behaviour on the
                    website. Used for internal analytics by the website
                    operator.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">AnalyticssyncHistory</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">linkedin.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used in connection with data-synchronization with
                    third-party analysis service.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">29 days</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    autotrack:??-?????????-?:plugins/page-visibility-tracker
                  </span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by Google Analytics to support single page application.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">autotrack:??-?????????-?:session</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by Google Analytics to support single page application.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck18">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">hjViewportId [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Sets a unique ID for the session. This allows the website to
                    obtain data on visitor behaviour for statistical purposes.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck14">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">jserrors/1/#</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">bam.nr-data.net</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pending</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pixel Tracker</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="ck9">
          <span className="ck13 ">Marketing (31)</span>
        </p>
        <p className="ck20">
          <span className=" ">
            Marketing cookies are used to track visitors across websites. The
            intention is to display ads that are relevant and engaging for the
            individual user and thereby more valuable for publishers and third
            party advertisers.
          </span>
        </p>
        <a id="t.e8a49a03bf9d082820763f0076dck27d65df6ck601" />
        <a id="t.3" />
        <table className="ck2">
          <tbody>
            <tr className="ck17">
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Name</span>
                </p>
              </td>
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Provider</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Purpose</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Expiry</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Type</span>
                </p>
              </td>
            </tr>
            <tr className="ck18">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_fbp</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by Facebook to deliver a series of advertisement
                    products such as real time bidding from third party
                    advertisers.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">3 months</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck21">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_gcl_au</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by Google AdSense for experimenting with advertisement
                    efficiency across websites using their services.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">3 months</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck23">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_hjRecordingEnabled [x2]</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    This cookie is used to identify the visitor and optimize
                    ad-relevance by collecting visitor data from multiple
                    websites – this exchange of visitor data is normally
                    provided by a third-party data-center or ad-exchange.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck26">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_lfa</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used in context with Account-Based-Marketing (ABM). The
                    cookie registers data such as IP-addresses, time spent on
                    the website and page requests for the visit. This is used
                    for retargeting of multiple users rooting from the same
                    IP-addresses. ABM usually facilitates B2B marketing
                    purposes.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">2 years</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck14">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_lfa_#</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pending</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">_lfa_expiry</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Contains the expiry-date for the cookie with corresponding
                    name.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">bcookie</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">linkedin.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by the social networking service, LinkedIn, for
                    tracking the use of embedded services.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">2 years</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">bscookie</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">linkedin.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by the social networking service, LinkedIn, for
                    tracking the use of embedded services.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">2 years</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck18">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">fr</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">facebook.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by Facebook to deliver a series of advertisement
                    products such as real time bidding from third party
                    advertisers.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">3 months</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck22">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">https://tr.lfeeder.com/</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">tr.lfeeder.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Tracks the individual sessions on the website, allowing the
                    website to compile statistical data from multiple visits.
                    This data can also be used to create leads for marketing
                    purposes.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pixel Tracker</span>
                </p>
              </td>
            </tr>
            <tr className="ck23">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">IDE</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">doubleclick.net</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by Google DoubleClick to register and report the
                    website user&aposs actions after viewing or clicking one of
                    the advertiser&aposs ads with the purpose of measuring the
                    efficacy of an ad and to present targeted ads to the user.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 year</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">lidc</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">linkedin.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by the social networking service, LinkedIn, for
                    tracking the use of embedded services.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck23">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">pagead/landing</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">doubleclick.net</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Collects data on visitor behaviour from multiple websites,
                    in order to present more relevant advertisement - This also
                    allows the website to limit the number of times that they
                    are shown the same advertisement.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pixel Tracker</span>
                </p>
              </td>
            </tr>
            <tr className="ck22">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">RUL</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">doubleclick.net</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used by DoubleClick to determine whether website
                    advertisement has been properly displayed - This is done to
                    make their marketing efforts more efficient.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 year</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck3">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">test_cookie</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">doubleclick.net</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used to check if the user&aposs browser supports cookies.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">1 day</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck18">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">UserMatchHistory</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">linkedin.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Used to track visitors on multiple websites, in order to
                    present relevant advertisement based on the visitor&aposs
                    preferences.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">29 days</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">VISITOR_INFO1_LIVE</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Tries to estimate the users&apos bandwidth on pages with
                    integrated YouTube videos.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">179 days</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">YSC</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Registers a unique ID to keep statistics of what videos from
                    YouTube the user has seen.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">yt.innertube::nextId</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Registers a unique ID to keep statistics of what videos from
                    YouTube the user has seen.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">yt.innertube::requests</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Registers a unique ID to keep statistics of what videos from
                    YouTube the user has seen.
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">yt-remote-cast-installed</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Stores the user&aposs video player preferences using
                    embedded YouTube video
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">yt-remote-connected-devices</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Stores the user&aposs video player preferences using
                    embedded YouTube video
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">yt-remote-device-id</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Stores the user&aposs video player preferences using
                    embedded YouTube video
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">yt-remote-fast-check-period</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Stores the user&aposs video player preferences using
                    embedded YouTube video
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">yt-remote-session-app</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Stores the user&aposs video player preferences using
                    embedded YouTube video
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">yt-remote-session-name</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">youtube.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    Stores the user&aposs video player preferences using
                    embedded YouTube video
                  </span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="ck9">
          <span className="ck13 ">Unclassified (10)</span>
        </p>
        <p className="ck20">
          <span className=" ">
            Unclassified cookies are cookies that we are in the process of
            classifying, together with the providers of individual cookies.
          </span>
        </p>
        <a id="t.582375addd9ck8f38ck0472886740ck21b97259086d" />
        <a id="t.4" />
        <table className="ck2">
          <tbody>
            <tr className="ck17">
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Name</span>
                </p>
              </td>
              <td className="ck0" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Provider</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Purpose</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Expiry</span>
                </p>
              </td>
              <td className="ck11" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ck19">Type</span>
                </p>
              </td>
            </tr>
            <tr className="ck3">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    autotrack:??-?????????-?:plugins/page-visibility-tracker
                  </span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pending</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck3">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">autotrack:??-?????????-?:session</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pending</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
            <tr className="ck14">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">events/1/#</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">bam.nr-data.net</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pending</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pixel Tracker</span>
                </p>
              </td>
            </tr>
            <tr className="ck14">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">events_distinct_id</span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">g2.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pending</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Session</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTTP Cookie</span>
                </p>
              </td>
            </tr>
            <tr className="ck12">
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">
                    intercom.intercom-state-???????? [x3]
                  </span>
                </p>
              </td>
              <td className="ck6" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">help.snapbrillia.com</span>
                </p>
                <p className="ck1">
                  <span className=" ">app.snapbrillia.com</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Pending</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">Persistent</span>
                </p>
              </td>
              <td className="ck4" colSpan={1} rowSpan={1}>
                <p className="ck1">
                  <span className=" ">HTML Local Storage</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="ck27">
          <span className=" " />
        </p>
      </div>
    </div>
  );
};

export default CookiePolicyText;
