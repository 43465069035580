import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { navigate } from '@reach/router';
import JobPostingInfo from './components/jobPostingInfo';
import JobInfoTab from './components/jobInfoTab';
import BackIcon from '../assets/icon/snapbrillia_softwareengineerback_icon.png';
import NavBar from '../shared/menus/navBar';
import { useParams } from '@reach/router';
import useViewModel from '../jobsApplicant/viewModels/jobWorkViewModel';
import { useAuth } from '../context/authContext';
import _ from 'lodash';
import Loading from '../jobs/components/loading';
import { getAssessments } from '../api/opportunities';
import { toast } from 'react-toastify';

function JobWorkPage(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [application, setOpportunity] = useState([]);
  const { auth } = useAuth();

  useEffect(() => {
    if (!_.isNull(props.location.state)) {
      setOpportunity(props.location.state);
      setLoading(false);
    } else {
      getAssessment();
    }
  }, []);

  const getAssessment = async () => {
    setLoading(true);
    try {
      const assessment = await getAssessments(id);
      if (assessment.length > 0) {
        const filteredAssessment = assessment.filter(
          (val) => val.candidateId._id === auth.user._id
        );
        setOpportunity(filteredAssessment[0]);
      }else{
        navigate('/opportunities');
      }
      setLoading(false);
    } catch (error) {
      toast('something went wrong');
      navigate('/opportunities');
    }
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <Loading />
      ) : (
        application &&
        !_.isEmpty(application) && (
          <Container fluid className="px-5 pb-5">
            <Row className="mb-3">
              <Col xs={12} lg={12}>
                <span
                  className="pointer link"
                  onClick={() => navigate('/teams')}
                >
                  <img
                    src={BackIcon}
                    alt="back-icon"
                    className="mb-1 me-1"
                    width={10}
                  />
                  <span className="primary ms-1">Back to Work</span>
                </span>
              </Col>
            </Row>
            <Row>
              <JobPostingInfo application={application} />
            </Row>
            <Row>
              <JobInfoTab application={application} />
            </Row>
          </Container>
        )
      )}
    </>
  );
}

export default JobWorkPage;
