import Modal from 'react-bootstrap/Dropdown';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStripe } from '@stripe/react-stripe-js';

export default function StripeModal({
  clientSecret,
  attachBank,
  reloadAccount,
}) {
  const stripe = useStripe();

  const connectBankAccount = () => {
    stripe
      .collectBankAccountToken({
        clientSecret: clientSecret,
      })
      .then(function (result) {
        if (result.error) {
          toast.error(result.error.message);
        } else if (result.token) {
          attachBank(result.token).then(function (result) {
            if (result) {
              toast('Connect bank successfully');
              reloadAccount();
            }
          });
        }
      });
  };

  useEffect(() => {
    if (clientSecret) {
      connectBankAccount();
    }
  }, [clientSecret]);
  return <Modal></Modal>;
}
