import axios from 'axios';

export async function getListNotifications() {
  try {
    const response = await axios.get(`/api/notifications`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
export async function getAllUserNotifications(id) {
  try {
    const response = await axios.get(`/api/all-notifications`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
export async function getOlderListNotifications() {
  try {
    const response = await axios.get(`/api/notifications/older`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
export async function readNotification(id) {
  try {
    const response = await axios.post(`/api/notifications/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
export async function readAllNotifications(id) {
  try {
    const response = await axios.post(`/api/notifications/read-all/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
